import type { Article } from "../../../../../types";

export const Article632: Article = {
	id: 632,
	rsuiteId: "05313d62-4e2a-4526-a8ae-3ce18d1159ac",
	type: "scientific article",
	title:
		"Effect of Ketorolac Administration on the Rate of Nonunion of Operatively Treated Pediatric Long-Bone Fractures",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=05313d62-4e2a-4526-a8ae-3ce18d1159ac&type=jpeg&name=JBJS.23.01225f1",
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Effect of Ketorolac Administration on the Rate of
                Nonunion of Operatively Treated Pediatric Long-Bone Fractures</div>
              <div class="text subtitle" style="position: unset;">A Matched Cohort Analysis</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Daniel E.
                      Pereira, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Caleb Ford,
                      MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 7 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_48" class="annotation strong"
                          style="position: unset;">Background:</span> Nonunion is a rare yet serious complication in
                        pediatric fracture healing that can lead to patient morbidity and economic burden. The
                        administration of nonsteroidal anti-inflammatory drugs (NSAIDs) has been associated with an
                        increased risk of fracture nonunion in adults, but data are lacking in the pediatric population.
                        This study examines the relationship between postoperative ketorolac administration and nonunion
                        in operatively managed pediatric long-bone fractures.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_49" class="annotation strong"
                          style="position: unset;">Methods:</span> A retrospective cohort study was conducted with use
                        of TriNetX, a research network that encompasses data from the United States, Canada, and Western
                        Europe. A total of 462,260 patients from 52 health-care organizations met the inclusion
                        criteria. Patients &lt;18 years old with operatively managed upper or lower-extremity long-bone
                        fractures were included. The exposure of interest was ketorolac administration within 30 days
                        postoperatively between 2003 and 2023. Nonunion was identified and verified with use of the
                        pertinent medical codes. Absolute risks and hazard ratios (HRs) were calculated for both study
                        groups. Significance was set at p &lt; 0.05.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_50" class="annotation strong"
                          style="position: unset;">Results:</span> After propensity score matching, 48,778 patients were
                        identified per group. The incidence of nonunion was 2.19% in the ketorolac group and 0.93% in
                        the non-ketorolac group (HR, 2.71; 95% confidence interval [CI]: 2.46, 3.21; p &lt; 0.0001).
                        Subgroup analyses demonstrated a higher risk of nonunion in patients with lower-extremity
                        fractures (HR, 3.45; 95% CI: 3.14, 3.75; p &lt; 0.0001) than in those with upper-extremity
                        fractures (HR, 2.11; 95% CI: 1.84, 2.32; p &lt; 0.0001). Among the fracture location subgroups,
                        the greatest HR for nonunion was observed in patients with femoral fractures, followed
                        sequentially by those with tibial and/or fibular fractures, humeral fractures, and radial and/or
                        ulnar fractures.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_51" class="annotation strong"
                          style="position: unset;">Conclusions:</span> To our knowledge, this is the largest study to
                        date to explore postoperative ketorolac use and nonunion in the setting of operatively managed
                        pediatric long-bone fractures. Nonunion in children was rare, occurring in &lt;1% of all
                        included patients. Ketorolac administration was associated with a 2 to 3-fold increase in
                        nonunion risks, with pronounced implications for patients with lower-extremity fractures,
                        particularly those with femoral fractures. Clinicians should weigh the therapeutic advantages of
                        non-opiate analgesia with ketorolac against the risk of nonunion in order to optimize
                        postoperative pain management and recovery.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_52" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Nonunion signifies the inability of the body to heal a
                  fracture as a result of an internal pathology or external influence<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">2</span></a>.
                  Fracture nonunion is a rare occurrence in the pediatric population. A fracture that persists unhealed
                  at 9 months after the injury, with no evident progression toward healing over a 3-month period, is a
                  widely accepted definition for nonunion, although the precise definition can vary<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">4</span></a>. The
                  incidence of pediatric nonunion is low relative to that in adults. For example, Shrader et al.
                  documented only 43 pediatric nonunions over a span of 15 years at a Level-I trauma center<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">5</span></a>. Another investigation found a nonunion rate of 0.85% in a
                  cohort of 237,033 pediatric fractures<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a>.
                  Despite the infrequency of nonunion in the pediatric population, several risk factors have been
                  identified, including opioid prescription, vitamin-D deficiency, and surgical intervention<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">9</span></a>. The
                  importance of understanding the leading causes of nonunion cannot be understated, as nonunions can
                  lead to considerable patient morbidity, caregiver burden, and health-care cost<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">9</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The relationship between nonsteroidal anti-inflammatory
                  drug (NSAID) administration and fracture nonunion is a topic of debate. NSAIDs are often administered
                  following surgery in order to control acute postoperative pain. The proposed pathophysiology by which
                  NSAIDs may promote nonunion is by inhibiting cyclooxygenase isozyme activity, which decreases
                  prostaglandin production. Prostaglandins increase osteoblast activity and promote angiogenesis—both of
                  which are essential for osseous healing<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">12</span></a>. Accordingly, studies have noted an increased nonunion risk
                  with the postoperative administration of NSAIDs, particularly with prolonged use<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">13</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">14</span></a>. A common NSAID often utilized in the postoperative period
                  is ketorolac (Toradol), as it offers strong analgesic effects when utilized as an adjunct to opiates
                  and is well tolerated, especially in pediatric patients<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">15</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">17</span></a>. Despite various studies exploring the impact of NSAIDs on
                  fracture healing, the evidence remains mixed: some studies have demonstrated delayed healing, whereas
                  others have suggested that there is no impact when NSAIDs are utilized for short-term pain management
                  in skeletally mature patients<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">24</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The effects of NSAIDs, particularly ketorolac, on
                  surgically treated pediatric long-bone fractures remain poorly understood. The available studies on
                  NSAID use following fracture treatment either focus on other medications, such as ibuprofen; have a
                  mixed population of nonoperatively and operatively treated fractures; or are too underpowered to be
                  generalizable given the rarity of nonunion in pediatric populations<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">28</span></a>. Our objective was to investigate the impact of ketorolac
                  administration on the risk of nonunion in children undergoing surgical treatment for long-bone
                  fractures and to discern whether risk patterns emerge based on the general fracture location.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study Design
                and Data Source</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">We conducted a retrospective cohort study with use of
                  TriNetX, a research network that encompasses data from approximately 110 million patients across the
                  United States, Canada, and Western Europe. Within this dataset, 462,260 patients from 52 health-care
                  organizations satisfied the inclusion criteria of our study. TriNetX, which aggregates data from
                  various hospitals and public death registries, improves data quality by normalizing data formats and
                  units across health-care organizations, which reduces errors when comparing data from different
                  electronic health record (EHR) sources. The data import process employed by TriNetX converts diverse
                  data types into standardized formats, enhancing their reliability for comparative analysis<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">28</span></a>. We chose this network to overcome the sample size
                  limitations observed in previous studies. Although TriNetX enables indirect access to patient notes
                  and imaging via natural language processing models, we did not use these resources. Lastly, we
                  followed the STROBE (Strengthening the Reporting of Observational Studies in Epidemiology) guidelines
                  to guide data extraction and analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Complying with HIPAA (Health Insurance Portability and
                  Accountability Act) and ISO/IEC (International Organization for Standardization and International
                  Electrotechnical Commission) 27001:2013, TriNetX ensures rigorous safeguarding of health-care data.
                  Datasets generated by the TriNetX platform are deidentified in accordance with the deidentification
                  standard defined in Section 164.514(a) of the HIPAA Privacy Rule. As such, this study was exempt from
                  full institutional review board approval (IRBHSC20230367NHR).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Usage of
                Codes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The use of EHR data, as opposed to claims data, helps to
                  mitigate some database-related limitations. This approach facilitates patient tracking that extends
                  beyond the initial hospital visit, allowing for the follow-up of patients across other inpatient,
                  outpatient, and emergency room settings. The integration of Current Procedural Terminology (CPT) codes
                  and International Classification of Diseases, Ninth Revision (ICD-9) and Tenth Revision (ICD-10)
                  codes, coupled with General Equivalence Mapping 2 (GEMS2), effectively bridged ICD-9 codes from 2015
                  and earlier to their corresponding ICD-10 codes. This enhanced the precision of searches across
                  different health-care contexts and related codes, and amplified the sensitivity of the search
                  algorithms<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">34</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Index Event,
                Study Population, and Outcome</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Children &lt;18 years old with surgically managed upper or
                  lower-extremity long-bone fractures (i.e., excluding those in the hands and feet) were identified.
                  Patient groups were delineated by ketorolac exposure within the 30-day postoperative window. Nonunion,
                  our outcome of interest, was identified and defined according to the diagnostic coding documented in
                  the EHR by orthopaedic surgeons, trusting their adherence to standard criteria for diagnosis.
                  Operative management, or the index event, was utilized as the starting point of the follow-up period
                  for each patient. The dataset spans from 2003 to 2023 and includes surgeries performed no later than
                  2021 in order to facilitate 2 years of comprehensive follow-up. Follow-up for nonunion began at 90
                  days post-exposure in order to capture all diagnosed cases, accounting for anticipated variations in
                  the timing of a nonunion diagnosis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Propensity
                Score Matching (PSM)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">An initial regression model was conducted to identify the
                  factors influencing ketorolac administration. This model revealed significant (p &lt; 0.001)
                  differences in age, sex, race, body mass index (BMI), specific fracture patterns, and use of other
                  analgesics between the 2 groups. These variables were integrated into our PSM model, enhancing the
                  subsequent analysis. Furthermore, patients in the ketorolac and non-ketorolac groups were matched
                  according to their respective dates of surgery, which had to be no more than 2 months apart. Matching
                  was performed with use of a greedy matching algorithm with a caliper of 0.1. Balance between the
                  groups after matching was verified with use of standardized mean differences. An additional analysis
                  was conducted to compare the incidences of open versus closed fractures between the groups. We provide
                  both the pre- and post-matching data. Patient characteristics prior to matching can be found in the
                  Appendix.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Tools, Models, and Measures of Association</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Absolute risk identifies the probability of an event
                  occurring in each group, which is equivalent to the incidence of the condition within the targeted
                  cohort<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">35</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">36</span></a>. Conversely, hazard ratios (HRs), which we derived from our
                  Cox regression models, convey the risk over time, thereby representing the instantaneous risk<a
                    href="" data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">35</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">37</span></a>. Absolute risks provide a clear perspective on the
                  proportion of a group that experienced a specific event, whereas HRs reveal details regarding event
                  timing, survival, or event history and the relationship between 2 groups<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">35</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">40</span></a>. Hence, we calculated both the absolute risk (also referred
                  to as incidence), which is presented as the count and corresponding percentage, and the HR.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">By utilizing daily patient data in our analysis, we were
                  able to conduct analyses with day-to-day precision. This mitigated the risk of errors by ensuring that
                  outcomes such as nonunion occurred after the index surgery, thereby enhancing our ability to establish
                  causality. Specifically, the cumulative incidence and event rate were calculated with use of the
                  single-factor Aalen-Johansen estimator (AJE) of the cumulative incidence curve in order to depict the
                  progression and estimated time to outcome. Additionally, HR significance was tested with use of the
                  log-rank test, and proportionality tests ensured adherence to Cox proportional hazards model
                  assumptions. Assumptions for all statistical tests were verified before their use initially.
                  Significance testing utilized the Fisher exact test or chi-square test with Kendall tau C for
                  categorical variables and t-test statistics for continuous variables. HRs are presented with 95%
                  confidence intervals (CIs) and associated p values. Significance was set at p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Software
                Suite for Testing, Visualization, and Validation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Data compilation and analysis were accomplished with use
                  of TriNetX LIVE, Python (version 3.11.3; Python Software Foundation), and Stata MP 18 (24-core)
                  (StataCorp). Visuals and illustrations were crafted through Adobe Illustrator Cloud, Adobe Creative
                  Suite, BioRender, Adobe InDesign, and Microsoft Excel. All analytical procedures underwent validation
                  by 2 independent statisticians, and critical project stages were affirmed by at least 1 senior author.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Demographics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">A total of 462,260 pediatric patients with operatively
                  managed upper or lower-extremity fractures were identified; of these patients, 48,778 had been
                  administered ketorolac and 413,482 had not. An initial regression model identified age, sex, race,
                  BMI, fracture patterns, and other analgesic use as key factors affecting ketorolac use, as these
                  variables differed significantly between the groups (p &lt; 0.001). These variables were subsequently
                  incorporated into our PSM model. After matching, the total population was 48,778 per group. The
                  follow-up rate was 100% at 1 year and 86% at 2 years. In the ketorolac group, the mean age (and
                  standard deviation) was 9.66 ± 4.62 years, with 30,411 (62.35%) male and 18,367 (37.65%) female
                  patients. A total of 30,612 patients (62.76%) were White and 5,831 (11.95%) were Black. The mean BMI
                  percentile was 63.51 ± 33.23. Similar findings were observed in the matched comparison group, which
                  are presented in <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a> along
                  with a further demographic breakdown of the study population. Pre-matching characteristics are
                  available in the Appendix.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Post-Matching Characteristics</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1321.99px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.976261);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ketorolac
                          (N = 48,778)<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No
                          Ketorolac (N = 48,778)<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">SMD<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.66 ±
                          4.62</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.67 ±
                          4.61</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.775</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30,411
                          (62.35)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30,448
                          (62.42)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.949</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18,367
                          (37.65)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17,920
                          (36.74)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.939</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.019</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race and ethnicity</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hispanic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,392
                          (17.20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,401
                          (17.22)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.981</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Asian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,528
                          (3.13)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,521
                          (3.12)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.902</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> American Indian or
                          Alaskan</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">351
                          (0.72)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">256
                          (0.52)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.261</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.004</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Black</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,831
                          (11.95)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,851
                          (12.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.850</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Native Hawaiian</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">276
                          (0.57)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">269
                          (0.55)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.349</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.003</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> White</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30,612
                          (62.76)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30,603
                          (62.74)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.949</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.000</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unknown race</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,914
                          (20.32)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10,132
                          (20.77)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.070</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.011</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI percentile</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.51 ±
                          33.23</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.44 ±
                          31.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.207</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lower-extremity
                          fracture<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,787
                          (28.26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,998
                          (28.69)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.886</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.010</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femur</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,922
                          (28.45)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,075
                          (29.11)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.722</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.015</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tibia and/or
                          fibula</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,864
                          (71.55)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,923
                          (70.89)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.649</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.015</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Upper-extremity
                          fracture<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34,991
                          (71.73)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34,780
                          (71.30)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.829</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.010</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Humerus</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19,465
                          (55.63)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18,875
                          (54.27)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.663</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.027</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Radius and/or
                          ulna</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15,525
                          (44.37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15,905
                          (45.73)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.576</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.027</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± standard deviation or as the number of
                        patients, with the percentage in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">SMD = standardized mean difference.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Totals may not equate to 100% of the cohort size due to missing
                        data.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Incidence of
                Nonunion Following Ketorolac Administration</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">In the pre-matching analysis of the 48,778 patients who
                  received ketorolac and the 413,482 who did not, we observed nonunion rates of 2.19% (1,066 patients)
                  in the ketorolac group and 0.68% (2,822 patients) in the non-ketorolac group, yielding an HR of 3.49
                  (95% CI: 3.23, 3.82; p &lt; 0.0001; <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). After
                  matching, we found that nonunion developed in 2.19% (1,066) of the patients who received ketorolac
                  versus 0.93% (453) of the patients who did not receive ketorolac, with an HR of 2.71 (95% CI: 2.46,
                  3.21; p &lt; 0.0001; <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). When
                  stratifying nonunion data in the ketorolac group by age, we found that, among patients 5 to 9 years of
                  age, the HR for nonunion versus &lt;5-year-olds was 1.48 (95% CI: 1.14, 1.93; p = 0.0031); among those
                  10 to 14 years of age, the HR for nonunion increased to 2.01 (95% CI: 1.57, 2.56; p &lt; 0.0001); and
                  among those 15 to 17 years of age, the HR increased further to 3.12 (95% CI: 2.47, 3.95; p &lt;
                  0.0001; <a href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>, <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Nonunion Rates and Hazard Ratios, Overall and by
                          Location</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1011px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Fracture
                          Subgroup</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Nonunion<span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">*</span><span
                            data-id="emphasis_48" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;"></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;"></span>
                      </th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ketorolac</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No
                          Ketorolac</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Hazard
                          Ratio (95% CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Overall,
                          pre-matching</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,066
                          (2.19)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,822
                          (0.68)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.49
                          (3.23, 3.82)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Post-matching</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Overall</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,066
                          (2.19)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">453
                          (0.93)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.71
                          (2.46, 3.21)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lower extremity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">545
                          (3.95)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">206
                          (1.47)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.45
                          (3.14, 3.75)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Femur</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">191
                          (4.87)<span data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (1.57)<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.76
                          (3.61, 3.92)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Tibia and/or
                          fibula</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">354
                          (3.59)<span data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">142
                          (1.43)<span data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.99
                          (2.85, 3.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Upper extremity</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">521
                          (1.49)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">247
                          (0.71)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.11
                          (1.84, 2.32)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Humerus</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">229
                          (1.18)<span data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101
                          (0.54)<span data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.24
                          (1.92, 2.54)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Radius and/or
                          ulna</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">292
                          (1.88)<span data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">146
                          (0.92)<span data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.89
                          (1.65, 2.11)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the number of patients, with the percentage in
                        parentheses. Except as noted, percentages are based on the total number of patients in that
                        category for that group (as described in <a href="" data-id="figure_reference_6"
                          class="annotation figure_reference resource-reference" style="position: unset;">Table
                          I</a>).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Percentages are based on the total number of patients with the
                        specified fracture location in that group, as described in <a href=""
                          data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                          style="position: unset;">Table I</a>.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01225f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=05313d62-4e2a-4526-a8ae-3ce18d1159ac&type=jpeg&name=JBJS.23.01225f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Plot of the hazard ratios for nonunion in the
                          ketorolac group, stratified by age group. The whiskers represent the 95% CI.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Nonunion Rates and Hazard Ratios in the Ketoralac
                          Group, by Age</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 318px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Age</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Nonunion<span data-id="table_footnote_reference_16"
                            class="annotation table_footnote_reference" style="position: unset;">*</span><span
                            data-id="emphasis_49" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Hazard
                          Ratio (95% CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;5 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">138
                          (1.19)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 (reference)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">5 to 9 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">226
                          (1.47)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.48 (1.14, 1.93)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0031</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10 to 14 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">306
                          (2.68)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.01 (1.57, 2.56)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">15 to 17 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">396
                          (3.85)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.12 (2.47, 3.95)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the number of patients, with the percentage in
                        parentheses. Percentages are based on the total number of patients in each age group.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Fracture
                Location</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">When focusing on lower-extremity fractures, we observed a
                  nonunion rate of 3.95% (545 patients) in the ketorolac group and 1.47% (206 patients) in the
                  non-ketorolac group, yielding an HR of 3.45 (95% CI: 3.14, 3.75; p &lt; 0.0001). Upon further
                  stratification of patients with lower-extremity fractures, the incidence of femoral nonunion was 4.87%
                  (191 patients) in the ketorolac group and 1.57% (64 patients) in the non-ketorolac group (HR, 3.76;
                  95% CI: 3.61, 3.92; p &lt; 0.0001). For tibial and/or fibular nonunion, the incidence was 3.59% (354
                  patients) with ketorolac and 1.43% (142 patients) without ketorolac (HR, 2.99; 95% CI: 2.85, 3.71; p
                  &lt; 0.0001). When investigating upper-extremity fractures, the incidence of nonunion was 1.49% (521
                  patients) in the ketorolac group and 0.71% (247 patients) in the non-ketorolac group, with an HR of
                  2.11 (95% CI: 1.84, 2.32; p &lt; 0.0001). The incidence of humeral nonunion was 1.18% (229 patients)
                  among those who received ketorolac, whereas the incidence was 0.54% (101 patients) among those who did
                  not (HR, 2.24; 95% CI: 1.92, 2.54; p &lt; 0.0001). For radial and/or ulnar nonunion, the incidence was
                  1.88% (292 patients) in the ketorolac group and 0.92% (146 patients) in the non-ketorolac group (HR,
                  1.89; 95% CI: 1.65, 2.11; p &lt; 0.0001). This information is represented in <a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 2</a> and <a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>. No
                  significant differences were found in the incidences of open versus closed fractures between the
                  ketorolac and non-ketorolac groups (p &gt; 0.05).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01225f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=05313d62-4e2a-4526-a8ae-3ce18d1159ac&type=jpeg&name=JBJS.23.01225f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;">Forest plot of the hazard ratios for lower and
                          upper-extremity fractures. The rectangles represent the estimate, the whiskers represent the
                          95% CI, and the dashed line is the reference line indicating no difference in risk.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Effective pain management is crucial following surgical
                  treatment of long-bone fractures, with narcotics and NSAIDs being the most commonly administered
                  analgesics. However, the association between ketorolac, a commonly utilized NSAID, and pediatric
                  nonunion is debated. This study demonstrated that nonunions, although rare in pediatric patients with
                  surgically treated fractures, were more common in pediatric patients who received ketorolac than in
                  those who did not, especially among those with lower-extremity fractures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">In contrast with our findings, a study by Kay et al. that
                  explored the impact of ketorolac use on 221 pediatric patients with upper and lower-extremity
                  fractures demonstrated no increased complication risk or incidence of nonunion associated with
                  ketorolac administration<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">41</span></a>.
                  Similarly, a systematic review by Choo and Nuelle showed that NSAID use during the acute phase of bone
                  healing did not precipitate higher rates of nonunion<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">42</span></a>.
                  However, the studies on ketorolac that were utilized in that review often focused on spinal fusions or
                  had limited power due to the rarity of nonunions and small sample sizes<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">41</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">43</span></a>. Given the rarity of nonunions within pediatrics, smaller
                  sample sizes might inadequately discern differences in nonunion data as nonsignificant. The rate of
                  pediatric nonunion has been reported in the literature as 0.85%<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">6</span></a>, whereas the present study found a 0.68% incidence of
                  nonunion in the non-ketorolac group prior to matching. In our post-matching analysis of the
                  association between ketorolac use within 30 days postoperatively and nonunion in pediatric long-bone
                  fractures in 97,556 patients, we identified a significantly higher incidence of nonunion in the
                  ketorolac group (2.19%) than in the non-ketorolac group (0.93%) and significantly elevated HRs among
                  the subgroups. The increased risk of nonunion associated with ketorolac use was greater for patients
                  with lower-extremity fractures than for those with upper-extremity fractures; this finding is notable
                  given that lower-extremity fractures have been shown to affect function, activity, and overall
                  conditioning more severely than upper-extremity fractures<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">44</span></a>.
                  The elevated risk of nonunion in patients with lower-extremity fractures, particularly those with
                  femoral fractures, following ketorolac administration underscores the importance of carefully
                  evaluating postoperative pain management strategies in these patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The ability of our study to identify these associations
                  stems partly from our robust dataset, which contrasts with prior studies, which were limited by their
                  datasets. Furthermore, we employed PSM to minimize the comparative age-related factors between the
                  groups, recognizing that the threshold of skeletal maturity, which is often marked by physeal closure,
                  can influence bone-healing expectations. Our study also accounted for a potential association between
                  open fractures and increased nonunion rates. Our finding of no significant differences in the
                  incidences of open versus closed fractures between the groups further strengthens the validity of our
                  results. Additionally, our selection of a 30-day time frame for ketorolac exposure reflects the
                  nuanced use of ketorolac in pain management, capturing the variability in both patient-experienced
                  pain and healing trajectories that exists even among patients with similar conditions<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">45</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Although the differences in the absolute risks of nonunion
                  between the study groups were not markedly large, they were nonetheless significant, highlighting the
                  need for careful, individualized consideration of ketorolac use. NSAIDs, including ketorolac, are
                  valuable for reducing opioid dependency and managing pain and inflammation, but they come with
                  associated risks<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">46</span></a>.
                  However, completely forgoing NSAIDs is neither a realistic nor a safe approach. Furthermore,
                  alternative pain management strategies such as local blocks are not uniformly implemented at various
                  institutions<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">47</span></a>.
                  Given the array of pain management strategies, it is crucial for physicians to holistically evaluate
                  the needs of each patient, judiciously weighing every risk and benefit that has been substantiated by
                  rigorous research in order to tailor the postoperative care regimen to the individual<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">46</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">48</span></a>. Future research should not only focus on understanding how
                  NSAIDs might obstruct bone healing in biomechanical and pharmaceutical studies but also explore the
                  role of NSAIDs in various surgical treatments and specific fracture locations and compare ketorolac
                  with alternative postoperative analgesics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Our study has limitations. Its retrospective nature made
                  it vulnerable to biases, and the utilization of an extensive network might have resulted in
                  unaccounted-for outcome measures. Moreover, despite comprehensive data, multi-code verification, and
                  detailed data-cleaning to minimize errors, the possibility of coding inaccuracies and clinical
                  misattributions could not be completely eliminated. Additionally, the TriNetX database primarily
                  consists of individuals seeking medical care from large academic medical centers, which introduces a
                  potential discrepancy in the incidences of ketorolac administration and/or nonunion between the
                  database and the general population<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">25</span></a>.
                  Furthermore, the present study did not delineate the percentage of nonunion diagnoses made at the
                  initial hospital visit versus at other treatment facilities, which is a key factor in understanding
                  the continuity of care and resource utilization. Another limitation is that patient notes and
                  radiographs were not utilized to confirm nonunion, potentially leading to some uncertainty in the
                  definitive assessment of fracture-healing outcomes. Moreover, our data did not include detailed
                  information on ketorolac dosage and duration. Finally, we were not able to account for fracture
                  severity or the type of surgery received, which may explain the confounding that we observed in the
                  differences between subgroups, namely between the upper and lower-extremity subgroups. Matching
                  patients by age and fracture type was intended to standardize comparisons as much as possible, with
                  the assumption that matched pairs would likely have received age and fracture-appropriate surgical
                  interventions. However, this does not guarantee uniformity in treatment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">In this study encompassing &gt;400,000 operatively treated
                  fractures, we explored the relationship between ketorolac administration within 30 days after surgery
                  and the incidence of nonunion among pediatric patients. Although the overall rate of postoperative
                  pediatric nonunion was low, the administration of ketorolac was correlated with a 2 to 3-fold increase
                  in nonunion rates. The hazard was particularly elevated in patients with lower-extremity fractures.
                  Therefore, the benefits and risks of the administration of ketorolac for symptomatic pain management
                  in these groups should be carefully considered. These findings accentuate the importance of cautious
                  use of ketorolac in postoperative analgesia and support a patient-centered approach that considers
                  individual risk. Our results call for further robust research to refine analgesic protocols with the
                  aim of optimizing fracture healing and minimizing nonunion risk in pediatric orthopaedic care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_5" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I255" target="_blank" data-id="link_6" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I255</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Thomas JD</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Kehoe JL</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Bone Nonunion</span>. In: <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">StatPearls.</span>
                StatPearls Publishing; 2023.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20Nonunion&as_occt=title&as_sauthors=%20%22JD%20Thomas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Schmal H</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Brix M</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Bue M</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Ekman A</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Ferreira N</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Gottlieb H</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Kold S</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Taylor A</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Toft Tengberg P</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Ban I</span>; Danish Orthopaedic
                Trauma Society. <span data-id="strong_2" class="annotation strong" style="position: unset;">Nonunion -
                  consensus from the 4th annual meeting of the Danish Orthopaedic Trauma Society</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">EFORT Open Rev.</span> 2020
                Jan 29;5(1):46-57.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nonunion%20-%20consensus%20from%20the%204th%20annual%20meeting%20of%20the%20Danish%20Orthopaedic%20Trauma%20Society&as_occt=title&as_sauthors=%20%22H%20Schmal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Nicholson JA</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Makaram N</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Simpson A</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Keating JF</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Fracture nonunion in long bones:
                  A literature review of risk factors and surgical management</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Injury.</span> 2021 Jun;52(Suppl
                2):S3-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20nonunion%20in%20long%20bones%3A%20A%20literature%20review%20of%20risk%20factors%20and%20surgical%20management&as_occt=title&as_sauthors=%20%22JA%20Nicholson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> National Archives</span>: <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Federal Register</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Guidance Document for Industry
                  and CDRH Staff for the Preparation of Investigational Device Exemptions and Premarket Approval
                  Applications for Bone Growth Stimulator Devices. Draft; Availability</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Federal Register.</span>
                1998 Apr 28. Accessed 2023 Sep 30. <a
                  href="https://www.federalregister.gov/documents/1998/04/28/98-11158/guidance-document-for-industry-and-cdrh-staff-for-the-preparation-of-investigational-device"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.federalregister.gov/documents/1998/04/28/98-11158/guidance-document-for-industry-and-cdrh-staff-for-the-preparation-of-investigational-device</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Guidance%20Document%20for%20Industry%20and%20CDRH%20Staff%20for%20the%20Preparation%20of%20Investigational%20Device%20Exemptions%20and%20Premarket%20Approval%20Applications%20for%20Bone%20Growth%20Stimulator%20Devices.%20Draft%3B%20Availability&as_occt=title&as_sauthors=%20%22National%20Archives%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Shrader MW</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Stans AA</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Shaughnessy WJ</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Haidukewych GJ</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Nonunion of fractures in
                  pediatric patients: 15-year experience at a level I trauma center</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2009
                Jun;32(6):410.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nonunion%20of%20fractures%20in%20pediatric%20patients%3A%2015-year%20experience%20at%20a%20level%20I%20trauma%20center&as_occt=title&as_sauthors=%20%22MW%20Shrader%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Zura R</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Kaste SC</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Heffernan MJ</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Accousti WK</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Gargiulo D</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Wang Z</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Steen RG</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Risk factors for nonunion of
                  bone fracture in pediatric patients: An inception cohort study of 237,033 fractures</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Medicine (Baltimore).</span>
                2018 Aug;97(31):e11691.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20nonunion%20of%20bone%20fracture%20in%20pediatric%20patients%3A%20An%20inception%20cohort%20study%20of%20237%2C033%20fractures&as_occt=title&as_sauthors=%20%22R%20Zura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Rupp M</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Biehl C</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Budak M</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Thormann U</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Heiss C</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Alt V</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Diaphyseal long bone nonunions -
                  types, aetiology, economics, and treatment recommendations</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2018
                Feb;42(2):247-58.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diaphyseal%20long%20bone%20nonunions%20-%20types%2C%20aetiology%2C%20economics%2C%20and%20treatment%20recommendations&as_occt=title&as_sauthors=%20%22M%20Rupp%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Hak DJ</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Fitzpatrick D</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Bishop JA</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Marsh JL</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Tilp S</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Schnettler R</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Simpson H</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Alt V</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Delayed union and nonunions:
                  epidemiology, clinical issues, and financial aspects</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Injury.</span> 2014 Jun;45(Suppl
                2):S3-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Delayed%20union%20and%20nonunions%3A%20epidemiology%2C%20clinical%20issues%2C%20and%20financial%20aspects&as_occt=title&as_sauthors=%20%22DJ%20Hak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Antonova E</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Le TK</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Burge R</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Mershon J</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Tibia shaft fractures: costly
                  burden of nonunions</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">BMC Musculoskelet Disord.</span> 2013 Jan 26;14:42.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibia%20shaft%20fractures%3A%20costly%20burden%20of%20nonunions&as_occt=title&as_sauthors=%20%22E%20Antonova%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Tucker WA</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Birt MC</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Heddings AA</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Horton GA</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The Effect of Postoperative
                  Nonsteroidal Anti-inflammatory Drugs on Nonunion Rates in Long Bone Fractures</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2020
                Jul 1;43(4):221-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Effect%20of%20Postoperative%20Nonsteroidal%20Anti-inflammatory%20Drugs%20on%20Nonunion%20Rates%20in%20Long%20Bone%20Fractures&as_occt=title&as_sauthors=%20%22WA%20Tucker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Simon AM</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Manigrasso MB</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> O’Connor JP</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Cyclo-oxygenase 2 function is
                  essential for bone fracture healing</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Bone Miner Res.</span> 2002 Jun;17(6):963-76.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cyclo-oxygenase%202%20function%20is%20essential%20for%20bone%20fracture%20healing&as_occt=title&as_sauthors=%20%22AM%20Simon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Su B</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> O’Connor JP</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">NSAID therapy effects on
                  healing of bone, tendon, and the enthesis</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Appl Physiol(</span> (1985). 2013
                Sep;115(6):892-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=NSAID%20therapy%20effects%20on%20healing%20of%20bone%2C%20tendon%2C%20and%20the%20enthesis&as_occt=title&as_sauthors=%20%22B%20Su%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Azmi. Do</span><span
                  data-id="strong_13" class="annotation strong" style="position: unset;">NSAID/COX-2 Inhibitors Increase
                  Nonunion after Fracture Surgery? Dilemma and Consideration in Use</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Orthop Traumatol Surabaya.</span>
                2022;11(2):70-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=NSAID%2FCOX-2%20Inhibitors%20Increase%20Nonunion%20after%20Fracture%20Surgery%3F%20Dilemma%20and%20Consideration%20in%20Use&as_occt=title&as_sauthors=%20%22Azmi.%20Do%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Karateev RA</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Nikitinskaya ОА</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Toroptsova NV</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Karateev АЕ</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Nonsteroidal anti-inflammatory
                  drugs for pain relief in case of injuries: Is there a risk of bone metabolism disorders and nonunion
                  of bone fractures? Научно-Практическая Ревматология</span>. 2022;60(3):299-305.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nonsteroidal%20anti-inflammatory%20drugs%20for%20pain%20relief%20in%20case%20of%20injuries%3A%20Is%20there%20a%20risk%20of%20bone%20metabolism%20disorders%20and%20nonunion%20of%20bone%20fractures%3F%20%D0%9D%D0%B0%D1%83%D1%87%D0%BD%D0%BE-%D0%9F%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%A0%D0%B5%D0%B2%D0%BC%D0%B0%D1%82%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F&as_occt=title&as_sauthors=%20%22RA%20Karateev%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Forrest JB</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Heitlinger EL</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Revell S</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Ketorolac for postoperative
                  pain management in children</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Drug Saf.</span> 1997 May;16(5):309-29.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ketorolac%20for%20postoperative%20pain%20management%20in%20children&as_occt=title&as_sauthors=%20%22JB%20Forrest%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Eberson CP</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Pacicca DM</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Ehrlich MG</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The role of ketorolac in
                  decreasing length of stay and narcotic complications in the postoperative pediatric orthopaedic
                  patient</span>. <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J
                  Pediatr Orthop.</span> 1999 Sep-Oct;19(5):688-92.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20ketorolac%20in%20decreasing%20length%20of%20stay%20and%20narcotic%20complications%20in%20the%20postoperative%20pediatric%20orthopaedic%20patient&as_occt=title&as_sauthors=%20%22CP%20Eberson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Gillis JC</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Brogden RN</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Ketorolac. A reappraisal of its
                  pharmacodynamic and pharmacokinetic properties and therapeutic use in pain management</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Drugs.</span> 1997
                Jan;53(1):139-88.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ketorolac.%20A%20reappraisal%20of%20its%20pharmacodynamic%20and%20pharmacokinetic%20properties%20and%20therapeutic%20use%20in%20pain%20management&as_occt=title&as_sauthors=%20%22JC%20Gillis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Murnaghan M</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Li G</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Marsh DR</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Nonsteroidal Anti-Inflammatory
                  Drug-Induced Fracture Nonunion: An Inhibition of Angiogenesis?</span><span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2006;88(suppl_3):140.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1085904"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Ho ML</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Chang JK</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Wang GJ</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Effects of ketorolac on bone
                  repair: A radiographic study in modeled demineralized bone matrix grafted rabbits</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Pharmacology.</span> 1998
                Sep;57(3):148-59.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20ketorolac%20on%20bone%20repair%3A%20A%20radiographic%20study%20in%20modeled%20demineralized%20bone%20matrix%20grafted%20rabbits&as_occt=title&as_sauthors=%20%22ML%20Ho%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> White AE</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Henry JK</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Dziadosz D</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The Effect of Nonsteroidal
                  Anti-inflammatory Drugs and Selective COX-2 Inhibitors on Bone Healing</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">HSS J.</span> 2021
                Jul;17(2):231-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Effect%20of%20Nonsteroidal%20Anti-inflammatory%20Drugs%20and%20Selective%20COX-2%20Inhibitors%20on%20Bone%20Healing&as_occt=title&as_sauthors=%20%22AE%20White%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> George MD</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Baker JF</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Leonard CE</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Mehta S</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Miano TA</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Hennessy S</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Risk of Nonunion with
                  Nonselective NSAIDs, COX-2 Inhibitors, and Opioids</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2020 Jul
                15;102(14):1230-8.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2436316"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Kim H</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Kim DH</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Kim DM</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Kholinne E</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Lee ES</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Alzahrani WM</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Kim JW</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Jeon IH</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Koh KH</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Do Nonsteroidal
                  Anti-Inflammatory or COX-2 Inhibitor Drugs Increase the Nonunion or Delayed Union Rates After Fracture
                  Surgery?: A Propensity-Score-Matched Study</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2021 Aug
                4;103(15):1402-10.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2909317"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Dodwell ER</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Latorre JG</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Parisini E</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Zwettler E</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Chandra D</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Mulpuri K</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Snyder B</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">NSAID exposure and risk of
                  nonunion: a meta-analysis of case-control and cohort studies</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Calcif Tissue Int.</span> 2010
                Sep;87(3):193-202.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=NSAID%20exposure%20and%20risk%20of%20nonunion%3A%20a%20meta-analysis%20of%20case-control%20and%20cohort%20studies&as_occt=title&as_sauthors=%20%22ER%20Dodwell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Kurmis AP</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Kurmis TP</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> O’Brien JX</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Dalén T</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">The Effect of Nonsteroidal
                  Anti-Inflammatory Drug Administration on Acute Phase Fracture-Healing: A Review</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2012;94(9):815.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1141612" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Palchuk MB</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> London JW</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Perez-Rey D</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Drebert ZJ</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Winer-Jones JP</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Thompson CN</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Esposito J</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Claerhout B</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">A global federated real-world
                  data and analytics platform for research</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">JAMIA Open.</span> 2023 May
                13;6(2):ooad035.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20global%20federated%20real-world%20data%20and%20analytics%20platform%20for%20research&as_occt=title&as_sauthors=%20%22MB%20Palchuk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Sachdeva S.</span><span
                  data-id="strong_26" class="annotation strong" style="position: unset;">TriNetX</span>. Accessed 2024
                Jul 29. <a href="https://ictr.johnshopkins.edu/service/informatics/trinetx/" target="_blank"
                  data-id="link_2" class="link"
                  style="position: unset;">https://ictr.johnshopkins.edu/service/informatics/trinetx/</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=TriNetX&as_occt=title&as_sauthors=%20%22S.%20Sachdeva%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="strong_27" class="annotation strong" style="position: unset;">TriNetX. Homepage</span>.
                Accessed 2024 Jul 29. <a href="https://trinetx.com" target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://trinetx.com</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Vesoulis ZA</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Husain AN</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Cole FS</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Improving child health through
                  Big Data and data science</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Pediatr Res.</span> 2023 Jan;93(2):342-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Improving%20child%20health%20through%20Big%20Data%20and%20data%20science&as_occt=title&as_sauthors=%20%22ZA%20Vesoulis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Anand N</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Edwards L</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Baker LX</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Chren MM</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Wheless L</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Validity of Using Billing Codes
                  From Electronic Health Records to Estimate Skin Cancer Counts</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">JAMA Dermatol.</span> 2021 Sep
                1;157(9):1089-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validity%20of%20Using%20Billing%20Codes%20From%20Electronic%20Health%20Records%20to%20Estimate%20Skin%20Cancer%20Counts&as_occt=title&as_sauthors=%20%22N%20Anand%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Joseph A</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Mullett C</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Lilly C</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Armistead M</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Cox HJ</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Denney M</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Varma M</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Rich D</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Adjeroh DA</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Doretto G</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Neal W</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Pyles LA</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Coronary Artery Disease
                  Phenotype Detection in an Academic Hospital System Setting</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Appl Clin Inform.</span> 2021
                Jan;12(1):10-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Coronary%20Artery%20Disease%20Phenotype%20Detection%20in%20an%20Academic%20Hospital%20System%20Setting&as_occt=title&as_sauthors=%20%22A%20Joseph%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Nam YH</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Willis SJ</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Mendelsohn A</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Healthcare Claims-Based Lyme
                  Disease Case-Finding Algorithms in the United States: A Systematic Literature Review</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">PLoS One.</span> 2022 Oct
                27;17(10):e0276299.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Healthcare%20Claims-Based%20Lyme%20Disease%20Case-Finding%20Algorithms%20in%20the%20United%20States%3A%20A%20Systematic%20Literature%20Review&as_occt=title&as_sauthors=%20%22YH%20Nam%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Bindslev JB</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Ciochon UM</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Hansen K</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Engel Hoei-Hansen C</span>,
                <span data-id="annotation_141" class="annotation" style="position: unset;"> Truelsen T</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Predictive Value of Pediatric
                  Stroke Diagnoses in Administrative Data: A Systematic Literature Review</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Neuroepidemiology.</span>
                2022;56(6):413-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictive%20Value%20of%20Pediatric%20Stroke%20Diagnoses%20in%20Administrative%20Data%3A%20A%20Systematic%20Literature%20Review&as_occt=title&as_sauthors=%20%22JB%20Bindslev%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Cheng JY</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">The Association between
                  Serotonin Reuptake Inhibitors and Obstructive Sleep Apnea in People with Epilepsy-A Retrospective
                  Analysis</span>. <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">J
                  Sleep Med.</span> 2018;15(2):43-47.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Association%20between%20Serotonin%20Reuptake%20Inhibitors%20and%20Obstructive%20Sleep%20Apnea%20in%20People%20with%20Epilepsy-A%20Retrospective%20Analysis&as_occt=title&as_sauthors=%20%22JY%20Cheng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Sandritter TL</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Goldman JL</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Habiger CJ</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Daniel JF</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Lowry J</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Fischer RT</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">An electronic medical
                  records-based approach to identify idiosyncratic drug-induced liver injury in children</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">Sci Rep.</span> 2019 Dec
                2;9(1):18090.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20electronic%20medical%20records-based%20approach%20to%20identify%20idiosyncratic%20drug-induced%20liver%20injury%20in%20children&as_occt=title&as_sauthors=%20%22TL%20Sandritter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Higgins JPT</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Thomas J</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Chandler J</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Cochrane Handbook for
                  Systematic Reviews of Interventions.</span> 2nd ed. John Wiley & Sons; 2019.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Moriguchi M</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Seko Y</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Takahashi A</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Itoh Y</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Epidemiology Of Hepatocellular
                  Carcinoma In Nonalcoholic Fatty Liver Disease</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">Hepatoma Research.</span> 2019;5:43</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20Of%20Hepatocellular%20Carcinoma%20In%20Nonalcoholic%20Fatty%20Liver%20Disease&as_occt=title&as_sauthors=%20%22M%20Moriguchi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Hanson RK</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Babchishin KM</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Helmus L</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Thornton D</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Quantifying the relative risk
                  of sex offenders: risk ratios for static-99R</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">Sex Abuse.</span> 2013
                Oct;25(5):482-515.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quantifying%20the%20relative%20risk%20of%20sex%20offenders%3A%20risk%20ratios%20for%20static-99R&as_occt=title&as_sauthors=%20%22RK%20Hanson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Pidala J</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Djulbegovic B</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Anasetti C</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Kharfan-Dabaja MA</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Kumar A</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Allogeneic Hematopoietic Cell
                  Transplantation For Acute Lymphoblastic Leukemia In First Complete Remission: a Systematic Review And
                  Meta-analysis</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Blood.</span> 2010;21(116):3511.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Allogeneic%20Hematopoietic%20Cell%20Transplantation%20For%20Acute%20Lymphoblastic%20Leukemia%20In%20First%20Complete%20Remission%3A%20a%20Systematic%20Review%20And%20Meta-analysis&as_occt=title&as_sauthors=%20%22J%20Pidala%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Taquet M</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Husain M</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Geddes JR Jr</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Luciano S</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Harrison PJ</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Cerebral venous thrombosis and
                  portal vein thrombosis: A retrospective cohort study of 537,913 COVID-19 cases</span>. <span
                  data-id="emphasis_36" class="annotation emphasis" style="position: unset;">EClinicalMedicine.</span>
                2021 Sep;39:101061.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cerebral%20venous%20thrombosis%20and%20portal%20vein%20thrombosis%3A%20A%20retrospective%20cohort%20study%20of%20537%2C913%20COVID-19%20cases&as_occt=title&as_sauthors=%20%22M%20Taquet%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Taquet M</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Luciano S</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Geddes JR Jr</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Harrison PJ</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Bidirectional associations
                  between COVID-19 and psychiatric disorder: retrospective cohort studies of 62 354 COVID-19 cases in
                  the USA</span>. <span data-id="emphasis_37" class="annotation emphasis"
                  style="position: unset;">Lancet Psychiatry.</span> 2021 Feb;8(2):130-40.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bidirectional%20associations%20between%20COVID-19%20and%20psychiatric%20disorder%3A%20retrospective%20cohort%20studies%20of%2062%20354%20COVID-19%20cases%20in%20the%20USA&as_occt=title&as_sauthors=%20%22M%20Taquet%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Kay RM</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Directo MP</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Leathers M</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Myung K</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Skaggs DL</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Complications of ketorolac use
                  in children undergoing operative fracture care</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2010
                Oct-Nov;30(7):655-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Complications%20of%20ketorolac%20use%20in%20children%20undergoing%20operative%20fracture%20care&as_occt=title&as_sauthors=%20%22RM%20Kay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Choo S</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Nuelle JAV</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">NSAID Use and Effects on
                  Pediatric Bone Healing: A Review of Current Literature</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">Children (Basel).</span> 2021 Sep
                18;8(9):821.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=NSAID%20Use%20and%20Effects%20on%20Pediatric%20Bone%20Healing%3A%20A%20Review%20of%20Current%20Literature&as_occt=title&as_sauthors=%20%22S%20Choo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Vitale MG</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Choe JC</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Hwang MW</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Bauer RM</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Hyman JE</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Lee FY</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Roye DP Jr</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Use of ketorolac tromethamine
                  in children undergoing scoliosis surgery. an analysis of complications</span>. <span
                  data-id="emphasis_40" class="annotation emphasis" style="position: unset;">Spine J.</span> 2003
                Jan-Feb;3(1):55-62.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Use%20of%20ketorolac%20tromethamine%20in%20children%20undergoing%20scoliosis%20surgery.%20an%20analysis%20of%20complications&as_occt=title&as_sauthors=%20%22MG%20Vitale%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Mills LA</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Simpson AH</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">The risk of non-union per
                  fracture in children</span>. <span data-id="emphasis_41" class="annotation emphasis"
                  style="position: unset;">J Child Orthop.</span> 2013 Oct;7(4):317-22.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20risk%20of%20non-union%20per%20fracture%20in%20children&as_occt=title&as_sauthors=%20%22LA%20Mills%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Nowicki PD</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Vanderhave KL</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Gibbons K</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Haydar B</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Seeley M</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Kozlow K</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Bhoopal K</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Gauger VT</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Perioperative pain control in
                  pediatric patients undergoing orthopaedic surgery</span>. <span data-id="emphasis_42"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2012
                Dec;20(12):755-65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perioperative%20pain%20control%20in%20pediatric%20patients%20undergoing%20orthopaedic%20surgery&as_occt=title&as_sauthors=%20%22PD%20Nowicki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Adams AJ</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Buczek MJ</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Flynn JM</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Shah AS</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Perioperative Ketorolac for
                  Supracondylar Humerus Fracture in Children Decreases Postoperative Pain, Opioid Usage, Hospitalization
                  Cost, and Length-of-Stay</span>. <span data-id="emphasis_43" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop.</span> 2019 Jul;39(6):e447-51.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perioperative%20Ketorolac%20for%20Supracondylar%20Humerus%20Fracture%20in%20Children%20Decreases%20Postoperative%20Pain%2C%20Opioid%20Usage%2C%20Hospitalization%20Cost%2C%20and%20Length-of-Stay&as_occt=title&as_sauthors=%20%22AJ%20Adams%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Kamel I</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Ahmed MF</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Sethi A</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Regional anesthesia for
                  orthopedic procedures: What orthopedic surgeons need to know</span>. <span data-id="emphasis_44"
                  class="annotation emphasis" style="position: unset;">World J Orthop.</span> 2022 Jan
                18;13(1):11-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Regional%20anesthesia%20for%20orthopedic%20procedures%3A%20What%20orthopedic%20surgeons%20need%20to%20know&as_occt=title&as_sauthors=%20%22I%20Kamel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Johnson MA</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Andras LM</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Andras LE</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Ellington MD</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Upasani VV</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Shah AS</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">What’s New in Pain Management
                  for Pediatric Orthopaedic Surgery</span>. <span data-id="emphasis_45" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop.</span> 2021 Nov-Dec 01;41(10):e923-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%E2%80%99s%20New%20in%20Pain%20Management%20for%20Pediatric%20Orthopaedic%20Surgery&as_occt=title&as_sauthors=%20%22MA%20Johnson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;05313d62-4e2a-4526-a8ae-3ce18d1159ac&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=05313d62-4e2a-4526-a8ae-3ce18d1159ac&type=supplement&name=12522"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D05313d62-4e2a-4526-a8ae-3ce18d1159ac%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): 66</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01225</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 12, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_46"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Washington
                            University School of Medicine, St. Louis, Missouri</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;05313d62-4e2a-4526-a8ae-3ce18d1159ac&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=05313d62-4e2a-4526-a8ae-3ce18d1159ac&type=pdf&name=JBJS.23.01225.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=05313d62-4e2a-4526-a8ae-3ce18d1159ac&type=pdf&name=JBJS.23.01225.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_53"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this study. The <span data-id="strong_54" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I256"
                            target="_blank" data-id="link_4" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I256</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;05313d62-4e2a-4526-a8ae-3ce18d1159ac&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=05313d62-4e2a-4526-a8ae-3ce18d1159ac&type=zip&name=JBJS.23.01225.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daniel E. Pereira, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6356-9830" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6356-9830</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Caleb Ford, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3710-8037" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3710-8037</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mehul M. Mittal, BBA<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8837-0396" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8837-0396</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tiffany M. Lee, BS<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0001-0754-9157" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0001-0754-9157</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Karan Joseph, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9583-9957" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9583-9957</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sabrina C. Madrigal, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2622-0752" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2622-0752</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">David Momtaz, BS, MPH<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2086-5717" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2086-5717</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Beltran Torres-Izquierdo, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0737-9271" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0737-9271</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Pooya Hosseinzadeh, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:hosseinzadehp@wustl.edu" class=""
                    style="position: unset;">hosseinzadehp@wustl.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7146-664X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7146-664X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Washington University School of Medicine, St.
                Louis, Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedics, UT Southwestern Medical Center, Dallas,
                Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Baylor College of Medicine, Houston, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopaedics, UT Health San Antonio, San Antonio, Texas</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Daniel E. Pereira, MD, and Caleb Ford, MD, PhD,
                        contributed equally to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 30303.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
