// ----------------- React Native ---------------
import { SectionList } from "react-native";

import { CustomListSubheader } from "@memorang/ui";
// ----------------- Local Imports ---------------
import type { MoreSettingsItem, MoreSettingsSection } from "../types";
import MoreSettingsListItem from "./MoreSettingsListItem";
interface Props {
	sections: MoreSettingsSection[];
	handleClickMoreSettingsItem: (type: MoreSettingsItem) => void;
}
const MoreRouteView = ({ sections, handleClickMoreSettingsItem }: Props) => {
	return (
		<SectionList
			sections={sections}
			renderSectionHeader={({ section: { sectionTitle } }) => {
				return <CustomListSubheader>{sectionTitle}</CustomListSubheader>;
			}}
			renderItem={({ item }) => {
				return (
					<MoreSettingsListItem
						item={item}
						handleClick={handleClickMoreSettingsItem}
					/>
				);
			}}
		/>
	);
};

export default MoreRouteView;
