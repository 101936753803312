import { useUser } from "@clerk/clerk-expo";

const useClerkUser = () => {
	const { user, isLoaded, isSignedIn } = useUser();

	const email = user?.emailAddresses[0].emailAddress;
	const username = user?.username;
	const userId = user?.id;
	const userPublicMetadataId = user?.publicMetadata.id as number;
	const userLogicalId = user?.publicMetadata.logicalId as string;

	return {
		email,
		username,
		userId,
		isLoaded,
		isSignedIn,
		userPublicMetadataId,
		userLogicalId,
	};
};

export default useClerkUser;
