/* ----------------- Types --------------- */
import type { HeaderSectionData } from "@features/dashboard/";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* ----------------- UI --------------- */
import {
	Box,
	DonutChart,
	DonutChartSkeleton,
	StatsSkeleton,
	TitleSubtitleSkeleton,
	useDevice,
} from "@memorang/ui";
import { Card, Text } from "react-native-paper";

import { SetExamDate } from "components/ExamContextListItem";
/* ----------------- Components --------------- */
import ProductIcon from "components/ProductIcon";
import RowStats from "components/RowStats";

/* ----------------- Icons --------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

interface Props {
	headerData?: HeaderSectionData;
}

const ExamInfoSection = ({ headerData }: Props) => {
	const { isMobile } = useDevice();

	const theme = useAppTheme();
	return (
		<Card
			mode="contained"
			style={{
				backgroundColor: theme.colors.surface,
				borderColor: theme.colors.surfaceVariant,
				borderRadius: isMobile ? 0 : 8,
				borderWidth: isMobile ? 0 : 1,
			}}
		>
			<Card.Content>
				<Box
					width={"100%"}
					flexDirection={isMobile ? "column" : "row"}
					gap={16}
					justifyContent="space-between"
				>
					<Box
						alignItems="center"
						flexDirection={isMobile ? "column" : "row"}
						gap={16}
					>
						{headerData ? (
							<DonutChart
								distribution={headerData.distribution}
								radius={41}
								innerRadius={30}
							>
								{headerData.examIcon ? (
									<ProductIcon image={headerData.examIcon} size={40} />
								) : (
									<Icon
										name="file-document-outline"
										size={64}
										color={theme.colors.onSurfaceVariant}
									/>
								)}
							</DonutChart>
						) : (
							<DonutChartSkeleton
								avatarStyle={{
									marginLeft: -8,
								}}
							/>
						)}
						<Box
							alignItems={isMobile ? "center" : "flex-start"}
							gap={8}
							width={isMobile ? "100%" : 300}
						>
							{headerData ? (
								<>
									<Text variant="titleMedium" numberOfLines={1}>
										{headerData.examName}
									</Text>
									<SetExamDate
										examDateLabel={headerData.examStatusText}
										textColor={theme.colors.primary}
										examDate={headerData.examDate}
									/>
								</>
							) : (
								<TitleSubtitleSkeleton />
							)}
						</Box>
					</Box>
					{headerData ? (
						<RowStats showBorder stats={headerData.headerStats} flexGrow={1} />
					) : (
						<StatsSkeleton />
					)}
				</Box>
			</Card.Content>
		</Card>
	);
};

export default ExamInfoSection;
