/* ----------------- Globals --------------- */
import { Box } from "@memorang/ui";

import { titleCase } from "@memorang/helpers";
import type { Article } from "../types";
/* ----------------- Types --------------- */
import SavedArticleListItem from "./SavedArticleListItem";

/* ----------------- Components --------------- */

type Props = {
	savedArticles: Article[];
	handleSelectArticle: (id: number) => void;
	selectedArticleIds: number[];
	handleNavigateToArticle: (id: number) => void;
};
export const SavedArticlesList = ({
	savedArticles,
	handleSelectArticle,
	selectedArticleIds,
	handleNavigateToArticle,
}: Props) => {
	return (
		<Box>
			{savedArticles.map((item) => {
				const status = selectedArticleIds.includes(item.id)
					? "checked"
					: "unchecked";
				return (
					<SavedArticleListItem
						key={item.title}
						{...item}
						description={titleCase(item.type)}
						status={status}
						onCheck={() => handleSelectArticle(item.id)}
						onPress={() => handleNavigateToArticle(item.id)}
					/>
				);
			})}
		</Box>
	);
};
