/* -----------------UI--------------- */
import { Text } from "react-native-paper";

import { Box } from "@memorang/ui";
import ItemStem from "./ItemStem";
/* -----------------Child components--------------- */
import StemAccordion from "./StemAccordion";

interface Props {
	stemTitle?: string;
	initExpand?: boolean;
	stem?: string;
	expandable?: boolean;
}
const StemExpandable = ({ stemTitle, initExpand, stem, expandable }: Props) => {
	return expandable ? (
		<StemAccordion title={stemTitle || ""} initExpand={initExpand}>
			{stem ? (
				<Box padding={16}>
					<ItemStem text={stem} />
				</Box>
			) : null}
		</StemAccordion>
	) : (
		<Box>
			{stemTitle && <Text variant="labelLarge">{stemTitle}</Text>}
			{stem && <ItemStem text={stem} />}
		</Box>
	);
};

export default StemExpandable;
