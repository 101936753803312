import { StackActions } from "@react-navigation/native";
import type { StudyGuideItem } from "../types";

const findParents = (
	topics: StudyGuideItem[],
	id: string,
	path: StudyGuideItem[] = [],
): StudyGuideItem[] | null => {
	for (const topic of topics) {
		if (topic.id === id) {
			return [...path, topic];
		}
		if (topic.childTags) {
			const found = findParents(topic.childTags, id, [...path, topic]);
			if (found) {
				return found;
			}
		}
	}
	return null;
};
export const getBreadcrumbs = ({
	notes,
	noteId,
	navigation,
}: {
	notes: StudyGuideItem[];
	noteId: string;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	navigation: any;
}) => {
	let breadcrumbs: StudyGuideItem[] = [];
	for (const subject of notes) {
		const found = findParents(subject.childTags, noteId, [subject]);
		if (found) {
			breadcrumbs = found;
			break;
		}
	}
	const finalBreadcrumbs = breadcrumbs.map((topic, index, array) => ({
		label: topic.label,
		action: () => {
			const popAction = StackActions.pop(array.length - 2 - index);

			navigation.dispatch(popAction);
		},
	}));
	return finalBreadcrumbs;
};
