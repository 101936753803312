import { useAuth } from "@clerk/clerk-expo";
import theme from "@configs/theme";
import { queryKeys } from "@constants/common";
import { tagIconUrlMap } from "@constants/mocked-image-challenge-data";
import { useAppStore } from "@hooks/useAppStore";
import useClerkUser from "@hooks/useClerkUser";
import useImageChallengeAppConfig from "@hooks/useImageChallengeAppConfig";
import { formatNumberWithAbbreviation } from "@memorang/helpers";
import { useQuery } from "@tanstack/react-query";
import pluralize from "pluralize";
import { fetchInsights } from "../queries";
import type { TagDetail } from "../queries/schema";

type Params = {
	itemId?: number;
	trigger?: boolean;
};

const calculateStrengthStats = (tagDetails: TagDetail[] | undefined | null) => {
	if (!tagDetails?.length) {
		return [];
	}

	const stats = [];

	// Best specialty (only if there's a non-zero high score)
	const bestSpecialty = tagDetails.reduce(
		(prev, current) => {
			if (current.distribution.high === 0) {
				return prev;
			}
			if (!prev || current.distribution.high > prev.distribution.high) {
				return current;
			}
			return prev;
		},
		null as TagDetail | null,
	);

	if (bestSpecialty) {
		stats.push({
			label: "Best",
			value: bestSpecialty.label,
			icon: tagIconUrlMap[bestSpecialty.label],
			score: bestSpecialty.distribution.high,
		});
	}

	// Worst specialty (only if there's a non-zero low score)
	const worstSpecialty = tagDetails.reduce(
		(prev, current) => {
			if (current.distribution.low === 0) {
				return prev;
			}
			if (!prev || current.distribution.low > prev.distribution.low) {
				return current;
			}
			return prev;
		},
		null as TagDetail | null,
	);

	if (worstSpecialty) {
		stats.push({
			label: "Worst",
			value: worstSpecialty.label,
			icon: tagIconUrlMap[worstSpecialty.label],
			score: worstSpecialty.distribution.low,
		});
	}

	// Favorite specialty (only if there's any activity)
	const favoriteSpecialty = tagDetails.reduce(
		(prev, current) => {
			const currentTotal = current.distribution.high + current.distribution.low;
			if (currentTotal === 0) {
				return prev;
			}
			if (
				!prev ||
				currentTotal > prev.distribution.high + prev.distribution.low
			) {
				return current;
			}
			return prev;
		},
		null as TagDetail | null,
	);

	if (favoriteSpecialty) {
		stats.push({
			label: "Favorite",
			value: favoriteSpecialty.label,
			icon: tagIconUrlMap[favoriteSpecialty.label],
			score:
				favoriteSpecialty.distribution.high +
				favoriteSpecialty.distribution.low,
		});
	}

	return stats;
};

export const useInsights = (params?: Params) => {
	const { trigger = true } = params ?? {};
	const { userPublicMetadataId } = useClerkUser();
	const { collectionId } = useImageChallengeAppConfig();
	const cmeEnabled = useAppStore((store) => store.app.cmeEnabled);
	const { getToken } = useAuth();
	const tenantId = useAppStore((state) => state.tenant.id);
	const { data: insightsData, isLoading } = useQuery({
		queryKey: [queryKeys.insights],
		queryFn: async () => {
			const token = await getToken({
				template: "jwt-with-user",
			});

			if (!token) {
				throw new Error("No token");
			}

			return await fetchInsights({
				userId: userPublicMetadataId,
				collectionId,
				token,
				tenantId,
			});
		},
		enabled: trigger && !!userPublicMetadataId,
	});

	const showEmptyState = !(insightsData?.data.challengeCompleted || isLoading);

	const overallProgressData = {
		title: "Key stats",
		subtitle: "Based on your activity so far",
		insights: "You'are a super star in Cardiology!",
		tooltip: "",
		stats: [
			{
				label: "Completed",
				value: formatNumberWithAbbreviation(
					insightsData?.data.challengeCompleted ?? 0,
				),
				icon: "arm-flex-outline",
				color: theme().palette.success.dark,
			},
			// {
			// 	label: "Percentile",
			// 	value: "4th",
			// 	icon: "podium-gold",
			// 	color: theme().palette.info.main,
			// },
			{
				label: "Accuracy",
				value: Number.isNaN(Number(insightsData?.data.accuracy))
					? "0%"
					: `${Math.round(Number(insightsData?.data.accuracy) * 100)}%`,
				icon: "target",
				color: theme().palette.warning.light,
			},
			...(cmeEnabled
				? [
						{
							label: "CME Earned",
							value: formatNumberWithAbbreviation(
								insightsData?.data.cmeEarned ?? 0,
							),
							icon: "certificate-outline",
							color: theme().palette.error.dark,
						},
					]
				: []),
		],
	};

	const overallAchievementsData = {
		title: "Your achievements",
		subtitle: "Based on your activity so far",
		insights: "You'are a super star in Cardiology!",
		tooltip: "",
		rank: insightsData?.data.achivements?.level ?? 0,
		xp: insightsData?.data.achivements?.currentXP ?? 0,
		xpToNextLevel: insightsData?.data.achivements?.remainingXP ?? 0,
		stats: [
			{
				label: "Rank",
				value: `Level ${insightsData?.data.achivements?.level ?? 0}`,
				icon: "shield-star-outline",
			},
			{
				label: "Points earned",
				value: `${formatNumberWithAbbreviation(insightsData?.data.achivements?.currentXP ?? 0)} XP`,
				icon: "star-circle-outline",
			},
			{
				label: "To next rank",
				value: `${formatNumberWithAbbreviation(insightsData?.data.achivements?.remainingXP ?? 0)} XP`,
				icon: "ladder",
			},
		],
	};

	const calculateAvgGuessesPerChallenge = () => {
		const distribution = insightsData?.data.guessDistribution?.attempts ?? {
			"1": 0,
			"2": 0,
			"3": 0,
			"4": 0,
			"5": 0,
		};

		const totalAttempts = Object.keys(distribution).length;
		if (totalAttempts === 0) {
			return 0;
		}

		const weightedSum = Object.values(distribution).reduce((sum, count) => {
			return sum + count;
		}, 0);

		return weightedSum / totalAttempts;
	};

	const overallGuessDistributionData = {
		title: "Your guess distribution",
		subtitle: "Based on challenges completed",
		insights: "You'are a super star in Cardiology!",
		tooltip: "",
		numChallengesCompleted: insightsData?.data.challengeCompleted ?? 0,
		distribution: insightsData?.data.guessDistribution?.attempts ?? {
			"1": 0,
			"2": 0,
			"3": 0,
			"4": 0,
			"5": 0,
		},
		stats: [
			{
				label: "Challenges",
				value: formatNumberWithAbbreviation(
					insightsData?.data.challengeCompleted ?? 0,
				),
				icon: "check-circle-outline",
			},
			{
				label: "AVG Guesses",
				value: formatNumberWithAbbreviation(calculateAvgGuessesPerChallenge()),
				icon: "comment-question-outline",
			},
		],
	};

	const overallRankData = {
		title: "Your rank",
		subtitle: "Based on your activity so far",
		insights: "You'are a super star in Cardiology!",
		tooltip: "",
	};

	const overallPerformanceData = {
		title: "Peformance by system",
		subtitle: "Your accuracy on each speciality",
		insights: "You'are a super star in Cardiology!",
		tooltip: "",
		specialties: [
			{ name: "Cardiology", progress: 50 },
			{ name: "Dermatology", progress: 75 },
			{ name: "Neurology", progress: 25 },
			{ name: "Oncology", progress: 60 },
			{ name: "Pediatrics", progress: 80 },
			{ name: "Psychiatry", progress: 40 },
			{ name: "Urology", progress: 90 },
		],
	};

	const overallStreakData = {
		title: "Your streak",
		subtitle: "Ongoing stream you have accumulated",
		insights: "You'are a super star in Cardiology!",
		tooltip: "",
		stats: [
			{
				label: "Current",
				value: `${pluralize("day", insightsData?.data.streaks?.currentStreak ?? 0, true)}`,
				icon: "fire",
				color: theme().palette.warning.main,
			},
			{
				label: "Best",
				value: `${pluralize(
					"day",
					insightsData?.data.streaks?.bestStreak ?? 0,
					true,
				)}`,
				icon: "calendar-check-outline",
				color: theme().palette.warning.dark,
			},
			// {
			// 	label: "Ranking",
			// 	value: "Top 10%",
			// 	icon: "trophy-outline",
			// 	color: theme().palette.warning.light,
			// },
		],
	};

	const strengthsAndWeaknessData =
		insightsData?.data.tagDetails?.length &&
		insightsData?.data.tagDetails?.length > 0
			? {
					title: "Strengths and challenges",
					subtitle: `Based on the ${insightsData.data.tagDetails.length} specialties you've encountered`,
					insights: "You're a super star in Cardiology!",
					tooltip: "",
					stats: calculateStrengthStats(insightsData.data.tagDetails),
				}
			: undefined;

	return {
		overallProgressData,
		overallRankData,
		overallAchievementsData,
		overallGuessDistributionData,
		overallPerformanceData,
		overallStreakData,
		strengthsAndWeaknessData,
		isLoading,
		showEmptyState,
	};
};
