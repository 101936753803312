/* ----------------- Globals --------------- */
import { CollectNameOnboardingView } from "@memorang/ui";

import { pageViewTypes } from "@constants/tracking";
/* ----------------- Hooks --------------- */
import { getCollectNameOnboarding } from "@helpers/find-onboarding";
import { physicianRoles } from "@helpers/user";
import { useAppStore } from "@hooks/useAppStore";
import { trackPageView } from "analytics";
import { router } from "expo-router";
import { useEffect } from "react";
import { useNameOnboarding } from "../hooks/useNameOnboarding";

const CollectNameRouteContainer = () => {
	const {
		firstName,
		lastName,
		handleSetName,
		handleClickContinue,
		selectedAvatarUrl,
		updatingUserInfo,
		selectedSubspecialitesIconUrls,
		subRoleLabel,
		primaryRoleLabel,
		primaryRoleType,
	} = useNameOnboarding();

	useEffect(() => {
		trackPageView({ type: pageViewTypes.onboardingName });
	}, []);

	const handleBack = () => {
		router.setParams({
			step: "specialty",
		});
	};

	const onboarding = useAppStore((store) => store.onboarding);

	const avatarType = useAppStore((store) => store.app.avatarType);

	const onboardingNameStep = getCollectNameOnboarding(onboarding!);

	const roleAppbartitle = onboardingNameStep?.title!;

	const footerImagesUrls = selectedSubspecialitesIconUrls?.filter(
		(url): url is string => url !== undefined,
	);

	return (
		<CollectNameOnboardingView
			title={roleAppbartitle}
			handleSetName={handleSetName}
			handleClickContinue={handleClickContinue}
			showBackButton
			firstName={firstName}
			avatarType={avatarType}
			lastName={lastName}
			handleBack={handleBack}
			selectedAvatarUrl={selectedAvatarUrl}
			primaryRole={
				primaryRoleLabel && primaryRoleType
					? physicianRoles.includes(primaryRoleType)
						? "Physician"
						: primaryRoleLabel
					: undefined
			}
			subRole={subRoleLabel}
			updatingUserInfo={updatingUserInfo}
			footerImagesUrls={footerImagesUrls}
		/>
	);
};

export default CollectNameRouteContainer;
