import ChangeUserNameRouteView from "@features/account/components/ChangeUserNameRouteView";
import { CustomAppbar } from "components/index";

const ChangeUsernameRoute = () => {
	return (
		<>
			<CustomAppbar
				showCommandBar={false}
				showHambugerMenu
				mode="medium"
				align="flex-start"
				back
				showSettings={false}
				showUserMenu={false}
				options={{
					headerTitle: "Change Username",
				}}
			/>
			<ChangeUserNameRouteView />
		</>
	);
};

export default ChangeUsernameRoute;
