import { Platform } from "react-native";
/* -----------------Globals--------------- */
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { SessionAnalysis } from "@memorang/features";
/* -----------------UI--------------- */
import ReportCardsContainer from "./ReportCardsContainer";
import { SuggestedActionsChips } from "./SuggestedActionsChips";
import ReviewAnswersDialog, {
	type CheckboxStateMap,
} from "./dialogs/ReviewAnswersDialog";

/* -----------------Helpers & Hooks--------------- */
import { getReportData } from "../helpers/report-data";

/* -----------------Types--------------- */
import type { ReportDetails } from "@memorang/types";

/* -----------------Child components--------------- */

import { useExamContext } from "@features/exam";
import { currentAppVersion } from "@helpers/expo-extras";
import { useAppStore } from "@hooks/useAppStore";
import { useDevice } from "@hooks/useDevice";
import { useAuthContext } from "@memorang/applets";
import {
	ScrollContainer,
	StudySessionMasteryTagProgressCard,
	StudySessionPerformanceCard,
	StudySessionSummaryCard,
} from "@memorang/ui";
import UpSaleCard from "./UpSaleCard";

import { isDemoMode } from "@constants/common";
import { useTagsStore } from "@features/dashboard/";
import type { TagItem } from "@helpers/content/types";
import { formatDate } from "@helpers/date";
import { isWeb } from "@helpers/platform";
import { useLocalSearchParams, useRouter } from "expo-router";
import { useProductAccess } from "../hooks/useProductAccess";

type Props = {
	reportDetails: ReportDetails;
	showReviewDialog: boolean;
	handleContinue: () => void;
	handleClickActionChip: (action: string) => void;
	checkboxStateMap: CheckboxStateMap;
	handleChangeCheckboxStateMap: (key: keyof CheckboxStateMap) => void;
	handleReviewButtonClick: () => void;
	handleCloseReviewDialog: () => void;
	selectedItemTypeForReview?: "QUESTION";
	handleReview: (checkboxStateMap: CheckboxStateMap) => void;
	fetchingReviewItems: boolean;
	isSummativeTest?: boolean;
	handleUpdateIncorrectCheckboxStateMap: () => void;
	handleTracking: (
		eventName: string,
		params?: Record<string, string | number>,
	) => void;
};
export const ReportView = ({
	reportDetails,
	showReviewDialog,
	handleContinue,
	handleClickActionChip,
	checkboxStateMap,
	isSummativeTest,
	handleChangeCheckboxStateMap,
	handleReviewButtonClick,
	handleCloseReviewDialog,
	selectedItemTypeForReview,
	handleReview,
	fetchingReviewItems,
	handleTracking,
	handleUpdateIncorrectCheckboxStateMap,
}: Props) => {
	const {
		summaryCardData,
		masteryCardData,
		masteryTagProgressData,
		reviewListItemsData,
		sessionId,
	} = getReportData(reportDetails, isSummativeTest);

	const isImageChallengeApp = useAppStore(
		(state) => state.app.isImageChallengeApp,
	);

	const showFullReport = !reportDetails?.sessionSummaryConfig?.length;

	const { newUserInfo } = useAuthContext();

	const { id } = useLocalSearchParams<{
		id: string;
	}>();

	const {
		currentExamContext: { examName },
	} = useExamContext();

	const { hasPracticeTestAccess, hasStudyPackAccess, showStudyPackViews } =
		useProductAccess();

	const { isMobile, isMediumWindowSize } = useDevice();

	const {
		companyName,
		app: { name: appName, scoreRanges },
		tenant: { name: tenantName },
		reportView,
	} = useAppStore((store) => ({
		companyName: store.companyName,
		app: store.app,
		tenant: store.tenant,
		reportView: store.reportView,
	}));

	const reviewEnabled = reportView?.practiceTest?.reviewEnabled;

	const userName = newUserInfo?.name!;
	const email = newUserInfo?.email;

	const setCurrentSelectedMasteryItems = useTagsStore(
		(state) => state.setCurrentSelectedMasteryItems,
	);
	const productName = `${examName} ${
		isSummativeTest ? "Practice Tests" : "Study-Pack"
	}`;

	const insets = useSafeAreaInsets();

	const router = useRouter();

	const selectedReviewListItemsData =
		reviewListItemsData?.[selectedItemTypeForReview!];

	const handleClickCard = (items: TagItem[], title: string) => {
		setCurrentSelectedMasteryItems(items);
		router.push({
			pathname: "/(protected)/session/[id]/report/detailed-list/mastery",
			params: {
				type: title,
				id,
				examName: reportDetails?.examName,
			},
		});
	};
	const masteryTagsCards = masteryTagProgressData?.map((item) => {
		return (
			<StudySessionMasteryTagProgressCard
				handleClickCard={() => handleClickCard(item.items, item.title)}
				key={item.title}
				{...item}
			/>
		);
	});

	const getReviewEnabled = () => {
		switch (reportDetails?.reportSessionType) {
			case "DIAGNOSTIC":
				return isWeb ? true : !isMobile;
			case "PRACTICETEST":
				return reviewEnabled && (isWeb ? true : !isMobile);
			case "STUDYPACK":
				return true;
		}
	};
	const showReview = getReviewEnabled();

	const cards = [
		<StudySessionSummaryCard
			{...summaryCardData}
			handleContinue={handleContinue}
			key={summaryCardData?.title}
			scoreRanges={scoreRanges!}
		/>,
		...(showFullReport
			? [
					<SuggestedActionsChips
						showReview={showReview}
						showStudy={showStudyPackViews && !isImageChallengeApp}
						handleClickActionChip={handleClickActionChip}
						key={reportDetails?.examName}
					/>,
				]
			: []),
		...((isSummativeTest && isWeb) || isDemoMode
			? [
					<SessionAnalysis
						sessionId={sessionId}
						context={examName}
						handleTracking={handleTracking}
						key={sessionId}
						sessionMetadata={{
							sessionTitle: reportDetails.examName,
							sessionDate: formatDate(reportDetails.sessionDate),
							numItems: reportDetails.numQuestions,
						}}
						feedbackDialogParams={{
							currentAppVersion,
							userName,
							email,
							product: productName,
							appName,
							platform: Platform.OS.toUpperCase(),
							view: "Session Report",
							tenantId: tenantName,
							context: examName,
							feature: "AI Session Analysis",
							sessionId,
							companyName,
						}}
					/>,
				]
			: []),
		...(showFullReport
			? [
					<UpSaleCard
						hasPracticeTestAccess={hasPracticeTestAccess}
						hasStudyPackAccess={hasStudyPackAccess}
						showStudyPack={showStudyPackViews}
						key={productName}
					/>,
				]
			: []),
		...(masteryCardData && showFullReport
			? [
					<StudySessionPerformanceCard
						checkboxStateMap={checkboxStateMap}
						handleChangeCheckboxStateMap={handleChangeCheckboxStateMap}
						{...masteryCardData}
						handleReviewButtonClick={handleReviewButtonClick}
						showReview={showReview}
						key={masteryCardData?.title}
					/>,
				]
			: []),
		...masteryTagsCards,
	];
	return (
		<ScrollContainer
			contentContainerStyle={{
				paddingTop: 48,
				paddingBottom: 88 + insets.bottom,
				paddingHorizontal: 8,
				gap: 16,
			}}
			maxWidth={isMediumWindowSize ? "sm" : "md"}
		>
			<ReportCardsContainer cards={cards} />
			{showReviewDialog && (
				<ReviewAnswersDialog
					open={showReviewDialog}
					handleChangeCheckboxStateMap={handleChangeCheckboxStateMap}
					checkboxStateMap={checkboxStateMap}
					sections={selectedReviewListItemsData?.items?.sections!}
					handleClose={handleCloseReviewDialog}
					handleReview={handleReview}
					loading={fetchingReviewItems}
					numIncorrect={selectedReviewListItemsData?.numIncorrect}
					handleUpdateIncorrectCheckboxStateMap={
						handleUpdateIncorrectCheckboxStateMap
					}
				/>
			)}
		</ScrollContainer>
	);
};
