/* ----------------- External Imports --------------- */
import { FlatList } from "react-native-gesture-handler";
import ChallengeCard from "../ChallengeCard";
import useResponsiveCardLayout from "../ImagesCarousel/useResponsiveCardLayout";
import type { HandleTrackVideo } from "../VideoPlayer";
import type { ChallengesData } from "./types";
interface Props {
	challenges: ChallengesData[];
	handleClickCard?: (
		id: number,
		type: string,
		isWeeklyChallenge?: boolean,
	) => void;
	isPremiumUser?: boolean;
	handleTrackVideo?: HandleTrackVideo;
}
export const CardsList = ({
	challenges,
	handleClickCard,
	handleTrackVideo,
	isPremiumUser,
}: Props) => {
	const { cardWidth } = useResponsiveCardLayout(challenges.length);
	return (
		<FlatList
			horizontal
			data={challenges}
			keyExtractor={(item) => item.id.toString()}
			contentContainerStyle={{
				paddingHorizontal: 16,
				gap: 16,
				paddingBottom: 4,
			}}
			showsHorizontalScrollIndicator={false}
			renderItem={({ item }) => {
				return (
					<ChallengeCard
						handleTrackVideo={handleTrackVideo}
						fullWidth={false}
						cardWidth={cardWidth}
						locked={item.accessType === "PREMIUM" && !isPremiumUser}
						onPress={
							handleClickCard
								? () => handleClickCard(item.id, item.type, false)
								: undefined
						}
						{...item}
					/>
				);
			}}
		/>
	);
};
