import { useAuth } from "@clerk/clerk-expo";
import { useAppStore } from "@hooks/useAppStore";
import { Box } from "@memorang/ui";
import { useState } from "react";
import { Button } from "react-native-paper";

const Sandbox = () => {
	const [isLoading, setIsLoading] = useState(false);

	const tenantId = useAppStore((state) => state.tenant.id);
	const auth = useAuth();

	const handleFetch = async () => {
		setIsLoading(true);
		const token = await auth.getToken({
			template: "jwt-with-user",
		});
		try {
			const response = await fetch(
				"https://prod.api.memorang.io/user-events/sample-event",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `${token}`,
						tenantId: tenantId,
					},
					body: JSON.stringify({ itemId: 12121 }),
				},
			);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			console.info("Response:", data);
		} catch (error) {
			console.error("Fetch error:", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box margin={64}>
			<Button onPress={handleFetch} loading={isLoading} disabled={isLoading}>
				Test Fetch
			</Button>
		</Box>
	);
};

export default Sandbox;
