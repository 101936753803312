import type { Article } from "../../../../../types";

export const Article577: Article = {
	id: 577,
	rsuiteId: "909d5142-7c34-442e-bb81-ea6184bd9411",
	type: "scientific article",
	title:
		"Safety of Traditional Growing Rods in Patients with Early-Onset Congenital Scoliosis Associated with Type-I Split Cord Malformation",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=909d5142-7c34-442e-bb81-ea6184bd9411&type=jpeg&name=JBJS.23.01454f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Safety of Traditional Growing Rods in Patients with
                Early-Onset Congenital Scoliosis Associated with Type-I Split Cord Malformation</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Heng Sun,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Yizhen Huang,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Background:</span> Literature regarding the application of
                        traditional growing rod (TGR) instrumentation in patients with early-onset congenital scoliosis
                        with type-I split cord malformation is scarce. The purpose of the present study was to assess
                        the safety and effectiveness of TGR surgery and repeated lengthening procedures in patients with
                        congenital scoliosis with type-I split cord malformation not treated with prophylactic osseous
                        spur excision.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Methods:</span> Thirteen patients with early-onset congenital
                        scoliosis associated with type-I split cord malformation and a stable neurologic status between
                        March 2009 and July 2020 were recruited. All patients underwent primary TGR surgery and
                        subsequent repeated lengthening procedures without osseous spur excision by the same surgical
                        team. Clinical information and radiographic data from the preoperative, postoperative, and
                        latest follow-up periods were collected.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Results:</span> The mean preoperative Cobb angle of the major coronal
                        curve was 74.62° ± 25.59°, the mean early postoperative angle was 40.23° ± 17.89°, and the mean
                        latest follow-up angle was 40.62° ± 16.60°. The scoliotic deformity correction percentage was
                        46.81% ± 12.26% after the initial operation and 45.08% ± 15.53% at the latest follow-up.
                        Compared with the preoperative values, significant improvements were observed in the coronal and
                        sagittal balance early postoperatively and at the latest follow-up (p &lt; 0.05 for all). The
                        average annual amounts of spinal height gained were 15.73 ± 5.95 mm at T1-S1, 8.94 ± 3.94 mm at
                        T1-T12, and 12.02 ± 6.70 mm between the instrumented segments. The total height gained at T1-S1
                        and T1-T12 was 72.18 ± 28.74 mm and 37.62 ± 12.53 mm, respectively. No intraoperative
                        neurophysiological monitoring events were observed, and no case of neurological deficit was
                        observed postoperatively or during follow-up.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Patients without neurologic deficit and having a
                        stable neurologic exam who have early-onset congenital scoliosis associated with type-I split
                        cord malformation can safely and effectively undergo TGR surgery, followed by repeated
                        lengthening procedures, without prophylactic osseous spur excision.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level IV</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Early-onset scoliosis is a spinal deformity that manifests
                  in children &lt;10 years of age<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. The
                  primary surgical choices for early-onset scoliosis are the traditional growing rod (TGR) and
                  magnetically controlled growing rod techniques, which allow continued spinal and chest growth<a
                    href="" data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">4</span></a>.
                  Early-onset scoliosis can be classified as congenital, neuromuscular, syndromic, or idiopathic<a
                    href="" data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">5</span></a>. As congenital scoliosis often presents alongside various
                  underlying intraspinal anomalies<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a>,
                  particularly split cord malformations<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">7</span></a>,
                  early-onset congenital scoliosis is challenging for surgeons to treat. McMaster, in a study of 251
                  patients with congenital scoliosis, reported that the prevalence of split cord malformation was
                  16.3%<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">8</span></a>. Feng
                  et al. found that split cord malformation is a common intraspinal anomaly in patients with congenital
                  scoliosis<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">9</span></a>. The
                  pathological feature of split cord malformation is that the spinal cord is divided into 2 parts, each
                  enclosed within its own dural tube and separated by either an osseous (type I) or fibrous septum (type
                  II)<a href="" data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">11</span></a>.
                  Because of the special pathological characteristics of split cord malformation, identifying a safe and
                  effective approach for the treatment of early-onset congenital scoliosis associated with split cord
                  malformation is important.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The treatment of early-onset congenital scoliosis with
                  split cord malformation is a complex and challenging endeavor that necessitates a multidisciplinary
                  approach, particularly for patients with type-I split cord malformation. Patients with early-onset
                  scoliosis with type-II split cord malformation can safely undergo TGR surgery and repeated lengthening
                  procedures without treatment of the type-II split cord malformation<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">13</span></a>.
                  However, the presence of a rigid osseous spur in patients with type-I split cord malformation raises
                  concerns about potential spinal cord tethering during surgery, which may lead to an increased risk of
                  neurological complications<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">14</span></a>.
                  Therefore, the general recommendation is to excise the osseous spur in patients with type-I split cord
                  malformation before performing any spinal correction or growing rod surgery, regardless of
                  neurological deficits<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">16</span></a>. Shen et al. previously reported that 1-stage correction
                  and fusion surgery can be performed safely and feasibly without intervention in patients with type-I
                  split cord malformation with skeletal maturation and stable or intact neurological functionality<a
                    href="" data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">17</span></a>. However, the safety and efficacy of repeated lengthening
                  procedures without treating the osseous spur in children with early-onset congenital scoliosis
                  associated with type-I split cord malformation remain unknown.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, no study has explored the use of TGR
                  instrumentation for the treatment of early-onset congenital scoliosis associated with type-I split
                  cord malformation without osseous spur resection. Consequently, the purpose of the present study was
                  to assess the safety and effectiveness of TGR surgery and repeated lengthening procedures without
                  prophylactic osseous spur excision.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Sample</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">This was a retrospective cohort study. In March 2009, the
                  Institutional Board of Peking Union Medical College Hospital approved the use of TGR for the treatment
                  of congenital scoliosis associated with type-I split cord malformation. Before surgery, every patient
                  underwent a thorough neurological evaluation, including assessment of sensory perception, strength in
                  the extremities, abdominal reflexes, and tendon reflexes. The medical history of each patient was
                  meticulously documented to ascertain the presence of progressive neurological deficits. Thirteen
                  patients were recruited between March 2009 and July 2020. The inclusion criteria included confirmed
                  congenital scoliosis associated with type-I split cord malformation and neurological stability &gt;2
                  years before primary TGR surgery, with &gt;2 years of follow-up after surgery. Patients with unstable
                  neurological symptoms who underwent preventive osseous spur resection for neurological defects were
                  excluded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Strategy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">During the initial surgery, distinct subperiosteal
                  incisions were made at the proximal and distal anchor sites. Growing rods were placed overlappingly
                  underneath the muscle and connected with use of rod-to-rod connectors. To prevent spontaneous fusion,
                  the regions between the instrumented vertebrae remained untouched. The primary focus of the initial
                  surgery was the bilateral placement of growing rods; however, in cases involving substantial rotation
                  of the apical vertebrae, a noticeable rib hump, thin soft tissue, or undernutrition, a unilateral
                  growth rod was used. Subsequent lengthening procedures were performed during follow-up, between 6 and
                  12 months postoperatively. In cases of mechanical failure, such as rod fractures, revision surgery was
                  performed simultaneously with the lengthening procedures. The surgical procedures were performed by
                  the same surgical team. Spinal cord monitoring was mandatory during the procedures. Somatosensory and
                  motor-evoked potentials were continuously monitored during the primary surgical procedure as well as
                  during lengthening procedures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                and Clinical Assessments</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Preoperative, initial postoperative, and latest follow-up
                  standing posteroanterior and lateral radiographs were evaluated by an impartial observer. Both the
                  major coronal curve and kyphosis were measured according to the Cobb method<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">18</span></a>. Apical vertebral translation, trunk shift, and the
                  sagittal vertical axis were measured as previously described<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">19</span></a>.
                  The T1-S1 and T1-T12 heights were measured on standing lateral radiographs<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">20</span></a>. A vertical distance between 2 parallel lines passing
                  through the center of the UIV (upper instrumented vertebra) and the center of the LIV (lower
                  instrumented vertebra) was used to measure the height of the instrumented segment. Clinical
                  complications such as rod breakage, implant loosening, infection, and neurological complications in
                  the immediate postoperative period and subsequent follow-up were recorded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Data were analyzed with use of SPSS for Windows (version
                  27.0; IBM). To assess the differences between the preoperative and postoperative, preoperative and
                  latest follow-up, and postoperative and latest follow-up radiographic measurements, paired sample t
                  tests were used. The level of significance was set at p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Fifteen patients were recruited. Of these, 2 patients with
                  progressive neurological deficits who underwent preventative osseous spur resection before primary TGR
                  surgery were excluded. However, 1 female patient with left lower-extremity weakness (Medical Research
                  Council grade 4) before surgery was enrolled because the neurological symptoms were stable for &gt;2
                  years and did not progress. Therefore, 13 patients (5 male and 8 female) with an average age (and
                  standard deviation) of 8.76 ± 2.35 years (range, 3.17 to 12.25 years) at initial surgery were enrolled
                  in the present study (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). Two
                  patients over the age of 10 years with a Risser sign of grade 0 and open triradiate cartilage also
                  underwent primary TGR surgery to enhance spinal and chest growth. Three patients (23%) had failure of
                  formation, and 10 patients (77%) had the mixed type of congenital scoliosis. Eight patients (62%)
                  graduated from the lengthening process (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Data on the 13 Patients<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 409.15px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.43461);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Patient</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sex</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Type of
                          CS</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Level of
                          Osseous Spur</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Level of
                          SCM</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Apex of
                          Major Curve</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Lengthening Operations</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Average
                          Interval Between Lengthening Procedures <span data-id="emphasis_31"
                            class="annotation emphasis" style="position: unset;">(mo)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Duration
                          of Follow-up <span data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(mo)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Graduated</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Graduation
                          Age <span data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Complication</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">M</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.25</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mixed</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T4-T12</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.25</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">M</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.33</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">FF</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T2-T9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.67</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.67</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">F</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.17</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mixed</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T4</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T2-T9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">4</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">M</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.08</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">FF</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T3-T12</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T11-T12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">F</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.25</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">FF</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T3-T11</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.92</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">F</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.25</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mixed</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T4-T12</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.89</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">89</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.67</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Rod breakage</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">M</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.92</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mixed</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T8-T10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.88</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.17</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">F</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.75</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mixed</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T5-T10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.44</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">F</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.58</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mixed</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T4-T8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">F</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.08</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mixed</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T6-T12</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.08</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">11</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">F</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.33</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mixed</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T4-T8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.33</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">12</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">F</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.00</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mixed</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T2-T3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T1-T5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.50</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">13</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">M</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.83</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mixed</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T7-T12</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">—</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CS = congenital scoliosis, SCM = split cord malformation, FF = failure
                        of formation, Mixed = mixed-type congenital scoliosis, Graduated = has undergone final
                        fusion.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Seventy-seven lengthening procedures were performed
                  overall, and ≥3 lengthening procedures were performed in all patients. The mean number of lengthening
                  procedures was 5.92 ± 2.29 (range, 3 to 9). The average time between lengthening procedures was 9.34 ±
                  1.71 months (range, 6 to 12 months). The average duration of follow-up was 56.31 ± 18.18 months
                  (range, 30 to 89 months). Detailed information on the level of the osseous spur, apex of the major
                  curve, and final fusion state are presented in <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Deformity
                Correction</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">The mean Cobb angle of the major coronal curve in the
                  patients with early-onset congenital scoliosis associated with type-I split cord malformation was
                  74.62° ± 25.59° (range, 37° to 119°) preoperatively, 40.23° ± 17.89° (range, 17° to 74°) after the
                  initial operation, and 40.62° ± 16.60° (range, 19° to 69°) at the latest follow-up (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). The correction percentage of the major coronal curve was
                  46.81% ± 12.26% early postoperatively and 45.08% ± 15.53% at the time of the latest follow-up. No
                  significant difference was observed in the correction rate between the early postoperative and latest
                  follow-up periods (p = 0.850). Kyphosis was corrected early postoperatively (p &lt; 0.001) and at the
                  time of the latest follow-up (p &lt; 0.001); however, no significant difference (p = 0.658) was
                  observed between the early postoperative and latest follow-up periods in terms of the correction
                  percentage (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Preoperative and Postoperative Radiographic
                          Parameters<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 721px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Major
                          Coronal Curve <span data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Kyphosis
                          <span data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Trunk
                          Shift <span data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">AVT <span
                            data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">SVA <span
                            data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop.<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.62 ±
                          25.59</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.38 ±
                          12.30</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.88 ±
                          22.78</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92.25 ±
                          28.55</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40.41 ±
                          18.14</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postop.<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40.23 ±
                          17.89</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.54 ±
                          10.76</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.66 ±
                          11.76</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.81 ±
                          23.49</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.82 ±
                          9.21</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Latest follow-up<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40.62 ±
                          16.60</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.85 ±
                          13.86</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.55 ±
                          11.72</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52.35 ±
                          22.15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.47 ±
                          16.96</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">P value</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop. vs.
                          postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.003</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.010</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop. vs. latest
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.015</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.015</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postop. vs. latest
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.850</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.658</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.973</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.038</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.078</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The level of significance was set at p &lt; 0.05. AVT = apical
                        vertebral translation, SVA = sagittal vertical axis, Postop. = 1 week after the initial surgical
                        procedure.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Trunk shift, apical vertebral translation, and the
                  sagittal vertical axis significantly improved from 37.88 ± 22.78 mm, 92.25 ± 28.55 mm, and 40.41 ±
                  18.14 mm preoperatively to 22.66 ± 11.76 mm (p = 0.003), 57.81 ± 23.49 mm (p &lt; 0.001), and 23.82 ±
                  9.21 mm (p = 0.010) postoperatively and to 22.55 ± 11.72 mm (p = 0.015), 52.35 ± 22.15 mm (p &lt;
                  0.001), and 22.47 ± 16.96 mm (p = 0.015) at the latest follow-up, respectively (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Spinal
                Height Gained</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The height gained at T1-S1, T1-T12, and the instrumented
                  segment significantly increased (p &lt; 0.001) after the initial surgical procedure (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). Compared with the early postoperative measurements, the
                  heights of T1-S1, T1-T12, and the instrumented segment increased from 294.01 ± 60.90 to 366.19 ± 70.73
                  mm (p &lt; 0.001), from 162.92 ± 41.58 to 200.54 ± 44.46 mm (p &lt; 0.001), and from 212.62 ± 40.71 to
                  268.32 ± 47.06 mm (p &lt; 0.001), respectively, at the latest follow-up (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). The total spinal height gained at these locations was 72.18
                  ± 28.74 mm, 37.62 ± 12.53 mm, and 55.70 ± 29.66 mm, respectively (<a href=""
                    data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). The annual height gain at T1-S1, T1-T12, and the
                  instrumented segment was 15.73 ± 5.95 mm, 8.94 ± 3.94 mm, and 12.02 ± 6.70 mm, respectively (<a
                    href="" data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Data on Spinal Height in the 13 Patients</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 384.662px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.605893);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Patient</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Preop. <span data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Postop. <span data-id="emphasis_40" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Latest Follow-up <span data-id="emphasis_41"
                            class="annotation emphasis" style="position: unset;">(mm)</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">T1-T12</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">T1-S1</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Instrumented Segment</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">T1-T12</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">T1-S1</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Instrumented Segment</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">T1-T12</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">T1-S1</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Instrumented Segment</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">185.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">349.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">190.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">234.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">420.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">215.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">292.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">533.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">341.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">167.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">223.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">200.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">180.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">245.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">216.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">213.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">356.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">288.5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">132.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">244.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">169.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">157.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">286.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">197.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">206.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">379.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">276.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">106.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">260.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">213.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">117.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">287.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">230.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">157.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">318.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">250.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">83.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">169.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">90.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">103.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">202.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">110.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">152.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">285.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">167.3</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">130.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">238.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">210.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">143.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">260.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">221.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">184.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">362.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">305.5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">157.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">273.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">235.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">170.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">296.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">263.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">204.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">357.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">318.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">93.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">184.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">151.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">103.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">208.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">172.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">140.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">254.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">219.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">167.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">280.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">180.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">174.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">291.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">186.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">206.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">353.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">227.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">217.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">350.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">240.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">225.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">364.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">253.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">258.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">444.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">306.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">185.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">330.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">195.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">193.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">346.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">204.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">242.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">423.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">242.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">12</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">165.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">307.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">222.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">174.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">329.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">247.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">186.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">351.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">262.5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">13</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">130.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">263.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">225.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">139.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">282.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">244.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">159.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">340.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">281.2</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Comparisons of Spinal Heights<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 629px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">T1-S1</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">T1-T12</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Instrumented Segment</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">267.33 ±
                          57.29</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">147.81 ±
                          39.27</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">194.29 ±
                          40.57</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">294.01 ±
                          60.90</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">162.92 ±
                          41.58</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">212.62 ±
                          40.71</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Latest follow-up<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">366.19 ±
                          70.73</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">200.54 ±
                          44.46</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">268.32 ±
                          47.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total height gained<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72.18 ±
                          28.74</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.62 ±
                          12.53</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55.70 ±
                          29.66</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Annual height gain<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(mm/yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.73 ±
                          5.95</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.94 ±
                          3.94</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.02 ±
                          6.70</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">P value</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop. vs.
                          postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop. vs. latest
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postop. vs. latest
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The level of significance was set at p &lt; 0.05. Postoperative = 1
                        week after initial surgical procedure.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Postoperative Complications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Overall, postoperative complications were observed in 1
                  patient (8%), who had rod breakage. No infections or implant loosening occurred. The rod breakage
                  occurred after the eighth lengthening procedure, likely because of fatigue resulting from the repeated
                  lengthening operations. Revision surgery for the patient was performed simultaneously during the ninth
                  lengthening procedure. No patient experienced transient or permanent neurological complications
                  following the initial TGR surgery, following subsequent lengthening procedures, or by the time of
                  final follow-up. The neurological symptoms of the patient who exhibited left lower-extremity weakness
                  (Medical Research Council grade 4) before surgery were not aggravated by the TGR surgery or subsequent
                  lengthening procedures. The left lower-extremity strength was maintained at grade 4 during the early
                  postoperative and final follow-up periods. No changes in neuromonitoring or neurological events were
                  observed in any patients during either the TGR application procedure or the lengthening procedures. No
                  cases of neurological deficits were observed at the latest follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Congenital scoliosis often presents with various
                  underlying intraspinal anomalies, with split cord malformation being a common occurrence<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">22</span></a>. Split cord malformation is characterized by the division
                  of the spinal cord into 2 parts, separated by either an osseous septum (type I) or a fibrous septum
                  (type II)<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">23</span></a>.
                  Patients with early-onset scoliosis associated with type-II split cord malformation can safely undergo
                  TGR surgery, followed by repeated lengthening procedures, without treatment of the type-II split cord
                  malformation<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">13</span></a>. However, in type-I split cord malformation, the presence
                  of a rigid osseous spur raises concerns about potential spinal cord tethering during surgery,
                  increasing the risk of neurological complications<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">14</span></a>.
                  Traditionally, in patients with type-I split cord malformation, the protocol has been to excise the
                  osseous spur before spinal surgery to prevent neurological deficits<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">25</span></a>. McMaster recommended the prophylactic removal of
                  intraspinal anomalies in children &lt;6 years of age with congenital scoliosis, regardless of their
                  neurological status<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">8</span></a>.
                  However, our previous study provided evidence that 1-stage correction and fusion surgery can be safely
                  and feasibly performed without intervention for the treatment of type-I split cord malformation in
                  adolescents with scoliosis with stable or intact neurological function<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">17</span></a>. Therefore, the main question is whether prophylactic
                  neurosurgical intervention is necessary for patients with early-onset congenital scoliosis associated
                  with type-I split cord malformation who have stable or intact neurological function before they
                  undergo primary TGR surgery and subsequent repeated lengthening procedures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Many studies have investigated the advantages and
                  disadvantages of 2-stage operative treatment for patients with type-I split cord malformation. Osseous
                  spur excision is a reasonable procedure for patients with deteriorating neurological symptoms or
                  signs. However, prophylactic osseous spur excision remains controversial for patients with intact or
                  stable neurological status. For example, Miller et al. reported that, among 33 patients who underwent
                  spur excision, 22 patients (67%) experienced no change in their neurological condition, 1 patient
                  experienced a postoperative deterioration in symptoms, 9 patients (27%) showed improvement, and 1
                  patient had 1 symptom improve and another symptom worsen after the operation<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">26</span></a>. Consequently, prophylactic detethering may not be a
                  reliable means of improving postoperative neurological status. Moreover, complications following
                  osseous spur excision remain a concern, with reported risks of neurological deterioration,
                  cerebrospinal fluid leakage, and infection ranging from 7% to 31%<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">28</span></a>. Prophylactic detethering can lead to the removal of the
                  posterior spinal elements, thereby reducing the bone graft bed and causing osseous defects.
                  Traditional recommendations are often based on limited clinical experience or surgical opinions
                  without robust evidence-based support. Clinically, some children in whom a type-I split cord
                  malformation is left untreated, especially those with no or only minor neurological abnormalities, may
                  remain neurologically stable throughout their lifetime.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">In the present study, regardless of whether bilateral or
                  unilateral TGR treatment was performed, all patients with early-onset congenital scoliosis associated
                  with type-I split cord malformation who were neurologically intact or stable underwent TGR surgery and
                  lengthening procedures without osseous spur excision had safe and effective spinal deformity
                  correction and continued spinal height gain (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 1</a> and <a
                    href="" data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">2</a>). The major coronal curve and kyphosis were greatly improved
                  postoperatively, and the correction was maintained at the time of the latest follow-up. Significant
                  improvements were observed in both coronal balance and sagittal balance immediately postoperatively
                  and at the latest follow-up. The T1-T12 and T1-S1 heights increased from 162.92 and 294.01 mm to
                  200.54 and 366.19 mm, respectively, at the latest follow-up, with an average annual increase of 8.94
                  mm and 15.7 mm, respectively. These results suggest that TGR surgery and subsequent lengthening
                  procedures are effective for correcting spinal deformity and enhancing spinal height in patients with
                  early-onset congenital scoliosis associated with type-I split cord malformation. In the present study,
                  no patient experienced transient or permanent neurological complications following TGR surgery,
                  following subsequent lengthening procedures, or at the time of the latest follow-up. Thus, TGR surgery
                  and repeated lengthening procedures without prophylactic osseous spur excision are safe and effective
                  for patients in whom the neurological status is intact or stable. Furthermore, spinal cord monitoring
                  is mandatory for every TGR procedure in patients with early-onset congenital scoliosis, especially
                  those undergoing high-risk corrective and lengthening procedures for type-I split cord malformation
                  when an osseous spur is not treated.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01454f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=909d5142-7c34-442e-bb81-ea6184bd9411&type=jpeg&name=JBJS.23.01454f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_36"
                            class="annotation strong" style="position: unset;">Figs. 1-A through 1-H</span> Patient 4. A
                          9-year-old boy who had been diagnosed with mixed-type congenital scoliosis with type-I split
                          cord malformation underwent TGR surgery and lengthening procedures. <span data-id="strong_37"
                            class="annotation strong" style="position: unset;">Figs. 1-A and 1-B</span> The preoperative
                          major coronal curve was 110°, and the kyphosis angle was 76°. <span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Figs. 1-C and 1-D</span> Preoperatively,
                          T2-weighted magnetic resonance imaging and computed tomography scans showed mixed-type
                          congenital scoliosis and type-I split cord malformation. <span data-id="strong_39"
                            class="annotation strong" style="position: unset;">Figs. 1-E and 1-F</span> Postoperatively,
                          the major coronal curve improved to 74°, and the kyphosis angle improved to 40°. <span
                            data-id="strong_40" class="annotation strong" style="position: unset;">Figs. 1-G and
                            1-H</span> At 40 months of follow-up, the scoliosis correction was well maintained, T1-T12
                          and T1-S1 heights had increased from 117.3 to 157.8 mm and from 287.6 to 318.4 mm,
                          respectively, and the neurological status was intact.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01454f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=909d5142-7c34-442e-bb81-ea6184bd9411&type=jpeg&name=JBJS.23.01454f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_41"
                            class="annotation strong" style="position: unset;">Figs. 2-A through 2-H</span> Patient 9. A
                          7-year-old girl who had been diagnosed with mixed-type congenital scoliosis with type-I split
                          cord malformation underwent TGR surgery and lengthening procedures. <span data-id="strong_42"
                            class="annotation strong" style="position: unset;">Figs. 2-A and 2-B</span> The preoperative
                          major coronal curve was 40°. <span data-id="strong_43" class="annotation strong"
                            style="position: unset;">Figs. 2-C and 2-D</span> Preoperatively, T2-weighted magnetic
                          resonance imaging and computed tomography scans showed congenital type-1 scoliosis and type-I
                          split cord malformation. <span data-id="strong_44" class="annotation strong"
                            style="position: unset;">Figs. 2-E and 2-F</span> Postoperatively, the major coronal curve
                          improved to 22°, and the T1-T12 and T1-S1 heights increased from 167.6 to 174.3 mm and from
                          280.2 to 291.5 mm, respectively. <span data-id="strong_45" class="annotation strong"
                            style="position: unset;">Figs. 2-G and 2-H</span> At 43 months of follow-up, the scoliosis
                          correction was well maintained, the T1-T12 and T1-S1 heights had increased from 174.3 to 206.3
                          mm and from 291.5 to 353.3 mm, respectively, and the neurological status was intact.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, the results of the present study indicate
                  that patients with early-onset congenital scoliosis with type-I split cord malformation who are
                  neurologically intact or stable can have safe and effective spinal deformity correction and continued
                  gains in spinal height following TGR surgery and repeated lengthening procedures without the need to
                  treat osseous spurs. These findings challenge the traditional surgical approach for the treatment of
                  type-I split cord malformation in patients with early-onset congenital scoliosis: in patients with
                  intact or stable neurological status, osseous spur excision may not be a mandatory part of the
                  treatment process.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Yang S</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Andras LM</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Redding GJ</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Skaggs DL</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Early-Onset Scoliosis: A Review
                  of History, Current Treatment, and Future Directions</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Pediatrics.</span> 2016 Jan;137(1).</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early-Onset%20Scoliosis%3A%20A%20Review%20of%20History%2C%20Current%20Treatment%2C%20and%20Future%20Directions&as_occt=title&as_sauthors=%20%22S%20Yang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Cheung JPY</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Cheung KM</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Current status of the
                  magnetically controlled growing rod in treatment of early-onset scoliosis: What we know after a decade
                  of experience</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Orthop Surg (Hong Kong).</span> 2019
                Sep-Dec;27(3):2309499019886945.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20status%20of%20the%20magnetically%20controlled%20growing%20rod%20in%20treatment%20of%20early-onset%20scoliosis%3A%20What%20we%20know%20after%20a%20decade%20of%20experience&as_occt=title&as_sauthors=%20%22JPY%20Cheung%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Jiang H</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Hai JJ</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Yin P</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Su Q</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Zhu S</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Pan A</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Hai Y</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Traditional growing rod for
                  early-onset scoliosis in high-altitude regions: a retrospective study</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J Orthop Surg Res.</span>
                2021 Aug 10;16(1):483.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Traditional%20growing%20rod%20for%20early-onset%20scoliosis%20in%20high-altitude%20regions%3A%20a%20retrospective%20study&as_occt=title&as_sauthors=%20%22H%20Jiang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Samadov F</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Ozdemir HM</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Talmac MA</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Erinc S</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Cakirturk S</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Cengiz B</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Traditional versus magnetically
                  controlled growing rods in early onset scoliosis surgical treatment</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2023
                Mar;32(3):889-98.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Traditional%20versus%20magnetically%20controlled%20growing%20rods%20in%20early%20onset%20scoliosis%20surgical%20treatment&as_occt=title&as_sauthors=%20%22F%20Samadov%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Cunin V</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Early-onset scoliosis: current
                  treatment</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Orthop Traumatol Surg Res.</span> 2015 Feb;101(1)(Suppl):S109-18.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early-onset%20scoliosis%3A%20current%20treatment&as_occt=title&as_sauthors=%20%22V%20Cunin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Shen J</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Wang Z</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Liu J</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Xue X</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Qiu G</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Abnormalities associated with
                  congenital scoliosis: a retrospective study of 226 Chinese surgical cases</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2013 May 1;38(10):814-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Abnormalities%20associated%20with%20congenital%20scoliosis%3A%20a%20retrospective%20study%20of%20226%20Chinese%20surgical%20cases&as_occt=title&as_sauthors=%20%22J%20Shen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Jayaswal A</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Kandwal P</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Goswami A</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Vijayaraghavan G</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Jariyal A</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Upendra BN</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Gupta A</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Early onset scoliosis with
                  intraspinal anomalies: management with growing rod</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2016
                Oct;25(10):3301-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20onset%20scoliosis%20with%20intraspinal%20anomalies%3A%20management%20with%20growing%20rod&as_occt=title&as_sauthors=%20%22A%20Jayaswal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> McMaster MJ</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Occult intraspinal anomalies and
                  congenital scoliosis</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1984 Apr;66(4):588-601.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=485039" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Feng F</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Tan H</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Li X</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Chen C</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Li Z</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Zhang J</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Shen J</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Radiographic characteristics in
                  congenital scoliosis associated with split cord malformation: a retrospective study of 266 surgical
                  cases</span>. <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">BMC
                  Musculoskelet Disord.</span> 2017 Oct 23;18(1):420.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20characteristics%20in%20congenital%20scoliosis%20associated%20with%20split%20cord%20malformation%3A%20a%20retrospective%20study%20of%20266%20surgical%20cases&as_occt=title&as_sauthors=%20%22F%20Feng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Sack AM</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Khan TW</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Diastematomyelia: Split Cord
                  Malformation</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Anesthesiology.</span> 2016 Aug;125(2):397.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diastematomyelia%3A%20Split%20Cord%20Malformation&as_occt=title&as_sauthors=%20%22AM%20Sack%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Tsai TJ</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Michaud LJ</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Split cord malformation</span>.
                <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Am J Phys Med
                  Rehabil.</span> 2013 Sep;92(9):839.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Split%20cord%20malformation&as_occt=title&as_sauthors=%20%22TJ%20Tsai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Zhang HQ</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Deng A</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Liu SH</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Wang YX</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Alonge E</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Posterior-Only Surgical
                  Correction with Heavy Halo-Femoral Traction for the Treatment of Severe and Rigid Congenital Scoliosis
                  Associated with Tethered Spinal Cord and Type II Split Cord Malformation</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">World Neurosurg.</span>
                2020 Jul;139:e151-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior-Only%20Surgical%20Correction%20with%20Heavy%20Halo-Femoral%20Traction%20for%20the%20Treatment%20of%20Severe%20and%20Rigid%20Congenital%20Scoliosis%20Associated%20with%20Tethered%20Spinal%20Cord%20and%20Type%20II%20Split%20Cord%20Malformation&as_occt=title&as_sauthors=%20%22HQ%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Ma H</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Shi B</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Liu D</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Liu Z</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Sun X</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Qiu Y</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Zhu Z</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Similar surgical outcomes of
                  the growing rod technique for treatment of early-onset scoliosis with versus without untreated
                  intraspinal anomalies</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">J Neurosurg Spine.</span> 2021 Mar 5;34(5):734-40.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Similar%20surgical%20outcomes%20of%20the%20growing%20rod%20technique%20for%20treatment%20of%20early-onset%20scoliosis%20with%20versus%20without%20untreated%20intraspinal%20anomalies&as_occt=title&as_sauthors=%20%22H%20Ma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Karim Ahmed A</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Howell EP</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Harward S</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Sankey EW</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Ehresman J</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Schilling A</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Wang T</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Pennington Z</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Gray L</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Sciubba DM</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Goodwin CR</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Split cord malformation in
                  adults: Literature review and classification</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Clin Neurol Neurosurg.</span> 2020
                Jun;193:105733.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Split%20cord%20malformation%20in%20adults%3A%20Literature%20review%20and%20classification&as_occt=title&as_sauthors=%20%22A%20Karim%20Ahmed%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Zhang HQ</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Deng A</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Tang MX</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Liu SH</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Wang YX</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Gao QL</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Posterior-only surgical
                  correction with heavy halo-femoral traction for the treatment of rigid congenital scoliosis associated
                  with split cord malformation</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">BMC Musculoskelet Disord.</span> 2020 Feb 13;21(1):98.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior-only%20surgical%20correction%20with%20heavy%20halo-femoral%20traction%20for%20the%20treatment%20of%20rigid%20congenital%20scoliosis%20associated%20with%20split%20cord%20malformation&as_occt=title&as_sauthors=%20%22HQ%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Singh PK</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Khandelwal A</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Singh A</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Ailawadhi P</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Gupta D</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Mahapatra AK</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Long-segment type 1 split cord
                  malformation with two-level split cord malformation and a single dural sac at the lower split</span>.
                <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Pediatr
                  Neurosurg.</span> 2011;47(3):227-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-segment%20type%201%20split%20cord%20malformation%20with%20two-level%20split%20cord%20malformation%20and%20a%20single%20dural%20sac%20at%20the%20lower%20split&as_occt=title&as_sauthors=%20%22PK%20Singh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Shen J</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Zhang J</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Feng F</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Qiu G</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Li Z</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Corrective Surgery for
                  Congenital Scoliosis Associated with Split Cord Malformation: It May Be Safe to Leave Diastematomyelia
                  Untreated in Patients with Intact or Stable Neurological Status</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2016 Jun
                1;98(11):926-36.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1255302"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Cobb JR</span><span
                  data-id="strong_18" class="annotation strong" style="position: unset;">. Outline for the study of
                  scoliosis</span><span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">.
                  American Academy of Orthopaedic Surgeons Instr Course Lect.</span> 1948;5:261-75.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=.%20Outline%20for%20the%20study%20of%20scoliosis&as_occt=title&as_sauthors=%20%22Cobb%20JR%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Qin X</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Sun W</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Xu L</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Qiu Y</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Zhu Z</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Effectiveness of Selective
                  Thoracic Fusion in the Surgical Treatment of Syringomyelia-associated Scoliosis: A Case-control Study
                  With Long-term Follow-up</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2016 Jul 15;41(14):E887-92.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20of%20Selective%20Thoracic%20Fusion%20in%20the%20Surgical%20Treatment%20of%20Syringomyelia-associated%20Scoliosis%3A%20A%20Case-control%20Study%20With%20Long-term%20Follow-up&as_occt=title&as_sauthors=%20%22X%20Qin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Heyer JH</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Anari JB</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Baldwin KD</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Mitchell SL</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Luhmann SJ</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Sturm PF</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Flynn JM</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Cahill PJ</span>; Pediatric
                Spine Study Group. <span data-id="strong_20" class="annotation strong"
                  style="position: unset;">Lengthening Behavior of Magnetically Controlled Growing Rods in Early-Onset
                  Scoliosis: A Multicenter Study</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2022 Dec 21;104(24):2186-94.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=3485465" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Feng F</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Shen J</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Zhang J</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Li S</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Yu K</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Tan H</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Characteristics and Clinical
                  Relevance of the Osseous Spur in Patients with Congenital Scoliosis and Split Spinal Cord
                  Malformation</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2016 Dec 21;98(24):2096-102.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1266357" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Musson RE</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Warren DJ</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Bickle I</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Connolly DJ</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Griffiths PD</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Imaging in childhood scoliosis:
                  a pictorial review</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Postgrad Med J.</span> 2010 Jul;86(1017):419-27.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Imaging%20in%20childhood%20scoliosis%3A%20a%20pictorial%20review&as_occt=title&as_sauthors=%20%22RE%20Musson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Babu R</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Reynolds R</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Moreno JR</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Cummings TJ</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Bagley CA</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Concurrent split cord
                  malformation and teratoma: dysembryology, presentation, and treatment</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Clin Neurosci.</span>
                2014 Feb;21(2):212-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Concurrent%20split%20cord%20malformation%20and%20teratoma%3A%20dysembryology%2C%20presentation%2C%20and%20treatment&as_occt=title&as_sauthors=%20%22R%20Babu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Muzumdar D</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Darji H</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Split Cord Malformation Type 1
                  (Bony Spur) Excision Using Microrongeur</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">J Pediatr Neurosci.</span> 2020
                Oct-Dec;15(4):349-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Split%20Cord%20Malformation%20Type%201%20(Bony%20Spur)%20Excision%20Using%20Microrongeur&as_occt=title&as_sauthors=%20%22D%20Muzumdar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Erşahin Y</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Mutluer S</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Kocaman S</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Demirtaş E</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Split spinal cord malformations
                  in children</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J
                  Neurosurg.</span> 1998 Jan;88(1):57-65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Split%20spinal%20cord%20malformations%20in%20children&as_occt=title&as_sauthors=%20%22Y%20Er%C5%9Fahin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Miller A</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Guille JT</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Bowen JR</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Evaluation and treatment of
                  diastematomyelia</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1993 Sep;75(9):1308-17.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=582556" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Sinha S</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Agarwal D</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Mahapatra AK</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Split cord malformations: an
                  experience of 203 cases</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Childs Nerv Syst.</span> 2006 Jan;22(1):3-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Split%20cord%20malformations%3A%20an%20experience%20of%20203%20cases&as_occt=title&as_sauthors=%20%22S%20Sinha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Ayvaz M</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Alanay A</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Yazici M</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Acaroglu E</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Akalan N</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Aksoy C</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Safety and efficacy of
                  posterior instrumentation for patients with congenital scoliosis and spinal dysraphism</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                2007 Jun;27(4):380-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Safety%20and%20efficacy%20of%20posterior%20instrumentation%20for%20patients%20with%20congenital%20scoliosis%20and%20spinal%20dysraphism&as_occt=title&as_sauthors=%20%22M%20Ayvaz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D909d5142-7c34-442e-bb81-ea6184bd9411%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="authorinsights_reference"
            class="annotation authorinsights_reference resource-reference" style="position: unset;"></a>
          <div class="content-node authorinsights" data-id="authorinsights" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Author Insights</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6357842308112&quot;,&quot;type&quot;:&quot;author_insights&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;909d5142-7c34-442e-bb81-ea6184bd9411&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6357842308112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6357842308112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6357842308112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6357842308112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/9db7f532-6e77-421d-b7c2-331fb8dac805/d7e9a1a0-ef05-4002-acb0-5bfffc66eadd/1280x720/match/image.jpg"
                        src="blob:https://www.jbjs.org/8f4c77cc-7928-4703-9a97-f6336a621612">
                        <track kind="metadata" label="segment-metadata">
                        <track kind="subtitles" label="English" srclang="en" default="" id="English">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/9db7f532-6e77-421d-b7c2-331fb8dac805/d7e9a1a0-ef05-4002-acb0-5bfffc66eadd/1280x720/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">3:08</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 3:08">
                            <div class="vjs-load-progress" style="width: 5.25%;"><span
                                class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">5.25%</span></span>
                              <div data-start="0" data-end="9.919999" style="left: 0%; width: 100%;"></div>
                            </div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">3:08</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden vjs-disabled">
                          <button
                            class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button vjs-disabled"
                            type="button" aria-disabled="true" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false" disabled="disabled"><span class="vjs-icon-placeholder"
                              aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu vjs-hidden">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content" role="menu">
                              <li class="vjs-menu-item" tabindex="-1" role="menuitemradio" aria-disabled="false"
                                aria-checked="false"><span class="vjs-menu-item-text">captions off</span><span
                                  class="vjs-control-text" aria-live="polite"></span></li>
                              <li class="vjs-menu-item vjs-subtitles-menu-item vjs-selected" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">English</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button" type="button"
                          title="Picture-in-Picture" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2144</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01454</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 30, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_29"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Peking Union
                            Medical College Hospital, Beijing, People’s Republic of China.</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;909d5142-7c34-442e-bb81-ea6184bd9411&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=909d5142-7c34-442e-bb81-ea6184bd9411&type=pdf&name=JBJS.23.01454.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=909d5142-7c34-442e-bb81-ea6184bd9411&type=pdf&name=JBJS.23.01454.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_34"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was funded
                          by the National Natural Science Foundation of China (grants 81974354 and 82230083). The <span
                            data-id="strong_35" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I51" target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I51</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;909d5142-7c34-442e-bb81-ea6184bd9411&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=909d5142-7c34-442e-bb81-ea6184bd9411&type=zip&name=JBJS.23.01454.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Heng Sun, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span><span data-id="footnote_reference_1"
                  class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-0624-0156" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-0624-0156</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yizhen Huang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-4130-0505" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-4130-0505</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yulei Dong, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8366-9417" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8366-9417</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yang Jiao, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9582-1650" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9582-1650</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Junduo Zhao, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6102-9371" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6102-9371</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jianxiong Shen, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:sjxpumch@163.com" class="" style="position: unset;">sjxpumch@163.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7172-9550" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7172-9550</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Peking Union Medical College Hospital, Peking
                Union Medical College, Chinese Academy of Medical Science, Beijing, People’s Republic of China</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Heng Sun, MD, and Yizhen Huang, MD, contributed
                        equally to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 24952.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
