/* ----------------- React Native Paper Imports --------------- */
import { Appbar } from "react-native-paper";

/* ----------------- Component Imports --------------- */
import { ScrollContainer } from "@memorang/ui";
import { Box } from "@memorang/ui";
import { router } from "expo-router";
import ChallengesFolderView from "../components/ChallengesFolderView";
import { useChallenges } from "../hooks/useChallenges";

const AllChallengesContainer = () => {
	const { challengesSectionList } = useChallenges();

	const handleNavigateToChallengesFolder = (fodlerName: string) => {
		router.push({
			pathname: "/(protected)/challenges/[id]/challenges",
			params: {
				id: decodeURIComponent(fodlerName),
			},
		});
	};
	return (
		<>
			<Appbar.Header mode="medium" elevated>
				<Appbar.BackAction onPress={() => router.back()} />
				<Appbar.Content title="All Challenges" />
			</Appbar.Header>
			<ScrollContainer>
				<Box paddingTop={8}>
					<ChallengesFolderView
						challengesSectionList={challengesSectionList}
						showViewAll={false}
						handleNavigateToChallengesFolder={handleNavigateToChallengesFolder}
					/>
				</Box>
			</ScrollContainer>
		</>
	);
};

export default AllChallengesContainer;
