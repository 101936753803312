import type { Article } from "../../../../../types";

export const Article512: Article = {
	id: 512,
	rsuiteId: "8a98de2c-7ac9-48e2-b98f-76f9ea4c39ac",
	type: "commentary and perspective",
	title:
		"A Call to Start Implementing the Results of High-Quality Randomized Trials in Daily Orthopaedic Practice",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/512.png",
	subSpecialties: ["hand_wrist"],
	content: `<div id="main" class="" style="opacity: 1; position: unset">
<div class="article lens-article" style="position: unset" data-context="toc">
<div class="panel content document width100" style="position: unset">
<div class="surface resource-view content" style="position: unset">
  <div class="nodes" style="padding-left: 0px; position: unset">
    <div
      class="content-node cover"
      data-id="cover"
      style="padding-top: 30px; position: unset"
    >
      <div class="content" style="position: unset">
        <div class="text title" style="position: unset">
          A Call to Start Implementing the Results of High-Quality
          Randomized Trials in Daily Orthopaedic Practice
        </div>
        <div class="text subtitle" style="position: unset">
          Commentary on an article by Neil J. White, MD, et al.:
          “Low-Intensity Pulsed Ultrasound Versus Sham in the Treatment of
          Operatively Managed Scaphoid Nonunions. The SNAPU Randomized
          Controlled Trial”
        </div>
        <div class="authors" style="position: unset">
          <div
            class="content-node text"
            data-id="text_contributor_1_reference"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <a
                href=""
                data-id="contributor_reference_1"
                class="annotation contributor_reference resource-reference"
                style="position: unset"
                >Tom J. Crijns, MD</a
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="heading_4"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset">Commentary</span>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_3"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_3"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            As orthopaedic surgeons, we are trained to think in terms of
            solutions: when a patient comes in with a problem, our brain
            is hardwired to propose a surgery or a procedure that will
            provide relief, preferably instantaneously. During the
            COVID-19 pandemic, an interesting societal experiment was
            conducted: suddenly, elective surgeries had to be postponed,
            and there have been numerous reports of patients who opted to
            forgo elective surgeries because the symptoms had subsided
            prior to the postponed surgical date<a
              href=""
              data-id="citation_reference_1"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_1"
                class="annotation superscript"
                style="position: unset"
                >1</span
              ></a
            ><span
              data-id="superscript_1"
              class="annotation superscript"
              style="position: unset"
              >,</span
            ><a
              href=""
              data-id="citation_reference_2"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_1"
                class="annotation superscript"
                style="position: unset"
                >2</span
              ></a
            >. It seems that patience is key when treating musculoskeletal
            conditions.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_4"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_4"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            When patience on the part of the patient and/or surgeon is
            wearing thin, it can sometimes be compelling to offer a
            low-value treatment to create the sense that “something is
            being done.” Such low-value treatments have the potential to
            cause iatrogenic and/or financial harm to patients.
            Low-intensity pulsed ultrasound (LIPUS) is an example of a
            low-value treatment that was demonstrated to be no more
            effective than a sham device in the well-done randomized trial
            by White et al.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_5"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_5"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Scaphoid fractures are an example of a musculoskeletal
            condition that requires patience, and frustration may ensue
            when healing, as shown on radiographs, does not seem to
            progress. In the study by White et al., 17.4% of fractures in
            the active LIPUS group and 6.8% in the sham group healed at
            ≥26 weeks postoperatively. It can be concluded that, in this
            cohort study of fractures that initially went on to nonunion
            and had surgical repair, the majority healed reliably without
            further intervention.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_6"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_6"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            This sham-controlled randomized trial should be viewed in the
            light of multiple randomized controlled studies of once
            well-regarded orthopaedic surgeries and procedures that have
            proven to be no more effective than a placebo<a
              href=""
              data-id="citation_reference_3"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_2"
                class="annotation superscript"
                style="position: unset"
                >3</span
              ></a
            ><span
              data-id="superscript_2"
              class="annotation superscript"
              style="position: unset"
              >-</span
            ><a
              href=""
              data-id="citation_reference_4"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_2"
                class="annotation superscript"
                style="position: unset"
                >7</span
              ></a
            >. When a procedure has been shown to be no more effective
            than a sham treatment in a high-quality randomized trial, we,
            as an orthopaedic community, have the ethical and moral
            responsibility to avoid resorting to such treatments, even
            when the patient is feeling uneasy about the duration of
            symptoms.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_7"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_7"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Implementation of the latest scientific evidence continues to
            be an issue, and these low-value procedures remain prevalent
            in orthopaedic practice despite current best evidence. Logical
            fallacies that are often quoted in this setting are “I believe
            in X procedure,” “In my hands, patients do well with X
            procedure,” or “My patients are dissimilar from patients who
            participate in these RCTs [randomized controlled trials].”
            Such discussions expose our own biases—the very biases that
            science is designed to contain. Science is about knowing;
            belief is reserved for religion.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_8"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_8"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Sham-controlled randomized trials should become the standard
            by which we assess the efficacy of our elective surgical
            interventions. When a sham control is not feasible, a
            stepped-wedge trial may be a viable alternative. When
            conducted with sufficient scientific rigor, the results of
            these studies should be utilized to guide our practice, and
            evidence should be implemented in a timely fashion.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_9"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_9"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            To date, ultrasonic osteogenic stimulators are still covered
            under Medicare in the setting of nonunion, defined as (1) a
            documented failure of ≥1 surgical intervention for treatment
            of the fracture and (2) no evidence of interval
            fracture-healing on 2 sets of radiographs that are 90 days
            apart<a
              href=""
              data-id="citation_reference_5"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_3"
                class="annotation superscript"
                style="position: unset"
                >8</span
              ></a
            >. This is just 1 example in which Medicare and private health
            insurance companies are still reimbursing low-value treatment.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_10"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_10"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            In this day and age in which insurance companies have come to
            play a pivotal role in deciding the treatments that a patient
            can and cannot receive through pre-authorizations, it is
            conceivable that, in the future, insurers will deny
            reimbursement for these types of low-value treatments on the
            basis of the current best evidence. I believe that, as
            musculoskeletal specialists, the onus should be on us to
            decide which treatments are appropriate for patients.
            Therefore, we should also take responsibility for limiting
            health-care expenditure by avoiding surgeries and procedures
            with questionable health benefits. If we, as surgeons, do not
            start doing our part to contain the ever-rising expenditure on
            health care in the U.S. by eliminating low-value treatments,
            the involvement of insurers in making these decisions may
            progressively undermine surgeon autonomy.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="undefined_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset">References</span>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        1&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_1"
            class="annotation"
            style="position: unset"
          >
            Sanko C</span
          >,
          <span
            data-id="annotation_2"
            class="annotation"
            style="position: unset"
          >
            Cox R</span
          >,
          <span
            data-id="annotation_3"
            class="annotation"
            style="position: unset"
          >
            Hadley C</span
          >,
          <span
            data-id="annotation_4"
            class="annotation"
            style="position: unset"
          >
            Gilmore G</span
          >,
          <span
            data-id="annotation_5"
            class="annotation"
            style="position: unset"
          >
            Wood C</span
          >,
          <span
            data-id="annotation_6"
            class="annotation"
            style="position: unset"
          >
            Getz C</span
          >,
          <span
            data-id="annotation_7"
            class="annotation"
            style="position: unset"
          >
            Namdari S</span
          >,
          <span
            data-id="annotation_8"
            class="annotation"
            style="position: unset"
          >
            Davis D</span
          >.
          <span
            data-id="strong_1"
            class="annotation strong"
            style="position: unset"
            >The impact of elective surgery restrictions during the
            COVID-19 (coronavirus disease 2019) pandemic on shoulder and
            elbow surgery: patient perceptions</span
          >.
          <span
            data-id="emphasis_1"
            class="annotation emphasis"
            style="position: unset"
            >J Shoulder Elbow Surg.</span
          >
          2023 Mar;32(3):662-1-2.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20elective%20surgery%20restrictions%20during%20the%20COVID-19%20(coronavirus%20disease%202019)%20pandemic%20on%20shoulder%20and%20elbow%20surgery%3A%20patient%20perceptions&as_occt=title&as_sauthors=%20%22C%20Sanko%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_2"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        2&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_9"
            class="annotation"
            style="position: unset"
          >
            Hutton D</span
          >,
          <span
            data-id="annotation_10"
            class="annotation"
            style="position: unset"
          >
            Mohamed B</span
          >,
          <span
            data-id="annotation_11"
            class="annotation"
            style="position: unset"
          >
            Mehmood K</span
          >,
          <span
            data-id="annotation_12"
            class="annotation"
            style="position: unset"
          >
            Magro J</span
          >,
          <span
            data-id="annotation_13"
            class="annotation"
            style="position: unset"
          >
            Shekhar H</span
          >,
          <span
            data-id="annotation_14"
            class="annotation"
            style="position: unset"
          >
            Solth A</span
          >,
          <span
            data-id="annotation_15"
            class="annotation"
            style="position: unset"
          >
            Pulhorn H</span
          >,
          <span
            data-id="annotation_16"
            class="annotation"
            style="position: unset"
          >
            Bennett D</span
          >,
          <span
            data-id="annotation_17"
            class="annotation"
            style="position: unset"
          >
            Okasha M</span
          >.
          <span
            data-id="strong_2"
            class="annotation strong"
            style="position: unset"
            >COVID-19 and Spontaneous Resolution of Lumbar Disk Prolapse:
            A Retrospective Cohort Study of Patients Awaiting
            Microdiscectomy</span
          >.
          <span
            data-id="emphasis_2"
            class="annotation emphasis"
            style="position: unset"
            >J Neurol Surg A Cent Eur Neurosurg.</span
          >
          2024 Jan 24. [Epub ahead of print].</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=COVID-19%20and%20Spontaneous%20Resolution%20of%20Lumbar%20Disk%20Prolapse%3A%20A%20Retrospective%20Cohort%20Study%20of%20Patients%20Awaiting%20Microdiscectomy&as_occt=title&as_sauthors=%20%22D%20Hutton%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_3"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        3&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_18"
            class="annotation"
            style="position: unset"
          >
            Buchbinder R</span
          >,
          <span
            data-id="annotation_19"
            class="annotation"
            style="position: unset"
          >
            Osborne RH</span
          >,
          <span
            data-id="annotation_20"
            class="annotation"
            style="position: unset"
          >
            Ebeling PR</span
          >,
          <span
            data-id="annotation_21"
            class="annotation"
            style="position: unset"
          >
            Wark JD</span
          >,
          <span
            data-id="annotation_22"
            class="annotation"
            style="position: unset"
          >
            Mitchell P</span
          >,
          <span
            data-id="annotation_23"
            class="annotation"
            style="position: unset"
          >
            Wriedt C</span
          >,
          <span
            data-id="annotation_24"
            class="annotation"
            style="position: unset"
          >
            Graves S</span
          >,
          <span
            data-id="annotation_25"
            class="annotation"
            style="position: unset"
          >
            Staples MP</span
          >,
          <span
            data-id="annotation_26"
            class="annotation"
            style="position: unset"
          >
            Murphy B</span
          >.
          <span
            data-id="strong_3"
            class="annotation strong"
            style="position: unset"
            >A randomized trial of vertebroplasty for painful osteoporotic
            vertebral fractures</span
          >.
          <span
            data-id="emphasis_3"
            class="annotation emphasis"
            style="position: unset"
            >N Engl J Med.</span
          >
          2009 Aug 6;361(6):557-1-2.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20randomized%20trial%20of%20vertebroplasty%20for%20painful%20osteoporotic%20vertebral%20fractures&as_occt=title&as_sauthors=%20%22R%20Buchbinder%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_4"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        4&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_27"
            class="annotation"
            style="position: unset"
          >
            Sihvonen R</span
          >,
          <span
            data-id="annotation_28"
            class="annotation"
            style="position: unset"
          >
            Paavola M</span
          >,
          <span
            data-id="annotation_29"
            class="annotation"
            style="position: unset"
          >
            Malmivaara A</span
          >,
          <span
            data-id="annotation_30"
            class="annotation"
            style="position: unset"
          >
            Itälä A</span
          >,
          <span
            data-id="annotation_31"
            class="annotation"
            style="position: unset"
          >
            Joukainen A</span
          >,
          <span
            data-id="annotation_32"
            class="annotation"
            style="position: unset"
          >
            Nurmi H</span
          >,
          <span
            data-id="annotation_33"
            class="annotation"
            style="position: unset"
          >
            Kalske J</span
          >,
          <span
            data-id="annotation_34"
            class="annotation"
            style="position: unset"
          >
            Järvinen TL</span
          >; Finnish Degenerative Meniscal Lesion Study (FIDELITY) Group.
          <span
            data-id="strong_4"
            class="annotation strong"
            style="position: unset"
            >Arthroscopic partial meniscectomy versus sham surgery for a
            degenerative meniscal tear</span
          >.
          <span
            data-id="emphasis_4"
            class="annotation emphasis"
            style="position: unset"
            >N Engl J Med.</span
          >
          2013 Dec 26;369(26):2515-1-2.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20partial%20meniscectomy%20versus%20sham%20surgery%20for%20a%20degenerative%20meniscal%20tear&as_occt=title&as_sauthors=%20%22R%20Sihvonen%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_5"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        5&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_35"
            class="annotation"
            style="position: unset"
          >
            Paavola M</span
          >,
          <span
            data-id="annotation_36"
            class="annotation"
            style="position: unset"
          >
            Kanto K</span
          >,
          <span
            data-id="annotation_37"
            class="annotation"
            style="position: unset"
          >
            Ranstam J</span
          >,
          <span
            data-id="annotation_38"
            class="annotation"
            style="position: unset"
          >
            Malmivaara A</span
          >,
          <span
            data-id="annotation_39"
            class="annotation"
            style="position: unset"
          >
            Inkinen J</span
          >,
          <span
            data-id="annotation_40"
            class="annotation"
            style="position: unset"
          >
            Kalske J</span
          >,
          <span
            data-id="annotation_41"
            class="annotation"
            style="position: unset"
          >
            Savolainen V</span
          >,
          <span
            data-id="annotation_42"
            class="annotation"
            style="position: unset"
          >
            Sinisaari I</span
          >,
          <span
            data-id="annotation_43"
            class="annotation"
            style="position: unset"
          >
            Taimela S</span
          >,
          <span
            data-id="annotation_44"
            class="annotation"
            style="position: unset"
          >
            Järvinen TL</span
          >; Finnish Shoulder Impingement Arthroscopy Controlled Trial
          (FIMPACT) Investigators.
          <span
            data-id="strong_5"
            class="annotation strong"
            style="position: unset"
            >Subacromial decompression versus diagnostic arthroscopy for
            shoulder impingement: a 5-year follow-up of a randomised,
            placebo surgery controlled clinical trial</span
          >.
          <span
            data-id="emphasis_5"
            class="annotation emphasis"
            style="position: unset"
            >Br J Sports Med.</span
          >
          2021 Jan;55(2):99-1-2.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Subacromial%20decompression%20versus%20diagnostic%20arthroscopy%20for%20shoulder%20impingement%3A%20a%205-year%20follow-up%20of%20a%20randomised%2C%20placebo%20surgery%20controlled%20clinical%20trial&as_occt=title&as_sauthors=%20%22M%20Paavola%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_6"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        6&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_45"
            class="annotation"
            style="position: unset"
          >
            Lindenhovius A</span
          >,
          <span
            data-id="annotation_46"
            class="annotation"
            style="position: unset"
          >
            Henket M</span
          >,
          <span
            data-id="annotation_47"
            class="annotation"
            style="position: unset"
          >
            Gilligan BP</span
          >,
          <span
            data-id="annotation_48"
            class="annotation"
            style="position: unset"
          >
            Lozano-Calderon S</span
          >,
          <span
            data-id="annotation_49"
            class="annotation"
            style="position: unset"
          >
            Jupiter JB</span
          >,
          <span
            data-id="annotation_50"
            class="annotation"
            style="position: unset"
          >
            Ring D</span
          >.
          <span
            data-id="strong_6"
            class="annotation strong"
            style="position: unset"
            >Injection of dexamethasone versus placebo for lateral elbow
            pain: a prospective, double-blind, randomized clinical
            trial</span
          >.
          <span
            data-id="emphasis_6"
            class="annotation emphasis"
            style="position: unset"
            >J Hand Surg Am.</span
          >
          2008 Jul-Aug;33(6):909-1-2.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Injection%20of%20dexamethasone%20versus%20placebo%20for%20lateral%20elbow%20pain%3A%20a%20prospective%2C%20double-blind%2C%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22A%20Lindenhovius%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_7"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        7&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_51"
            class="annotation"
            style="position: unset"
          >
            Moseley JB</span
          >,
          <span
            data-id="annotation_52"
            class="annotation"
            style="position: unset"
          >
            O’Malley K</span
          >,
          <span
            data-id="annotation_53"
            class="annotation"
            style="position: unset"
          >
            Petersen NJ</span
          >,
          <span
            data-id="annotation_54"
            class="annotation"
            style="position: unset"
          >
            Menke TJ</span
          >,
          <span
            data-id="annotation_55"
            class="annotation"
            style="position: unset"
          >
            Brody BA</span
          >,
          <span
            data-id="annotation_56"
            class="annotation"
            style="position: unset"
          >
            Kuykendall DH</span
          >,
          <span
            data-id="annotation_57"
            class="annotation"
            style="position: unset"
          >
            Hollingsworth JC</span
          >,
          <span
            data-id="annotation_58"
            class="annotation"
            style="position: unset"
          >
            Ashton CM</span
          >,
          <span
            data-id="annotation_59"
            class="annotation"
            style="position: unset"
          >
            Wray NP</span
          >.
          <span
            data-id="strong_7"
            class="annotation strong"
            style="position: unset"
            >A controlled trial of arthroscopic surgery for osteoarthritis
            of the knee</span
          >.
          <span
            data-id="emphasis_7"
            class="annotation emphasis"
            style="position: unset"
            >N Engl J Med.</span
          >
          2002 Jul 11;347(2):81-1-2.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20controlled%20trial%20of%20arthroscopic%20surgery%20for%20osteoarthritis%20of%20the%20knee&as_occt=title&as_sauthors=%20%22JB%20Moseley%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_8"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        8&nbsp;<span class="text" style="position: unset"
          >Centers for Medicare & Medicaid Services.
          <span
            data-id="strong_8"
            class="annotation strong"
            style="position: unset"
            >National Coverage Determination (NCD) Osteogenic
            Stimulators</span
          >. Accessed 2024 May 23.
          <a
            href="https://www.cms.gov/medicare-coverage-database/view/ncd.aspx?ncdid=65&ncdver=2"
            target="_blank"
            data-id="link_1"
            class="link"
            style="position: unset"
            >https://www.cms.gov/medicare-coverage-database/view/ncd.aspx?ncdid=65&ncdver=2</a
          >.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=National%20Coverage%20Determination%20(NCD)%20Osteogenic%20Stimulators&as_occt=title&as_sauthors=%20%22Centers%20for%20Medicare%20%26%20Medicaid%20Services%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <a
      href=""
      data-id="eletter_reference"
      class="annotation eletter_reference resource-reference"
      style="position: unset"
    ></a>
    <div
      class="content-node eletter-submit"
      data-id="eletters"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="label">eLetters</div>
        <div class="link" style="position: unset">
          <a
            href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D8a98de2c-7ac9-48e2-b98f-76f9ea4c39ac%26native%3D1"
            target="_blank"
            class=""
            style="position: unset"
            ><img
              class="image"
              src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
              style="position: unset"
            /><span class="label">Submit an eLetter</span></a
          >
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="heading_5"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset"
          >Additional information</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node publication-info"
      data-id="publication_info"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="meta-data" style="position: unset">
          <div class="journal" style="position: unset">
            <div class="label">Journal</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >The Journal of Bone and Joint Surgery</span
              >
            </div>
          </div>
          <div class="subject" style="position: unset">
            <div class="label">Section</div>
            <div class="value" style="position: unset">
              Commentary and Perspective
            </div>
          </div>
          <div class="publishing" style="position: unset">
            <div class="label">Published</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >September 4, 2024; 106 (17): e38</span
              >
            </div>
          </div>
          <div class="doi" style="position: unset">
            <div class="label">DOI</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >10.2106/JBJS.24.00491</span
              >
            </div>
          </div>
          <div class="dates" style="position: unset">
            The article was first published on
            <b class="" style="position: unset">September 4, 2024</b>.
          </div>
        </div>
        <div
          class="content-node paragraph"
          data-id="articleinfo"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            <div
              class="content-node related-articles"
              data-id="related_articles_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="related-articles-box" style="position: unset">
                  <div class="related-article" style="position: unset">
                    <div class="heading" style="position: unset">
                      <i class="fa fa-link" style="position: unset"></i
                      >Article
                    </div>
                    <div class="article-info" style="position: unset">
                      <a
                        href="https://www.jbjs.org/reader.php?rsuite_id=68500bc9-5b84-4c86-a985-0a048cd0c120&native=1"
                        class="article-title"
                        style="position: unset"
                        >Low-Intensity Pulsed Ultrasound Versus Sham in
                        the Treatment of Operatively Managed Scaphoid
                        Nonunions<span
                          class="subtitle"
                          style="position: unset"
                          ><br style="position: unset" />The SNAPU
                          Randomized Controlled Trial</span
                        ></a
                      >
                      <div
                        class="article-authors text-with-ellipsis one-line"
                        style="position: unset"
                      >
                        White, Neil J.; Patterson, Ethan D.; Dhaliwal,
                        Gurpreet S.; Hildebrand, Kevin A.; WECAN :~
                        Rollick, Natalie; Goetz, Thomas T.; Gammon,
                        Braden; Clark, Tod A.; Hiscox, Christina; Vaughan
                        Bowen, Clifford Ainsley; Frizzell, J. Bevan;
                        Walker, Richard E.A.; Murphy, Lisa M.; Sayre, Eric
                        C.; Vergouwen, Martina; Mattiello, Brenna C.;
                        Grewal, Ruby; Rollick, Natalie; Goetz, Thomas J.;
                        Gammon, Braden; Clark, Tod A.; Hiscox, Christina;
                        Vaughan Bowen, Clifford Ainsley; Frizzell, J.
                        Bevan; Walker, Richard E. A.; Murphy, Lisa M.;
                        Sayre, Eric C.; Vergouwen, Martina; Mattiello,
                        Brenna C.; Grewal, Ruby;
                      </div>
                      <div
                        class="article-citations"
                        style="position: unset"
                      >
                        <img
                          src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg"
                          style="position: unset"
                        />J Bone Joint Surg Am, 106(17):1573 | July 1,
                        2024
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node heading level-3"
              data-id="heading_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <span class="text title" style="position: unset"
                  >Copyright & License</span
                >
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node paragraph"
              data-id="paragraph_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_1"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    Copyright © 2024 by The Journal of Bone and Joint
                    Surgery, Incorporated.
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node supplement"
              data-id="supplement_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="file" style="position: unset">
                  <span class="" style="position: unset"
                    >Open article PDF</span
                  ><a
                    class="jbjs_tracking gtm_tracking"
                    jbjs_tracking_type="download"
                    jbjs_tracking_data='{"id":"8a98de2c-7ac9-48e2-b98f-76f9ea4c39ac","type":"article","topics":[]}'
                    href="https://www.jbjs.org/reader.php?rsuite_id=8a98de2c-7ac9-48e2-b98f-76f9ea4c39ac&type=pdf&name=JBJS.24.00491.pdf"
                    target="_blank"
                    gtm_action="reader"
                    gtm_category="PDF_article_downloads"
                    gtm_label="https://www.jbjs.org/reader.php?rsuite_id=8a98de2c-7ac9-48e2-b98f-76f9ea4c39ac&type=pdf&name=JBJS.24.00491.pdf"
                    jbjs_tracking_source="reader"
                    style="position: unset"
                    ><img
                      src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                      style="position: unset"
                    />
                    Download</a
                  >
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node heading level-3"
              data-id="heading_3"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <span class="text title" style="position: unset"
                  >Disclosures of Potential Conflicts of Interest</span
                >
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node paragraph"
              data-id="paragraph_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_2"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <span
                      data-id="strong_9"
                      class="annotation strong"
                      style="position: unset"
                      >Disclosure:</span
                    >
                    The
                    <span
                      data-id="strong_10"
                      class="annotation strong"
                      style="position: unset"
                      >Disclosure of Potential Conflicts of Interest</span
                    >
                    form is provided with the online version of the
                    article (<a
                      href="http://links.lww.com/JBJS/I91"
                      target="_blank"
                      data-id="link_2"
                      class="link"
                      style="position: unset"
                      >http://links.lww.com/JBJS/I91</a
                    >).
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node supplement"
              data-id="supplement_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="file" style="position: unset">
                  <span class="" style="position: unset"
                    >Disclosures of Potential Conflicts of Interest</span
                  ><a
                    class="jbjs_tracking"
                    jbjs_tracking_type="download"
                    jbjs_tracking_data='{"id":"8a98de2c-7ac9-48e2-b98f-76f9ea4c39ac","type":"disclosure","topics":[]}'
                    href="/php/content/content_api.php?op=download&rsuite_id=8a98de2c-7ac9-48e2-b98f-76f9ea4c39ac&type=zip&name=JBJS.24.00491.disclosure.zip&subtype=disclosure"
                    target="_blank"
                    jbjs_tracking_source="reader"
                    style="position: unset"
                    ><i class="fa fa-download" style="position: unset">
                      Download</i
                    ></a
                  >
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-3"
      data-id="heading_1_author_list"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset">Authors</span>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node contributor"
      data-id="contributor_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="contributor-name" style="position: unset">
          Tom J. Crijns, MD<span
            data-id="affiliation_reference_1"
            class="label annotation cross_reference"
            >1</span
          >
        </div>
        <div class="affiliations" style="position: unset">
          <span class="" style="position: unset"></span>
        </div>
        <div class="emails contrib-data" style="position: unset">
          <span class="contrib-label" style="position: unset"
            >For correspondence: </span
          ><span class="" style="position: unset"
            ><a
              href="mailto:crijns.tom@mayo.edu"
              class=""
              style="position: unset"
              >crijns.tom@mayo.edu</a
            ></span
          >
        </div>
        <div class="contrib-data" style="position: unset">
          <img
            class="orcid"
            src="https://www.jbjs.org/images/orcid_logo.png"
            style="position: unset"
          /><a
            href="https://orcid.org/0000-0002-2910-5071"
            target="_blank"
            class="orcid"
            style="position: unset"
            >https://orcid.org/0000-0002-2910-5071</a
          >
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node affiliation"
      data-id="affiliation_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="label">1</span
        ><span class="text" style="position: unset"
          >Orthopedic Surgery, Mayo Clinic Arizona, Phoenix, Arizona</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <a class="logo" href="home.php" style="position: unset"></a>
  </div>
</div>
<div
  class="surface-scrollbar content hidden"
  style="display: none; position: unset"
>
  <div
    class="visible-area"
    style="top: 0px; height: 6431.39px; position: unset"
  ></div>
</div>
</div>
</div>
</div>
`,
};
