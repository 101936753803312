/* -----------------Globals--------------- */
import {
	Box,
	ProgressSummaryCard,
	StudySessionMasteryTagProgressCard,
} from "@memorang/ui";

import type { MasteryChartCardProps } from "./MasteryChartCard";
/* -----------------Child components--------------- */
import ProgressCardsContainer from "./ProgressCardsContainer";

import { isDemoMode } from "@constants/common";
import { SessionConfigDialog } from "@features/session-config";
/* -----------------Types--------------- */
import type { MasteryTagProgressData, TagItem } from "@helpers/content/types";
import type { ExamCardData, SummaryCardData } from "@helpers/progress-data";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
import { router } from "expo-router";
import { useState } from "react";
import { useTagsStore } from "../../hooks/useTagsStore";
import { PracticeTestsCard } from "./PracticeTestsCard";

interface Props {
	masteryTagsProgressData: MasteryTagProgressData[];
	masteryChartProgressData: MasteryChartCardProps | null;
	needsImprovementProgressData: MasteryTagProgressData | null;
	contentIds?: string[];
	summaryCardData?: SummaryCardData;
	practiceTestsCardData?: ExamCardData;
	showStudyPackViews?: boolean;
}
const ProgressView = ({
	masteryTagsProgressData,
	// masteryChartProgressData,
	needsImprovementProgressData,
	summaryCardData,
	practiceTestsCardData,
	showStudyPackViews,
}: Props) => {
	const [showSessionConfigDialog, setShowSessionConfigDialog] = useState(false);

	const setCurrentSelectedMasteryItems = useTagsStore(
		(state) => state.setCurrentSelectedMasteryItems,
	);

	const { handleSessionConfigNavigation } = useCustomNavigation();
	const weakTagIds = needsImprovementProgressData
		? needsImprovementProgressData.items.map((item) => item.id!)
		: [];
	const toggleSessionConfigDialog = () => {
		setShowSessionConfigDialog(!showSessionConfigDialog);
	};

	const handleClickStudy = () => {
		const tagIds = needsImprovementProgressData
			? needsImprovementProgressData.items.map((item) => item.id!)
			: [];
		handleSessionConfigNavigation(toggleSessionConfigDialog, {
			selectedTagIds: tagIds,
			reset: "true",
		});
	};

	const handleClickCard = (items: TagItem[], title: string) => {
		setCurrentSelectedMasteryItems(items);
		router.push({
			pathname: "/progress/detailed-list/mastery",
			params: {
				type: title,
			},
		});
	};
	const masteryTagsCards = masteryTagsProgressData.map((item) => {
		return (
			<StudySessionMasteryTagProgressCard
				handleClickCard={() => handleClickCard(item.items, item.title)}
				key={item.title}
				{...item}
			/>
		);
	});

	const cards = [
		...(practiceTestsCardData && !isDemoMode
			? // biome-ignore lint/correctness/useJsxKeyInIterable: // TODO fix me later
				[<PracticeTestsCard {...practiceTestsCardData} />]
			: []),
		// biome-ignore lint/correctness/useJsxKeyInIterable: // TODO fix me later
		...(summaryCardData ? [<ProgressSummaryCard {...summaryCardData} />] : []),
		// biome-ignore lint/correctness/useJsxKeyInIterable: // TODO fix me later
		<Box gap={16}>{masteryTagsCards}</Box>,
		...(needsImprovementProgressData
			? [
					// biome-ignore lint/correctness/useJsxKeyInIterable: // TODO fix me later
					<StudySessionMasteryTagProgressCard
						handleClickCard={showStudyPackViews ? handleClickStudy : undefined}
						{...needsImprovementProgressData}
						cta={showStudyPackViews ? "Study Now" : undefined}
					/>,
				]
			: []),
	];
	return (
		<Box gap={16}>
			<ProgressCardsContainer cards={cards} />
			{showSessionConfigDialog ? (
				<SessionConfigDialog
					visible={showSessionConfigDialog}
					selectedTagIds={weakTagIds}
					handleClose={toggleSessionConfigDialog}
				/>
			) : null}
		</Box>
	);
};

export default ProgressView;
