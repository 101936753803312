/* -----------------Globals--------------- */
import { useCallback, useEffect, useMemo, useState } from "react";

/* -----------------UI--------------- */
import { Box, useDevice } from "@memorang/ui";

import { useExamContext } from "@features/exam";
/* -----------------Helpers & Hooks--------------- */
import { isWeb } from "@helpers/platform";
import { getUserPrompt } from "../components/QASearch/helpers";
import { useAilaStore } from "../hooks/useAilaStore";
import useSessionStore from "../hooks/useSessionStore";

import { useCustomNavigation } from "@hooks/useCustomNavigation";
import type { SessionChildItem } from "@memorang/types";
import { useFocusEffect, useLocalSearchParams, useRouter } from "expo-router";
import { Button, Portal } from "react-native-paper";
import QAChipsWrapper from "../components/QASearch/QAChipsWrapper";
import SuggestCorrectionDialog from "../components/dialogs/SuggestCorrectionDialog";

/* -----------------Child components--------------- */
import PostAnswerModal from "../components/postanswer/PostAnswerModal";

import { useUserProfilePrompt } from "@features/aila/useUserProfilePrompt";
import { useAppStore } from "@hooks/useAppStore";
import { useGlobalStore } from "@hooks/useGlobalStore";
/* -----------------Types--------------- */
import type { OriginalQuestionData } from "@memorang/features";
import AssistantFab from "components/AssistantFab";
import { calculateXp } from "../components/postanswer/helper";
import { sessionMaxWidth } from "../constants";
import { getPointsEarnedStatItemValue } from "../helpers/stats";

const StudySessionFeedbackContainer = () => {
	const { index } = useLocalSearchParams<{
		index: string;
	}>();

	const [showSuggestCorrectionDialog, setShowSuggestCorrectionDialog] =
		useState(false);

	const { isMobile } = useDevice();

	const { handleSuggestCorrectionNavigation } = useCustomNavigation();

	const toggleShowSuggestCorrectionDialog = () => {
		setShowSuggestCorrectionDialog((prev) => !prev);
	};
	const currentItemIndex = index ? Number.parseInt(index) - 1 : 0;

	const sessionView = useAppStore((state) => state.sessionView);

	const showXpEarned = sessionView?.postAnswerStats?.showXpEarned;
	const showNumAttempts = sessionView?.postAnswerStats?.showNumAttempts;
	const isSummativeTest = useSessionStore((state) => state.isSummativeTest);
	const currentBlockIndex = useSessionStore((state) => state.currentBlockIndex);
	const inReviewMode = useSessionStore((state) => state.inReviewMode);
	const sessionItems = useSessionStore((state) => state.sessionItems);
	const answers = useSessionStore((state) => state.answers);

	const hintUsed = useSessionStore((state) => state.hintUsed);

	const currentSessionItems = isSummativeTest
		? sessionItems[currentBlockIndex].children
		: sessionItems;
	const itemType = currentSessionItems[currentItemIndex]?.itemType;
	const currentItem = currentSessionItems[currentItemIndex] as SessionChildItem;
	const currentItemAnswer = answers?.get(currentItem.id);

	const explanationMedia = currentItem.media?.filter(
		(item) => item.metadata?.source === "EXPLANATION",
	);

	const attempts = useSessionStore((state) => state.attempts);

	const currentAttempt = attempts.get(currentItem.id);

	const attemptKeys = Object.keys(currentAttempt?.choiceAttempts || {});

	const numAttempts = attemptKeys.length;

	const numTotalAttempts = currentItem.children?.length || 0;

	const attemptsStatValue = `${numAttempts} of ${numTotalAttempts}`;

	const lastAttemptChoiceId = attemptKeys[attemptKeys.length - 1];

	const numResponses = currentItem?.children?.find(
		(choice) => choice.id === lastAttemptChoiceId,
	)?.numAttempts;

	const isAssistantVisible = useAilaStore((state) => state.showDrawer);

	const isSingleItemSession = sessionItems.length === 1;
	const updateIsAssistantVisible = useAilaStore(
		(state) => state.updateShowDrawer,
	);
	const updateSelectedQuery = useAilaStore(
		(state) => state.updateSelectedQuery,
	);

	const userProfilePrompt = useUserProfilePrompt();

	const router = useRouter();

	const currentAnswer = answers?.get(currentItem.id);

	const handleAssistantFabPress = () => {
		updateIsAssistantVisible(true);
		if (!isWeb) {
			router.push(`/aila/${currentItem.id}`);
		}
	};

	useFocusEffect(
		useCallback(() => {
			if (!isWeb) {
				updateIsAssistantVisible(false);
				updateSelectedQuery("");
			}
		}, [updateIsAssistantVisible, updateSelectedQuery]),
	);

	useEffect(() => {
		if (isSummativeTest && inReviewMode) {
			updateIsAssistantVisible(true);
		}
	}, [isSummativeTest, inReviewMode, updateIsAssistantVisible]);

	const {
		currentExamContext: { examName },
	} = useExamContext();

	const ailaEnabled = useGlobalStore(
		(state) => state.settingsEnabledFeatures.aila,
	);

	const renderSuggestCorrectionButton = () => {
		if (!isSummativeTest) {
			return (
				<Box alignSelf="center" marginTop={16}>
					<Button
						onPress={() =>
							handleSuggestCorrectionNavigation(
								toggleShowSuggestCorrectionDialog,
								currentItemIndex,
							)
						}
						icon={"flag-outline"}
					>
						Suggest Correction
					</Button>
					{showSuggestCorrectionDialog && (
						<SuggestCorrectionDialog
							visible={showSuggestCorrectionDialog}
							handleClose={toggleShowSuggestCorrectionDialog}
						/>
					)}
				</Box>
			);
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const renderCommonPostAnswerModal = useMemo(() => {
		const shouldRenderPostAnswerModal = isSummativeTest
			? inReviewMode
			: currentAnswer != null;

		const confidence = currentAnswer?.confidence;
		const timeTaken = currentItem.answers
			? currentItem.answers.timeTaken
			: currentAnswer?.timeTaken;
		const correct = currentItem.answers
			? currentItem.answers.isCorrect
			: currentAnswer?.correct;
		const choices = currentItem.children as SessionChildItem[];

		const pointsEarnedStatValue = getPointsEarnedStatItemValue({
			answers: currentItem.answers,
			children: currentItem.children,
		});

		const tags = currentItem?.tags;
		const correctChoices = choices?.filter(({ isCorrect }) => isCorrect);
		const data = {
			stem: currentItem.stem,
			explanation: correctChoices[0]?.explanation,
			choices,
			topics: tags?.map((tagItem) => tagItem.label),
		};
		const userPrompt = getUserPrompt(data, examName, userProfilePrompt);
		const originalQuestionData: OriginalQuestionData = {
			stem: data.stem,
			explanation: data?.explanation,
			topics:
				(currentItem.tags?.map((tagItem) => tagItem.label).filter(Boolean) as
					| string[]
					| undefined) ?? [],
			choices:
				data.choices.map((choice) => ({
					text: choice.stem,
					isCorrect: choice.isCorrect,
				})) ?? [],
		};

		const isAiLAFabVisible =
			!isAssistantVisible && shouldRenderPostAnswerModal && ailaEnabled;

		const isHintUsed = hintUsed?.get(currentItem.id);
		const xpEarned = calculateXp(numAttempts, Boolean(isHintUsed));

		return (
			<>
				{isAiLAFabVisible && (
					<Portal>
						<AssistantFab handleAssistantFabPress={handleAssistantFabPress} />
					</Portal>
				)}
				{shouldRenderPostAnswerModal && (
					<PostAnswerModal
						choices={choices}
						timeTaken={`${timeTaken} secs`}
						confidence={confidence}
						showExplanation
						correct={correct}
						explanation={currentItem.explanation}
						tags={currentItem.tags}
						xpEarned={xpEarned}
						currentQuestionAnswered
						currentItemIndex={currentItemIndex}
						itemId={Number(currentItem.id)}
						userPrompt={userPrompt}
						renderSuggestCorrectionButton={renderSuggestCorrectionButton}
						originalQuestionData={originalQuestionData}
						pointsEarnedStatValue={pointsEarnedStatValue}
						showNumAttempts={showNumAttempts}
						showXpEarned={showXpEarned}
						attemptsStatValue={attemptsStatValue}
						numAttempts={numAttempts}
						isSingleItemSession={isSingleItemSession}
						numResponses={numResponses}
						explanationMedia={explanationMedia}
					/>
				)}
				<Box margin={16}>
					{(!isSummativeTest || inReviewMode) && ailaEnabled && (
						<QAChipsWrapper
							itemId={Number(currentItem.id)}
							userPrompt={userPrompt}
							shouldRender={!!shouldRenderPostAnswerModal && isWeb}
							originalQuestionData={originalQuestionData}
						/>
					)}
				</Box>
			</>
		);
		//TODO:Need to remove all these useMemo :(
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		answers,
		currentItem,
		currentItemIndex,
		examName,
		inReviewMode,
		isSummativeTest,
		isAssistantVisible,
	]);

	const lookup: Record<string, React.ReactNode> = {
		QUESTION: renderCommonPostAnswerModal,
		PASSAGE: renderCommonPostAnswerModal,
	};

	const Component = lookup[itemType || "QUESTION"];

	if (!currentItem) {
		return null;
	}

	return (
		<Box
			maxWidth={
				isSummativeTest ? sessionMaxWidth.summative : sessionMaxWidth.formative
			}
			alignSelf={isSummativeTest ? "flex-start" : "center"}
			paddingHorizontal={isMobile ? 16 : 0}
		>
			{Component}
			{isWeb && currentItemAnswer != null && renderSuggestCorrectionButton()}
		</Box>
	);
};

export default StudySessionFeedbackContainer;
