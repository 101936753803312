import type { Article } from "../../../../../types";

export const Article629: Article = {
	id: 629,
	rsuiteId: "c7de6984-22aa-4fe0-a548-59ed4af2bc9b",
	type: "scientific article",
	title:
		"Early-Stage Femoral Head Hypoperfusion Correlates with Femoral Head Deformity at Intermediate Follow-up in Legg-Calvé-Perthes Disease",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=c7de6984-22aa-4fe0-a548-59ed4af2bc9b&type=jpeg&name=JBJS.23.01429f1",
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Early-Stage Femoral Head Hypoperfusion Correlates with
                Femoral Head Deformity at Intermediate Follow-up in Legg-Calvé-Perthes Disease</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Michael
                      Seungcheol Kang, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">David
                      Zimmerhanzel, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 2 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Background:</span> Perfusion MRI (pMRI) can quantify femoral head
                        hypoperfusion in early-stage Legg-Calvé-Perthes disease. We investigated whether the severity of
                        hypoperfusion measured at the early stage correlates with femoral head deformity at
                        intermediate-term follow-up.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Methods:</span> Sixty-three patients who were 5 to 11 years of age at
                        the diagnosis of Legg-Calvé-Perthes disease and who had pMRI performed at an early stage
                        (Waldenström Stage 1 to 2a) were retrospectively reviewed. Twenty-eight patients were treated
                        nonoperatively and 35 were treated with proximal femoral varus osteotomy (PFVO). The sphericity
                        deviation score (SDS) was used as the primary outcome. Femoral head perfusion and SDS were
                        measured by 2 observers. Models assessing the relationship between hypoperfusion and SDS were
                        fitted without and with stratifications by age at diagnosis and treatment method.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Results:</span> All 63 patients had a minimum of 4 years of follow-up
                        (mean follow-up, 7.5 ± 2.6 years). All had reached the healed stage (Stage 4), and their mean
                        age was 15.6 ± 2.8 years. The SDS outcome showed a significant positive correlation with the
                        hypoperfusion % (p &lt; 0.001). In the &lt;50% hypoperfusion range, the SDS indicated no-to-low
                        deformity, with narrow variability of outcome. However, the SDS became exponentially worse and
                        had a wider variability of outcome at the ≥50% hypoperfusion range. Multivariable analyses
                        revealed age at diagnosis, hypoperfusion %, and treatment method as significant prognostic
                        factors for SDS (p = 0.007, &lt;0.001, and 0.042, respectively). When treatment outcomes were
                        stratified by age at diagnosis and hypoperfusion %, PFVO showed significantly better SDS outcome
                        than nonoperative treatment in patients with an age at diagnosis of ≥8 years and low (&lt;50%)
                        and intermediate (50% to 80%) hypoperfusion ranges (p = 0.036 and 0.021, respectively).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Our study found a significant relationship
                        between femoral head hypoperfusion measured in early-stage Legg-Calvé-Perthes disease and
                        femoral head deformity at intermediate-term follow-up. This study provides new insight into the
                        relationship between early-stage femoral head hypoperfusion and deformity.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Legg-Calvé-Perthes disease is an idiopathic juvenile
                  osteonecrosis of the femoral head with a variety of prognoses<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">4</span></a>. The lateral pillar and Catterall classifications are the
                  most commonly used radiographic classifications to predict the prognosis and to facilitate treatment
                  decision-making<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">6</span></a>. Both classifications, however, are not applicable to
                  patients who present in the initial or early fragmentation stage, as accurate classification requires
                  waiting until the mid-to-late fragmentation stage, when femoral head deformity may be severe<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">9</span></a>.
                  Several studies indicate that treatment of Legg-Calvé-Perthes disease should begin at an early stage,
                  before the development of notable deformity, for bypassing the fragmentation stage and for a better
                  sphericity outcome<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">12</span></a>. Thus, a prognosticator that can be applied at early stages
                  is needed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Perfusion magnetic resonance imaging (pMRI) is an advanced
                  MRI technique that can quantify femoral head perfusion using the gadolinium-enhanced subtraction
                  method<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">13</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">14</span></a>. The extent of femoral head hypoperfusion measured by pMRI
                  represents the severity of avascularity caused by the disease. The extent of hypoperfusion measured at
                  an early stage has been reported to correlate with the lateral pillar classification at the
                  mid-fragmentation stage<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">15</span></a> and
                  to predict the 2-year radiographic outcome<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">16</span></a>.
                  While pMRI has gained greater use, with many centers performing pMRI as a standard of care<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">19</span></a>, no
                  study, to our knowledge, has investigated the relationship between femoral head hypoperfusion assessed
                  at an early stage of Legg-Calvé-Perthes disease and femoral head deformity within a cohort that is
                  larger and with longer follow-up than seen in previous studies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The sphericity deviation score (SDS) is a validated
                  radiographic measure that quantifies the severity of femoral head deformity after the healed stage<a
                    href="" data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">20</span></a>. The Stulberg classification, which has been widely used as
                  the outcome measure for Legg-Calvé-Perthes disease, is a subjective, categorical classification with
                  modest interobserver agreement<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">21</span></a>,
                  whereas the SDS is a continuous variable with more objective quantitative characteristics, better
                  interobserver agreement<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">22</span></a>, and greater statistical power<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">23</span></a>. The SDS is increasingly being used as a quantitative tool
                  to measure femoral head deformity<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_13" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">24</span></a>. It has been shown to correlate well with the Stulberg
                  classification<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">20</span></a>.
                  Furthermore, a previous study showed a high probability of a good outcome, defined as a Stulberg Class
                  I or II hip, if the SDS value was &lt;10 at the healed stage<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">22</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The purpose of the current study was to determine whether
                  the severity of femoral head hypoperfusion as measured at the early stage of Legg-Calvé-Perthes
                  disease correlates with femoral head deformity, as measured by the SDS, at intermediate-term
                  follow-up. We hypothesized that increased femoral head hypoperfusion at the early stage would show a
                  significant positive correlation with the SDS outcome at intermediate-term follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Cohort</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The study was approved by the local institutional review
                  board. Chart review identified 140 patients with Legg-Calvé-Perthes disease who had pMRI during the
                  period of 2003 to 2017. Exclusion criteria were patients in the late stage (Stage 2b or later) when
                  pMRI was performed (n = 27), patients with an age at diagnosis of &lt;5 years (n = 10) or ≥12 years (n
                  = 6), patients with &lt;4 years of follow-up (n = 23), and patients managed by operative treatments
                  other than proximal femoral varus osteotomy (PFVO) (n = 11: 9 multiple drilling, 1 valgus osteotomy, 1
                  hip distraction with an external fixator). This left 63 patients (63 hips) who had pMRI in the early
                  stage with a minimum follow-up of 4 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Perfusion
                MRI Technique and Hypoperfusion Measurement</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">For patients presenting in the early stage (Stage 1 or
                  2a), pMRI was performed as soon as possible after the initial visit. A 1.5-T scanner (GE Healthcare)
                  with a 4 or 8-channel surface coil was used to scan both hips. Noncontrast coronal fast-spin-echo
                  (FSE) fat-suppressed (FS) T1-weighted sequences were obtained, followed by gadoteridol administration
                  (0.2 mL/kg intravenously at 0.5 mmol/mL, maximum dose of 20 mL). FS coronal T1-weighted images were
                  obtained 2 minutes after the gadolinium administration. The subtraction technique consisted of
                  subtracting the precontrast from the corresponding postcontrast coronal FSE FS T1-weighted images. A
                  pMRI analysis software, HipVasc (Scottish Rite for Children) was used to obtain the hypoperfusion % of
                  the femoral head<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">25</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Two independent observers measured the hypoperfusion %,
                  and the interobserver agreement was 0.85 (95% confidence interval [CI], 0.75 to 0.91; p &lt; 0.001).
                  The mean values from both observers were used for analyses. For stratification, the hypoperfusion %
                  was unequally trichotomized as &lt;50%, 50% to 80%, and &gt;80%, because it showed an exponential
                  relationship with the SDS, especially at the ≥50% hypoperfusion ranges. Furthermore, these ranges are
                  clinically relevant, as some surgeons use hypoperfusion of ≥50% as a threshold to recommend PFVO for
                  the age group of 8 to 11 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Investigated
                Variables</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Sex, side, bilaterality, age at diagnosis, Waldenström
                  stage when pMRI was acquired, treatment method, hypoperfusion %, and the SDS at the latest visit were
                  analyzed. Age at diagnosis (age when the first radiograph was obtained for diagnosis) was dichotomized
                  as 5 to 7 (≥5 and &lt;8) and 8 to 11 (≥8 and &lt;12) years. Treatment was rendered on the basis of the
                  surgeons’ preference, taking into consideration the hypoperfusion %, age at diagnosis, hip range of
                  motion, and the legal guardian’s opinion. The treatment methods were divided into PFVO (n = 35) and
                  nonoperative treatments (n = 28). Both treatment groups could include Petrie casting with or without
                  hip adductor tenotomy. For the nonoperative treatments (n = 28), the patients were subdivided into a
                  low-restriction group (n = 15), defined as patients who were allowed to fully weight-bear (i.e., walk)
                  with hip range-of-motion exercises or restrictions from high-impact sports activities or symptomatic
                  treatment only, and a high-restriction group (n = 13), defined as patients with prolonged
                  non-weight-bearing (≥6 months of non- or toe-touch weight-bearing) using crutches, a walker, and/or a
                  wheelchair<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">26</span></a>.
                  Eight of the 13 patients in this group also had 6 weeks of Petrie casting followed by use of a wide
                  abduction brace at night. Because a comparison of these 2 nonoperative subgroups showed no significant
                  difference in SDS outcome (p = 0.060; see the Results section) and because of the small sample size of
                  each subgroup, they were combined as the nonoperative group for further analyses. The SDS at the
                  latest visit was measured using the modified method<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">27</span></a> by
                  2 independent observers, with an interobserver agreement of 0.93 (95% CI, 0.88 to 0.96; p &lt; 0.001).
                  The mean values of both observers were used for analyses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">To evaluate the independent effects of variables on SDS
                  outcomes, we used multivariable linear regression analyses. To investigate the relationship between
                  the SDS and hypoperfusion %, the hypoperfusion % was trichotomized (&lt;50%, 50% to 80%, and &gt;80%).
                  We used the hypoperfusion % as a continuous variable and fitted the models using multiple powers of
                  hypoperfusion, then chose the one with the smallest Akaike information criterion (AIC). We also fitted
                  the models in the same way after stratification by the significant prognostic factors revealed on the
                  multivariable regression analyses. The treatment outcomes were investigated after stratification by
                  hypoperfusion % and other significant prognostic factors. For the continuous variables, the
                  Kruskal-Wallis test with Bonferroni correction was used for comparison of ≥3 groups and the Wilcoxon
                  rank-sum test was used for 2 groups, as the assumption of normality was not established. Significance
                  was set at a p value of &lt;0.05. R (version 4.3.1; R Foundation for Statistical Computing) was used
                  for the analyses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Patient demographics and characteristics are shown
                  according to hypoperfusion % in <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Tables I</a> and <a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">II</a>. The mean age at diagnosis (and standard deviation) was 8.1 ± 1.7
                  years (range, 5.1 to 11.6 years). At the final follow-up, all patients had a minimum of 4 years of
                  follow-up (mean, 7.5 ± 2.6 years) and had reached the healed stage (Stage 4). The mean age at the time
                  of final follow-up was 15.6 ± 2.8 years (range, 10.4 to 24.0 years), and 41 patients (65.1%) showed
                  complete closure of the triradiate cartilage.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Demographics, Hypoperfusion %, Sphericity
                          Deviation Score, and Follow-up Duration (N = 63)<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1033px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Values</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49
                          (77.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (22.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Side</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (44.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (55.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Laterality</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unilateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59
                          (93.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bilateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (6.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at diagnosis <span
                            data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.1 ±
                          1.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age category</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  5-7 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (47.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  8-11 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (52.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Waldenström stage at
                          perfusion MRI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1a</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1b</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (55.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2a</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (41.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hypoperfusion %</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64.0 ±
                          21.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Treatment</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (44.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Low-restriction</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (23.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  High-restriction</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (20.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PFVO</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (55.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sphericity deviation
                          score</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.3 ±
                          16.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up duration <span
                            data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.5 ±
                          2.6</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number, with the percentage in parentheses,
                        or as the mean and standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Patient Demographics, Sphericity Deviation Score,
                          and Follow-up Duration According to Hypoperfusion %<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1614px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Characteristics</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Hypoperfusion %</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">&lt;50% (N
                          = 16, 25.4%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">50%-80% (N
                          = 33, 52.4%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">&gt;80% (N
                          = 14, 22.2%)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (93.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (78.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (57.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.054</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (6.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (21.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (42.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Side</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (50.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (42.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (42.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.874</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (50.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (57.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (57.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Laterality</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unilateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (93.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (90.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.505</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bilateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (6.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (9.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at diagnosis <span
                            data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.9 ±
                          1.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.0 ±
                          1.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.4 ±
                          1.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.717</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age category</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.917</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  5-7 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (50.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (48.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (42.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  8-11 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (50.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (51.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (57.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Waldenström stage</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1a</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (7.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.003<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1b</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (18.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (63.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (78.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2a</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (81.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (33.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (14.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hypoperfusion %</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.4 ±
                          12.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.6 ±
                          7.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87.8 ±
                          4.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Treatment</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonoperative</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.140</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Low-restriction</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (12.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (21.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (42.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  High-restriction</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (37.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (15.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (14.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PFVO</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (50.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (63.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (42.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sphericity deviation
                          score</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.2 ±
                          5.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.2 ±
                          14.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.0 ±
                          20.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up duration <span
                            data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.2 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.5 ±
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.1 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.007<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number, with the percentage in parentheses,
                        or as the mean and standard deviation. NA = not applicable.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Significant (p &lt; 0.05).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Relationship
                Between Hypoperfusion % and SDS</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The hypoperfusion % showed an exponential relationship
                  with the SDS (p &lt; 0.001) (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). The SDS
                  outcome revealed relatively narrow variability and no-to-mild femoral head deformity in the &lt;50%
                  hypoperfusion range. The SDS became exponentially worse and had a wider variability as the
                  hypoperfusion % increased ≥50%.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01429f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=c7de6984-22aa-4fe0-a548-59ed4af2bc9b&type=jpeg&name=JBJS.23.01429f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">A scatter plot (<span data-id="strong_37"
                            class="annotation strong" style="position: unset;">Fig. 1-A</span>) and box plot (<span
                            data-id="strong_38" class="annotation strong" style="position: unset;">Fig. 1-B</span>)
                          showing an exponential relationship between sphericity deviation score (SDS) measurements and
                          hypoperfusion %. For the scatter plot, the log-transformed SDS showed a linear relationship
                          with the third power of hypoperfusion %, which had the smallest AIC (p &lt; 0.001). In the
                          &lt;50% hypoperfusion range, the SDS value was low, indicating no-to-mild femoral head
                          deformity, and SDS variability was narrow. However, the SDS value became exponentially worse
                          (i.e., greater increase in deformity), with a wider variability, at hypoperfusion of ≥50%. The
                          asterisk indicates a significant p value (&lt;0.05). On the box plot, each box indicates the
                          interquartile range, horizontal lines in the boxes indicate the median value, the black dot
                          indicates an outlier, and the symbol X indicates the mean value. The whiskers indicate the
                          minimum and maximum values excluding outliers.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Significant
                Prognostic Factors in Regression Models</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Age at diagnosis and hypoperfusion % showed significant
                  associations with the SDS on univariate analyses (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). The
                  age group of 8 to 11 years demonstrated a worse SDS than the 5 to 7-year-old group (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2-A</a>). A higher hypoperfusion % was also associated with a worse
                  SDS, with much wider variability of the SDS at higher hypoperfusion % ranges (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2-B</a>). The treatment method did not show a significant association
                  with the SDS on univariate analysis (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-C</a>).
                  Subdividing the nonoperative treatments showed a better SDS outcome in the high-restriction group
                  compared with the low-restriction group; however, the difference was not significant (p = 0.060). On
                  multivariable analysis, age at diagnosis, hypoperfusion %, and treatment method showed significant
                  associations with SDS (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>), with
                  a greater contribution by the hypoperfusion % (coefficient, 27.3 for hypoperfusion of &gt;80% and 18.7
                  for hypoperfusion of 50% to 80%) than age at diagnosis (coefficient, 10.4) and treatment method
                  (coefficient, 8.0). Age at diagnosis and treatment method were found to be significant prognostic
                  factors and were used for stratification.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Results of Univariate and Multivariable Linear
                          Regression Models Assessing Associations with SDS<span data-id="table_footnote_reference_13"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1619.56px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.970501);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Characteristics</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Univariate</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Multivariable</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Coefficient (95% CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Coefficient (95% CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.397
                          (−0.439 to 19.233)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.061</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.814
                          (−6.929 to 10.557)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.679</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Side</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−5.172
                          (−13.540 to 3.196)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.221</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−4.275
                          (−11.217 to 2.667)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.222</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Laterality</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unilateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.369
                          (−3.553 to 30.290)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.119</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.035
                          (−5.482 to 23.552)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.217</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bilateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age category at
                          diagnosis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 8-11 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.615
                          (1.553 to 17.677)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.020<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.393
                          (3.031 to 17.755)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.007<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5-7 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Waldenström stage</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2a</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−16.080
                          (−40.363 to 8.194)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.190</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−4.378
                          (−24.424 to 15.668)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.663</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1b</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−11.960
                          (−36.010 to 12.099)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.324</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−13.153
                          (−32.911 to 6.605)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.188</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1a</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hypoperfusion
                          category</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &gt;80%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.826
                          (15.477 to 36.175)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.337
                          (15.786 to 38.887)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_9"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 50%-80%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.031
                          (5.416 to 22.646)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.002<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.681
                          (9.370 to 27.993)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;50%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Treatment method</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonoperative</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.439
                          (−6.010 to 10.888)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.566</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.044
                          (0.284 to 15.804)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.042<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PFVO</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Patient age and hypoperfusion % showed significant associations with
                        the sphericity deviation score (SDS) on both univariate and multivariable linear regression
                        analyses. Treatment method was nonsignificant on univariate analysis but became significant on
                        multivariable analysis. CI = confidence interval, and PFVO = proximal femoral varus
                        osteotomy.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Significant (p &lt; 0.05).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01429f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=c7de6984-22aa-4fe0-a548-59ed4af2bc9b&type=jpeg&name=JBJS.23.01429f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_39"
                            class="annotation strong" style="position: unset;">Fig. 2-A</span> Dichotomization of age at
                          diagnosis (5 to 7 versus 8 to 11 years) showed significantly higher sphericity deviation score
                          (SDS) measurements in the older age group (mean, 18.2 ± 14.6 for 5 to 7 versus 27.8 ± 17.1 for
                          8 to 11 years; p = 0.018). <span data-id="strong_40" class="annotation strong"
                            style="position: unset;">Fig. 2-B</span> The hypoperfusion % was trichotomized (&lt;50%, 50%
                          to 80%, &gt;80%) because of exponential worsening of the SDS at ≥50% hypoperfusion. The solid
                          gray arrow indicates a linear increase in the mean SDS value with a higher hypoperfusion %
                          range (mean, 10.2 ± 5.2 for &lt;50% hypoperfusion versus 24.2 ± 14.1 for 50% to 80% versus
                          36.0 ± 20.1 for &gt;80%; p &lt; 0.001). A wider variability of SDS outcome was seen with a
                          higher hypoperfusion % range, as indicated by the dashed gray arrows. <span
                            data-id="strong_41" class="annotation strong" style="position: unset;">Fig. 2-C</span>
                          Although the treatment method showed significance on the multivariable analysis, it failed to
                          show significance on the univariate analysis (30.0 ± 19.4 for the low restriction group, 15.4
                          ± 13.1 for the high restriction group, and 23.3 ± 15.6 for the group with proximal femoral
                          varus osteotomy [PFVO]; p = 0.064). On further analysis, this seems to be because of age-based
                          treatment selection bias (Table IV). The asterisks indicate significant p values (&lt;0.05).
                          On the box plots, each box indicates the interquartile range, horizontal lines in the boxes
                          indicate the median value, black dots indicate the outliers, and the symbol X indicates the
                          mean value. The whiskers indicate the minimum and maximum values excluding the outliers.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">To evaluate the reason for the nonsignificance of
                  treatment method in univariate analysis (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-C</a>), the
                  differences in patient characteristics according to treatment methods were investigated. Only age at
                  diagnosis differed significantly according to treatment method (mean, 7.1 ± 1.8 years for nonoperative
                  with low restriction, 7.9 ± 2.1 years for nonoperative with high restriction, and 8.5 ± 1.3 years for
                  PFVO; p = 0.021) (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>),
                  indicating possible surgeon preference for selecting nonoperative treatments for younger patients and
                  PFVO for older patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Patient Demographics, Hypoperfusion %, Sphericity
                          Deviation Score (SDS), and Follow-up Duration According to the Treatment Method<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1751.69px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.98503);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Characteristics</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Treatment Method</span></th>
                      <th align="center" rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Nonoperative</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">PFVO (N = 35, 55.6%)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Low-Restriction (N = 15, 23.8%)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">High-Restriction (N = 13, 20.6%)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.467</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (66.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (84.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (80.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (33.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (15.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (20.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Side</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.924</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (40.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (46.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (45.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (60.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (53.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (54.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Laterality</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.370</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unilateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (86.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (92.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (97.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bilateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (13.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (7.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at diagnosis <span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.1 ±
                          1.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.9 ±
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.5 ±
                          1.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.021<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age category</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td colspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.013<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  5-7 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (73.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (61.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (31.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  8-11 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (26.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (38.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (68.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Waldenström stage</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.436</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1a</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (5.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1b</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (73.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (53.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (48.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2a</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (26.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (46.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (45.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hypoperfusion %</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72.0 ±
                          23.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55.9 ±
                          20.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.5 ±
                          19.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.130</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypoperfusion
                          category</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.140</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  &gt;80%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (40.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (15.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (17.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  50%-80%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (46.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (38.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (60.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  &lt;50%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (13.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (46.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (22.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SDS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.0 ±
                          19.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.4 ±
                          13.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.3 ±
                          15.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.064</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up duration <span
                            data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.3 ±
                          3.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.3 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.3 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.408</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">A significantly higher percentage of younger patients (age group of 5
                        to 7 years) had nonoperative treatments, while a significantly higher percentage of older
                        patients (age group of 8 to 11 years) had proximal femoral varus osteotomy (PFVO). No
                        significant difference in SDS outcomes by treatment methods were found on univariate analysis,
                        which may due to age-based treatment selection bias.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Significant (p &lt; 0.05).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Relationship
                Between Hypoperfusion % and SDS, Stratified by Age and Treatment Method</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The hypoperfusion % showed positive correlations with the
                  SDS when patients were stratified by age or treatment method, with exponential worsening of the SDS in
                  the ≥50% hypoperfusion range (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 3-A</a> and <a
                    href="" data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">3-B</a>). Because of the differences in SDS outcome according to age group
                  (<a href="" data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3-A</a>), we further investigated these after stratification by
                  hypoperfusion %. A significant difference in SDS was noted in the intermediate hypoperfusion range
                  (50% to 80%) according to age group (mean SDS, 15.7 ± 10.7 for an age of 5 to 7 years versus 32.3 ±
                  12.1 for an age of 8 to 11 years; p &lt; 0.001) (<a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3-C</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01429f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=c7de6984-22aa-4fe0-a548-59ed4af2bc9b&type=jpeg&name=JBJS.23.01429f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_42"
                            class="annotation strong" style="position: unset;">Fig. 3-A</span> A scatter plot of
                          hypoperfusion % and sphericity deviation score (SDS) stratified by age group. The
                          log-transformed SDS showed a linear relationship with the fourth power of hypoperfusion % in
                          the age group of 5 to 7 years (p = 0.006, red line) and the first power of hypoperfusion % in
                          the age group of 8 to 11 years (p = 0.002, blue dashed line). <span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Fig. 3-B</span> A scatter plot of
                          hypoperfusion % and SDS stratified by treatment group. The log-transformed SDS showed a linear
                          relationship with the first power of hypoperfusion % for the proximal femoral varus osteotomy
                          (PFVO) group (p &lt; 0.001, red line). However, for the nonoperative group, the SDS itself
                          (not its log-transformed form) showed a linear relationship with the fifth power of
                          hypoperfusion % (p = 0.049, blue dashed line). There was no significant difference between the
                          treatment methods, which may be because of age-based treatment selection bias (Table IV).
                          <span data-id="strong_44" class="annotation strong" style="position: unset;">Fig. 3-C</span>
                          Since outcome difference due to age was suspected on the scatter plot (<span
                            data-id="strong_45" class="annotation strong" style="position: unset;">Fig. 3-A</span>), the
                          difference in the SDS according to age group was analyzed for each hypoperfusion range, as
                          shown in the box plots. A significant difference in the SDS was found only in the 50% to 80%
                          hypoperfusion range (mean SDS, 15.7 ± 10.7 for age 5 to 7 years versus 32.3 ± 12.1 for age 8
                          to 11 years; p &lt; 0.001). The asterisks indicate significant p values (&lt;0.05). On the box
                          plots, each box indicates the interquartile range, horizontal lines in the boxes indicate the
                          median value, black dots indicate the outliers, and the symbol X indicates the mean value. The
                          whiskers indicate the minimum and maximum values excluding the outliers.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Treatment
                Outcomes Stratified by Both Age and Hypoperfusion %</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">For clinical application and treatment selection, we
                  compared treatment outcomes as stratified by both age and hypoperfusion % (<a href=""
                    data-id="figure_reference_15" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 4</a> and <a href="" data-id="figure_reference_16"
                    class="annotation figure_reference resource-reference" style="position: unset;">5</a>). For patients
                  5 to 7 years of age (<a href="" data-id="figure_reference_17"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4-A</a>), there
                  were no significant differences in the SDS according to the treatment method in the &lt;50% and 50% to
                  80% hypoperfusion ranges. For the &gt;80% hypoperfusion range, there was only 1 patient in the PFVO
                  group, and thus, statistical comparison was not performed. For patients 8 to 11 years of age (<a
                    href="" data-id="figure_reference_18" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4-B</a>), the PFVO group showed significantly better SDS outcomes than
                  the nonoperative group if the patients were in the &lt;50% or 50% to 80% hypoperfusion range (p =
                  0.036 and 0.021, respectively). For hypoperfusion of &gt;80%, there were only 2 patients in the
                  nonoperative group, and thus, statistical comparison was not performed. It is important to note that,
                  because of age-based treatment bias, the sample size of the PFVO group was low in the age group of 5
                  to 7 years, while the sample size of the nonoperative treatment group was low in the age group of 8 to
                  11 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01429f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=c7de6984-22aa-4fe0-a548-59ed4af2bc9b&type=jpeg&name=JBJS.23.01429f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_46"
                            class="annotation strong" style="position: unset;">Figs. 4-A and 4-B</span> Scatter plots
                          stratified by the 2 variables of age at diagnosis and hypoperfusion %. <span
                            data-id="strong_47" class="annotation strong" style="position: unset;">Fig. 4-A</span> For
                          patients 5 to 7 years of age with hypoperfusion of ≤80%, the SDS outcome distributions were
                          similar between the nonoperative and PFVO groups (p = 1.0 and 0.299 for the hypoperfusion
                          ranges of &lt;50% and 50% to 80%, respectively). <span data-id="strong_48"
                            class="annotation strong" style="position: unset;">Fig. 4-B</span> For patients 8 to 11
                          years of age, the PFVO group showed significantly better SDS outcomes than the nonoperative
                          group for the hypoperfusion ranges of &lt;50% (p = 0.036) and 50% to 80% (p = 0.021). For the
                          hypoperfusion range of &lt;50%, the mean SDS was 7.6 ± 0.8 in the PFVO group and 17.0 ± 8.8 in
                          the nonoperative group. For the hypoperfusion range of 50% to 80%, the mean SDS was 28.9 ±
                          10.5 in the PFVO group and 48.0 ± 1.8 in the nonoperative group.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01429f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=c7de6984-22aa-4fe0-a548-59ed4af2bc9b&type=jpeg&name=JBJS.23.01429f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_49"
                            class="annotation strong" style="position: unset;">Figs. 5-A through 5-E</span> Cases
                          illustrating the relationship between hypoperfusion % and the sphericity deviation score (SDS)
                          outcome. Each row shows the initial radiograph, perfusion MRI, and final radiographs
                          (anteroposterior and frog-leg lateral views) from left to right. <span data-id="strong_50"
                            class="annotation strong" style="position: unset;">Fig. 5-A</span> A 9-year-old boy with a
                          low level of hypoperfusion (47%) treated nonoperatively. At age 13, his femoral head showed an
                          excellent SDS outcome of 10. <span data-id="strong_51" class="annotation strong"
                            style="position: unset;">Fig. 5-B</span> A 5-year-old boy with an intermediate level of
                          hypoperfusion (74%) treated nonoperatively. At age 12, the SDS for his femoral head was 15,
                          indicating a good SDS outcome. <span data-id="strong_52" class="annotation strong"
                            style="position: unset;">Fig. 5-C</span> A 7-year-old girl with an intermediate level of
                          hypoperfusion (64%) treated with proximal femoral varus osteotomy (PFVO). At age 16, the SDS
                          for her femoral head was 17, indicating a good SDS outcome. For the age group of 5 to 7 years
                          with intermediate-range hypoperfusion (50% to 80%), there was no significant difference in SDS
                          outcome according to the treatment method. <span data-id="strong_53" class="annotation strong"
                            style="position: unset;">Fig. 5-D</span> A 9-year-old girl with an intermediate level of
                          hypoperfusion (71%) treated nonoperatively. At age 18, the SDS for her femoral head was 47,
                          indicating a poor SDS outcome. <span data-id="strong_54" class="annotation strong"
                            style="position: unset;">Fig. 5-E</span> An 8-year-old boy with an intermediate level of
                          hypoperfusion (57%) treated with PFVO. At age 17, the SDS for his femoral head was 21. For the
                          age group of 8 to 11 years, the SDS outcome was significantly better in the PFVO group
                          compared with the nonoperative group in the hypoperfusion ranges of ≤80%.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this is the first study using a
                  continuous variable (the SDS) to investigate the relationship between femoral head hypoperfusion and
                  femoral head deformity in patients with Legg-Calvé-Perthes disease at intermediate-term follow-up. The
                  results support the hypothesis that increased femoral head hypoperfusion at the early stage will show
                  a significant positive correlation with SDS outcome at intermediate-term follow-up. However, the
                  relationship between hypoperfusion % and the SDS outcome was not linear. On the basis of the results
                  of previous studies<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">16</span></a>, we expected a linear relationship between hypoperfusion %
                  and the SDS outcome. Our results surprisingly revealed a nonlinear relationship at hypoperfusion of
                  ≥50%, and the SDS outcome became exponentially worse, with greater variability, suggesting that it is
                  difficult to consistently obtain a good SDS outcome at higher hypoperfusion. This relationship was
                  independent of age at diagnosis or treatment method. The finding of greater SDS variability at
                  hypoperfusion of ≥50% suggests that the SDS may be influenced by unmeasured or unmeasurable
                  confounders, such as innate bone healing potential, patient activity/hyperactivity level, patient
                  adherence to activity restrictions, epiphyseal growth disturbance, timing of treatment, and the amount
                  of deformity present before initiating treatment. Another variable that could have influenced the
                  outcome is the bone age of the patients, which was not measured in this study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">For the treatment outcomes stratified by both age and
                  hypoperfusion %, we found no significant differences in SDS outcomes between the PFVO and the
                  nonoperative treatments in the hypoperfusion ranges of &lt;50% and 50% to 80% for patients in the age
                  group of 5 to 7 years. However, we cannot rule out the possibility of a type-II error because of the
                  small sample sizes in the stratified analysis. For the &gt;80% hypoperfusion range, there was only 1
                  patient in the PFVO group, and thus, statistical comparison was not performed. The findings from this
                  study warrant a large prospective study to determine the role of PFVO more conclusively in the age
                  group of 5 to 7 years among patients with different levels of hypoperfusion.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">For the age group of 8 to 11 years, PFVO showed
                  significantly better SDS outcomes than the nonoperative treatments for the hypoperfusion ranges of
                  &lt;50% and 50% to 80%. In the patients with hypoperfusion of &lt;50%, the mean SDS was 17.0 for the
                  nonoperative group compared with 7.6 for the PFVO group. A previous study correlating SDS measurements
                  to Stulberg classification showed a very high probability of a Stulberg Class I or II outcome if the
                  SDS value was &lt;10 at the healed stage<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">22</span></a>.
                  For patients 8 to 11 years of age with a hypoperfusion % in the 50% to 80% range, the mean SDS was
                  48.0 in the nonoperative group compared with 28.9 in the PFVO group (<a href=""
                    data-id="figure_reference_19" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4-B</a>). In previous studies, SDS measurements of between 15 and 30
                  were associated with Stulberg Class III and between 35 and 50, with Stulberg Class IV<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">22</span></a>. For patients with hypoperfusion of &gt;80%, statistical
                  comparison was not performed as there were only 2 patients in the nonoperative group because of
                  stratification by age and hypoperfusion range. A large prospective study of the age group of 8 to 11
                  years is also warranted to further validate these findings.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">It is interesting to note that age at diagnosis showed a
                  significant association with SDS, but this association was seen mainly in the 50% to 80% hypoperfusion
                  range (<a href="" data-id="figure_reference_20" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3-C</a>). While having a larger proportion of patients in the 50% to
                  80% hypoperfusion range (52.4%) may have contributed to this finding, it is also possible that,
                  regardless of age, those with low hypoperfusion (&lt;50%) heal better and those with high
                  hypoperfusion (&gt;80%) heal worse. Future research quantifying patient characteristics that determine
                  femoral head healing will provide further insight into factors that contribute to the prognosis of
                  Legg-Calvé-Perthes disease and femoral head deformity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Our study had limitations. First, it had inherent
                  limitations of the retrospective study design. While we found a significant correlation between the
                  hypoperfusion % and SDS outcome, the study design did not allow the establishment of a causal
                  relationship. Possible confounding variables, such as treatment selection bias, cannot be controlled
                  for and may not be knowable in a retrospective study design. Second, the sample size was small after
                  stratification by 2 variables (age and hypoperfusion %) to assess treatment outcomes. Thus, we cannot
                  rule out a type-II statistical error for some comparisons showing nonsignificant differences between
                  the nonoperative and the PFVO groups and between the low- and high-restriction groups. Propensity
                  scoring might be useful to control such bias; however, because of the small sample size in each
                  subgroup, this was not possible in the present study. Finally, our study was limited by the
                  intermediate-term assessment. Although some studies have demonstrated that the shape of the femoral
                  head shows nonsignificant change after reaching the healed stage<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">28</span></a>, some patients may have further remodeling<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">29</span></a>. Ultimately, long-term follow-up studies at skeletal
                  maturity are needed to assess the deformity. We are planning to follow these patients, along with the
                  patients enrolled in the International Perthes Study Group studies, and hope to report the long-term
                  results in the future.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In summary, our findings provide new insight into the
                  relationship between early-stage femoral head hypoperfusion and radiographic outcome in different age
                  groups of patients with Legg-Calvé-Perthes disease. As a result of this study, we suggest the
                  following treatment guidance: for patients 8 to 11 years of age with low (&lt;50%) and intermediate
                  (50% to 80%) hypoperfusion ranges, we recommend PFVO over nonoperative treatments (<a href=""
                    data-id="figure_reference_21" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>). For patients 5 to 7 years of age with low (&lt;50%) and
                  intermediate (50% to 80%) hypoperfusion ranges, nonoperative and PFVO treatments produced similar SDS
                  outcomes. However, we cannot rule out the possibility of a type-II error because of the small sample
                  size. The findings from the study warrant a large prospective study to confirm the role of pMRI as an
                  early-stage prognosticator of outcome that can facilitate treatment decision-making.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Joseph B</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Prognostic factors and outcome
                  measures in Perthes disease</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Orthop Clin North Am.</span> 2011 Jul;42(3):303-15, v-vi.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prognostic%20factors%20and%20outcome%20measures%20in%20Perthes%20disease&as_occt=title&as_sauthors=%20%22B%20Joseph%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Wiig O</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Terjesen T</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Svenningsen S</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Prognostic factors and outcome
                  of treatment in Perthes’ disease: a prospective study of 368 patients with five-year follow-up</span>.
                <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 2008 Oct;90(10):1364-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prognostic%20factors%20and%20outcome%20of%20treatment%20in%20Perthes%E2%80%99%20disease%3A%20a%20prospective%20study%20of%20368%20patients%20with%20five-year%20follow-up&as_occt=title&as_sauthors=%20%22O%20Wiig%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Joseph B</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Rao N</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Mulpuri K</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Varghese G</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Nair S</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">How does a femoral varus
                  osteotomy alter the natural evolution of Perthes’ disease?</span><span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop B.</span> 2005
                Jan;14(1):10-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20does%20a%20femoral%20varus%20osteotomy%20alter%20the%20natural%20evolution%20of%20Perthes%E2%80%99%20disease%3F&as_occt=title&as_sauthors=%20%22B%20Joseph%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Singh KA</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Shah H</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Joseph B</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Aarvold A</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Kim HKW</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Evolution of Legg-Calvé-Perthes
                  disease following proximal femoral varus osteotomy performed in the avascular necrosis stage:a
                  prospective study</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Child Orthop.</span> 2020 Feb 1;14(1):58-67.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evolution%20of%20Legg-Calv%C3%A9-Perthes%20disease%20following%20proximal%20femoral%20varus%20osteotomy%20performed%20in%20the%20avascular%20necrosis%20stage%3Aa%20prospective%20study&as_occt=title&as_sauthors=%20%22KA%20Singh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Catterall A</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Legg-Calvé-Perthes
                  syndrome</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 1981 Jul-Aug;(158):41-52.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Legg-Calv%C3%A9-Perthes%20syndrome&as_occt=title&as_sauthors=%20%22A%20Catterall%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Herring JA</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Kim HT</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Browne R</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Legg-Calve-Perthes disease. Part
                  II: Prospective multicenter study of the effect of treatment on outcome</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2004 Oct;86(10):2121-34.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=925459"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Price CT</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">The lateral pillar
                  classification for Legg-Calvé-Perthes disease</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2007
                Jul-Aug;27(5):592-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20lateral%20pillar%20classification%20for%20Legg-Calv%C3%A9-Perthes%20disease&as_occt=title&as_sauthors=%20%22CT%20Price%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Van Dam BE</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Crider RJ</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Noyes JD</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Larsen LJ</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Determination of the Catterall
                  classification in Legg-Calvé-Perthes disease</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 1981
                Jul;63(6):906-14.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=462807"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Lappin K</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Kealey D</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Cosgrove A</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Herring classification: how
                  useful is the initial radiograph?</span><span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop.</span> 2002 Jul-Aug;22(4):479-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Herring%20classification%3A%20how%20useful%20is%20the%20initial%20radiograph%3F&as_occt=title&as_sauthors=%20%22K%20Lappin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Sankar WN</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Lavalva SM</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Mcguire MF</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Jo C</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Laine JC</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Kim HKW</span>; International
                Perthes Study Group. <span data-id="strong_10" class="annotation strong" style="position: unset;">Does
                  Early Proximal Femoral Varus Osteotomy Shorten the Duration of Fragmentation in Perthes Disease?
                  Lessons From a Prospective Multicenter Cohort</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2020
                May/Jun;40(5):e322-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20Early%20Proximal%20Femoral%20Varus%20Osteotomy%20Shorten%20the%20Duration%20of%20Fragmentation%20in%20Perthes%20Disease%3F%20Lessons%20From%20a%20Prospective%20Multicenter%20Cohort&as_occt=title&as_sauthors=%20%22WN%20Sankar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Kim S</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Oh HS</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Lim JH</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Cho S</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Jung ST</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Results of Early Proximal
                  Femoral Osteotomy at Skeletal Maturity in Legg-Calvé-Perthes Disease: Implication for the Bypass of
                  Fragmentation Stage</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop.</span> 2021 Oct 1;41(9):e768-73.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Results%20of%20Early%20Proximal%20Femoral%20Osteotomy%20at%20Skeletal%20Maturity%20in%20Legg-Calv%C3%A9-Perthes%20Disease%3A%20Implication%20for%20the%20Bypass%20of%20Fragmentation%20Stage&as_occt=title&as_sauthors=%20%22S%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Mishima K</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Kamiya Y</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Matsushita M</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Kitoh H</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Bypass of Epiphyseal
                  Fragmentation Following Early Salter Innominate Osteotomy and Its Clinical Relevance in
                  Legg-Calvé-Perthes Disease</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop.</span> 2022 May-Jun;42(5):239-45.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bypass%20of%20Epiphyseal%20Fragmentation%20Following%20Early%20Salter%20Innominate%20Osteotomy%20and%20Its%20Clinical%20Relevance%20in%20Legg-Calv%C3%A9-Perthes%20Disease&as_occt=title&as_sauthors=%20%22K%20Mishima%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Lamer S</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Dorgeret S</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Khairouni A</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Mazda K</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Brillet PY</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Bacheville E</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Bloch J</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Penneçot GF</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Hassan M</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Sebag GH</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Femoral head vascularisation in
                  Legg-Calvé-Perthes disease: comparison of dynamic gadolinium-enhanced subtraction MRI with bone
                  scintigraphy</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Pediatr Radiol.</span> 2002 Aug;32(8):580-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femoral%20head%20vascularisation%20in%20Legg-Calv%C3%A9-Perthes%20disease%3A%20comparison%20of%20dynamic%20gadolinium-enhanced%20subtraction%20MRI%20with%20bone%20scintigraphy&as_occt=title&as_sauthors=%20%22S%20Lamer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Kim HK</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Kaste S</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Dempsey M</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Wilkes D</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">A comparison of non-contrast
                  and contrast-enhanced MRI in the initial stage of Legg-Calvé-Perthes disease</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Pediatr Radiol.</span> 2013
                Sep;43(9):1166-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comparison%20of%20non-contrast%20and%20contrast-enhanced%20MRI%20in%20the%20initial%20stage%20of%20Legg-Calv%C3%A9-Perthes%20disease&as_occt=title&as_sauthors=%20%22HK%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Kim HK</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Wiesman KD</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Kulkarni V</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Burgess J</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Chen E</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Brabham C</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Ikram H</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Du J</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Lu A</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Kulkarni AV</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Dempsey M</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Herring JA</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Perfusion MRI in Early Stage of
                  Legg-Calvé-Perthes Disease to Predict Lateral Pillar Involvement: A Preliminary Study</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2014 Jul 16;96(14):1152-60.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1192852" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Du J</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Lu A</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Dempsey M</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Herring JA</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Kim HK</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">MR perfusion index as a
                  quantitative method of evaluating epiphyseal perfusion in Legg-Calve-Perthes disease and correlation
                  with short-term radiographic outcome: a preliminary study</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2013
                Oct-Nov;33(7):707-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=MR%20perfusion%20index%20as%20a%20quantitative%20method%20of%20evaluating%20epiphyseal%20perfusion%20in%20Legg-Calve-Perthes%20disease%20and%20correlation%20with%20short-term%20radiographic%20outcome%3A%20a%20preliminary%20study&as_occt=title&as_sauthors=%20%22J%20Du%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Sankar WN</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Thomas S</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Castañeda P</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Hong T</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Shore BJ</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Kim HK</span>; Study Conducted by
                the International Perthes Study Group. <span data-id="strong_17" class="annotation strong"
                  style="position: unset;">Feasibility and safety of perfusion MRI for Legg-Calvé-Perthes
                  disease</span>. <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J
                  Pediatr Orthop.</span> 2014 Oct-Nov;34(7):679-82.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Feasibility%20and%20safety%20of%20perfusion%20MRI%20for%20Legg-Calv%C3%A9-Perthes%20disease&as_occt=title&as_sauthors=%20%22WN%20Sankar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Dillman JR</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Hernandez RJ</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">MRI of Legg-Calve-Perthes
                  disease</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">AJR
                  Am J Roentgenol.</span> 2009 Nov;193(5):1394-407.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=MRI%20of%20Legg-Calve-Perthes%20disease&as_occt=title&as_sauthors=%20%22JR%20Dillman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Laine JC</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Martin BD</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Novotny SA</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Kelly DM</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Role of Advanced Imaging in the
                  Diagnosis and Management of Active Legg-Calvé-Perthes Disease</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2018 Aug
                1;26(15):526-36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Role%20of%20Advanced%20Imaging%20in%20the%20Diagnosis%20and%20Management%20of%20Active%20Legg-Calv%C3%A9-Perthes%20Disease&as_occt=title&as_sauthors=%20%22JC%20Laine%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Shah H</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Siddesh ND</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Pai H</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Tercier S</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Joseph B</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Quantitative measures for
                  evaluating the radiographic outcome of Legg-Calvé-Perthes disease</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2013 Feb
                20;95(4):354-61.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1159539"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Huhnstock S</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Wiig O</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Merckoll E</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Svenningsen S</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Terjesen T</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The modified Stulberg
                  classification is a strong predictor of the radiological outcome 20 years after the diagnosis of
                  Perthes’ disease</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2021 Dec;103-B(12):1815-20.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20modified%20Stulberg%20classification%20is%20a%20strong%20predictor%20of%20the%20radiological%20outcome%2020%20years%20after%20the%20diagnosis%20of%20Perthes%E2%80%99%20disease&as_occt=title&as_sauthors=%20%22S%20Huhnstock%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Siddesh ND</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Shah H</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Tercier S</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Pai H</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Nair S</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Joseph B</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">The sphericity deviation score:
                  a quantitative radiologic outcome measure of Legg-Calvé Perthes disease applicable at the stage of
                  healing and at skeletal maturity</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop.</span> 2014 Jul-Aug;34(5):522-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20sphericity%20deviation%20score%3A%20a%20quantitative%20radiologic%20outcome%20measure%20of%20Legg-Calv%C3%A9%20Perthes%20disease%20applicable%20at%20the%20stage%20of%20healing%20and%20at%20skeletal%20maturity&as_occt=title&as_sauthors=%20%22ND%20Siddesh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Lazic SE</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Why we should use simpler
                  models if the data allow this: relevance for ANOVA designs in experimental biology</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">BMC Physiol.</span> 2008
                Jul 21;8:16.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Why%20we%20should%20use%20simpler%20models%20if%20the%20data%20allow%20this%3A%20relevance%20for%20ANOVA%20designs%20in%20experimental%20biology&as_occt=title&as_sauthors=%20%22SE%20Lazic%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Singh KA</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Guddattu V</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Shah H</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Radiologic Outcomes of
                  Bilateral and Unilateral Perthes Disease: A Comparative Cohort Study</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                2022 Feb 1;42(2):e168-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiologic%20Outcomes%20of%20Bilateral%20and%20Unilateral%20Perthes%20Disease%3A%20A%20Comparative%20Cohort%20Study&as_occt=title&as_sauthors=%20%22KA%20Singh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Kim HK</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Burgess J</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Thoveson A</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Gudmundsson P</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Dempsey M</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Jo CH</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Assessment of Femoral Head
                  Revascularization in Legg-Calvé-Perthes Disease Using Serial Perfusion MRI</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2016 Nov 16;98(22):1897-904.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1264593" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Peck JB</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Greenhill DA</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Morris WZ</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Do DH</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> McGuire MF</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Kim HKW</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Prolonged non-weightbearing
                  treatment decreases femoral head deformity compared to symptomatic treatment in the initial stage of
                  Legg-Calvé-Perthes disease</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop B.</span> 2022 May 1;31(3):209-15.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prolonged%20non-weightbearing%20treatment%20decreases%20femoral%20head%20deformity%20compared%20to%20symptomatic%20treatment%20in%20the%20initial%20stage%20of%20Legg-Calv%C3%A9-Perthes%20disease&as_occt=title&as_sauthors=%20%22JB%20Peck%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Kang MS</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Kak A</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Prasadh JG</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Kim HK</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Reliability of the Modified
                  Method for Sphericity Deviation Score Using Only the Involved Hip Radiographs in the Patients With
                  Legg-Calve-Perthes Disease</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop.</span> 2023 Aug 1;43(7):e554-60.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%20of%20the%20Modified%20Method%20for%20Sphericity%20Deviation%20Score%20Using%20Only%20the%20Involved%20Hip%20Radiographs%20in%20the%20Patients%20With%20Legg-Calve-Perthes%20Disease&as_occt=title&as_sauthors=%20%22MS%20Kang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Shah H</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Siddesh ND</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Joseph B</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">To what extent does remodeling
                  of the proximal femur and the acetabulum occur between disease healing and skeletal maturity in
                  Perthes disease? A radiological study</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop.</span> 2008 Oct-Nov;28(7):711-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=To%20what%20extent%20does%20remodeling%20of%20the%20proximal%20femur%20and%20the%20acetabulum%20occur%20between%20disease%20healing%20and%20skeletal%20maturity%20in%20Perthes%20disease%3F%20A%20radiological%20study&as_occt=title&as_sauthors=%20%22H%20Shah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Keller NB</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Liu RW</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Prediction of adolescent pelvis
                  development using femoral head and acetabulum growth in a longitudinal radiographic study</span>.
                <span data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Clin Anat.</span> 2021
                Jul;34(5):726-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prediction%20of%20adolescent%20pelvis%20development%20using%20femoral%20head%20and%20acetabulum%20growth%20in%20a%20longitudinal%20radiographic%20study&as_occt=title&as_sauthors=%20%22NB%20Keller%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dc7de6984-22aa-4fe0-a548-59ed4af2bc9b%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): 36</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01429</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 29, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_30"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Scottish
                            Rite for Children, Dallas, Texas</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;c7de6984-22aa-4fe0-a548-59ed4af2bc9b&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=c7de6984-22aa-4fe0-a548-59ed4af2bc9b&type=pdf&name=JBJS.23.01429.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=c7de6984-22aa-4fe0-a548-59ed4af2bc9b&type=pdf&name=JBJS.23.01429.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_35"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_36" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I253"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I253</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;c7de6984-22aa-4fe0-a548-59ed4af2bc9b&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=c7de6984-22aa-4fe0-a548-59ed4af2bc9b&type=zip&name=JBJS.23.01429.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael Seungcheol Kang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0172-2721" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0172-2721</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">David Zimmerhanzel, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1565-7764" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1565-7764</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shamrez Haider, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4468-3846" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4468-3846</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Harry Kwang-Woo Kim, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Harry.Kim@tsrh.org" class="" style="position: unset;">Harry.Kim@tsrh.org</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0330-4777" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0330-4777</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Scottish Rite for Children, Dallas, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, University of Texas Southwestern Medical
                Center, Dallas, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Asan Medical Center Children’s Hospital,
                University of Ulsan College of Medicine, Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 34237.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
