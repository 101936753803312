import ContentLoader from "react-native-easy-content-loader";

type Props = {
	numLines?: number;
	height?: number;
};

export const TextSkeleton = ({ numLines = 1, height = 34 }: Props) => {
	return (
		//@ts-ignore
		<ContentLoader
			paragraphStyles={{
				borderRadius: 8,
				overflow: "hidden",
			}}
			containerStyles={{
				paddingHorizontal: 0,
			}}
			active
			title={false}
			pRows={numLines}
			pHeight={height}
			pWidth={"100%"}
		/>
	);
};
