import type { Article } from "../../../../../types";

export const Article671: Article = {
	id: 671,
	rsuiteId: "10bba976-69bc-4740-b828-7a1f40f3af22",
	type: "the orthopaedic forum",
	title:
		"Development and Validation of Objective and Subjective Osteoporosis Knowledge Instruments Among Chinese Orthopaedic Surgeons",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=10bba976-69bc-4740-b828-7a1f40f3af22&type=jpeg&name=JBJS.23.01136f1",
	subSpecialties: ["spine"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Development and Validation of Objective and Subjective
                Osteoporosis Knowledge Instruments Among Chinese Orthopaedic Surgeons</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jian Mo,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ying Mo,
                      BM</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 11 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_62" class="annotation strong"
                          style="position: unset;">Background:</span> Clinicians must be knowledgeable about
                        osteoporosis so that they can convey information regarding the prevention of fragility fractures
                        to their patients. The purposes of this study were to develop objective and subjective knowledge
                        instruments for osteoporosis and fragility fractures and then test their reliability and
                        validity among Chinese orthopaedic surgeons.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_63" class="annotation strong"
                          style="position: unset;">Methods:</span> A 2-round procedure was used to develop the objective
                        and subjective knowledge instruments. A cross-sectional online survey was distributed to 293
                        orthopaedic surgeons; 189 surgeons returned the questionnaires. We examined internal
                        consistency, test-retest reliability, criterion validity, and discriminant validity; we also
                        compared the subjective knowledge level with the objective knowledge level among surgeons.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_64" class="annotation strong"
                          style="position: unset;">Results:</span> Our results showed that the Subjective Knowledge
                        Scale (SKS) regarding Osteoporosis and Fragility Fractures had a high Cronbach alpha coefficient
                        (0.915), and the objective Osteoporosis Knowledge Test for Clinicians (OKTC) had an adequate
                        Kuder-Richardson 20 coefficient (0.64). Item analyses were conducted, and a short version of the
                        OKTC (the OKTC-SF) was developed. The SKS, the OKTC, and the OKTC-SF all showed good test-retest
                        reliability, criterion validity, and discriminant validity. The percentage of surgeons with a
                        high subjective knowledge level was higher than the percentage of surgeons who selected the
                        correct answer for several corresponding questions related to objective knowledge.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_65" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The SKS, the OKTC, and the OKTC-SF all
                        demonstrated good reliability and validity. However, the orthopaedic surgeons may have
                        overestimated their knowledge level regarding osteoporosis. Targeted continuing medical
                        education that is based on individual knowledge level is needed to improve the undertreatment of
                        osteoporosis among patients with fragility fractures.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Aging has become a global concern<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. The worldwide population of people who are ≥65 years of age
                  has been projected to increase from 761 million in 2021 to 1.6 billion in 2050<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. Similarly, it has been projected that the elderly
                  population in the People’s Republic of China (PRC) will increase from 267 million (19%) in 2021 to 420
                  million (30%) in 2035, creating a super-aged society<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a>. Aging is associated with osteoporosis and fragility
                  fractures<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>, and
                  previous studies have found that the prevalence of osteoporosis in the PRC is steadily increasing<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">7</span></a>; the
                  current prevalence is 5.0% to 20.7% for men and 20.6% to 38.1% for women ≥50 years of age<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">12</span></a>.
                  Moreover, most prior studies found that the fragility fracture rate in the PRC had increased in recent
                  years<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">17</span></a>, and
                  the absolute number of patients with fragility fractures as well as hospitalization costs also have
                  been increasing<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">15</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">16</span></a>. Fragility fractures can lead to severe outcomes, such as
                  disability and death<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">18</span></a>. The
                  12-month cumulative mortality following hip fractures was 18.3% in women and 28.6% in men<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">19</span></a>, and only half of the patients recovered their prefracture
                  levels of mobility and activities of daily living<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">20</span></a>.
                  Different from the care model in Western countries, many elderly people in the PRC require long-term
                  home care<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">21</span></a>.
                  Based on current trends, it has been predicted that 241.7 million fragility fractures will occur in
                  the PRC from 2020 to 2040, with a cost of 997 billion U.S. dollars (USD)<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">22</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Various guidelines have suggested that patients with
                  fragility fractures should be screened and treated for osteoporosis<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">28</span></a>. However, previous studies have shown that osteoporosis is
                  underdiagnosed and undertreated worldwide<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">31</span></a>. A recent study reported that only 37.8% of patients with a
                  fragility fracture have undergone a bone mineral density (BMD) test in the U.S.<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">32</span></a>. In the PRC, the postfracture BMD testing rates have varied
                  from 19% to 61%<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">34</span></a>. Only 0.3% of men and 1.4% of women who were diagnosed with
                  osteoporosis based on BMD testing or who had fragility fractures received anti-osteoporosis
                  treatment<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">8</span></a>.
                  Another study reported an anti-osteoporosis treatment rate of 22.1% for women and 9.5% for men
                  following fragility fractures in the PRC, but a slightly declining trend has been noted in recent
                  years<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">35</span></a>.
                  More efforts are required to reverse the declining treatment rate in order to prevent subsequent
                  fractures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Several studies have demonstrated that clinicians with a
                  better understanding of osteoporosis are more capable of diagnosing and treating osteoporosis among
                  patients with fragility fractures<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">38</span></a>. However, previous studies have shown that clinicians have
                  insufficient knowledge regarding osteoporosis<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">40</span></a>. Only a few studies have investigated the knowledge level
                  of Chinese orthopaedic surgeons regarding osteoporosis. Our previous study found that most of the
                  orthopaedic surgeons rated their knowledge level as “excellent” or “good” for several self-rated
                  knowledge questions, although they may have overestimated their actual knowledge level<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">38</span></a>. Two other studies found that surgeons and internists
                  generally adhered to Chinese osteoporosis guidelines<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">37</span></a>. The Chinese Society of Osteoporosis and Bone Mineral
                  Research updated their guidelines in 2017 and 2022<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">41</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">42</span></a>, but more research is needed to investigate the knowledge
                  level among health professionals in the PRC<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">26</span></a>. Although various osteoporosis knowledge tools have been
                  developed throughout the past few decades<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">43</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">47</span></a>, we used and revised 2 recent questionnaires to keep in
                  line with the newest guidelines<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">39</span></a>. We also used the subjective knowledge questionnaire and
                  the behavior-based sensitivity scale that we had described in our previous study<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">38</span></a>. The validity and reliability of the objective and
                  subjective knowledge instruments were evaluated, and the differences between the objective and
                  subjective knowledge levels were determined.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study Design
                and Participants</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">This study used a pilot survey for orthopaedic surgeons
                  from the Practice Patterns in the Diagnosis and Treatment of the Secondary Prevention of Osteoporotic
                  Fracture: A Multicenter, Observational, Ambidirectional Cohort Study (PRevention of OsTEoporotiC
                  FracTure Study [PROTECT-1]). Institutional review board approval was obtained, and completion of the
                  survey was considered to imply consent for participation. The study was registered at <a
                    href="https://www.chictr.org.cn/" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">https://www.chictr.org.cn/</a> (ChiCTR2100048900).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">A cross-sectional survey was distributed to orthopaedic
                  surgeons in the PRC from October 2022 through June 2023. We recruited respondents in 2 ways. First, we
                  sent email and/or text messages with a survey link to respondents who had participated in our survey
                  in 2015 and had provided their contact information<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">38</span></a>. If
                  a surgeon did not respond to the initial email or text message, 2 additional messages were sent to
                  encourage participation in the survey. Among the 452 respondents from 2015, 276 had provided email
                  addresses or telephone numbers, and a total of 36 surgeons responded to our survey. Second, we invited
                  surgeons from several national online WeChat orthopaedic surgeon groups to take our online survey. We
                  also encouraged respondents to share the survey link with their colleagues. All of the participants
                  were informed that their participation was voluntary and that their responses would be confidential.
                  We offered respondents a financial incentive (60-100 Chinese yuan [CNY] [8 to 14 USD]) for responding.
                  Only orthopaedic surgeons with ≥3 months of clinical experience were included.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Instruments</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The instruments that were used in this study had several
                  parts, including subjective knowledge, objective knowledge, sensitivity to the secondary prevention of
                  fragility fractures, health beliefs about osteoporosis, and questions related to sociodemographic
                  characteristics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">A 2-round procedure was used to design our instruments
                  (see Appendix 1). In the first round, guidelines<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_30"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_30"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">42</span></a><span data-id="superscript_30"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_30"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">49</span></a> and previous scientific literature<a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">39</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">43</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">45</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">47</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">50</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">51</span></a> were reviewed, and discussions among 6 experts (J.M. and 5
                  other experienced orthopaedic surgeons) were conducted to obtain the first version of the instrument,
                  including the Subjective Knowledge Scale (SKS) regarding Osteoporosis and Fragility Fractures, the
                  objective Osteoporosis Knowledge Test for Clinicians (OKTC), and the behavior-based sensitivity score
                  system for the secondary prevention of fragility fractures (SSPF). The details of the development
                  process and the scoring of the instruments are described in Appendix 1.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">In the second round, 7 experts (4 orthopaedic surgeons, 1
                  rheumatologist, 1 endocrinologist, and 1 public health expert) were invited to review the instruments.
                  Based on their suggestions regarding clarity, face validity, and content validity, we revised the
                  instruments and obtained the final version.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">In the final instrument, we used 3 questions to check the
                  seriousness of respondents (see Appendix 2 Part D), and only the clinicians who answered the questions
                  correctly were included in the final analyses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">SPSS Statistics (version 26.0; IBM) was used for the
                  statistical analyses. Percentages were used to describe the demographic categorical variables. The
                  chi-square test or the Fisher exact test was used to test differences for the categorical variables,
                  and the Bonferroni correction was used for multiple comparisons. The normality of the data
                  distribution was examined using visual inspection of the histogram that demonstrated the frequency
                  distribution and using the Shapiro-Wilk test. The results showed that the scores, which were presented
                  with the median and the interquartile range (IQR), were non-normally distributed. The Kruskal-Wallis
                  test was used for comparison of the 3 groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Reliability was investigated using the internal
                  consistency coefficient and test-retest reliability. The Cronbach alpha coefficient or
                  Kuder-Richardson 20 (KR-20) coefficient was used for the SKS and the OKTC, respectively. The
                  intraclass correlation coefficient (ICC) was used for the evaluation of test-retest reliability<a
                    href="" data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">52</span></a>. A total of 53 respondents were retested with the SKS, the
                  OKTC, and the SSPF system after an interval of 2 to 4 weeks<a href="" data-id="citation_reference_61"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">53</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Construct validity of the SKS was determined with
                  exploratory factor analysis (EFA). A principal component analysis with varimax rotation was performed
                  for the answers to the 10 items in the SKS. We retained factors based on eigenvalues of &gt;1 and
                  scree plot analysis, and we removed items with factor loading of &lt;0.40.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Item analysis was performed to develop the short version
                  of the OKTC (the OKTC-SF). We examined the difficulty index, the discrimination index, and the
                  item-rest correlation of each item to evaluate which items could be retained in the OKTC-SF<a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">54</span></a> (see Appendix 1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The criterion validity of the SKS, the OKTC, and the
                  OKTC-SF was examined using Spearman rank correlation coefficients with the SSPF scores. To evaluate
                  the discriminant validity of the SKS, the OKTC, and the OKTC-SF, group comparisons were performed
                  among participants with different SSPF score levels. We believed that respondents with higher scores
                  on the SSPF would have a higher knowledge level about osteoporosis and would score higher on the SKS,
                  the OKTC, and the OKTC-SF. A p value of &lt;0.05 (2-tailed) was deemed significant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">A total of 293 respondents submitted surveys, and 189 met
                  the selection criteria and were included in the analyses (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). Most of
                  the respondents were male, attending surgeons, general orthopaedic surgeons, and from East China, and
                  the mean age was 40 years (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).
                  Hospitals in the PRC are divided into 3 levels (1 to 3) and are further grouped into 3 ranks (A, B,
                  and C). The top-ranked hospitals are designated 3A; they provide the highest degree of specialized
                  medical services<a href="" data-id="citation_reference_63"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">55</span></a><span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_65" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">56</span></a> (see Appendix 1). More than half of the respondents worked
                  in 3A hospitals. Compared with the participants from our previous study<a href=""
                    data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">38</span></a>, the respondents had job titles with higher rankings and a
                  higher mean age, and fewer of the respondents practiced general orthopaedics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01136f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=10bba976-69bc-4740-b828-7a1f40f3af22&type=jpeg&name=JBJS.23.01136f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart demonstrating inclusion of the study
                          participants.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Characteristics of Responding Chinese Orthopaedic
                          Surgeons in the Current and Previous Studies</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1472px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Respondents in Current Study (N = 189) <span data-id="emphasis_62"
                            class="annotation emphasis" style="position: unset;">(no. [%])</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Respondents in Previous Study (N = 452)<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_63" class="annotation emphasis"
                            style="position: unset;">(no. [%])</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age in yr</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N =
                          382</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;25</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 25-34</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (24%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">160
                          (42%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 35-44</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95
                          (50%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">175
                          (46%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 45-54</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (19%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (11%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 55-59</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥60</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N =
                          402</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.414</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">184
                          (97%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">386
                          (96%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Job title</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N =
                          371</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Junior (resident)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67
                          (18%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Intermediate (attending
                          physician)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83
                          (44%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">200
                          (54%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Subsenior and senior
                          (associate chief and chief attending physician)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93
                          (49%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">104
                          (28%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Specialty</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N =
                          389</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> General
                          orthopaedics</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">129
                          (68%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">342
                          (88%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Spine surgery</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other orthopaedic
                          subspecialties</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (15%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other specialties</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Region</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N =
                          366</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.065</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> East</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">147
                          (78%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">313
                          (86%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Middle</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (16%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37
                          (10%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> West</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hospital level</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">N =
                          389</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.601</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Below 3A</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85
                          (45%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">166
                          (43%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3A</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">104
                          (55%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">223
                          (57%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Responding orthopaedic surgeons from our 2018 study<a href=""
                          data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                          style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                            style="position: unset;">38</span></a><span data-id="superscript_37"
                          class="annotation superscript" style="position: unset;"></span>. The N values of each question
                        may not sum to the total number of 452 because of missing data.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The chi-square or Fisher exact test p values for the differences
                        between respondents from the current and previous studies.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Construct
                Validity and Reliability of the SKS</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">As shown in the figure in Appendix 3, many surgeons rated
                  themselves as “excellent” or “good” on most of the items in the SKS, and the median score was 88 (IQR,
                  75 to 98). The factor loading and item-rest correlation coefficient for each item are shown in <a
                    href="" data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>. No item was dropped, and 2 factors were extracted and named
                  as “etiology knowledge” (items A1 through A4) and “diagnosis and treatment knowledge” (items A5
                  through A10), with a cumulative variance contribution of 70%. The SKS Cronbach alpha was 0.915, and
                  the test-retest ICC coefficient was 0.771 (n = 53; p &lt; 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Factor Loading and Item-Rest Correlation
                          Coefficients for the SKS by Item<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 879px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Item</span>
                      </th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Factor Loading<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Item-Rest Correlation Coefficient</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A1. Definition and types
                          of fragility fractures/low-energy fractures</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_68" class="annotation strong" style="position: unset;">0.81</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.65</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A2. Causes of secondary
                          osteoporosis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_69" class="annotation strong" style="position: unset;">0.86</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A3. Risk factors for
                          osteoporosis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_70" class="annotation strong" style="position: unset;">0.81</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.67</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A4. Diagnostic criteria
                          for osteoporosis</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.55</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_71" class="annotation strong" style="position: unset;">0.61</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.74</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A5. Pharmacologic therapy
                          for osteoporosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_72" class="annotation strong" style="position: unset;">0.66</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.45</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.74</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A6. Definition of drug
                          holidays</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_73" class="annotation strong" style="position: unset;">0.80</span></span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.64</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A7. Long-term treatment
                          monitoring and follow-up with patients</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_74" class="annotation strong" style="position: unset;">0.87</span></span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.79</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A8. Lifestyle
                          modification recommendations for osteoporosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_75" class="annotation strong" style="position: unset;">0.84</span></span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.75</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A9. Ways to prevent
                          falls</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_76" class="annotation strong" style="position: unset;">0.53</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.43</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.61</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A10. Educational contents
                          for patients with fragility/low-energy fractures</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_77" class="annotation strong" style="position: unset;">0.71</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.48</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.80</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The Kaiser-Meyer-Olkin value (0.886) and the Bartlett test of
                        sphericity (χ<span data-id="superscript_38" class="annotation superscript"
                          style="position: unset;">2</span> = 1287.170, p &lt; 0.001) indicated that the data were
                        appropriate for conducting factor analysis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The bold numbers represent the items that contributed primarily to the
                        factor for that column.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Item
                Analyses, Validity, and Reliability of the OKTC and the OKTC-SF</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">The median score of the surgeons’ objective knowledge
                  level on the OKTC was 29 (IQR, 19 to 38). The KR-20 coefficient of the OKTC was 0.64, and the mean
                  discrimination index of the questionnaire was 29% (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). We
                  removed items with a difficulty index of &lt;0.1 (items C8, C19, and C21). Although item C5, C9, C13,
                  and C14 had a difficulty index of &lt;0.2, we retained them in the OKTC-SF because of their importance
                  regarding knowledge about osteoporosis. Item C4 was removed because of a low point-biserial
                  correlation with the total score.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Analysis, Reliability, and Validity of the OKTC by
                          Item<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 2530.05px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.808354);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Item</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Difficulty
                          Index</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Discrimination Index</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Point-Biserial Correlation</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">KR-20
                          Coefficient with the Item Omitted</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_78"
                            class="annotation strong" style="position: unset;">C1.T-score threshold for diagnosis of
                            osteoporosis</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.83</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.35</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_79"
                            class="annotation strong" style="position: unset;">C2. Prevalence of osteoporosis-related
                            fracture in older women</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.34</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.34</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.37</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_80"
                            class="annotation strong" style="position: unset;">C3. Mortality after hip
                            fracture</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.29</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.34</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C4. Proportion of hip
                          fracture patients who regain prefracture level of independence</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.44</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.17</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.65</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_81"
                            class="annotation strong" style="position: unset;">C5. Age to begin routine bone density
                            screening in women (Chinese guideline)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.14</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.33</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_82"
                            class="annotation strong" style="position: unset;">C6. Osteoporosis screening in men
                            (Chinese guideline)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.44</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.41</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.40</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.62</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_83"
                            class="annotation strong" style="position: unset;">C7. A hip fracture occurs, but the x-ray
                            bone density results are normal. The diagnosis is?</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.26</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.31</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.34</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C8. Medications
                          demonstrated in randomized trials to decrease hip fracture risk</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.09</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.15</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.27</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_84"
                            class="annotation strong" style="position: unset;">C9. First-line therapy for osteoporosis
                            in women</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.14</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.31</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_85"
                            class="annotation strong" style="position: unset;">C10. Optimal duration of osteoporosis
                            pharmacotherapy</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.37</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.30</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.29</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.64</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_86"
                            class="annotation strong" style="position: unset;">C11. Drug holiday
                            definition</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.57</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.50</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.48</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.61</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_87"
                            class="annotation strong" style="position: unset;">C12. Most rapid bone density decline
                            after discontinuation</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.41</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.62</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.60</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_88"
                            class="annotation strong" style="position: unset;">C13. Medications associated with
                            osteonecrosis of the jaw</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.18</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.33</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.36</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.62</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_89"
                            class="annotation strong" style="position: unset;">C14. Characteristics of
                            bisphosphonate-associated atypical femoral fractures</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.14</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.15</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.25</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.64</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_90"
                            class="annotation strong" style="position: unset;">C15. Tests before osteoporotic
                            pharmacotherapy</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.44</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.43</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.41</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.62</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_91"
                            class="annotation strong" style="position: unset;">C16. Mechanisms of medicine</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.27</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.57</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.52</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.60</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_92"
                            class="annotation strong" style="position: unset;">C17. Clinical factors associated with
                            increased risk of osteoporosis</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.19</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.29</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_93"
                            class="annotation strong" style="position: unset;">C18. Therapeutic goal for a 66-year-old
                            patient diagnosed with osteoporosis and treated with FOSAVANCE and calcium 600
                            mg/day?</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.56</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.45</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.49</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.61</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C19. Treatment failure in
                          osteoporosis</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.64</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span data-id="strong_94"
                            class="annotation strong" style="position: unset;">C20. Indications for anti-osteoporosis
                            therapy?</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.32</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.38</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.62</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C21. Medicines should not
                          be used in a patient with osteoporosis who has a creatinine clearance &lt;30 mL/min?</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.64</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The items in bold text are also included as the items on the
                        OKTC-SF.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The OKTC-SF contains 5 dimensions and 17 items:
                  “diagnostic and DXA [dual x-ray absorptiometry] knowledge” (items C1, C5, C6, C7, and C15), “general
                  knowledge” (items C2, C3, and C17), “drug holiday” (item C11), “medication side effects and long-term
                  follow-up” (items C10, C12, C13, C14, and C18), and “medication treatment” (items C9, C16, and C20).
                  The average discrimination index of the OKTC-SF was 34%, and the KR-20 coefficient was 0.65. The
                  test-retest ICC coefficient of the OKTC and the OKTC-SF was 0.734 and 0.736, respectively (n = 53; p
                  &lt; 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Criterion
                Validity and Discriminant Validity of the SKS, the OKTC, and the OKTC-SF</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">As shown in Appendix 4 Table 1, a behavior-based
                  sensitivity score system was developed for the SPFF. The total score was calculated, and the median
                  score was 75 (IQR, 67 to 83). The criterion validity, or the correlation coefficients, between the
                  SKS, the OKTC, the OKTC-SF, and the SSPF was high (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Correlation Coefficients of the SSPF, the SKS, the
                          OKTC, and the OKTC-SF<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 403px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Instrument</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">SSPF</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">SKS</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">OKTC</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">OKTC-SF</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SSPF</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.000</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SKS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.592
                          (0.492-0.677)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.000</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OKTC</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.351
                          (0.214-0.479)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.393
                          (0.267-0.506)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.000</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OKTC-SF</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.352
                          (0.226-0.477)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.365
                          (0.232-0.493)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.982
                          (0.976-0.986)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.000</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values in parentheses indicate the 95% confidence interval for each
                        correlation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">For the discriminant validity analysis, the respondents
                  were divided into 3 groups according to their SSPF score (high group: &gt;90, n = 21; middle group: 60
                  to 90, n = 147; and low group: &lt;60, n = 21). The Kruskal-Wallis tests revealed significant
                  differences in the SKS, the OKTC, and the OKTC-SF scores among the 3 different SSPF score groups, and
                  the post hoc analyses using the Bonferroni correction showed significant differences between the low
                  group and the high group (see Appendix 4 Table 2). The responses to each question from the low and
                  high SSPF score groups (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>; see also
                  Appendix 4 Table 3) indicated that respondents with higher SSPF scores tended to perform better on
                  individual items of the SKS and the OKTC.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01136f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=10bba976-69bc-4740-b828-7a1f40f3af22&type=jpeg&name=JBJS.23.01136f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;">Discriminant validity analyses. The percentages of
                          clinicians who selected “excellent” or “good” for the subjective items on the SKS (<span
                            data-id="strong_95" class="annotation strong" style="position: unset;">Fig. 2-A</span>) and
                          the percentages who selected the “right” answer on the OKTC (<span data-id="strong_96"
                            class="annotation strong" style="position: unset;">Fig. 2-B</span>) are shown. ** indicates
                          p &lt; 0.0167 and *** indicates p &lt; 0.001.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">The percentage of respondents who selected “excellent” or
                  “good” was high for 5 SKS items, while the number of respondents who selected the right answer was
                  relatively low for 16 corresponding OKTC items, indicating large gaps between the subjective and
                  objective knowledge levels (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01136f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=10bba976-69bc-4740-b828-7a1f40f3af22&type=jpeg&name=JBJS.23.01136f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;">Comparison of subjective and objective knowledge
                          of osteoporosis and fragility fractures. The black columns indicate the percentages of
                          clinicians who selected “excellent” or “good” as responses to 5 subjective questions items,
                          and the gray columns indicate the percentages of those who selected the “right” answer for 16
                          objective items. ONJ = osteonecrosis of the jaw.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">With the rapidly growing aging population, osteoporosis
                  and fragility fractures have become a serious public health problem. Previous studies have found that
                  the screening and treatment of osteoporosis have been inadequate in the PRC and worldwide. To address
                  the crisis in osteoporosis care<a href="" data-id="citation_reference_68"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">57</span></a>, it
                  is important to encourage orthopaedic surgeons to take responsibility and provide osteoporosis
                  screening, treatment, and education<a href="" data-id="citation_reference_69"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript" style="position: unset;">38</span></a>. In
                  the current study, we revised and developed instruments to investigate the osteoporosis knowledge
                  level among orthopaedic surgeons, and we assessed the validity and reliability of these instruments.
                  Our findings indicated that the instruments may be useful tools for knowledge evaluation; however,
                  although most surgeons had confidence (good or excellent) regarding their knowledge level, their
                  actual knowledge may have been poor.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Our results showed that the SKS had high internal
                  consistency. The KR-20 coefficient of the OKTC was 0.64 and adequate<a href=""
                    data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">58</span></a>, but it was still lower than that previously reported for
                  the revised Osteoporosis Knowledge Test (KR-20 = 0.85)<a href="" data-id="citation_reference_71"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_42" class="annotation superscript" style="position: unset;">43</span></a>. A
                  possible reason for this difference may be that some questions in this study were difficult for
                  surgeons<a href="" data-id="citation_reference_72"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">59</span></a>,
                  such as questions about medication efficacy (C8), treatment failure (C19), and medication for patients
                  with reduced kidney function (C21). To reduce respondent burden, we removed some of the questions
                  based on item analyses and discussions among experts. However, all of the items in the OKTC were still
                  important for evaluating the knowledge level about osteoporosis. The KR-20 coefficient of the OKTC-SF
                  was 0.65. The exploratory factor analysis showed that the SKS had good structural validity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">The correlations between the SKS, the OKTC, the OKTC-SF,
                  and the SSPF were high, suggesting that both subjective and objective knowledge levels are valid
                  indicators to predict surgeons’ behaviors regarding fracture prevention. We also evaluated the
                  discriminant validity of the SKS, the OKTC, and the OKTC-SF, and the results demonstrated that
                  surgeons with higher SSPF scores tended to have higher scores on the SKS, the OKTC, and the OKTC-SF,
                  indicating good discriminant validity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">We further compared the results of the SKS with the OKTC
                  (<a href="" data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>; see also Appendix 4 Table 4) and found that although
                  respondents rated themselves as having a high knowledge level, the results of the objective knowledge
                  tests revealed that they did not perform well. Although 94% of the surgeons rated themselves as
                  “excellent” or “good” regarding knowledge for the diagnosis of osteoporosis (item A4), only 6% of
                  respondents knew that the guidelines suggested that women ≥65 years of age should be screened with a
                  BMD test (item C5); additionally, only 13% believed that the evidence was insufficient for screening
                  in men (item C6). Those percentages were lower than the percentages in the U.S. (81% for items C5 and
                  75% for C6)<a href="" data-id="citation_reference_73"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript" style="position: unset;">29</span></a>.
                  Although 83% of respondents knew the T-score threshold for the diagnosis of osteoporosis (item C1; see
                  Appendix 4 Table 4), only 26% agreed that healthy 65-year-old men with hip fractures from minimal
                  trauma but normal BMD should be diagnosed with osteoporosis (item C7).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Similarly, 93% of the respondents believed that they had
                  “excellent” or “good” knowledge about the treatment for osteoporosis (item A5), while only
                  approximately 10% of them recognized all of the medications that can decrease hip fracture risk (item
                  C8) or all of the first-line medications for osteoporosis (item C9), which was lower than the
                  percentages of clinicians in the U.S. (36% to 67%, respectively)<a href=""
                    data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">29</span></a>. Only 22% of respondents recognized all of the indications
                  for anti-osteoporosis medications (item C20). Only 44% to 60% of surgeons knew that a bisphosphonate
                  should not be used among patients with a creatinine clearance of &lt;30 mL/min (item C21; see Appendix
                  4 Table 4), while 19% believed that denosumab should not be used for patients with creatinine
                  clearance &lt;30 mL/min. Regarding long-term follow-up, 75% of the respondents rated themselves as
                  having “excellent” or “good” knowledge (item A7); however, only 37% of them agreed that the optimal
                  duration of osteoporosis pharmacotherapy is unclear (item C10) compared with 63% of resident
                  physicians in the U.S.)<a href="" data-id="citation_reference_75"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_46" class="annotation superscript" style="position: unset;">29</span></a>.
                  Therefore, a large gap was noticed between self-rated (subjective) knowledge and objective knowledge,
                  indicating that orthopaedic surgeons may overestimate their knowledge level about osteoporosis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">The importance of knowledge that affects behaviors among
                  clinicians has been recognized<a href="" data-id="citation_reference_76"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_47"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_77" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">38</span></a>. In our study, the knowledge instruments (i.e., the SKS,
                  the OKTC, and the OKTC-SF) showed good validity and reliability. These results have important
                  implications for future continuing medical education because these instruments may be used in
                  pre-education needs assessment and post-education effectiveness evaluation. We found a gap between
                  levels of objective and subjective knowledge. Consistent with previous studies<a href=""
                    data-id="citation_reference_78" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">60</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_79" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">61</span></a>, we believe that subjective knowledge (which includes
                  confidence regarding one’s own knowledge) may have a stronger effect on prevention behavior than
                  objective knowledge does. Education strategies should be carefully designed to match the needs of
                  clinicians with different levels of knowledge. For example, we can provide education to an
                  overconfident surgeon who has a low level of objective knowledge regarding guideline details. For
                  clinicians with a low self-rated knowledge level, efforts can be focused on their attitudes and
                  beliefs about osteoporosis treatment<a href="" data-id="citation_reference_80"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_49" class="annotation superscript" style="position: unset;">62</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Our study has some limitations. The convenience sampling
                  and small number of orthopaedic surgeons who participated in this study may have contributed to bias,
                  and our results should therefore be interpreted with caution. In this pilot study, we evaluated the
                  validity and reliability of the objective and subjective osteoporosis knowledge instruments, and the
                  final instruments will be used in a future national study. Lastly, recall bias may have been present
                  because we used self-reported answers about knowledge and preventive behaviors.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, we developed subjective and objective
                  knowledge instruments about osteoporosis and fragility fractures for orthopaedic surgeons. Our results
                  indicated that these instruments have good validity and reliability, and they may be useful in future
                  targeted continuing medical education. We found a gap between subjective and objective knowledge
                  levels, and more efforts should focus on improving surgeons’ beliefs regarding osteoporosis treatment
                  as well as their knowledge about osteoporosis so that more patients can benefit from this knowledge
                  concerning treatment and the prevention of fractures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_5" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I274" target="_blank" data-id="link_6" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I274</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: We thank Dr. Carolyn J. Crandall and Dr. Yacov Fogelman for
                  their support and permission to use their osteoporosis knowledge instruments. We are grateful to all
                  of the participants who took the time to respond our survey.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Partridge L</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Deelen J</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Slagboom PE</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Facing up to the global
                  challenges of ageing</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Nature.</span> 2018 Sep;561(7721):45-56.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Facing%20up%20to%20the%20global%20challenges%20of%20ageing&as_occt=title&as_sauthors=%20%22L%20Partridge%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;">United
                Nations Department of Economic and Social Affairs. <span data-id="strong_2" class="annotation strong"
                  style="position: unset;">World social report 2023: leaving no one behind in an ageing World</span>.
                2023. Accessed 2024 Oct 18. <a
                  href="https://desapublications.un.org/publications/world-social-report-2023-leaving-no-one-behind-ageing-world"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://desapublications.un.org/publications/world-social-report-2023-leaving-no-one-behind-ageing-world</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=World%20social%20report%202023%3A%20leaving%20no%20one%20behind%20in%20an%20ageing%20World&as_occt=title&as_sauthors=%20%22United%20Nations%20Department%20of%20Economic%20and%20Social%20Affairs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Xiaowei M</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Report of the State Council on
                  the progress of strengthening and promoting work on aging</span>. 2024. Accessed 2022 Sep 3. <a
                  href="https://www.nhc.gov.cn/wjw/mtbd/202209/2ca97b572ee34a38819cceaf5207dd1a.shtml" target="_blank"
                  data-id="link_2" class="link"
                  style="position: unset;">https://www.nhc.gov.cn/wjw/mtbd/2022ee09/2ca97b572ee34a38819cceaf5207dd1a.shtml</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Report%20of%20the%20State%20Council%20on%20the%20progress%20of%20strengthening%20and%20promoting%20work%20on%20aging&as_occt=title&as_sauthors=%20%22M%20Xiaowei%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Luo Y</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Su B</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Zheng X</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Trends and Challenges for
                  Population and Health During Population Aging - China, 2015-2050</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">China CDC Wkly.</span> 2021 Jul
                9;3(28):593-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20and%20Challenges%20for%20Population%20and%20Health%20During%20Population%20Aging%20-%20China%2C%202015-2050&as_occt=title&as_sauthors=%20%22Y%20Luo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Johnston CB</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Dagar M</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Osteoporosis in Older
                  Adults</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Med
                  Clin North Am.</span> 2020 Sep;104(5):873-84.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoporosis%20in%20Older%20Adults&as_occt=title&as_sauthors=%20%22CB%20Johnston%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Yu F</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Xia W</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The epidemiology of
                  osteoporosis, associated fragility fractures, and management gap in China</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Arch Osteoporos.</span> 2019
                Mar 8;14(1):32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20epidemiology%20of%20osteoporosis%2C%20associated%20fragility%20fractures%2C%20and%20management%20gap%20in%20China&as_occt=title&as_sauthors=%20%22F%20Yu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Chen P</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Li Z</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Hu Y</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Prevalence of osteoporosis in
                  China: a meta-analysis and systematic review</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">BMC Public Health.</span> 2016 Oct
                3;16(1):1039.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20osteoporosis%20in%20China%3A%20a%20meta-analysis%20and%20systematic%20review&as_occt=title&as_sauthors=%20%22P%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Yu W</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Yin X</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Cui L</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Tang S</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Jiang N</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Cui L</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Zhao N</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Lin Q</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Chen L</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Lin H</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Jin X</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Dong Z</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Ren Z</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Hou Z</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Zhong J</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Cai S</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Liu Y</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Meng R</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Deng Y</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ding X</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Ma J</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Xie Z</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Shen L</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Wu W</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Zhang M</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Ying Q</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Zeng Y</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Dong J</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Cummings SR</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Li Z</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Xia W</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Prevalence of Osteoporosis and
                  Fracture in China: The China Osteoporosis Prevalence Study</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2021 Aug
                2;4(8):e2121106.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20Osteoporosis%20and%20Fracture%20in%20China%3A%20The%20China%20Osteoporosis%20Prevalence%20Study&as_occt=title&as_sauthors=%20%22L%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Wang J</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Shu B</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Tang DZ</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Li CG</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Xie XW</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Jiang LJ</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Jiang XB</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Chen BL</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Lin XC</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Wei X</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Leng XY</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Liao ZY</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Li BL</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Cui XJ</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Zhang Q</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Lu S</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Shi Q</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Wang YJ</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The prevalence of osteoporosis
                  in China, a community based cohort study of osteoporosis</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Front Public Health.</span> 2023 Feb
                16;11:1084005.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20prevalence%20of%20osteoporosis%20in%20China%2C%20a%20community%20based%20cohort%20study%20of%20osteoporosis&as_occt=title&as_sauthors=%20%22J%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Cheng X</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Zhao K</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Zha X</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Du X</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Chen S</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Wu Y</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Li S</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Lu Y</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Xiao X</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Ma X</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Gong X</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Chen W</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Yang Y</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Jiao J</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Chen B</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Lv Y</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Gao J</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Hong G</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Pan Y</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Yan Y</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Qi H</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Ran L</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Zhai J</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Li K</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Fu H</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Wu J</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Liu S</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Blake GM</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Pickhardt PJ</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Ma Y</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Fu X</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Dong S</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Zeng Q</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Guo Z</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Hind K</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Engelke K</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Tian W</span>; <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">China Health Big Data (China
                  Biobank) project investigators. Opportunistic Screening Using Low-Dose CT and the Prevalence of
                  Osteoporosis in China: A Nationwide, Multicenter Study</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">J Bone Miner Res.</span> 2021
                Mar;36(3):427-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=China%20Health%20Big%20Data%20(China%20Biobank)%20project%20investigators.%20Opportunistic%20Screening%20Using%20Low-Dose%20CT%20and%20the%20Prevalence%20of%20Osteoporosis%20in%20China%3A%20A%20Nationwide%2C%20Multicenter%20Study&as_occt=title&as_sauthors=%20%22X%20Cheng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Cui Z</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Meng X</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Feng H</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Zhuang S</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Liu Z</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Zhu T</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Ye K</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Xing Y</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Sun C</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Zhou F</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Tian Y</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Estimation and projection about
                  the standardized prevalence of osteoporosis in mainland China</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Arch Osteoporos.</span> 2019 Dec
                6;15(1):2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Estimation%20and%20projection%20about%20the%20standardized%20prevalence%20of%20osteoporosis%20in%20mainland%20China&as_occt=title&as_sauthors=%20%22Z%20Cui%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Zeng Q</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Li N</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Wang Q</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Feng J</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Sun D</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Zhang Q</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Huang J</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Wen Q</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Hu R</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Ma Y</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Fu X</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Dong S</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Cheng X</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The Prevalence of Osteoporosis
                  in China, a Nationwide, Multicenter DXA Survey</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Bone Miner Res.</span> 2019
                Oct;34(10):1789-97.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Prevalence%20of%20Osteoporosis%20in%20China%2C%20a%20Nationwide%2C%20Multicenter%20DXA%20Survey&as_occt=title&as_sauthors=%20%22Q%20Zeng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Xia WB</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> He SL</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Xu L</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Liu AM</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Jiang Y</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Li M</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Wang O</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Xing XP</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Sun Y</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Cummings SR</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Rapidly increasing rates of hip
                  fracture in Beijing, China</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Bone Miner Res.</span> 2012 Jan;27(1):125-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rapidly%20increasing%20rates%20of%20hip%20fracture%20in%20Beijing%2C%20China&as_occt=title&as_sauthors=%20%22WB%20Xia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Gong XF</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Li XP</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Zhang LX</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Center JR</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Bliuc D</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Shi Y</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Wang HB</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> He L</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Wu XB</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Current status and distribution
                  of hip fractures among older adults in China</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2021
                Sep;32(9):1785-93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20status%20and%20distribution%20of%20hip%20fractures%20among%20older%20adults%20in%20China&as_occt=title&as_sauthors=%20%22XF%20Gong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Zheng XQ</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Xu L</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Huang J</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Zhang CG</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Yuan WQ</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Sun CG</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Zhang ZS</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Wei C</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Wang JX</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Cummings SR</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Xia WB</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Wang SF</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Zhan SY</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Song CL</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Incidence and cost of vertebral
                  fracture in urban China: a 5-year population-based cohort study</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Int J Surg.</span> 2023 Jul
                1;109(7):1910-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20and%20cost%20of%20vertebral%20fracture%20in%20urban%20China%3A%20a%205-year%20population-based%20cohort%20study&as_occt=title&as_sauthors=%20%22XQ%20Zheng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Zhang C</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Feng J</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Wang S</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Gao P</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Xu L</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Zhu J</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Jia J</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Liu L</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Liu G</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Wang J</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Zhan S</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Song C</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Incidence of and trends in hip
                  fracture among adults in urban China: A nationwide retrospective cohort study</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">PLoS Med.</span> 2020 Aug
                6;17(8):e1003180.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20of%20and%20trends%20in%20hip%20fracture%20among%20adults%20in%20urban%20China%3A%20A%20nationwide%20retrospective%20cohort%20study&as_occt=title&as_sauthors=%20%22C%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Hou XL</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Liu JY</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Fan XH</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Zhang N</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Cao GL</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Guo ZB</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Zhang YY</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Yu YH</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Tian YQ</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Sun XX</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Tian FM</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Secular trends of incidence and
                  hospitalization cost of hip fracture in Tangshan, China</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2022
                Jan;33(1):89-96.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Secular%20trends%20of%20incidence%20and%20hospitalization%20cost%20of%20hip%20fracture%20in%20Tangshan%2C%20China&as_occt=title&as_sauthors=%20%22XL%20Hou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Papadimitriou N</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Tsilidis KK</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Orfanos P</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Benetou V</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Ntzani EE</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Soerjomataram I</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Künn-Nelen A</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Pettersson-Kymmer U</span>,
                <span data-id="annotation_197" class="annotation" style="position: unset;"> Eriksson S</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Brenner H</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Schöttker B</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Saum KU</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Holleczek B</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Grodstein FD</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Feskanich D</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Orsini N</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Wolk A</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Bellavia A</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Wilsgaard T</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Jørgensen L</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Boffetta P</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Trichopoulos D</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Trichopoulou A</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Burden of hip fracture using
                  disability-adjusted life-years: a pooled analysis of prospective cohorts in the CHANCES
                  consortium</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Lancet Public Health.</span> 2017 May;2(5):e239-46.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Burden%20of%20hip%20fracture%20using%20disability-adjusted%20life-years%3A%20a%20pooled%20analysis%20of%20prospective%20cohorts%20in%20the%20CHANCES%20consortium&as_occt=title&as_sauthors=%20%22N%20Papadimitriou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Llopis-Cardona F</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Armero C</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Hurtado I</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> García-Sempere A</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Peiró S</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Rodríguez-Bernal CL</span>,
                <span data-id="annotation_218" class="annotation" style="position: unset;"> Sanfélix-Gimeno G</span>.
                <span data-id="strong_19" class="annotation strong" style="position: unset;">Incidence of Subsequent Hip
                  Fracture and Mortality in Elderly Patients: A Multistate Population-Based Cohort Study in Eastern
                  Spain</span>. <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Bone
                  Miner Res.</span> 2022 Jun;37(6):1200-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20of%20Subsequent%20Hip%20Fracture%20and%20Mortality%20in%20Elderly%20Patients%3A%20A%20Multistate%20Population-Based%20Cohort%20Study%20in%20Eastern%20Spain&as_occt=title&as_sauthors=%20%22F%20Llopis-Cardona%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Dyer SM</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Crotty M</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Fairhall N</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Magaziner J</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Beaupre LA</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Cameron ID</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Sherrington C</span>; Fragility
                Fracture Network (FFN) Rehabilitation Research Special Interest Group. <span data-id="strong_20"
                  class="annotation strong" style="position: unset;">A critical review of the long-term disability
                  outcomes following hip fracture</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">BMC Geriatr.</span> 2016 Sep 2;16(1):158.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20critical%20review%20of%20the%20long-term%20disability%20outcomes%20following%20hip%20fracture&as_occt=title&as_sauthors=%20%22SM%20Dyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Liu C</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Zhou S</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Bai X</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Ageing in China: Trends,
                  Challenges and Opportunities</span>. In: <span data-id="annotation_229" class="annotation"
                  style="position: unset;"> Selin H</span>, editor. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Aging Across Cultures. Science Across Cultures:
                  The History of Non-Western Science.</span> Springer; 2021. p. 137-52.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ageing%20in%20China%3A%20Trends%2C%20Challenges%20and%20Opportunities&as_occt=title&as_sauthors=%20%22C%20Liu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Cui L</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Jackson M</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Wessler Z</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Gitlin M</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Xia W</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Estimating the future clinical
                  and economic benefits of improving osteoporosis diagnosis and treatment among women in China: a
                  simulation projection model from 2020 to 2040</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Arch Osteoporos.</span> 2021 Aug
                2;16(1):118.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Estimating%20the%20future%20clinical%20and%20economic%20benefits%20of%20improving%20osteoporosis%20diagnosis%20and%20treatment%20among%20women%20in%20China%3A%20a%20simulation%20projection%20model%20from%202020%20to%202040&as_occt=title&as_sauthors=%20%22L%20Cui%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Kanis JA</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Cooper C</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Rizzoli R</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Reginster JY</span>; <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Scientific Advisory Board of
                  the European Society for Clinical and Economic Aspects of Osteoporosis (ESCEO) and the Committees of
                  Scientific Advisors and National Societies of the International Osteoporosis Foundation (IOF).
                  European guidance for the diagnosis and management of osteoporosis in postmenopausal women</span>.
                <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Osteoporos Int.</span>
                2019 Jan;30(1):3-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Scientific%20Advisory%20Board%20of%20the%20European%20Society%20for%20Clinical%20and%20Economic%20Aspects%20of%20Osteoporosis%20(ESCEO)%20and%20the%20Committees%20of%20Scientific%20Advisors%20and%20National%20Societies%20of%20the%20International%20Osteoporosis%20Foundation%20(IOF).%20European%20guidance%20for%20the%20diagnosis%20and%20management%20of%20osteoporosis%20in%20postmenopausal%20women&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_239" class="annotation" style="position: unset;"> McLellan AR</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Gallacher SJ</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Fraser M</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> McQuillian C</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">The fracture liaison service:
                  success of a program for the evaluation and management of patients with osteoporotic fracture</span>.
                <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Osteoporos Int.</span>
                2003 Dec;14(12):1028-34.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20fracture%20liaison%20service%3A%20success%20of%20a%20program%20for%20the%20evaluation%20and%20management%20of%20patients%20with%20osteoporotic%20fracture&as_occt=title&as_sauthors=%20%22AR%20McLellan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Papa LJ</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Weber BE</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Physician characteristics
                  associated with the use of bone densitometry</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Gen Intern Med.</span> 1997
                Dec;12(12):781-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physician%20characteristics%20associated%20with%20the%20use%20of%20bone%20densitometry&as_occt=title&as_sauthors=%20%22LJ%20Papa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Jaglal S</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Hawker G</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Bogoch E</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Cadarette S</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Carroll J</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Davis D</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Jaakkimainen L</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Kreder H</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> McIsaac W</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Development of a model of
                  integrated post-fracture care</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">J Bone Miner Res.</span> 2004;19(Suppl 1):S163.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20of%20a%20model%20of%20integrated%20post-fracture%20care&as_occt=title&as_sauthors=%20%22S%20Jaglal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_254" class="annotation" style="position: unset;"> LeBoff MS</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Greenspan SL</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Insogna KL</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Lewiecki EM</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Saag KG</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Singer AJ</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Siris ES</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The clinician’s guide to
                  prevention and treatment of osteoporosis</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2022
                Oct;33(10):2049-102.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20clinician%E2%80%99s%20guide%20to%20prevention%20and%20treatment%20of%20osteoporosis&as_occt=title&as_sauthors=%20%22MS%20LeBoff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Camacho PM</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Petak SM</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Binkley N</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Diab DL</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Eldeiry LS</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Farooki A</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Harris ST</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Hurley DL</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Kelly J</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Lewiecki EM</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Pessah-Pollack R</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> McClung M</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Wimalawansa SJ</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Watts NB</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">American Association of
                  Clinical Endocrinologists/American College of Endocrinology Clinical Practice Guidelines for the
                  Diagnosis and Treatment of Postmenopausal Osteoporosis-2020 Update</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Endocr Pract.</span> 2020 May;26(Suppl
                1):1-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=American%20Association%20of%20Clinical%20Endocrinologists%2FAmerican%20College%20of%20Endocrinology%20Clinical%20Practice%20Guidelines%20for%20the%20Diagnosis%20and%20Treatment%20of%20Postmenopausal%20Osteoporosis-2020%20Update&as_occt=title&as_sauthors=%20%22PM%20Camacho%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Crandall CJ</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Chen LY</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Rodriguez TD</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Elashoff D</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Faubion SS</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Kling JM</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Shifren J</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Skinner L</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Bauer DC</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Knowledge and Needs of Resident
                  Physicians Regarding Osteoporosis: A Nationwide Survey of Residents</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">JBMR Plus.</span> 2021 Jul
                1;5(8):e10524.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Knowledge%20and%20Needs%20of%20Resident%20Physicians%20Regarding%20Osteoporosis%3A%20A%20Nationwide%20Survey%20of%20Residents&as_occt=title&as_sauthors=%20%22CJ%20Crandall%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Harvey NC</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> McCloskey EV</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Mitchell PJ</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Dawson-Hughes B</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Pierroz DD</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Reginster JY</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Rizzoli R</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Cooper C</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Kanis JA</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Mind the (treatment) gap: a
                  global perspective on current and future strategies for prevention of fragility fractures</span>.
                <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Osteoporos Int.</span>
                2017 May;28(5):1507-29.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mind%20the%20(treatment)%20gap%3A%20a%20global%20perspective%20on%20current%20and%20future%20strategies%20for%20prevention%20of%20fragility%20fractures&as_occt=title&as_sauthors=%20%22NC%20Harvey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Cheung EYN</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Tan KCB</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Cheung CL</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Kung AWC</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Osteoporosis in East Asia:
                  Current issues in assessment and management</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Osteoporos Sarcopenia.</span> 2016
                Sep;2(3):118-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoporosis%20in%20East%20Asia%3A%20Current%20issues%20in%20assessment%20and%20management&as_occt=title&as_sauthors=%20%22EYN%20Cheung%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Robinson WA</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Carlson BC</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Poppendeck H</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Wanderman NR</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Bunta AD</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Murphy S</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Sietsema DL</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Daffner SD</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Edwards BJ</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Watts NB</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Tosi LL</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Anderson PA</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Freedman BA</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Osteoporosis-related Vertebral
                  Fragility Fractures: A Review and Analysis of the American Orthopaedic Association’s Own the Bone
                  Database</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2020 Apr 15;45(8):E430-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoporosis-related%20Vertebral%20Fragility%20Fractures%3A%20A%20Review%20and%20Analysis%20of%20the%20American%20Orthopaedic%20Association%E2%80%99s%20Own%20the%20Bone%20Database&as_occt=title&as_sauthors=%20%22WA%20Robinson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Kung AW</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Fan T</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Xu L</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Xia WB</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Park IH</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Kim HS</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Chan SP</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Lee JK</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Koh L</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Soong YK</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Soontrapa S</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Songpatanasilp T</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Turajane T</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Yates M</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Sen S</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Factors influencing diagnosis
                  and treatment of osteoporosis after a fragility fracture among postmenopausal women in Asian
                  countries: a retrospective study</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">BMC Womens Health.</span> 2013 Feb 14;13:7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20influencing%20diagnosis%20and%20treatment%20of%20osteoporosis%20after%20a%20fragility%20fracture%20among%20postmenopausal%20women%20in%20Asian%20countries%3A%20a%20retrospective%20study&as_occt=title&as_sauthors=%20%22AW%20Kung%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Wang O</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Hu Y</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Gong S</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Xue Q</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Deng Z</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Liu H</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Tang H</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Guo X</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Chen J</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Jia X</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Xu Y</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Lan L</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Lei C</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Dong H</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Yuan G</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Fu Q</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Wei Y</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Xia W</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Xu L</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">A survey of outcomes and
                  management of patients post fragility fractures in China</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2015
                Nov;26(11):2631-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20survey%20of%20outcomes%20and%20management%20of%20patients%20post%20fragility%20fractures%20in%20China&as_occt=title&as_sauthors=%20%22O%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Wang X</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Li C</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> He Y</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Wang T</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Zhang H</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Ma Z</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Ma H</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Zhao H</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Anti-osteoporosis medication
                  treatment pattern after osteoporotic fracture during 2010-2016 in Fujian, China</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Arch Osteoporos.</span>
                2020 Aug 20;15(1):134.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anti-osteoporosis%20medication%20treatment%20pattern%20after%20osteoporotic%20fracture%20during%202010-2016%20in%20Fujian%2C%20China&as_occt=title&as_sauthors=%20%22X%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Lu J</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Ren Z</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Liu X</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Xu YJ</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Liu Q</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Osteoporotic Fracture
                  Guidelines and Medical Education Related to the Clinical Practices: A Nationwide Survey in
                  China</span>. <span data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Orthop
                  Surg.</span> 2019 Aug;11(4):569-77.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoporotic%20Fracture%20Guidelines%20and%20Medical%20Education%20Related%20to%20the%20Clinical%20Practices%3A%20A%20Nationwide%20Survey%20in%20China&as_occt=title&as_sauthors=%20%22J%20Lu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Man Y</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Pan W</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Lu J</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Li SY</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Zhang P</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Guo JZ</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Yu SF</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Zhang B</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Xiao E</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Zhang C</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Zuo W</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Jin HJ</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Chen Y</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Wu B</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Shi XE</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Hong D</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Liu X</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Gao YX</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Yin L</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Xue QY</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Treatment and Management of
                  Osteoporotic Fractures: A Nation-wide Survey of 484 Senior Orthopaedists in China</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Orthop Surg.</span> 2016
                Nov;8(4):432-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20and%20Management%20of%20Osteoporotic%20Fractures%3A%20A%20Nation-wide%20Survey%20of%20484%20Senior%20Orthopaedists%20in%20China&as_occt=title&as_sauthors=%20%22Y%20Man%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Mo J</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Huang K</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Wang X</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Sheng X</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Wang Q</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Fang X</span>, <span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Fan S</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">The Sensitivity of Orthopaedic
                  Surgeons to the Secondary Prevention of Fragility Fractures</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2018 Dec
                19;100(24):e153.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1891594"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Fogelman Y</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Goldshtein I</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Segal E</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Ish-Shalom S</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Managing Osteoporosis: A Survey
                  of Knowledge, Attitudes and Practices among Primary Care Physicians in Israel</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">PLoS One.</span> 2016 Aug
                5;11(8):e0160661.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Managing%20Osteoporosis%3A%20A%20Survey%20of%20Knowledge%2C%20Attitudes%20and%20Practices%20among%20Primary%20Care%20Physicians%20in%20Israel&as_occt=title&as_sauthors=%20%22Y%20Fogelman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Nguyen VH</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Osteoporosis knowledge
                  assessment and osteoporosis education recommendations in the health professions</span>. <span
                  data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Osteoporos
                  Sarcopenia.</span> 2016 Jun;2(2):82-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoporosis%20knowledge%20assessment%20and%20osteoporosis%20education%20recommendations%20in%20the%20health%20professions&as_occt=title&as_sauthors=%20%22VH%20Nguyen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;">Chinese
                Society of Osteoporosis and Bone Mineral Research. <span data-id="strong_41" class="annotation strong"
                  style="position: unset;">The Guideline of Diagnosis and Treatment of Primary Osteoporosis (2022)
                  [Chinese]</span>. <span data-id="emphasis_39" class="annotation emphasis"
                  style="position: unset;">Chinese Journal of Osteoporosis and Mineral Research.</span>
                2022;15(06):573-611.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Guideline%20of%20Diagnosis%20and%20Treatment%20of%20Primary%20Osteoporosis%20(2022)%20%5BChinese%5D&as_occt=title&as_sauthors=%20%22Chinese%20Society%20of%20Osteoporosis%20and%20Bone%20Mineral%20Research%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;">Chinese
                Society of Osteoporosis and Bone Mineral Research. <span data-id="strong_42" class="annotation strong"
                  style="position: unset;">The Guideline of Diagnosis and Treatment of Primary Osteoporosis (2017)
                  [Chinese]</span>. <span data-id="emphasis_40" class="annotation emphasis"
                  style="position: unset;">Chinese Journal of Osteoporosis and Mineral Research.</span>
                2017;10(05):413-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Guideline%20of%20Diagnosis%20and%20Treatment%20of%20Primary%20Osteoporosis%20(2017)%20%5BChinese%5D&as_occt=title&as_sauthors=%20%22Chinese%20Society%20of%20Osteoporosis%20and%20Bone%20Mineral%20Research%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Gendler PE</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Coviak CP</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Martin JT</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Kim KK</span>, <span
                  data-id="annotation_395" class="annotation" style="position: unset;"> Dankers JK</span>, <span
                  data-id="annotation_396" class="annotation" style="position: unset;"> Barclay JM</span>, <span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Sanchez TA</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Revision of the Osteoporosis
                  Knowledge Test: Reliability and Validity</span>. <span data-id="emphasis_41"
                  class="annotation emphasis" style="position: unset;">West J Nurs Res.</span> 2015
                Dec;37(12):1623-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revision%20of%20the%20Osteoporosis%20Knowledge%20Test%3A%20Reliability%20and%20Validity&as_occt=title&as_sauthors=%20%22PE%20Gendler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_398" class="annotation" style="position: unset;"> Ailinger RL</span>, <span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Lasus H</span>, <span
                  data-id="annotation_400" class="annotation" style="position: unset;"> Braun MA</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Revision of the Facts on
                  Osteoporosis Quiz</span>. <span data-id="emphasis_42" class="annotation emphasis"
                  style="position: unset;">Nurs Res.</span> 2003 May-Jun;52(3):198-201.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revision%20of%20the%20Facts%20on%20Osteoporosis%20Quiz&as_occt=title&as_sauthors=%20%22RL%20Ailinger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_401" class="annotation" style="position: unset;"> Hallit S</span>, <span
                  data-id="annotation_402" class="annotation" style="position: unset;"> El Hage C</span>, <span
                  data-id="annotation_403" class="annotation" style="position: unset;"> Hajj A</span>, <span
                  data-id="annotation_404" class="annotation" style="position: unset;"> Salameh P</span>, <span
                  data-id="annotation_405" class="annotation" style="position: unset;"> Sacre H</span>, <span
                  data-id="annotation_406" class="annotation" style="position: unset;"> Rahme C</span>, <span
                  data-id="annotation_407" class="annotation" style="position: unset;"> Akel M</span>, <span
                  data-id="annotation_408" class="annotation" style="position: unset;"> Dagher E</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Construction and validation of
                  the Lebanese Osteoporosis Knowledge Scale among a representative sample of Lebanese women</span>.
                <span data-id="emphasis_43" class="annotation emphasis" style="position: unset;">Osteoporos Int.</span>
                2020 Feb;31(2):379-89.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Construction%20and%20validation%20of%20the%20Lebanese%20Osteoporosis%20Knowledge%20Scale%20among%20a%20representative%20sample%20of%20Lebanese%20women&as_occt=title&as_sauthors=%20%22S%20Hallit%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_409" class="annotation" style="position: unset;"> Pande KC</span>, <span
                  data-id="annotation_410" class="annotation" style="position: unset;"> de Takats D</span>, <span
                  data-id="annotation_411" class="annotation" style="position: unset;"> Kanis JA</span>, <span
                  data-id="annotation_412" class="annotation" style="position: unset;"> Edwards V</span>, <span
                  data-id="annotation_413" class="annotation" style="position: unset;"> Slade P</span>, <span
                  data-id="annotation_414" class="annotation" style="position: unset;"> McCloskey EV</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Development of a questionnaire
                  (OPQ) to assess patient’s knowledge about osteoporosis</span>. <span data-id="emphasis_44"
                  class="annotation emphasis" style="position: unset;">Maturitas.</span> 2000 Dec
                29;37(2):75-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20of%20a%20questionnaire%20(OPQ)%20to%20assess%20patient%E2%80%99s%20knowledge%20about%20osteoporosis&as_occt=title&as_sauthors=%20%22KC%20Pande%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_415" class="annotation" style="position: unset;"> Cadarette SM</span>, <span
                  data-id="annotation_416" class="annotation" style="position: unset;"> Gignac MA</span>, <span
                  data-id="annotation_417" class="annotation" style="position: unset;"> Beaton DE</span>, <span
                  data-id="annotation_418" class="annotation" style="position: unset;"> Jaglal SB</span>, <span
                  data-id="annotation_419" class="annotation" style="position: unset;"> Hawker GA</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Psychometric properties of the
                  “Osteoporosis and You” questionnaire: osteoporosis knowledge deficits among older community-dwelling
                  women</span>. <span data-id="emphasis_45" class="annotation emphasis"
                  style="position: unset;">Osteoporos Int.</span> 2007 Jul;18(7):981-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Psychometric%20properties%20of%20the%20%E2%80%9COsteoporosis%20and%20You%E2%80%9D%20questionnaire%3A%20osteoporosis%20knowledge%20deficits%20among%20older%20community-dwelling%20women&as_occt=title&as_sauthors=%20%22SM%20Cadarette%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_420" class="annotation" style="position: unset;"> Viswanathan M</span>, <span
                  data-id="annotation_421" class="annotation" style="position: unset;"> Reddy S</span>, <span
                  data-id="annotation_422" class="annotation" style="position: unset;"> Berkman N</span>, <span
                  data-id="annotation_423" class="annotation" style="position: unset;"> Cullen K</span>, <span
                  data-id="annotation_424" class="annotation" style="position: unset;"> Middleton JC</span>, <span
                  data-id="annotation_425" class="annotation" style="position: unset;"> Nicholson WK</span>, <span
                  data-id="annotation_426" class="annotation" style="position: unset;"> Kahwati LC</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Screening to Prevent
                  Osteoporotic Fractures: Updated Evidence Report and Systematic Review for the US Preventive Services
                  Task Force</span>. <span data-id="emphasis_46" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2018 Jun 26;319(24):2532-51.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Screening%20to%20Prevent%20Osteoporotic%20Fractures%3A%20Updated%20Evidence%20Report%20and%20Systematic%20Review%20for%20the%20US%20Preventive%20Services%20Task%20Force&as_occt=title&as_sauthors=%20%22M%20Viswanathan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_427" class="annotation" style="position: unset;"> Gregson CL</span>, <span
                  data-id="annotation_428" class="annotation" style="position: unset;"> Armstrong DJ</span>, <span
                  data-id="annotation_429" class="annotation" style="position: unset;"> Bowden J</span>, <span
                  data-id="annotation_430" class="annotation" style="position: unset;"> Cooper C</span>, <span
                  data-id="annotation_431" class="annotation" style="position: unset;"> Edwards J</span>, <span
                  data-id="annotation_432" class="annotation" style="position: unset;"> Gittoes NJL</span>, <span
                  data-id="annotation_433" class="annotation" style="position: unset;"> Harvey N</span>, <span
                  data-id="annotation_434" class="annotation" style="position: unset;"> Kanis J</span>, <span
                  data-id="annotation_435" class="annotation" style="position: unset;"> Leyland S</span>, <span
                  data-id="annotation_436" class="annotation" style="position: unset;"> Low R</span>, <span
                  data-id="annotation_437" class="annotation" style="position: unset;"> McCloskey E</span>, <span
                  data-id="annotation_438" class="annotation" style="position: unset;"> Moss K</span>, <span
                  data-id="annotation_439" class="annotation" style="position: unset;"> Parker J</span>, <span
                  data-id="annotation_440" class="annotation" style="position: unset;"> Paskins Z</span>, <span
                  data-id="annotation_441" class="annotation" style="position: unset;"> Poole K</span>, <span
                  data-id="annotation_442" class="annotation" style="position: unset;"> Reid DM</span>, <span
                  data-id="annotation_443" class="annotation" style="position: unset;"> Stone M</span>, <span
                  data-id="annotation_444" class="annotation" style="position: unset;"> Thomson J</span>, <span
                  data-id="annotation_445" class="annotation" style="position: unset;"> Vine N</span>, <span
                  data-id="annotation_446" class="annotation" style="position: unset;"> Compston J</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">UK clinical guideline for the
                  prevention and treatment of osteoporosis</span>. <span data-id="emphasis_47"
                  class="annotation emphasis" style="position: unset;">Arch Osteoporos.</span> 2022 Apr
                5;17(1):58.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=UK%20clinical%20guideline%20for%20the%20prevention%20and%20treatment%20of%20osteoporosis&as_occt=title&as_sauthors=%20%22CL%20Gregson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_447" class="annotation" style="position: unset;"> Zhang YP</span>, <span
                  data-id="annotation_448" class="annotation" style="position: unset;"> Li XM</span>, <span
                  data-id="annotation_449" class="annotation" style="position: unset;"> Wang DL</span>, <span
                  data-id="annotation_450" class="annotation" style="position: unset;"> Guo XY</span>, <span
                  data-id="annotation_451" class="annotation" style="position: unset;"> Guo X</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Evaluation of educational
                  program on osteoporosis awareness and prevention among nurse students in China</span>. <span
                  data-id="emphasis_48" class="annotation emphasis" style="position: unset;">Nurs Health Sci.</span>
                2012 Mar;14(1):74-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20educational%20program%20on%20osteoporosis%20awareness%20and%20prevention%20among%20nurse%20students%20in%20China&as_occt=title&as_sauthors=%20%22YP%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_452" class="annotation" style="position: unset;"> Kim KK</span>, <span
                  data-id="annotation_453" class="annotation" style="position: unset;"> Horan ML</span>, <span
                  data-id="annotation_454" class="annotation" style="position: unset;"> Gendler P</span>, <span
                  data-id="annotation_455" class="annotation" style="position: unset;"> Patel MK</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Development and evaluation of
                  the Osteoporosis Health Belief Scale</span>. <span data-id="emphasis_49" class="annotation emphasis"
                  style="position: unset;">Res Nurs Health.</span> 1991 Apr;14(2):155-63.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20and%20evaluation%20of%20the%20Osteoporosis%20Health%20Belief%20Scale&as_occt=title&as_sauthors=%20%22KK%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_456" class="annotation" style="position: unset;"> Zhang YP</span>, <span
                  data-id="annotation_457" class="annotation" style="position: unset;"> Wei HH</span>, <span
                  data-id="annotation_458" class="annotation" style="position: unset;"> Wang W</span>, <span
                  data-id="annotation_459" class="annotation" style="position: unset;"> Xia RY</span>, <span
                  data-id="annotation_460" class="annotation" style="position: unset;"> Zhou XL</span>, <span
                  data-id="annotation_461" class="annotation" style="position: unset;"> Porr C</span>, <span
                  data-id="annotation_462" class="annotation" style="position: unset;"> Lammi M</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Cross-cultural adaptation and
                  validation of the osteoporosis assessment questionnaire short version (OPAQ-SV) for Chinese
                  osteoporotic fracture females</span>. <span data-id="emphasis_50" class="annotation emphasis"
                  style="position: unset;">Clin Rheumatol.</span> 2016 Apr;35(4):1003-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cross-cultural%20adaptation%20and%20validation%20of%20the%20osteoporosis%20assessment%20questionnaire%20short%20version%20(OPAQ-SV)%20for%20Chinese%20osteoporotic%20fracture%20females&as_occt=title&as_sauthors=%20%22YP%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_463" class="annotation" style="position: unset;"> Perneger TV</span>, <span
                  data-id="annotation_464" class="annotation" style="position: unset;"> Courvoisier DS</span>, <span
                  data-id="annotation_465" class="annotation" style="position: unset;"> Hudelson PM</span>, <span
                  data-id="annotation_466" class="annotation" style="position: unset;"> Gayet-Ageron A</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Sample size for pre-tests of
                  questionnaires</span>. <span data-id="emphasis_51" class="annotation emphasis"
                  style="position: unset;">Qual Life Res.</span> 2015 Jan;24(1):147-51.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sample%20size%20for%20pre-tests%20of%20questionnaires&as_occt=title&as_sauthors=%20%22TV%20Perneger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_467" class="annotation" style="position: unset;"> Crocker L</span>, <span
                  data-id="annotation_468" class="annotation" style="position: unset;"> Algina J</span>. <span
                  data-id="emphasis_52" class="annotation emphasis" style="position: unset;">Introduction to classical
                  and modern test theory.</span> Holt, Rinehart, and Winston; 1986.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_469" class="annotation" style="position: unset;"> Deng S</span>, <span
                  data-id="annotation_470" class="annotation" style="position: unset;"> Mao X</span>, <span
                  data-id="annotation_471" class="annotation" style="position: unset;"> Meng X</span>, <span
                  data-id="annotation_472" class="annotation" style="position: unset;"> Yu L</span>, <span
                  data-id="annotation_473" class="annotation" style="position: unset;"> Xie F</span>, <span
                  data-id="annotation_474" class="annotation" style="position: unset;"> Huang G</span>, <span
                  data-id="annotation_475" class="annotation" style="position: unset;"> Duan Z</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">A comparison of Knowledge,
                  attitude and practice (KAP) of nurses on nursing Post-stroke dysphagia patients between iii-A and ii-A
                  hospitals in China: a propensity score-matched analysis</span>. <span data-id="emphasis_53"
                  class="annotation emphasis" style="position: unset;">BMC Nurs.</span> 2022 Jun
                29;21(1):171.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comparison%20of%20Knowledge%2C%20attitude%20and%20practice%20(KAP)%20of%20nurses%20on%20nursing%20Post-stroke%20dysphagia%20patients%20between%20iii-A%20and%20ii-A%20hospitals%20in%20China%3A%20a%20propensity%20score-matched%20analysis&as_occt=title&as_sauthors=%20%22S%20Deng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_476" class="annotation" style="position: unset;"> Ying Ge T</span>, <span
                  data-id="annotation_477" class="annotation" style="position: unset;"> Konstantatos AH</span>, <span
                  data-id="annotation_478" class="annotation" style="position: unset;"> Cai Fang Z</span>, <span
                  data-id="annotation_479" class="annotation" style="position: unset;"> Ying HJ</span>, <span
                  data-id="annotation_480" class="annotation" style="position: unset;"> Ai Fen Y</span>, <span
                  data-id="annotation_481" class="annotation" style="position: unset;"> Boyd D</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">A cross-sectional exploratory
                  survey of knowledge, attitudes and daily self-reported pain assessment practice among nurses in
                  Mainland China</span>. <span data-id="emphasis_54" class="annotation emphasis"
                  style="position: unset;">Pain Med.</span> 2013 Oct;14(10):1468-76.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20cross-sectional%20exploratory%20survey%20of%20knowledge%2C%20attitudes%20and%20daily%20self-reported%20pain%20assessment%20practice%20among%20nurses%20in%20Mainland%20China&as_occt=title&as_sauthors=%20%22T%20Ying%20Ge%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_482" class="annotation" style="position: unset;"> Khosla S</span>, <span
                  data-id="annotation_483" class="annotation" style="position: unset;"> Shane E</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">A Crisis in the Treatment of
                  Osteoporosis</span>. <span data-id="emphasis_55" class="annotation emphasis"
                  style="position: unset;">J Bone Miner Res.</span> 2016 Aug;31(8):1485-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Crisis%20in%20the%20Treatment%20of%20Osteoporosis&as_occt=title&as_sauthors=%20%22S%20Khosla%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_484" class="annotation" style="position: unset;"> Chaves CDS</span>, <span
                  data-id="annotation_485" class="annotation" style="position: unset;"> Camargo JT</span>, <span
                  data-id="annotation_486" class="annotation" style="position: unset;"> Zandonadi RP</span>, <span
                  data-id="annotation_487" class="annotation" style="position: unset;"> Nakano EY</span>, <span
                  data-id="annotation_488" class="annotation" style="position: unset;"> Ginani VC</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Nutrition Literacy Level in
                  Bank Employees: The Case of a Large Brazilian Company</span>. <span data-id="emphasis_56"
                  class="annotation emphasis" style="position: unset;">Nutrients.</span> 2023 May
                18;15(10):2360.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nutrition%20Literacy%20Level%20in%20Bank%20Employees%3A%20The%20Case%20of%20a%20Large%20Brazilian%20Company&as_occt=title&as_sauthors=%20%22CDS%20Chaves%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_489" class="annotation" style="position: unset;"> El-Uri FI</span>, <span
                  data-id="annotation_490" class="annotation" style="position: unset;"> Malas N</span>. <span
                  data-id="strong_58" class="annotation strong" style="position: unset;">Analysis of use of a single
                  best answer format in an undergraduate medical examination</span>. <span data-id="emphasis_57"
                  class="annotation emphasis" style="position: unset;">Qatar Med J.</span> 2013 Nov
                1;2013(1):3-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Analysis%20of%20use%20of%20a%20single%20best%20answer%20format%20in%20an%20undergraduate%20medical%20examination&as_occt=title&as_sauthors=%20%22FI%20El-Uri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_491" class="annotation" style="position: unset;"> Han TI</span>. <span
                  data-id="strong_59" class="annotation strong" style="position: unset;">Objective knowledge, subjective
                  knowledge, and prior experience of organic cotton apparel</span>. <span data-id="emphasis_58"
                  class="annotation emphasis" style="position: unset;">International Journal of Interdisciplinary
                  Research.</span> 2019;6(1):4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Objective%20knowledge%2C%20subjective%20knowledge%2C%20and%20prior%20experience%20of%20organic%20cotton%20apparel&as_occt=title&as_sauthors=%20%22TI%20Han%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_492" class="annotation" style="position: unset;"> House L</span>, <span
                  data-id="annotation_493" class="annotation" style="position: unset;"> Lusk J</span>, <span
                  data-id="annotation_494" class="annotation" style="position: unset;"> Jaeger S</span>, <span
                  data-id="annotation_495" class="annotation" style="position: unset;"> Traill WB</span>, <span
                  data-id="annotation_496" class="annotation" style="position: unset;"> Moore M</span>, <span
                  data-id="annotation_497" class="annotation" style="position: unset;"> Valli C</span>, <span
                  data-id="annotation_498" class="annotation" style="position: unset;"> Morrow B</span>, <span
                  data-id="annotation_499" class="annotation" style="position: unset;"> Yee WMS</span>. <span
                  data-id="strong_60" class="annotation strong" style="position: unset;">Objective and subjective
                  knowledge: impacts on consumer demand for genetically modified foods in the United States and the
                  European Union</span>. <span data-id="emphasis_59" class="annotation emphasis"
                  style="position: unset;">AgBioForum.</span> 2004;7(3):113-23.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Objective%20and%20subjective%20knowledge%3A%20impacts%20on%20consumer%20demand%20for%20genetically%20modified%20foods%20in%20the%20United%20States%20and%20the%20European%20Union&as_occt=title&as_sauthors=%20%22L%20House%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_500" class="annotation" style="position: unset;"> Lewiecki EM</span>. <span
                  data-id="strong_61" class="annotation strong" style="position: unset;">Risk communication and shared
                  decision making in the care of patients with osteoporosis</span>. <span data-id="emphasis_60"
                  class="annotation emphasis" style="position: unset;">J Clin Densitom.</span> 2010
                Oct-Dec;13(4):335-45.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20communication%20and%20shared%20decision%20making%20in%20the%20care%20of%20patients%20with%20osteoporosis&as_occt=title&as_sauthors=%20%22EM%20Lewiecki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;10bba976-69bc-4740-b828-7a1f40f3af22&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=10bba976-69bc-4740-b828-7a1f40f3af22&type=supplement&name=12556"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D10bba976-69bc-4740-b828-7a1f40f3af22%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 330</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01136</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 7, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_61"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Spine Surgery, The Third Affiliated Hospital of Sun Yat-sen University,
                            Guangzhou, People’s Republic of China</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_7" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;10bba976-69bc-4740-b828-7a1f40f3af22&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=10bba976-69bc-4740-b828-7a1f40f3af22&type=pdf&name=JBJS.23.01136.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=10bba976-69bc-4740-b828-7a1f40f3af22&type=pdf&name=JBJS.23.01136.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_66"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was funded
                          by the National Natural Science Foundation of China (72004241), Guangzhou Basic and Applied
                          Basic Research Foundation (2023A04J1745), and Philosophy and Social Science Planning Project
                          of Guangzhou (2020GZQN56). The funding sources had no role in the design and conduct of the
                          study; collection, management, analysis, and interpretation of the data; preparation, review,
                          or approval of the manuscript; and decision to submit the manuscript for publication. The
                          <span data-id="strong_67" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I273" target="_blank" data-id="link_3"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I273</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;10bba976-69bc-4740-b828-7a1f40f3af22&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=10bba976-69bc-4740-b828-7a1f40f3af22&type=zip&name=JBJS.23.01136.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jian Mo, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span><span data-id="affiliation_reference_2"
                  class="label annotation cross_reference">2</span><span data-id="affiliation_reference_3"
                  class="label annotation cross_reference">3</span><span data-id="footnote_reference_1"
                  class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2084-8450" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2084-8450</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ying Mo, BM<span data-id="affiliation_reference_4"
                  class="label annotation cross_reference">4</span><span data-id="affiliation_reference_5"
                  class="label annotation cross_reference">5</span><span data-id="footnote_reference_1"
                  class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-9413-1492" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-9413-1492</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jiale He, MS<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span><span data-id="affiliation_reference_2"
                  class="label annotation cross_reference">2</span><span data-id="affiliation_reference_3"
                  class="label annotation cross_reference">3</span><span data-id="affiliation_reference_6"
                  class="label annotation cross_reference">6</span><span data-id="footnote_reference_1"
                  class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span><span class=""
                  style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4181-4890" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4181-4890</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Bu Yang, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span><span data-id="affiliation_reference_2"
                  class="label annotation cross_reference">2</span><span data-id="affiliation_reference_3"
                  class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Xieyuan Jiang, MD<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lei He, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span><span data-id="affiliation_reference_2"
                  class="label annotation cross_reference">2</span><span data-id="affiliation_reference_3"
                  class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shuai Lu, MD<span data-id="affiliation_reference_7"
                  class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Wenbin Wu, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1303-1426" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1303-1426</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mao Pang, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span><span data-id="affiliation_reference_2"
                  class="label annotation cross_reference">2</span><span data-id="affiliation_reference_3"
                  class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Feng Feng, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1640-6756" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1640-6756</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Peigen Xie, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span><span class=""
                  style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:ronglm@mail.sysu.edu.cn" class=""
                    style="position: unset;">ronglm@mail.sysu.edu.cn</a><span class=""
                    style="position: unset;">;</span><a href="mailto:xiepgen@mail.sysu.edu.cn" class=""
                    style="position: unset;">xiepgen@mail.sysu.edu.cn</a><span class=""
                    style="position: unset;">;</span><a href="mailto:shunwu_fan@zju.edu.cn" class=""
                    style="position: unset;">shunwu_fan@zju.edu.cn</a></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shunwu Fan, MD<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:ronglm@mail.sysu.edu.cn" class=""
                    style="position: unset;">ronglm@mail.sysu.edu.cn</a><span class=""
                    style="position: unset;">;</span><a href="mailto:xiepgen@mail.sysu.edu.cn" class=""
                    style="position: unset;">xiepgen@mail.sysu.edu.cn</a><span class=""
                    style="position: unset;">;</span><a href="mailto:shunwu_fan@zju.edu.cn" class=""
                    style="position: unset;">shunwu_fan@zju.edu.cn</a></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Limin Rong, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:ronglm@mail.sysu.edu.cn" class=""
                    style="position: unset;">ronglm@mail.sysu.edu.cn</a><span class=""
                    style="position: unset;">;</span><a href="mailto:xiepgen@mail.sysu.edu.cn" class=""
                    style="position: unset;">xiepgen@mail.sysu.edu.cn</a><span class=""
                    style="position: unset;">;</span><a href="mailto:shunwu_fan@zju.edu.cn" class=""
                    style="position: unset;">shunwu_fan@zju.edu.cn</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0373-7393" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0373-7393</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Spine Surgery, The Third Affiliated Hospital of Sun Yat-sen
                University, Guangzhou, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Guangdong Provincial Center for Engineering and Technology Research of
                Minimally Invasive Spine Surgery, Guangzhou, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Guangdong Provincial Center for Quality Control of Minimally Invasive Spine
                Surgery, Guangzhou, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Public Health, Changzhou Wujin Fifth People’s Hospital,
                Changzhou, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">School of Public Health, Hangzhou Medical College, Hangzhou, People’s Republic
                of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Joint and Trauma Surgery, The Third Affiliated Hospital of Sun
                Yat-sen University, Guangzhou, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Department of Orthopedic Trauma, Beijing Jishuitan Hospital, Capital Medical
                University, Beijing, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, The Third Affiliated Hospital of Sun Yat-sen
                University, Zhaoqing Hospital, Zhaoqing, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">9</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Sir Run Run Shaw Hospital, Zhejiang
                University School of Medicine, Hangzhou, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Jian Mo, MD, Ying Mo, BM, and Jiale He, MS,
                        contributed equally to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 38367.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
