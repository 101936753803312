/* ----------------- React Native Paper Imports --------------- */
import { Appbar, Searchbar } from "react-native-paper";

import { useAppTheme } from "@hooks/useAppTheme";

/* ----------------- UI Component Imports --------------- */
import { Box, Loader } from "@memorang/ui";

/* ----------------- Custom Component Imports --------------- */
import useLibrary from "../hooks/useLibrary";
import ChallengesListContainer from "./ChallengesListContainer";

const LibraryRouteContainer = () => {
	const { searchQuery, onChangeSearch, isLoading } = useLibrary();

	// const showArticles = homeView?.showArticles;

	const theme = useAppTheme();

	// const isChallengesTab = selectedIndex === 0;

	const placeholder = "Search challenges...";

	return (
		<>
			<Appbar.Header
				mode="small"
				style={{
					backgroundColor: theme.colors.elevation.level3,
				}}
			>
				<Appbar.Content
					title={
						<Box paddingBottom={16}>
							<Searchbar
								placeholder={placeholder}
								value={searchQuery}
								onChangeText={onChangeSearch}
								style={{
									backgroundColor: theme.colors.surface,
									marginRight: 8,
								}}
							/>
						</Box>
					}
				/>
			</Appbar.Header>
			{isLoading ? (
				<Box flex={1} justifyContent="center" alignItems="center">
					<Loader />
				</Box>
			) : (
				<>
					{/* {showArticles ? (
						<CustomTabs tabs={tabs} onChangeIndex={onChangeIndex} />
					) : (
						<ChallengesListContainer />
					)} */}
					<ChallengesListContainer />
				</>
			)}
		</>
	);
};

export default LibraryRouteContainer;
