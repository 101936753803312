import { Image } from "@memorang/ui";
/* ----------------- External Imports --------------- */
import { List } from "react-native-paper";

/* ----------------- Internal Imports --------------- */
import Box from "../Box";

interface Props {
	item: {
		title: string;
		description?: string;
		imageUri: string;
		id: number;
	}[];
	handleClickCard?: (id: number, type: string) => void;
}
const ItemsList = ({ item, handleClickCard }: Props) => {
	return (
		<Box>
			{item.map(({ title, description, imageUri, id }) => {
				return (
					<List.Item
						title={title}
						key={title}
						onPress={() => handleClickCard?.(id, "article")}
						titleNumberOfLines={2}
						description={description}
						style={{
							paddingLeft: 16,
						}}
						descriptionStyle={{
							paddingTop: 4,
						}}
						left={(p) => (
							<Image
								{...p}
								source={{ uri: imageUri }}
								style={{
									width: 92,
									height: 56,
								}}
							/>
						)}
					/>
				);
			})}
		</Box>
	);
};

export default ItemsList;
