/* -----------------Globals--------------- */
import { ScrollView } from "react-native";

/* -----------------Child components--------------- */
import { CustomChip } from "@memorang/ui";

type Props = {
	handleClickActionChip: (type: string) => void;
	showReview?: boolean;
	showStudy?: boolean;
};
export const SuggestedActionsChips = ({
	handleClickActionChip,
	showReview,
	showStudy,
}: Props) => {
	const actionsChips = [
		...(showStudy
			? [
					{
						label: "Study more",
						type: "study",
						icon: "book-open-page-variant",
					},
				]
			: []),
		...(showReview
			? [
					{
						label: "Review answers",
						type: "review",
						icon: "check-all",
					},
				]
			: []),
	];
	return (
		<ScrollView
			horizontal
			contentContainerStyle={{
				gap: 8,
				flex: 1,
			}}
		>
			{actionsChips.map(({ label, type, icon }) => {
				return (
					<CustomChip
						mode="outlined"
						key={type}
						icon={icon}
						onPress={() => handleClickActionChip(type)}
					>
						{label}
					</CustomChip>
				);
			})}
		</ScrollView>
	);
};
