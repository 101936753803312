/* ----------------- React Native Paper Imports --------------- */
import { useAppTheme } from "@hooks/useAppTheme";
import { Appbar } from "react-native-paper";

/* ----------------- UI Component Imports --------------- */
import { CustomBreadcrumb } from "@memorang/ui";

import DetailVideoView from "@features/videos/DetailVideoView";
import { useRouter } from "expo-router";

const LatestIssuesCollectionRouteContainer = () => {
	const router = useRouter();

	const theme = useAppTheme();

	const breadcrumbs = [
		{
			label: "Library",
			action: () => router.back(),
		},
		{
			label: "Videos",
		},
	];

	return (
		<>
			<Appbar.Header
				mode="medium"
				style={{
					backgroundColor: theme.colors.elevation.level3,
				}}
			>
				<Appbar.BackAction onPress={() => router.back()} />
				<Appbar.Content
					title={
						<CustomBreadcrumb
							textVariant="titleLarge"
							breadcrumbs={breadcrumbs}
						/>
					}
				/>
				<Appbar.Action
					onPress={() =>
						router.push({
							pathname: "/feedback",
							params: {
								view: "all-issues",
							},
						})
					}
					icon="comment-quote-outline"
				/>
			</Appbar.Header>
			<DetailVideoView />
		</>
	);
};

export default LatestIssuesCollectionRouteContainer;
