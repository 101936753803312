/* ----------------- Globals --------------- */
import { Box } from "@memorang/ui";

/* ----------------- Types --------------- */
import type { Challenges } from "types/image-challenge";

/* ----------------- Components --------------- */
import ChallengeFolderListItem from "./ChallengeFolderListItem";

type Props = {
	challenges: Challenges[];
	handleNavigateToChallengesFolder: (id: string) => void;
};
export const ChallengesFolderList = ({
	challenges,
	handleNavigateToChallengesFolder,
}: Props) => {
	return (
		<Box>
			{challenges.map((item) => {
				return (
					<ChallengeFolderListItem
						key={item.title}
						{...item}
						onPress={handleNavigateToChallengesFolder}
					/>
				);
			})}
		</Box>
	);
};
