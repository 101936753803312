import { create } from "zustand";
import type { SortOrder, SortType, SpecialityFilter } from "../types";

type FilterState = {
	isPremium: boolean;
	isCompleted: boolean;
};

type LibraryUIStore = {
	showFilterModal: boolean;
	showSortModal: boolean;
	sortType: SortType;
	sortOrder: SortOrder;
	selectedFilters: string[];
	filterState: FilterState;
	searchQuery: string;
	setShowFilterModal: (show: boolean) => void;
	setShowSortModal: (show: boolean) => void;
	handleSelectSortItem: (type: SortType) => void;
	toggleSortOrder: () => void;
	handleSortPress: (type: string) => void;
	setSearchQuery: (query: string) => void;
	setSelectedFilters: (filters: string[]) => void;
	toggleFilter: (filterType: keyof FilterState) => void;
	clearAllFilters: () => void;
	applySpecialityFilters: (filters: SpecialityFilter[]) => void;
};

const initialFilterState: FilterState = {
	isPremium: false,
	isCompleted: false,
};

export const useLibraryUIStore = create<LibraryUIStore>((set) => ({
	showFilterModal: false,
	showSortModal: false,
	sortType: "popular",
	sortOrder: "desc",
	selectedFilters: [],
	filterState: initialFilterState,
	searchQuery: "",
	setShowFilterModal: (show) => set({ showFilterModal: show }),
	setShowSortModal: (show) => set({ showSortModal: show }),
	handleSelectSortItem: (type) => set({ sortType: type }),
	toggleSortOrder: () =>
		set((state) => ({ sortOrder: state.sortOrder === "asc" ? "desc" : "asc" })),
	handleSortPress: (type) =>
		set((state) => {
			if (type === state.sortType) {
				return {
					sortOrder: state.sortOrder === "asc" ? "desc" : "asc",
					showSortModal: false,
				};
			}
			return {
				sortType: type as SortType,
				sortOrder: "asc",
				showSortModal: false,
			};
		}),
	setSearchQuery: (query) => set({ searchQuery: query }),
	setSelectedFilters: (filters) => set({ selectedFilters: filters }),
	toggleFilter: (filterType) =>
		set((state) => ({
			filterState: {
				...state.filterState,
				[filterType]: !state.filterState[filterType],
			},
		})),
	clearAllFilters: () =>
		set({
			selectedFilters: [],
			filterState: initialFilterState,
			searchQuery: "",
		}),
	applySpecialityFilters: (filters) => {
		const selectedFilters = filters
			.filter((filter) => filter.isSelected)
			.map((filter) => filter.title);
		set({ selectedFilters, showFilterModal: false });
	},
}));
