/* -----------------Globals--------------- */
import { Image } from "@memorang/ui";
import { AuthButton, AuthContainer, useBreakpoints } from "@memorang/ui";
import { Box } from "@memorang/ui";
import type { ImageSource } from "expo-image";
/* -----------------UI--------------- */
import { Text, useTheme } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

type AppLogo = {
	stacked: {
		light: ImageSource;
		dark: ImageSource;
	};
};

type Props = {
	appLogo: AppLogo;
	handleNext: () => void;
	subtitle?: string;
	title?: string;
};
const Landing = ({ appLogo, handleNext, subtitle, title }: Props) => {
	const theme = useTheme();
	const {
		stacked: { light, dark },
	} = appLogo;

	const AppLogo = theme.dark ? dark : light;
	const { smDown } = useBreakpoints();
	const insets = useSafeAreaInsets();

	return (
		<AuthContainer
			containerStyle={{
				gap: smDown ? 0 : 24,
				paddingTop: smDown ? 0 : 48,
				paddingVertical: 24,
				flexGrow: smDown ? 1 : 0,
				paddingHorizontal: 16,
				position: "relative",
			}}
		>
			<Box
				alignItems="center"
				gap={24}
				justifyContent={smDown ? "center" : "flex-start"}
				flexGrow={1}
			>
				<Box alignItems="center" gap={24}>
					<Image
						contentFit="contain"
						source={AppLogo}
						quality={100}
						style={{
							width: 300,
							height: smDown ? 200 : 150,
							maxWidth: "100%",
							alignSelf: "center",
						}}
					/>
					{title && (
						<Text
							style={{
								fontFamily: "opensans-semibolditalic",
								textAlign: "center",
							}}
							variant="displayMedium"
						>
							{title}
						</Text>
					)}
					{subtitle && (
						<Text
							style={{
								fontFamily: "opensans-italic",
								textAlign: "center",
							}}
							variant="titleMedium"
						>
							{subtitle}
						</Text>
					)}
				</Box>
			</Box>
			<Box
				alignItems="center"
				gap={16}
				position={smDown ? "absolute" : "relative"}
				bottom={smDown ? 16 : undefined}
				left={smDown ? 16 : undefined}
				right={smDown ? 16 : undefined}
				paddingBottom={insets.bottom}
			>
				<AuthButton
					testID="continue-with-email"
					icon={"email-outline"}
					fullWidth={smDown}
					onPress={handleNext}
				>
					Continue with email
				</AuthButton>
			</Box>
		</AuthContainer>
	);
};

export default Landing;
