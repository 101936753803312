import type { Article } from "../../../../../types";

export const Article525: Article = {
	id: 525,
	rsuiteId: "7536df41-f93e-416c-98ba-fed765da1a89",
	type: "the orthopaedic forum",
	title: "What’s Important: Building the Practice Your Community Needs",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/525.png",
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset">
<div class="article lens-article" style="position: unset" data-context="toc">
<div class="panel content document width100" style="position: unset">
<div class="surface resource-view content" style="position: unset">
  <div class="nodes" style="padding-left: 0px; position: unset">
    <div
      class="content-node cover"
      data-id="cover"
      style="padding-top: 30px; position: unset"
    >
      <div class="content" style="position: unset">
        <div class="text title" style="position: unset">
          What’s Important: Building the Practice Your Community Needs
        </div>
        <div class="text subtitle" style="position: unset"></div>
        <div class="authors" style="position: unset">
          <div
            class="content-node text"
            data-id="text_contributor_1_reference"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <a
                href=""
                data-id="contributor_reference_1"
                class="annotation contributor_reference resource-reference"
                style="position: unset"
                >Edwin L. Portalatín Pérez, MD</a
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_3"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_3"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            During my upbringing on the northwest side of Puerto Rico, a
            recurrent topic of conversation was the lack of orthopaedic
            surgical care in the area. Children experiencing any
            substantial orthopaedic injury were transferred to the only
            academic center on the island. As locals, we knew that this
            meant at least 2 days of waiting for proper evaluation before
            having any treatment. By no means was this secondary to a lack
            of effort on the part of the medical providers; it was mainly
            because of limited personnel and resources. Fortunately, I
            never experienced a major orthopaedic injury during my
            childhood. However, I did spend an impactful amount of time as
            an orthopaedic patient.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_4"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_4"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            At the age of 12, I was diagnosed with scoliosis and was
            referred to the same orthopaedic practice that took care of
            the trauma patients. I will never forget that we needed to
            wake up at 3:00 in the morning, travel 3 hours to the
            treatment center, and then wait about 8 to 10 hours for my
            evaluation with the physician. My treatment represented a very
            intense 2 years of our lives, with a huge emotional and
            economic burden to my family. Despite all of the hurdles, we
            are grateful that I received the suggested surgical treatment,
            and we testify to the community about the commitment of the
            medical staff in such difficult circumstances.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_5"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_5"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            These experiences ignited a desire in my heart to serve those
            in need close to home as an orthopaedic surgeon.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_6"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_6"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Over 40% of the population of Puerto Rico lives at or below
            the poverty level, limiting the ability of many to comply with
            the existing system of acquiring medical treatment. In an
            effort to mitigate the problem of accessibility to care, 2
            years ago, we started a pediatric orthopaedic practice in the
            northwestern part of Puerto Rico. Our efforts were aimed not
            only at developing a clinic in the underserved area but also
            building the practice that our community needs.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_7"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_7"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Building an orthopaedic practice is not an easy task and
            requires a good amount of planning and strategy. Considering
            my childhood experiences, it was evident that taking on
            orthopaedic trauma cases, especially those that would
            otherwise be automatic transfers, would be helpful to the
            community while also representing a strategy for growth.
            However, as we made ourselves available, and needs were
            present, we started to encounter other stumbling blocks we did
            not account for.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_8"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_8"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            In underserved areas, the opportunity to serve may not always
            be readily available. In a health-care system dominated by the
            private sector and a fee-for-service reimbursement model,
            operating room time in the hospital where we perform surgical
            procedures is mainly reserved for independent contractors who
            have seniority and a higher surgical volume. In practical
            terms, for example, we had to explain to the administration
            and faculty that treatment of a displaced supracondylar
            humeral fracture is a higher priority than an elective total
            knee replacement. Experiencing similar situations on various
            occasions, we understood that our community relied on us not
            only to be available for surgical procedures, but to change
            the system through long conversations and evidence to provide
            the needed services.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_9"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_9"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Another challenging scenario that we face is the lack of
            readily available pediatric implants, which may delay
            treatment if not properly coordinated. In Puerto Rico,
            orthopaedic implants are serviced through third-party
            companies, which are based on the metropolitan part of the
            island. In the best of scenarios, it may take about 6 hours
            for implants to be ready for surgical use after the request.
            While not a perfect solution, we believe that bringing the
            implants to our local hospital would be more optimal and
            cost-effective than transferring the patient to the academic
            institution where implants are currently consigned. We have
            made arrangements to try to consign the implants to the local
            hospital, but because of our current volume, it is not
            cost-effective for the companies. Our expectation is that this
            will resolve with the growth of our practice.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_10"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_10"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Despite the unexpected obstacles that we have faced, we marked
            a milestone of 300 surgical trauma cases performed over the
            first 2 years of our practice. Not only have patients had the
            chance to be treated closer to home, but our practice also
            represents a load relief for the academic center that
            continues to serve patients who do not have an orthopaedic
            practice nearby. That said, although we would love to be able
            to treat all local patients, the reality is that some are
            better off being transferred to an institution with a higher
            level of care. Our service to the community also encompasses
            making this decision as quickly and safely as possible.
            Certainly, our current services are not as comprehensive as we
            would like them to be, but our practice is more than what we
            had before and is meeting needs within the community.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_11"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_11"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Moreover, the trauma service experience has served as the
            foundation for developing other areas of a pediatric
            orthopaedic practice. After conversations with local primary
            care physicians, we a made list of changes that we could make
            to increase our reach and access. High on the list in terms of
            impact: pediatricians pointed out the lack of local providers
            who perform hip ultrasound to screen for developmental
            dysplasia of the hip. Responding to this need, our practice
            acquired ultrasound capability and we now offer such studies
            in our clinic. Some of our first ultrasounds were performed
            for patients who had needed the screening for months but,
            because of their socioeconomic status, were unable to travel
            to the recommended institutions. As we consider our next
            practice goal, we remain mindful of optimizing our limited
            resources and incorporating suggestions that have the highest
            impact for our community.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_12"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_12"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            The context of every orthopaedic practice is unique. For those
            on a similar path, understanding or spending time to
            understand the history of the area, its socioeconomic status,
            and the medical health-care system not only will guide your
            efforts to be more accessible and efficient, but could also
            help in anticipating potential roadblocks. Frustrations are
            inevitable because most result from our concern that we are
            not serving our community optimally. But remember, if we were,
            there would be no need to make any changes.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_13"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_13"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Leadership, communication skills, perseverance, and patience
            are as necessary and important as surgical skills to making it
            possible to serve those in need. Our desire to care for
            underserved patients, leading to context-specific solutions,
            should help guide us toward building the practice that our
            community needs.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <a
      href=""
      data-id="eletter_reference"
      class="annotation eletter_reference resource-reference"
      style="position: unset"
    ></a>
    <div
      class="content-node eletter-submit"
      data-id="eletters"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="label">eLetters</div>
        <div class="link" style="position: unset">
          <a
            href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D7536df41-f93e-416c-98ba-fed765da1a89%26native%3D1"
            target="_blank"
            class=""
            style="position: unset"
            ><img
              class="image"
              src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
              style="position: unset"
            /><span class="label">Submit an eLetter</span></a
          >
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="heading_4"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset"
          >Additional information</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node publication-info"
      data-id="publication_info"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="meta-data" style="position: unset">
          <div class="journal" style="position: unset">
            <div class="label">Journal</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >The Journal of Bone and Joint Surgery</span
              >
            </div>
          </div>
          <div class="subject" style="position: unset">
            <div class="label">Section</div>
            <div class="value" style="position: unset">
              The Orthopaedic Forum
            </div>
          </div>
          <div class="publishing" style="position: unset">
            <div class="label">Published</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >September 4, 2024; 106 (17): 1629</span
              >
            </div>
          </div>
          <div class="doi" style="position: unset">
            <div class="label">DOI</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >10.2106/JBJS.24.00001</span
              >
            </div>
          </div>
          <div class="dates" style="position: unset">
            The article was first published on
            <b class="" style="position: unset">April 4, 2024</b>.
          </div>
        </div>
        <div
          class="content-node paragraph"
          data-id="articleinfo"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            <div
              class="content-node heading level-3"
              data-id="heading_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <span class="text title" style="position: unset"
                  >Copyright & License</span
                >
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node paragraph"
              data-id="paragraph_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_1"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    Copyright © 2024 by The Journal of Bone and Joint
                    Surgery, Incorporated.
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node supplement"
              data-id="supplement_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="file" style="position: unset">
                  <span class="" style="position: unset"
                    >Open article PDF</span
                  ><a
                    class="jbjs_tracking gtm_tracking"
                    jbjs_tracking_type="download"
                    jbjs_tracking_data='{"id":"7536df41-f93e-416c-98ba-fed765da1a89","type":"article","topics":[]}'
                    href="https://www.jbjs.org/reader.php?rsuite_id=7536df41-f93e-416c-98ba-fed765da1a89&type=pdf&name=JBJS.24.00001.pdf"
                    target="_blank"
                    gtm_action="reader"
                    gtm_category="PDF_article_downloads"
                    gtm_label="https://www.jbjs.org/reader.php?rsuite_id=7536df41-f93e-416c-98ba-fed765da1a89&type=pdf&name=JBJS.24.00001.pdf"
                    jbjs_tracking_source="reader"
                    style="position: unset"
                    ><img
                      src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                      style="position: unset"
                    />
                    Download</a
                  >
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node heading level-3"
              data-id="heading_3"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <span class="text title" style="position: unset"
                  >Disclosures of Potential Conflicts of Interest</span
                >
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node paragraph"
              data-id="paragraph_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_2"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <span
                      data-id="strong_1"
                      class="annotation strong"
                      style="position: unset"
                      >Disclosure:</span
                    >
                    The
                    <span
                      data-id="strong_2"
                      class="annotation strong"
                      style="position: unset"
                      >Disclosure of Potential Conflicts of Interest</span
                    >
                    form is provided with the online version of the
                    article (<a
                      href="http://links.lww.com/JBJS/H960"
                      target="_blank"
                      data-id="link_1"
                      class="link"
                      style="position: unset"
                      >http://links.lww.com/JBJS/H960</a
                    >).
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node supplement"
              data-id="supplement_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="file" style="position: unset">
                  <span class="" style="position: unset"
                    >Disclosures of Potential Conflicts of Interest</span
                  ><a
                    class="jbjs_tracking"
                    jbjs_tracking_type="download"
                    jbjs_tracking_data='{"id":"7536df41-f93e-416c-98ba-fed765da1a89","type":"disclosure","topics":[]}'
                    href="/php/content/content_api.php?op=download&rsuite_id=7536df41-f93e-416c-98ba-fed765da1a89&type=zip&name=JBJS.24.00001.disclosure.zip&subtype=disclosure"
                    target="_blank"
                    jbjs_tracking_source="reader"
                    style="position: unset"
                    ><i class="fa fa-download" style="position: unset">
                      Download</i
                    ></a
                  >
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-3"
      data-id="heading_1_author_list"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset">Authors</span>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node contributor"
      data-id="contributor_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="contributor-name" style="position: unset">
          Edwin L. Portalatín Pérez, MD<span
            data-id="affiliation_reference_1"
            class="label annotation cross_reference"
            >1</span
          >
        </div>
        <div class="affiliations" style="position: unset">
          <span class="" style="position: unset"></span>
        </div>
        <div class="emails contrib-data" style="position: unset">
          <span class="contrib-label" style="position: unset"
            >For correspondence: </span
          ><span class="" style="position: unset"
            ><a
              href="mailto:drportalatin.orto@gmail.com"
              class=""
              style="position: unset"
              >drportalatin.orto@gmail.com</a
            ></span
          >
        </div>
        <div class="contrib-data" style="position: unset">
          <img
            class="orcid"
            src="https://www.jbjs.org/images/orcid_logo.png"
            style="position: unset"
          /><a
            href="https://orcid.org/0000-0003-0712-7146"
            target="_blank"
            class="orcid"
            style="position: unset"
            >https://orcid.org/0000-0003-0712-7146</a
          >
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node affiliation"
      data-id="affiliation_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="label">1</span
        ><span class="text" style="position: unset"
          >Clínica Geriatría y Ortopedia del Oeste, Aguadilla, Puerto
          Rico</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <a class="logo" href="home.php" style="position: unset"></a>
  </div>
</div>
<div
  class="surface-scrollbar content hidden"
  style="display: none; position: unset"
>
  <div
    class="visible-area"
    style="top: 0px; height: 5669.45px; position: unset"
  ></div>
</div>
</div>
</div>
</div>
`,
};
