import { relayEnv } from "@memorang/configs";
import { fetchData } from "@memorang/helpers";
import type { MixedItemDistResponse } from "@memorang/types";
/* -----------------Globals--------------- */
import { graphql } from "react-relay";

const query = graphql`
  query FetchStudyPackContentsQuery($bundleId: ID!) {
    getMixedItemDistribution(bundleId: $bundleId) {
      iconUrl
      title
      freeTagIds
      contents {
        id
        type
        distribution {
          low
          medium
          high
        }
        totalCount
      }
      tags {
        id
        title
        displayType
        type
        items {
          id
          distribution
          type
        }
      }
    }
  }
`;

const fetchStudyPackContents = async (bundleId: string) => {
	const variables = {
		bundleId,
	};

	const { getMixedItemDistribution } = (await fetchData(
		relayEnv,
		query,
		variables,
	)) as MixedItemDistResponse;

	return {
		getMixedItemDistribution,
	};
};

export default fetchStudyPackContents;
