/* -----------------Types--------------- */

import type { Distribution, MixedItemTag, ReportSessionType } from "../..";

interface Accuracy {
	numQuestions: number;
	mastered: number;
	needsWork: number;
}
interface QuestionsAccuracy {
	hotspot: Accuracy;
	mcq: Accuracy;
	passage: Accuracy;
	sata: Accuracy;
	sequence: Accuracy;
	written: Accuracy;
}

interface CSTitemsCount {
	numSections: number;
	numCorrectActions: number;
	numIncorrectActions: number;
	numItems: number;
}

type SessionSummaryConfig = ["ScoreReport"];

export interface ReportDetails {
	id: string;
	questionsAccuracy: QuestionsAccuracy;
	answered: number;
	accuracy: number;
	timeElapsed: number;
	totalChoicePoints?: number;
	numChoicePoints?: number;
	distribution: Distribution;
	totalTime: number;
	avgSpeed: number;
	reportSessionType: ReportSessionType;
	sessionDate: string;
	score: number;
	numQuestions: number;
	numCorrect: number;
	numIncorrect: number;
	numSkipped: number;
	markedCount: number;
	cstMarkedCount: number;
	isMigratedProgress: boolean;
	cstItemsCount: CSTitemsCount;
	numericId: number;
	examName: string;
	tagDetails: MixedItemTag[];
	sessionSummaryConfig?: SessionSummaryConfig;
}
