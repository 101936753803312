import type { Article } from "../../../../../types";

export const Article517: Article = {
	id: 517,
	rsuiteId: "a1fe809b-479b-434f-bd2d-299797ce0a68",
	type: "the orthopaedic forum",
	title: "AOA Critical Issues Symposium: Promoting Health Equity",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/517.png",
	subSpecialties: ["trauma"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">AOA Critical Issues Symposium: Promoting Health Equity
              </div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Keith Kenter,
                      MD, ScD(Hon), FAOA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Karen Bovid,
                      MD, FAOA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract:</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Promoting equitable health care is to ensure that
                        everyone has access to high-quality medical services and appropriate treatment options. The
                        definition of health equity often can be misinterpreted, and there are challenges in fully
                        understanding the disparities and costs of health care and when measuring the outcomes of
                        treatment. However, these topics play an important role in promoting health equity. The COVID-19
                        pandemic has made us more aware of profound health-care disparities and systemic racism, which,
                        in turn, has prompted many academic medical centers and health-care systems to increase their
                        efforts surrounding diversity, equity, and inclusion. Therefore, it is important to understand
                        the problems that some patients have in accessing care, promote health care that is culturally
                        competent, create policies and standard operating procedures (at the federal, state, regional,
                        or institutional level), and be innovative to provide cost-effective care for the underserved
                        population. All of these efforts can assist in promoting equitable care and thus result in a
                        more just and healthier society.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Optimal Care
                for All</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">In 1992, Margaret Whitehead brought our attention to the
                  importance of the key principles of health inequity, with a goal of increasing awareness concerning
                  issues of access to, utilization of, and quality of health care<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Her article evaluated the concepts and principles of health
                  equity as outlined in the Health in All Policies collaborative approach by the European members of the
                  World Health Organization (WHO) in 1984. The important points raised in the article stimulated other
                  authors to contribute to our understanding of the delivery and financing of health care<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a>,
                  further defining the terms and language used<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">6</span></a> and refining the concepts and goals of health equity<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">7</span></a>.
                  Although the words “equality” and “equity” have the same etymological Latin root (from “aequus,”
                  meaning “even” or “equal”), it is important to recognize the differences between the words. The Robert
                  Wood Johnson Foundation (RWJF) has used visual graphics to help promote the understanding of these
                  definitions. According to the RWJF, equality can be defined as each individual or group of people
                  being given the same resources and/or opportunities, while equity recognizes that each individual or
                  group may have challenges or a situation that prevents reaching a successful and equal outcome<a
                    href="" data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">8</span></a>. In a broader context, these definitions simply require that
                  we understand that all individuals do not start at the same place, because power and/or resources are
                  not evenly distributed, and that all forms of inequity in health care should be considered inhumane<a
                    href="" data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">10</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">The American Medical Association (AMA)/Association of
                  American Medical Colleges (AAMC) have simply stated that health equity is “optimal care for all.”<a
                    href="" data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">9</span></a> Other organizations have made statements that have further
                  defined health equity<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">13</span></a>. According to the Centers for Disease Control and
                  Prevention (CDC), health equity is defined as the “the state in which everyone has a fair and just
                  opportunity to attain their highest level of health.”<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">11</span></a> This
                  has been linked with the recent social justice movement, and optimal health is considered a basic and
                  fundamental human right. According to the WHO, “health is a state of complete physical, mental, and
                  social well-being and not merely the absence of disease and infirmity.”<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">12</span></a> The WHO also states that health equity implies the absence
                  of unfairness and the remediation of differences among individuals or groups of people<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">12</span></a>. This further emphasizes that health is a fundamental human
                  need and right. One can then argue that all human rights are interconnected, and that health should be
                  discussed along with other human rights<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">6</span></a>.
                  More recently, the Centers for Medicare & Medicaid Services (CMS) stated that health equity is “…
                  the highest level of health for all people where everyone has a fair and just opportunity…regardless
                  of race, ethnicity, disability, sexual orientation, gender identity, socioeconomic status, geography,
                  preferred language, and other factors that affect access to care and health outcomes.”<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">13</span></a> The CMS strategy is to further advance health equity by
                  addressing health disparities that affect the health-care system. This strategic plan further
                  encompasses the goals stated by Whitehead<span data-id="superscript_14" class="annotation superscript"
                    style="position: unset;">1</span> and emphasizes the presence of health disparity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">“Health disparity” is generally used as a term to refer to
                  the health burdens of illness, injury, disability, or even mortality experienced by one group of
                  individuals relative to another group of individuals. Braveman has described these differences as
                  avoidable, unfair, or unjust, and not just based on biology<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">14</span></a>.
                  This form of disparity is considered health inequity and often ignores the historical context of
                  health care, the political processes, and, often, the unjust or unfair nature in outcomes. Thus, it is
                  often difficult to identify the root cause of health disparity. In 2017, Adelani and O’Connor surveyed
                  members of the American Orthopaedic Association (AOA) to help determine the views of orthopaedic
                  surgeons regarding the extent of racial/ethnic disparities in musculoskeletal health and orthopaedic
                  outcomes, patient and system factors that may contribute to these racial/ethnic disparities, and the
                  role of orthopaedic surgeons in the reduction of these disparities<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">15</span></a>. They found that 12% of respondents agreed that clinically
                  similar patients “often” receive different care in the U.S. health-care system based on
                  race/ethnicity. Furthermore, 58% of these orthopaedic surgeons believed that differences in care
                  “never” exist in their own practice.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">An important strategy in making change is to emphasize and
                  promote common or key principles. This can provide a foundation for identifying the root cause of a
                  problem by defining the terms and understanding the language that is used. This helps with improved
                  communications between stakeholders. <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>
                  summarizes the common key principles in promoting health equity. The CDC developed a CORE (Cultivate
                  comprehensive health equity science, Optimize interventions, Reinforce and expand robust partnerships,
                  and Enhance capacity and workplace diversity, inclusion, and engagement) strategy to commit to health
                  equity<span data-id="superscript_17" class="annotation superscript"
                    style="position: unset;">11</span>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Common Key Principles in Promoting Health Equity
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 528px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Accessibility</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Ensuring that services
                          are available and accessible to all</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Affordability</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Making services
                          financially feasible so that cost is not a barrier</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Quality</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Promoting and meeting
                          standards of high-quality metrics for all</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cultural
                          competence</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Caregivers and
                          health-care systems need to be culturally aware and knowledgeable, and they must respect the
                          diverse backgrounds and beliefs of patients</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Health education</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Promoting health literacy
                          and information, allowing patients to make informed decisions regarding their own
                          health</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Health equity
                          research</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Identifying and
                          addressing health disparities to improve treatment outcomes and to create protocols and
                          policies for resource allocation</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Rural Area
                and Urban or Inner-City Medicine</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Lack of access to transportation is one of the main
                  challenges to delivering equitable health care to many patients who live in rural areas. Public
                  transportation is typically not available in less-densely populated areas. Distances to school, the
                  grocery store, pharmacies, and medical facilities are often not walkable, and road surfaces can be
                  poor and have poor accessibility at times. Rural areas may also have limited access to schools
                  dedicated to special education; physical, occupational, and speech therapy; orthotic and specialty
                  shoe facilities; home/visiting nurse resources; and/or respite care<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">17</span></a>. Furthermore, in the rural communities that we serve, our
                  experience is that health-care education and awareness of resources are lacking. We have met many of
                  these challenges by strategically developing partnerships with orthotic and brace companies that can
                  provide services in our offices and at the local schools. Transportation may be provided through
                  insurance or by community service agencies. Religious and community nonprofit organizations have been
                  available to provide some resources and support. For example, Lending Hands of Michigan (<a
                    href="https://lendinghandsmi.org" target="_blank" data-id="link_7" class="link"
                    style="position: unset;">https://lendinghandsmi.org</a>) is an all-volunteer nonprofit organization
                  that loans durable medical equipment to patients in need across 6 counties in southwest Michigan.
                  Churches may have a care committee that arranges for volunteers to visit those with health and
                  mobility challenges, provide transportation to appointments, and even give respite care to support
                  caregivers (e.g., the First United Methodist Church of Kalamazoo [<a
                    href="https://www.umc-kzo.org/generosity/volunteer-opportunities" target="_blank" data-id="link_8"
                    class="link"
                    style="position: unset;">https://www.umc-kzo.org/generosity/volunteer-opportunities</a>]).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Many of the strategies that are used to promote health
                  equity in rural areas are also utilized in urban or inner-city environments. Outpatient facilities and
                  family health centers can be located near public transportation routes. Kiosk machines to purchase
                  public transportation tickets can be located in the ambulatory outpatient center as a convenience and
                  assist patients in minimizing their concerns regarding lack of transportation or where they can
                  purchase tickets. Governmental and private foundation grants can be awarded to help pay for public
                  transportation services (e.g., in the form of tokens or transfer tickets) can be secured. Finally,
                  mobile vans or other vehicles that can drive to the campsites of the unhoused or established shelters
                  can also provide another way to access care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Despite these strategies to improve access to care, there
                  remain inadequate numbers of orthopaedic surgeons in inner-city communities because many physicians
                  have opted out of Medicare and Medicaid insurance plans, and many patients lack any type of medical
                  insurance. Not having orthopaedic surgeons routinely present in these communities contributes to the
                  lack of trust that many patients have in our specialty. Many of the traditional “city hospitals” in
                  metropolitan areas are poorly funded, which makes it potentially unaffordable to employ orthopaedic
                  surgeons. Institutions that are affiliated with universities and schools of medicine can play an
                  important role by providing education for their learners. With proper supervision from faculty,
                  outpatient services can act as an excellent resource for training and allow for graduated autonomy.
                  For example, the Department of Orthopaedic Surgery at Washington University in St. Louis has
                  maintained their “resident clinic” and has provided financial support to attending faculty who
                  supervise the residents in this outpatient clinic. This has acted as an excellent resource to allow
                  for graduated autonomy of orthopaedic residents. The department has also hired a community health
                  worker and partnered with social workers to enhance comprehensive care services during clinic times. A
                  similar model is in place at the Hospital for Special Surgery (HSS). HSS has partnered with Harlem
                  Hospital through New York City Health + Hospitals and has appointed a director of ambulatory care in
                  this setting. Attending staff are responsible for specialty clinics and have protected time to develop
                  these outpatient services. Again, social service and community health workers are available to help
                  navigate resources for patients and their family members. Experts who help in financial counseling and
                  financial assistance services are also available. Formalizing all of these services and developing
                  standard operational procedures emphasizes dedication to the community. This may improve continuity of
                  care, which should help in the development of trust and, thus, a stronger patient-physician
                  relationship, with the hope of improving quality outcome metrics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The Role of
                Social Services and the Community Health Worker</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Developing a robust partnership with social service and
                  community health workers is an important element in assisting patients in overcoming barriers that
                  result in health disparities and providing access to expertise. The Medical Legal Partnership Program
                  at Boston Children’s Hospital is an excellent example of the collaboration between physicians and the
                  Office of the General Counsel for patients needing legal support to address housing, immigration
                  status, access to benefits, family law matters (including guardianship), and access to educational
                  programs<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">18</span></a>.
                  Another example is the Trauma Recovery Service at Case Western Reserve Medical Center<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">20</span></a>. This program is a psychosocial support program for
                  patients admitted with traumatic injuries; it includes professional coaching, peer mentorship, and
                  screening and treatment for posttraumatic stress disorder (PTSD). These 2 examples demonstrate the
                  multidisciplinary approach to health equity, with social services or community health workers as the
                  centerpiece of the team. Social workers have a master’s degree, are licensed, and are skilled in
                  counseling; they are also trained to identify domestic violence and neglect, and they have knowledge
                  of available resources. Additionally, they can assist in navigating health-care systems, insurance,
                  and guardianship, and make appropriate referrals to community partners and available programs.
                  Community health workers have varying levels of training and are credentialed by the state. They also
                  have knowledge regarding community programs, health-related issues, and available resources. Ideally,
                  they are trusted members of the population they serve, with a shared ethnicity, language, and
                  socioeconomic status, as well as similar life experiences<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">22</span></a>. Community health workers are also able to provide peer
                  counseling, service coordination, and health education, and they may teach about social and
                  health-care systems programs<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">23</span></a>. They excel at outreach and connecting with community
                  partners. Lapidos et al. demonstrated improvements in health equity outcomes in patients with
                  diabetes, heart disease, and hypertension, and with cancer screening, hospital readmission rates, and
                  mental health<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">21</span></a>. In
                  2014, the CMS expanded preventative services to cover reimbursements for community health workers. The
                  Patient Protection and Affordable Care Act promoted care delivery models such as the Patient-Centered
                  Medical Home model and Accountable Care Organizations (ACOs) that provide a framework for integrating
                  community health workers into the team<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">24</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The Costs of
                Health Care</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The ethics of equitable care mandate that persons in need
                  of medical treatment should be provided reasonable options within the standard of care, regardless of
                  their ability to pay. In April 1986, the Emergency Medical Treatment and Active Labor Act (EMTALA) was
                  created by the United States Congress. It prevents hospital emergency rooms from transferring people
                  who present with an emergency medical condition until the condition is stabilized within the
                  considerations of the standard of care<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">25</span></a>.
                  While the EMTALA statute does not extend to elective or semi-elective procedures, the concept of “duty
                  of care” remains<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">27</span></a>. If the physician finds that the patient is unable to pay
                  for treatment, ethically (and in some cases legally), the practitioner must still either provide
                  treatment within the standard of care or arrange for transfer to an entity that will provide such
                  treatment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">To address this inequity, other organizations have
                  attempted to fill the gap between government-funded health care and private insurance carriers. For
                  example, Brady and Saucedo evaluated the Willis-Knighton health system in Louisiana, which focuses on
                  delivering care to the underprivileged<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">28</span></a>.
                  They identified 3 areas for care initiatives: “efficient operation, service line diversification, and
                  a commitment to serving the disadvantaged.”<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">28</span></a>
                  Handcox et al. discussed ways in which private practitioners may more effectively treat underfunded
                  patients by providing flexibility in payment planning, transparency in pricing, and a list of
                  potential charitable resources to “safety-net” institutions that can alleviate the burden of high-cost
                  health care<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">29</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Private practitioners and some hospitals are not equipped
                  to fully absorb the costs of unfunded care<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">30</span></a>.
                  Below, we address strategies to reduce clinical costs and yet remain faithful to the standards
                  expected from medical professionals.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Decreasing
                Pharmaceutical Costs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Understanding the standard of care with respect to
                  antibiotic administration, pain control, anticoagulation, and post-procedure nausea and vomiting may
                  be crucial in providing cost containment<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">31</span></a>.
                  For example, antibiotic coverage may not be required for many soft-tissue procedures in otherwise
                  healthy patients<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">32</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">33</span></a>. Narcotic administration may not be required for simple
                  procedures that have minimal recovery time. Post-procedure nausea may be treated with commonplace
                  generic medications such as diphenhydramine rather than expensive alternatives such as aprepitant<a
                    href="" data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">34</span></a>. Anticoagulation choices range from simply low-dose aspirin
                  once per day to more expensive options, which may not be necessary in all cases<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">35</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Consolidating Infrastructure</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The standard clinic and hospital infrastructure often is
                  built on redundancy<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">36</span></a>.
                  While such a model may provide a measure of security, one must look carefully at the cost-benefit
                  ratio when evaluating staffing needs<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_36"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">38</span></a>. Certainly, there is a minimum number of staff who must be
                  present for the safe administration of health care. The number may vary depending on the activities in
                  the clinic space on any given day<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">39</span></a>.
                  Carefully evaluating what level of staffing is considered safe within the context of expected
                  procedures or clinic load and consolidating procedural appointments to the same clinic days each week
                  could help obviate the need for some clinical staff on days when procedural activities may be less
                  demanding<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">39</span></a><span data-id="superscript_38"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">40</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Technological Advancements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Unfunded patients are individuals from all walks of life,
                  including college students who are between jobs and the chronically unhoused with limited social
                  support<a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">38</span></a>. A pervasive attribute of virtually any patient is the
                  ability to connect via cell phone service. A 2017 study showed that 94% of unhoused individuals owned
                  cell phones, with over half connecting to the internet daily<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript" style="position: unset;">41</span></a>.
                  Cell phone-enabled chat functions can be utilized by a wide variety of patients, and telenursing costs
                  can be shared among several clinics and their practitioners<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">39</span></a><span data-id="superscript_41"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">42</span></a>. Leveraging the ubiquitous use of technology can be a
                  cost-saving maneuver that also provides convenient and efficient care<a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">43</span></a><span data-id="superscript_42"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">44</span></a>. The use of telemedicine clinic visits can be a valuable
                  tool in providing needed assessments for some patients<a href="" data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">45</span></a><span data-id="superscript_43"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">46</span></a>. Telemedicine can potentially decrease the need for
                  practitioner staff to be present at the time of the encounter and assist with patient concerns
                  regarding lack of transportation. The advancements in digital technology and the creation of
                  consolidated remote clinic visits may allow for a substantial reduction in staffing<a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_44"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">43</span></a><span data-id="superscript_44"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">46</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Automated
                Check-in and Check-out Procedures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The use of inexpensive, readily available platforms such
                  as tablet computers allows for seamless check-in and check-out procedures with decreased staffing
                  requirements<a href="" data-id="citation_reference_62"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_45"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_63" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">43</span></a>. Software to effect these automated procedures is
                  affordable and pervasive, and could provide a substantial cost savings<a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">42</span></a><span data-id="superscript_46"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_65" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">47</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Standardizing Clinical Care Pathways</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Clinical care pathways, including postoperative
                  length-of-stay expectations, should be standardized to the best of the practitioner’s ability<a
                    href="" data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">48</span></a>. Such pathways may be achieved by providing out-of-clinic
                  or out-of-hospital coordination via telemedicine options to ensure that a patient’s support system or
                  home environment is sufficient for discharge and, if outpatient rehabilitation appears to be likely, a
                  facility that is able to work with a patient’s funding status has been identified<a href=""
                    data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_68" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">49</span></a>. When evaluating options for cost containment in the
                  setting of unfunded patients, it is important to realize that many benefits may extend from
                  instituting measures to engage in cost containment for unfunded patients, which will result in less
                  expensive treatment that remains within the standard of care<a href="" data-id="citation_reference_69"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_49"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">50</span></a>. Providing front-end care coordination would allow for safe
                  and effective care for virtually any surgical patient for whom a postoperative hospital stay remains
                  an expectation<a href="" data-id="citation_reference_71"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_50" class="annotation superscript" style="position: unset;">49</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Maximizing
                Reimbursements for Funded Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Funded patients are typically allowed to see only a
                  fraction of the available practitioners for a given medical problem<a href=""
                    data-id="citation_reference_72" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_51"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_73" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">51</span></a>. An insurance company may contract with a hospital or a
                  corporation that dictates which patients they can see and treat<a href=""
                    data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                      style="position: unset;">52</span></a><span data-id="superscript_52"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_75" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                      style="position: unset;">53</span></a>. The bargained-for exchange that must be present for a
                  contract to exist is only between the insurance organization and the hospital or a multi-specialty
                  corporation. The physician and the patient are the currency by which these 2 entities interact<a
                    href="" data-id="citation_reference_76" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">50</span></a><span data-id="superscript_53"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_77" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">52</span></a>. Collecting payment for work performed by physicians for
                  the benefit of these patients is very nearly at the whim of the insurance companies that specify which
                  procedures are covered without any input from the physicians.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Retention as a Cost Containment Measure</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Studies suggest that retaining patients within a medical
                  practice represents a substantial cost-saving measure over acquiring new patients<a href=""
                    data-id="citation_reference_78" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_54" class="annotation superscript"
                      style="position: unset;">54</span></a><span data-id="superscript_54"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_79" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_54" class="annotation superscript"
                      style="position: unset;">56</span></a>. Furthermore, patients are far more likely to retain
                  loyalty to a particular practice if their physician does not change<a href=""
                    data-id="citation_reference_80" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_55" class="annotation superscript"
                      style="position: unset;">55</span></a><span data-id="superscript_55"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_81" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_55" class="annotation superscript"
                      style="position: unset;">57</span></a>. Physician retention is often related to ease of practice,
                  including the ability to provide excellent care and the referral of patients to colleagues when care
                  exceeds the physician’s scope of practice. Allowing for a streamlined transfer of care among
                  physicians will increase access and decrease the overall burden for physicians, hospitals, and
                  patients<a href="" data-id="citation_reference_82"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_56" class="annotation superscript"
                      style="position: unset;">54</span></a><span data-id="superscript_56"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_83" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_56" class="annotation superscript"
                      style="position: unset;">56</span></a><span data-id="superscript_56"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_84" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_56" class="annotation superscript"
                      style="position: unset;">57</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Promoting equitable health care is to ensure that everyone
                  has access to high-quality medical services and appropriate treatment options. The definition of
                  health equity often can be misinterpreted, and there are challenges in fully understanding disparities
                  and costs of health care and when measuring the outcomes of treatment. However, these topics play an
                  important role in promoting health equity. More recently, with the COVID-19 pandemic, the study of
                  health-care disparities has prompted many academic medical centers and health-care systems to increase
                  their efforts surrounding diversity, equity, and inclusion. Therefore, it is important to understand
                  the problems that some patients have in accessing care, promote health care that is culturally
                  competent, create policies and standard operating procedures (at the federal, state, regional, or
                  institutional level), and be innovative to provide cost-effective care for the underserved population.
                  All of these efforts can assist in promoting equitable care and thus result in a more just and
                  healthier society.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Katie Cherry, LMSW, WMed Health, Kalamazoo,
                  MI; Amy Puth, LCSW, Psychiatric Emergency Services, VA Medical Center, Denver, CO; and Kayla Bouchey,
                  CHW-C, COTA, Washington University, St. Louis, MO, for their assistance with this paper.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Whitehead M</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The concepts and principles of
                  equity and health</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Int J Health Serv.</span> 1992;22(3):429-45.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20concepts%20and%20principles%20of%20equity%20and%20health&as_occt=title&as_sauthors=%20%22M%20Whitehead%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Wagstaff A</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> van Doorslaer E</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Paci P</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Horizontal Equity in the
                  Delivery of Health Care</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Health Econ.</span> 1991;10:251-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Horizontal%20Equity%20in%20the%20Delivery%20of%20Health%20Care&as_occt=title&as_sauthors=%20%22A%20Wagstaff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Wagstaff A</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> van Doorslaer E</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Calonge S</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Christiansen T</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Gerfin M</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Gottschalk P</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Janssen R</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Lachaud C</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Leu RE</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Nolan B</span>, . <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Equity in the finance of health
                  care: some international comparisons</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Health Econ.</span> 1992 Dec;11(4):361-87.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Equity%20in%20the%20finance%20of%20health%20care%3A%20some%20international%20comparisons&as_occt=title&as_sauthors=%20%22A%20Wagstaff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Culyer AJ</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Wagstaff A</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Equity and equality in health
                  and health care</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Health Econ.</span> 1993 Dec;12(4):431-57.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Equity%20and%20equality%20in%20health%20and%20health%20care&as_occt=title&as_sauthors=%20%22AJ%20Culyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Chang WC</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">The meaning and goals of equity
                  in health</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J
                  Epidemiol Community Health.</span> 2002 Jul;56(7):488-91.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20meaning%20and%20goals%20of%20equity%20in%20health&as_occt=title&as_sauthors=%20%22WC%20Chang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Braveman P</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Gruskin S</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Defining equity in
                  health</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J
                  Epidemiol Community Health.</span> 2003 Apr;57(4):254-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Defining%20equity%20in%20health&as_occt=title&as_sauthors=%20%22P%20Braveman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Anand S</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">The concern for equity in
                  health</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J
                  Epidemiol Community Health.</span> 2002 Jul;56(7):485-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20concern%20for%20equity%20in%20health&as_occt=title&as_sauthors=%20%22S%20Anand%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text"
                style="position: unset;">International Women’s Day. <span data-id="strong_8" class="annotation strong"
                  style="position: unset;">Equality vs Equity: what’s the difference as we #EmbraceEquity for IWD 2023
                  and beyond?</span> 2023. Accessed 2024 Mar 6. <a
                  href="https://www.internationalwomensday.com/EquityEquality" target="_blank" data-id="link_1"
                  class="link"
                  style="position: unset;">https://www.internationalwomensday.com/EquityEquality</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Equality%20vs%20Equity%3A%20what%E2%80%99s%20the%20difference%20as%20we%20%23EmbraceEquity%20for%20IWD%202023%20and%20beyond%3F&as_occt=title&as_sauthors=%20%22International%20Women%E2%80%99s%20Day%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;">American
                Medical Association/Association of American Medical Colleges. <span data-id="strong_9"
                  class="annotation strong" style="position: unset;">AMA/AAMC Advancing Health Equity: A Guide to
                  Language, Narrative and Concepts</span>. 2021. Accessed 2024 Mar 6. <a
                  href="https://www.ama-assn.org/system/files/ama-aamc-equity-guide.pdf" target="_blank"
                  data-id="link_2" class="link"
                  style="position: unset;">https://www.ama-assn.org/system/files/ama-aamc-equity-guide.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=AMA%2FAAMC%20Advancing%20Health%20Equity%3A%20A%20Guide%20to%20Language%2C%20Narrative%20and%20Concepts&as_occt=title&as_sauthors=%20%22American%20Medical%20Association%2FAssociation%20of%20American%20Medical%20Colleges%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Luther King M</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Presentation at the Second
                  National Convention of the Medical Committee for Human Rights</span>: Chicago; 1966.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;">Centers
                for Disease Control and Prevention. <span data-id="strong_10" class="annotation strong"
                  style="position: unset;">What is Health Equity?</span> Accessed 2024 Mar 6. <a
                  href="https://www.cdc.gov/healthequity/whatis/index.html#:%7E:text=Health%20equity%20is%20the%20state,their%20highest%20level%20of%20health"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://www.cdc.gov/healthequity/whatis/index.html#:∼:text=Health%20equity%20is%20the%20state,their%20highest%20level%20of%20health</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20is%20Health%20Equity%3F&as_occt=title&as_sauthors=%20%22Centers%20for%20Disease%20Control%20and%20Prevention%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;">World
                Health Organization. <span data-id="strong_11" class="annotation strong"
                  style="position: unset;">Constitution of the World Health Organization</span>. 1946. Accessed 2024 Mar
                6. <a href="https://apps.who.int/gb/bd/PDF/bd47/EN/constitution-en.pdf?ua=1" target="_blank"
                  data-id="link_4" class="link"
                  style="position: unset;">https://apps.who.int/gb/bd/PDF/bd47/EN/constitution-en.pdf?ua=1</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Constitution%20of%20the%20World%20Health%20Organization&as_occt=title&as_sauthors=%20%22World%20Health%20Organization%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;">Centers
                for Medicare & Medicaid Services. <span data-id="strong_12" class="annotation strong"
                  style="position: unset;">CMS Strategic Plan</span>. Accessed 2024 Mar 6. <a
                  href="https://www.cms.gov/about-cms/what-we-do/cms-strategic-plan" target="_blank" data-id="link_5"
                  class="link"
                  style="position: unset;">https://www.cms.gov/about-cms/what-we-do/cms-strategic-plan</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=CMS%20Strategic%20Plan&as_occt=title&as_sauthors=%20%22Centers%20for%20Medicare%20%26%20Medicaid%20Services%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Braveman P</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">What are health disparities and
                  health equity? We need to be clear</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Public Health Rep.</span> 2014 Jan-Feb;129(Suppl 2):5-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20are%20health%20disparities%20and%20health%20equity%3F%20We%20need%20to%20be%20clear&as_occt=title&as_sauthors=%20%22P%20Braveman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Adelani MA</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> O’Connor MI</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Perspectives of Orthopedic
                  Surgeons on Racial/Ethnic Disparities in Care</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Racial Ethn Health Disparities.</span> 2017
                Aug;4(4):758-62.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perspectives%20of%20Orthopedic%20Surgeons%20on%20Racial%2FEthnic%20Disparities%20in%20Care&as_occt=title&as_sauthors=%20%22MA%20Adelani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Braaten AD</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Hanebuth C</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> McPherson H</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Smallwood D</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Kaplan S</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Basirico D</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Clewley D</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Rethorn Z</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Social determinants of health
                  are associated with physical therapy use: a systematic review</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Br J Sports Med.</span> 2021
                Nov;55(22):1293-300.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Social%20determinants%20of%20health%20are%20associated%20with%20physical%20therapy%20use%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22AD%20Braaten%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> McAuley WJ</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Spector W</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Van Nostrand J</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Formal home care utilization
                  patterns by rural-urban community residence</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Gerontol B Psychol Sci Soc Sci.</span> 2009
                Mar;64(2):258-68.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Formal%20home%20care%20utilization%20patterns%20by%20rural-urban%20community%20residence&as_occt=title&as_sauthors=%20%22WJ%20McAuley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ward VL</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Garvin MM</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Tartarilla AB</span>, . <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Advancing Health Equity and
                  Inclusion in an Academic Pediatric Medical Center: Priorities Addressed and Lessons Learned</span>.
                <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Pediatr Orthop Soc
                  North Am.</span> 2023;5(S1).</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advancing%20Health%20Equity%20and%20Inclusion%20in%20an%20Academic%20Pediatric%20Medical%20Center%3A%20Priorities%20Addressed%20and%20Lessons%20Learned&as_occt=title&as_sauthors=%20%22VL%20Ward%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Breslin MA</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Bacharach A</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Ho D</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Kalina M Jr</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Moon T</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Furdock R</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Vallier HA</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Social Determinants of Health
                  and Patients with Traumatic Injuries: Is there a relationship between social health and orthopaedic
                  trauma?</span><span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2023 May 1;481(5):901-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Social%20Determinants%20of%20Health%20and%20Patients%20with%20Traumatic%20Injuries%3A%20Is%20there%20a%20relationship%20between%20social%20health%20and%20orthopaedic%20trauma%3F&as_occt=title&as_sauthors=%20%22MA%20Breslin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Terle MR</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">CORR Insights®: Social
                  Determinants of Health and Patients With Traumatic Injuries: Is There a Relationship Between Social
                  Health and Orthopaedic Trauma?</span><span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2023 May 1;481(5):909-11.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=CORR%20Insights%C2%AE%3A%20Social%20Determinants%20of%20Health%20and%20Patients%20With%20Traumatic%20Injuries%3A%20Is%20There%20a%20Relationship%20Between%20Social%20Health%20and%20Orthopaedic%20Trauma%3F&as_occt=title&as_sauthors=%20%22MR%20Terle%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Lapidos A</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Kieffer EC</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Guzmán R</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Hess K</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Flanders T</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Heisler M</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Barriers and Facilitators to
                  Community Health Worker Outreach and Engagement in Detroit, Michigan: A Qualitative Study</span>.
                <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Health Promot
                  Pract.</span> 2022 Nov;23(6):1094-104.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Barriers%20and%20Facilitators%20to%20Community%20Health%20Worker%20Outreach%20and%20Engagement%20in%20Detroit%2C%20Michigan%3A%20A%20Qualitative%20Study&as_occt=title&as_sauthors=%20%22A%20Lapidos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Olaniran A</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Smith H</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Unkels R</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Bar-Zeev S</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> van den Broek N</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Who is a community health
                  worker? - a systematic review of definitions</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Glob Health Action.</span>
                2017;10(1):1272223.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Who%20is%20a%20community%20health%20worker%3F%20-%20a%20systematic%20review%20of%20definitions&as_occt=title&as_sauthors=%20%22A%20Olaniran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Hartzler AL</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Tuzzio L</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Hsu C</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Wagner EH</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Roles and Functions of
                  Community Health Workers in Primary Care</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Ann Fam Med.</span> 2018
                May;16(3):240-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Roles%20and%20Functions%20of%20Community%20Health%20Workers%20in%20Primary%20Care&as_occt=title&as_sauthors=%20%22AL%20Hartzler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Schmit CD</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Washburn DJ</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> LaFleur M</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Martinez D</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Thompson E</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Callaghan T</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Community Health Worker
                  Sustainability: Funding, Payment, and Reimbursement Laws in the United States</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Public Health Rep.</span>
                2022 May-Jun;137(3):597-603.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Community%20Health%20Worker%20Sustainability%3A%20Funding%2C%20Payment%2C%20and%20Reimbursement%20Laws%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22CD%20Schmit%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Zibulewsky J</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">The Emergency Medical Treatment
                  and Active Labor Act (EMTALA): what it is and what it means for physicians</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Proc (Bayl Univ Med
                  Cent).</span> 2001 Oct;14(4):339-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Emergency%20Medical%20Treatment%20and%20Active%20Labor%20Act%20(EMTALA)%3A%20what%20it%20is%20and%20what%20it%20means%20for%20physicians&as_occt=title&as_sauthors=%20%22J%20Zibulewsky%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Ansell DA</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Schiff RL</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Patient dumping. Status,
                  implications, and policy recommendations</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">JAMA.</span> 1987 Mar
                20;257(11):1500-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20dumping.%20Status%2C%20implications%2C%20and%20policy%20recommendations&as_occt=title&as_sauthors=%20%22DA%20Ansell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;">American
                College of Emergency Physicians. <span data-id="strong_26" class="annotation strong"
                  style="position: unset;">Emergency care guidelines</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Ann Emerg Med.</span> 1997
                Apr;29(4):564-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Emergency%20care%20guidelines&as_occt=title&as_sauthors=%20%22American%20College%20of%20Emergency%20Physicians%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Brady CI</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Saucedo JM</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Providing Hand Surgery Care to
                  Vulnerably Uninsured Patients</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Hand Clin.</span> 2020 May;36(2):245-53.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Providing%20Hand%20Surgery%20Care%20to%20Vulnerably%20Uninsured%20Patients&as_occt=title&as_sauthors=%20%22CI%20Brady%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Handcox JE</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Saucedo JM</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Rose RA</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Corley FG</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Brady CI</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Providing Orthopaedic Care to
                  Vulnerably Underserved Patients: AOA Critical Issues</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2022 Oct
                5;104(19):e84.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=3335039"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;">Institute
                of Medicine (US) Committee on Implications of For-Profit Enterprise in HealthCare. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">For-Profit Enterprise in Health
                  Care</span>. <span data-id="annotation_78" class="annotation" style="position: unset;"> Gray
                  BH</span>, editor. Washington (DC): National Academies Press (US); 1986.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=For-Profit%20Enterprise%20in%20Health%20Care&as_occt=title&as_sauthors=%20%22BH%20Gray%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Elvir-Lazo OL</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> White PF</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Yumul R</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Cruz Eng H</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Management strategies for the
                  treatment and prevention of postoperative/postdischarge nausea and vomiting: an updated review</span>.
                <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">F1000Res.</span> 2020
                Aug 13;9:F1000 Faculty Rev-983.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20strategies%20for%20the%20treatment%20and%20prevention%20of%20postoperative%2Fpostdischarge%20nausea%20and%20vomiting%3A%20an%20updated%20review&as_occt=title&as_sauthors=%20%22OL%20Elvir-Lazo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Shapiro LM</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Zhuang T</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Li K</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Kamal RN</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">The Use of Preoperative
                  Antibiotics in Elective Soft-Tissue Procedures in the Hand: A Critical Analysis Review</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">JBJS Rev.</span> 2019
                Aug;7(8):e6.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2143104"
                  target="_new" class="" style="position: unset;">JBJS Reviews</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Li K</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Sambare TD</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Jiang SY</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Shearer EJ</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Douglass NP</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Kamal RN</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Effectiveness of Preoperative
                  Antibiotics in Preventing Surgical Site Infection After Common Soft Tissue Procedures of the
                  Hand</span>. <span data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2018;476(4):664-73.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20of%20Preoperative%20Antibiotics%20in%20Preventing%20Surgical%20Site%20Infection%20After%20Common%20Soft%20Tissue%20Procedures%20of%20the%20Hand&as_occt=title&as_sauthors=%20%22K%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Athavale A</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Athavale T</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Roberts DM</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Antiemetic drugs: what to
                  prescribe and when</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Aust Prescr.</span> 2020 Apr;43(2):49-56.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Antiemetic%20drugs%3A%20what%20to%20prescribe%20and%20when&as_occt=title&as_sauthors=%20%22A%20Athavale%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Alexander P</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Visagan S</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Issa R</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Gorantla VR</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Thomas SE</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Current Trends in the Duration
                  of Anticoagulant Therapy for Venous Thromboembolism: A Systematic Review</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Cureus.</span> 2021 Oct
                23;13(10):e18992.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20Trends%20in%20the%20Duration%20of%20Anticoagulant%20Therapy%20for%20Venous%20Thromboembolism%3A%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22P%20Alexander%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Aiken LH</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Clarke SP</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Sloane DM</span>, International
                Hospital Outcomes Research Consortium. <span data-id="strong_35" class="annotation strong"
                  style="position: unset;">Hospital staffing, organization, and quality of care: cross-national
                  findings</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">International Journal for Quality in Healthcare.</span>
                2002;14(1):5-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hospital%20staffing%2C%20organization%2C%20and%20quality%20of%20care%3A%20cross-national%20findings&as_occt=title&as_sauthors=%20%22LH%20Aiken%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Weiner S</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">“I can’t afford that!”:
                  dilemmas in the care of the uninsured and underinsured</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">J Gen Intern Med.</span> 2001
                Jun;16(6):412-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%E2%80%9CI%20can%E2%80%99t%20afford%20that!%E2%80%9D%3A%20dilemmas%20in%20the%20care%20of%20the%20uninsured%20and%20underinsured&as_occt=title&as_sauthors=%20%22S%20Weiner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Barnieh L</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Clement F</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Harris A</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Blom M</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Donaldson C</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Klarenbach S</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Husereau D</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Lorenzetti D</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Manns B</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">A systematic review of
                  cost-sharing strategies used within publicly-funded drug plans in member countries of the organisation
                  for economic co-operation and development</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">PLoS One.</span> 2014 Mar
                11;9(3):e90434.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20review%20of%20cost-sharing%20strategies%20used%20within%20publicly-funded%20drug%20plans%20in%20member%20countries%20of%20the%20organisation%20for%20economic%20co-operation%20and%20development&as_occt=title&as_sauthors=%20%22L%20Barnieh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Hughes RG</span>, editor. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Patient Safety and Quality:
                  An Evidence-Based Handbook for Nurses.</span> Agency for Healthcare Research and Quality (US);
                2008.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Rodziewicz TL</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Houseman B</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Hipskind JE</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Medical Error Reduction and
                  Prevention</span>. In: StatPearls. StatPearls Publishing; 2023.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Medical%20Error%20Reduction%20and%20Prevention&as_occt=title&as_sauthors=%20%22TL%20Rodziewicz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Rhoades H</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Wenzel S</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Rice E</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Winetrobe H</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Henwood B</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">No Digital Divide? Technology
                  Use among Homeless Adults</span>. <span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">J Soc Distress Homeless.</span> 2017;26(1):73-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=No%20Digital%20Divide%3F%20Technology%20Use%20among%20Homeless%20Adults&as_occt=title&as_sauthors=%20%22H%20Rhoades%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Khanna NN</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Maindarkar MA</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Viswanathan V</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Fernandes JFE</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Paul S</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Bhagawati M</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Ahluwalia P</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Ruzsa Z</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Sharma A</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Kolluri R</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Singh IM</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Laird JR</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Fatemi M</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Alizad A</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Saba L</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Agarwal V</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Sharma A</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Teji JS</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Al-Maini M</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Rathore V</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Naidu S</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Liblik K</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Johri AM</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Turk M</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Mohanty L</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Sobel DW</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Miner M</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Viskovic K</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Tsoulfas G</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Protogerou AD</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Kitas GD</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Fouda MM</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Chaturvedi S</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Kalra MK</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Suri JS</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Economics of Artificial
                  Intelligence in Healthcare: Diagnosis vs. Treatment</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">Healthcare (Basel).</span>
                2022;10(12):2493.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Economics%20of%20Artificial%20Intelligence%20in%20Healthcare%3A%20Diagnosis%20vs.%20Treatment&as_occt=title&as_sauthors=%20%22NN%20Khanna%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Abernethy A</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Adams L</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Barrett M</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Bechtel C</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Brennan P</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Butte A</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Faulkner J</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Fontaine E</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Friedhoff S</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Halamka J</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Howell M</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Johnson K</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Long P</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> McGraw D</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Miller R</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Lee P</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Perlin J</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Rucker D</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Sandy L</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Savage L</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Stump L</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Tang P</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Topol E</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Tuckson R</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Valdes K</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">The Promise of Digital Health:
                  Then, Now, and the Future</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">NAM Perspect.</span> 2022 Jun 27;2022.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Promise%20of%20Digital%20Health%3A%20Then%2C%20Now%2C%20and%20the%20Future&as_occt=title&as_sauthors=%20%22A%20Abernethy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Galvani AP</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Parpia AS</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Foster EM</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Singer BH</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Fitzpatrick MC</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Improving the prognosis of
                  health care in the USA</span>. <span data-id="emphasis_37" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2020 Feb 15;395(10223):524-33.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Improving%20the%20prognosis%20of%20health%20care%20in%20the%20USA&as_occt=title&as_sauthors=%20%22AP%20Galvani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Akhtar M</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Van Heukelom PG</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Ahmed A</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Tranter RD</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> White E</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Shekem N</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Walz D</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Fairfield C</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Vakkalanka JP</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Mohr NM</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Telemedicine Physical
                  Examination Utilizing a Consumer Device Demonstrates Poor Concordance with In-Person Physical
                  Examination in Emergency Department Patients with Sore Throat: A Prospective Blinded Study</span>.
                <span data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Telemed J E
                  Health.</span> 2018;24(10):790-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Telemedicine%20Physical%20Examination%20Utilizing%20a%20Consumer%20Device%20Demonstrates%20Poor%20Concordance%20with%20In-Person%20Physical%20Examination%20in%20Emergency%20Department%20Patients%20with%20Sore%20Throat%3A%20A%20Prospective%20Blinded%20Study&as_occt=title&as_sauthors=%20%22M%20Akhtar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Sprowls GR</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Brown JC</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Robin BN</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">The Shoulder Telehealth
                  Assessment Tool in Transition to Distance Orthopedics</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">Arthrosc Tech.</span> 2020 Oct
                24;9(11):e1673-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Shoulder%20Telehealth%20Assessment%20Tool%20in%20Transition%20to%20Distance%20Orthopedics&as_occt=title&as_sauthors=%20%22GR%20Sprowls%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Woodcock EW</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Barriers to and Facilitators of
                  Automated Patient Self-scheduling for Health Care Organizations: Scoping Review</span>. <span
                  data-id="emphasis_40" class="annotation emphasis" style="position: unset;">J Med Internet Res.</span>
                2022 Jan 11;24(1):e28323.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Barriers%20to%20and%20Facilitators%20of%20Automated%20Patient%20Self-scheduling%20for%20Health%20Care%20Organizations%3A%20Scoping%20Review&as_occt=title&as_sauthors=%20%22EW%20Woodcock%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Hipp R</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Abel E</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Weber RJ</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">A Primer on Clinical
                  Pathways</span>. <span data-id="emphasis_41" class="annotation emphasis" style="position: unset;">Hosp
                  Pharm.</span> 2016 May;51(5):416-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Primer%20on%20Clinical%20Pathways&as_occt=title&as_sauthors=%20%22R%20Hipp%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Cadel L</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Guilcher SJT</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Kokorelias KM</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Sutherland J</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Glasby J</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Kiran T</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Kuluski K</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Initiatives for improving
                  delayed discharge from a hospital setting: a scoping review</span>. <span data-id="emphasis_42"
                  class="annotation emphasis" style="position: unset;">BMJ Open.</span> 2021 Feb
                11;11(2):e044291.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Initiatives%20for%20improving%20delayed%20discharge%20from%20a%20hospital%20setting%3A%20a%20scoping%20review&as_occt=title&as_sauthors=%20%22L%20Cadel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Kierans C</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Padilla-Altamira C</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Garcia-Garcia G</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Ibarra-Hernandez M</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Mercado FJ</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">When health systems are
                  barriers to health care: challenges faced by uninsured Mexican kidney patients</span>. <span
                  data-id="emphasis_43" class="annotation emphasis" style="position: unset;">PLoS One.</span>
                2013;8(1):e54380.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=When%20health%20systems%20are%20barriers%20to%20health%20care%3A%20challenges%20faced%20by%20uninsured%20Mexican%20kidney%20patients&as_occt=title&as_sauthors=%20%22C%20Kierans%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Shenolikar RA</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Balkrishnan R</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Hall MA</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">How patient-physician
                  encounters in critical medical situations affect trust: results of a national survey</span>. <span
                  data-id="emphasis_44" class="annotation emphasis" style="position: unset;">BMC Health Serv Res.</span>
                2004;4(1):24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20patient-physician%20encounters%20in%20critical%20medical%20situations%20affect%20trust%3A%20results%20of%20a%20national%20survey&as_occt=title&as_sauthors=%20%22RA%20Shenolikar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Devers KJ</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Casalino LP</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Rudell LS</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Stoddard JJ</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Brewster LR</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Lake TK</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Hospitals’ negotiating leverage
                  with health plans: how and why has it changed?</span><span data-id="emphasis_45"
                  class="annotation emphasis" style="position: unset;">Health Serv Res.</span>
                2003;38(1):419-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hospitals%E2%80%99%20negotiating%20leverage%20with%20health%20plans%3A%20how%20and%20why%20has%20it%20changed%3F&as_occt=title&as_sauthors=%20%22KJ%20Devers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Bes RE</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> van den Berg B</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Ranking sources of hospital
                  quality information for orthopedic surgery patients: consequences for the system of managed
                  competition</span>. <span data-id="emphasis_46" class="annotation emphasis"
                  style="position: unset;">Patient.</span> 2013;6(2):75-80.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ranking%20sources%20of%20hospital%20quality%20information%20for%20orthopedic%20surgery%20patients%3A%20consequences%20for%20the%20system%20of%20managed%20competition&as_occt=title&as_sauthors=%20%22RE%20Bes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Jacobs K</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Patient Satisfaction by
                  Design</span>. <span data-id="emphasis_47" class="annotation emphasis" style="position: unset;">Semin
                  Hear.</span> 2016 Nov;37(4):316-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20Satisfaction%20by%20Design&as_occt=title&as_sauthors=%20%22K%20Jacobs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Poongothai S</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Anjana RM</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Aarthy R</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Unnikrishnan R</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Narayan KMV</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Ali MK</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Karkuzhali K</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Mohan V</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Strategies for participant
                  retention in long term clinical trials: A participant -centric approaches</span>. <span
                  data-id="emphasis_48" class="annotation emphasis" style="position: unset;">Perspect Clin Res.</span>
                2023;14(1):3-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Strategies%20for%20participant%20retention%20in%20long%20term%20clinical%20trials%3A%20A%20participant%20-centric%20approaches&as_occt=title&as_sauthors=%20%22S%20Poongothai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_237" class="annotation" style="position: unset;"> McCann J</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Ridgers ND</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Carver A</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Thornton LE</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Teychenne M</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">Effective recruitment and
                  retention strategies in community health programs</span>. <span data-id="emphasis_49"
                  class="annotation emphasis" style="position: unset;">Health Promot J Austr.</span>
                2013;24(2):104-110.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effective%20recruitment%20and%20retention%20strategies%20in%20community%20health%20programs&as_occt=title&as_sauthors=%20%22J%20McCann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Eakin MN</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">The Importance of Successful
                  Recruitment and Retention Strategies to Reduce Health Disparities</span>. <span data-id="emphasis_50"
                  class="annotation emphasis" style="position: unset;">Ann Am Thorac Soc.</span>
                2018;15(10):1138-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Importance%20of%20Successful%20Recruitment%20and%20Retention%20Strategies%20to%20Reduce%20Health%20Disparities&as_occt=title&as_sauthors=%20%22MN%20Eakin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Da1fe809b-479b-434f-bd2d-299797ce0a68%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1529</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01056</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">April 4, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a1fe809b-479b-434f-bd2d-299797ce0a68&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=a1fe809b-479b-434f-bd2d-299797ce0a68&type=pdf&name=JBJS.23.01056.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=a1fe809b-479b-434f-bd2d-299797ce0a68&type=pdf&name=JBJS.23.01056.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_56"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_57" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/H961" target="_blank" data-id="link_6"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/H961</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a1fe809b-479b-434f-bd2d-299797ce0a68&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=a1fe809b-479b-434f-bd2d-299797ce0a68&type=zip&name=JBJS.23.01056.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Keith Kenter, MD, ScD(Hon), FAOA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Keith.kenter@wmed.edu" class=""
                    style="position: unset;">Keith.kenter@wmed.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2767-8351" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2767-8351</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Karen Bovid, MD, FAOA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3635-4973" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3635-4973</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">E. Brooke Baker, MD, JD, MBA<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5550-5052" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5550-5052</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eric Carson, MD, FAOA<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5376-5766" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5376-5766</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Deana Mercer, MD, FAOA<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3641-7580" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3641-7580</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Western Michigan University Homer Styker
                M.D. School of Medicine, Kalamazoo, Michigan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Anesthesiology and Critical Care Medicine, University of New
                Mexico, Albuquerque, New Mexico</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Harlem Hospital Center, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Hospital for Special Surgery, Weill Cornell Medical College, New York,
                NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Orthopaedics and Rehabilitation, University of New Mexico,
                Albuquerque, New Mexico</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 26452.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
