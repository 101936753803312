import type { Article } from "../../../../../types";

export const Article662: Article = {
	id: 662,
	rsuiteId: "372924e6-9552-45ce-82ec-7a4e72ee0c1f",
	type: "scientific article",
	title: "Spondylolisthesis in Young Patients in a Large National Cohort",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=372924e6-9552-45ce-82ec-7a4e72ee0c1f&type=jpeg&name=JBJS.23.01242f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Spondylolisthesis in Young Patients in a Large National
                Cohort</div>
              <div class="text subtitle" style="position: unset;">Reoperation Rate Depends on Surgical Approach</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Paal K.
                      Nilssen, BA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Nakul
                      Narendran, BA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Background:</span> The current literature investigating surgical
                        treatments for lumbar spondylolisthesis in adolescent patients is limited by small sample sizes.
                        There are high reoperation rates, and posterior interbody fusion has not been reported to help.
                        The current study aimed to utilize a nationwide database to investigate outcomes of spinal
                        fusion for spondylolisthesis in young patients.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Methods:</span> The PearlDiver database was queried for patients
                        &lt;21 years old who had undergone lumbar spinal fusion for spondylolisthesis between 2010 and
                        2020. Patients were divided into 4 cohorts based on surgical approach: (1) posterior spinal
                        fusion with posterior instrumentation (PSF), (2) posterior spinal fusion with posterior
                        instrumentation plus interbody (PSF+I), (3) anterior spinal fusion without posterior
                        instrumentation (ASF), and (4) anterior spinal fusion plus posterior instrumentation (A+PSF).
                        Patients with &lt;2 years of follow-up were excluded. The primary outcome was reoperation.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Results:</span> Of 33,945 patients with spondylolisthesis, 578 (1.7%)
                        underwent lumbar spinal fusion: 236 (40.8%) had PSF, 219 (37.9%) had PSF+I, 66 (11.4%) had ASF,
                        and 57 (9.9%) had A+PSF. The mean age was 16.5 ± 1.1 years, and the mean follow-up was 5.4 ± 2.9
                        years. A higher percentage of girls underwent surgery compared with boys (2.0% versus 1.4%).
                        Survival analysis using all-cause reoperation as the end point demonstrated an overall 5-year
                        reoperation-free survival rate of 85.5% (95% confidence interval [CI]: 82.5% to 88.6%). The
                        overall reoperation rate within 5 years was significantly different depending on the approach,
                        with A+PSF being the lowest at 7.0% (PSF = 11.9%, PSF+I = 10.5%, and ASF = 31.8%).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_43" class="annotation strong"
                          style="position: unset;">Conclusions:</span> This is the largest reported series of
                        spondylolisthesis surgery in young people. The lowest rate of revisions within 5 years was for a
                        combined approach of A+PSF. The 5-year risk of reoperation of 31.8% for a stand-alone ASF
                        appeared to be unacceptably high compared with other approaches, and was over 4 times higher
                        than A+PSF (7.0%). Consistent with previous clinical series, the addition of an interbody fusion
                        to a PSF did not decrease the reoperation rate and did not appear to offer any advantages to a
                        PSF alone.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_44" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Spondylolisthesis is a common source of low back pain in
                  young patients, most commonly occurring in the lumbar spine<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Prominent risk factors include a dysplastic pars interarticularis (developmental), a pars fracture
                  (isthmic), and a tumor of the posterior spinal elements<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>. In
                  the pediatric and adolescent populations, isthmic and dysplastic spondylolisthesis are the most common
                  etiologies<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a>. Most children are asymptomatic, but when symptoms are
                  present, low back pain and radicular pain are common<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>.
                  Previous epidemiology studies have shown that children and adolescents are more likely to be affected
                  at L5-S1 (71% to 95%), followed by L4-L5 (5% to 15%)<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">7</span></a>.
                  Nonsurgical measures are classically the first-line management for low-grade spondylolisthesis, while
                  surgical intervention is recommended for persistent symptoms, high-grade spondylolisthesis, or
                  neurological deficits<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">8</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The goal of surgical intervention in spondylolisthesis is
                  decompression of the neural elements and fusion of the slipped vertebral levels<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">9</span></a>. Common surgical techniques include posterior lumbar fusion
                  and instrumentation, transforaminal and posterior lumbar interbody fusion, anterior lumbar interbody
                  fusion, and a combination of anterior and posterior spinal fusion techniques<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">11</span></a>.
                  Less commonly, the Bohlman method of transsacral fibular strut-grafting has been used for high-grade
                  spondylolisthesis<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">12</span></a>. To
                  date, there remains controversy regarding the levels of spinal fusion, the surgical technique, and the
                  surgical instrumentation that is utilized<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">13</span></a>.
                  Given the low prevalence of surgical management in this population, the majority of published papers
                  have small sample sizes, limiting the scope for which outcomes and risk factors can be evaluated.
                  Furthermore, to our knowledge, yearly nationwide trends of surgically managed spondylolisthesis in
                  young patients have not been previously reported.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Considering these limitations, we sought to generate a
                  large-scale assessment of surgical approaches for patients &lt;21 years of age with lumbar
                  spondylolisthesis, and to compare reoperation rates over time using the PearlDiver Patient Records
                  Database (PearlDiver Technologies). The primary outcome was reoperation. We hypothesized that
                  stand-alone anterior spinal fusion for spondylolisthesis in young patients results in higher
                  reoperation rates compared with posterior techniques.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Source</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">We performed a retrospective study utilizing the
                  PearlDiver database. PearlDiver is one of the largest databases that gathers data from a diverse
                  sample of medical centers representing a range of patient demographics and surgeon expertise for
                  &gt;161 million patients across all payer types (apart from active military and Kaiser Permanente
                  health plans) in the U.S. All provider claims are collected via a clearinghouse, which collects claims
                  across the payer types without discrimination. All of the claims are required to have annual,
                  independent, third-party audits of data validity and reliability. Additionally, all of the data are
                  deidentified and are compliant with the U.S. Health Insurance Portability and Accountability Act
                  (HIPAA). Consequently, this study did not require institutional review board approval.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Population</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">All patients who were &lt;21 years of age with a diagnosis
                  of isolated lumbar spondylolisthesis and had undergone spinal fusion between 2010 and 2020 were
                  included. In order to avoid potentially confounding variables, patients were excluded if they also had
                  a diagnosis of scoliosis or kyphosis. The analysis was restricted to patients with continuous
                  insurance for at least 1 year before and 2 years after their index date to minimize the number of
                  patients lost to insurance discontinuation. The database was queried for Current Procedural
                  Terminology (CPT) codes and International Classification of Diseases, Ninth and Tenth Revision (ICD-9
                  and ICD-10) codes. The ICD-9 codes were used to identify all cases prior to December 2014, and the
                  ICD-10 codes were used for cases after December 2014 (see Appendix Supplemental Table 1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Patients were divided into 4 cohorts based on the surgical
                  approach: (1) posterior spinal fusion with posterior instrumentation (PSF), (2) posterior spinal
                  fusion with posterior instrumentation plus interbody (PSF+I), (3) anterior spinal fusion without
                  posterior instrumentation (ASF), and (4) anterior spinal fusion plus posterior instrumentation
                  (A+PSF). The 4 surgical cohorts were mutually exclusive. Patients who underwent the Bohlman method
                  were excluded due to the small sample size (n &lt; 11). Of note, to protect patient privacy,
                  frequencies of ≤10 patients in any given group are represented by “&lt;11” in the PearlDiver database
                  and in our data. Follow-up duration was calculated from the day of the index surgery to the last
                  encounter recorded in the database. All of the patients had a minimum follow-up of 2 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcome
                Measures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Demographic characteristics included age, sex, and U.S.
                  region as defined by the U.S. Census Bureau (Midwest, Northeast, South, and West). Postoperative
                  outcomes assessed within 90 days from the index surgery included all-cause readmission rates and
                  complications, such as surgical site infection (defined as an infection following a procedure and
                  located at the incision site or adjacent to the surgical site), hematoma, wound disruption
                  (dehiscence), and neurological injury (e.g., spinal cord injury, lumbosacral plexus injury, lumbar
                  nerve root injury, dural tear, cauda equina injury, and lower-extremity paralysis). Specific billing
                  codes that were used for each complication cohort are listed in Appendix Supplemental Table 2.
                  Postoperative pseudoarthrosis, revision surgery, subsequent decompression surgery, and all-cause
                  return to the operating room (OR) for subsequent spine surgery were assessed within 1, 2, and 5 years
                  from the index surgery (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). A
                  10-day washout period was applied to the query code following all of the index surgeries before
                  querying for reoperations because of the possibility of counting planned, staged multiday procedures
                  as a reoperation. Of note, decompression billing is often reported in conjunction with other
                  procedures. Consequently, decompression, as described in this article, refers to an isolated
                  decompression surgery or a decompression that was performed on a vertebral level other than where the
                  fusion took place.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Postoperative Outcomes and Complications within 1, 2,
                          and 5 Years from the Index Surgery by Surgical Approach<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 986.424px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.788969);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">PSF (N =
                          236 [40.8%])</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">PSF+I (N =
                          219 [37.9%])</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ASF (N =
                          66 [11.4%])</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">A+PSF (N =
                          57 [9.9%])</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          578)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications within 1 yr
                          <span data-id="emphasis_41" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Revision</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (8.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (21.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (6.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Decompression</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.60</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pseudoarthrosis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (3.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.27</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> All-cause return to
                          OR</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (8.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (21.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (8.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications within 2 yr
                          <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Revision</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (10.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (6.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (28.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (5.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60
                          (10.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Decompression</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (3.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.53</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pseudoarthrosis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (6.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (5.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> All-cause return to
                          OR</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (10.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (7.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (28.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (7.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (11.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications within 5 yr
                          <span data-id="emphasis_43" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Revision</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (11.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (10.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (31.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (7.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74
                          (12.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Decompression</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (4.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.66</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pseudoarthrosis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (7.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (6.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (7.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> All-cause return to
                          OR</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (11.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (10.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (31.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (7.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76
                          (13.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.05</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">PSF = posterior spinal fusion with posterior instrumentation, PSF+ I =
                        posterior spinal fusion with posterior instrumentation plus interbody, ASF = anterior spinal
                        fusion without posterior instrumentation, A+PSF = anterior spinal fusion plus posterior
                        instrumentation, and OR = operating room.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Statistical analyses were performed using R (The R Project
                  for Statistical Computing) integrated within the PearlDiver software. Significance was defined as p
                  &lt; 0.05. Patient demographic data and short-term outcomes were compared using descriptive
                  statistics, with chi-square analysis for categorical variables and 1-way analysis of variance (ANOVA)
                  for continuous variables. Annual surgical rates were calculated by dividing the annual incidence of
                  surgery by the corresponding annual incidence of spondylolisthesis. A Kaplan-Meier log-rank survival
                  test was used to assess the all-cause reoperation probability within 5 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Our search yielded 41,819 patients &lt;21 years of age who
                  had been diagnosed with spondylolisthesis. Of those patients, 7,874 were excluded due to a concomitant
                  diagnosis of scoliosis or kyphosis, which had a surgical incidence of 4.7% (n = 367). Among the
                  remaining 33,945 patients, 578 (1.7%) were treated with surgery and comprised our sample; 52.5% (n =
                  17,831) of all of the spondylolisthesis diagnoses were in female patients, and 60.2% (n = 348) of
                  those treated with surgery (p &lt; 0.05) were female. The mean age (and standard deviation) at the
                  time of surgery was 16.5 ± 1.1 years (range, 6 to 20 years). Of those who underwent surgical
                  treatment, 236 (40.8%) underwent PSF, 219 (37.9%) underwent PSF+I, 66 (11.4%) underwent ASF, and 57
                  (9.9%) underwent A+PSF. The mean follow-up time was 5.4 ± 2.9 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">A trend analysis between 2010 and 2019 revealed that the
                  incidence of surgical treatment had steadily increased from 0.7% (7.4 of 1,000; 95% confidence
                  interval [CI]: 5.6 to 11.0) in 2010 to 3.1% (31.4 of 1,000; 95% CI: 22.0 to 43.5) in 2019 (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). The proportion of each surgical approach by year remained
                  relatively stable over time (p = 0.56) (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). Most
                  patients treated with surgery were ≥12 years of age (96.0%, n = 555). A positive association was
                  observed between age and incidence of surgical treatment (0.8% in 12-year-olds and 3.2% in
                  20-year-olds) (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>).
                  Overall, the surgical cohorts did not differ by age, geographic region, or medical comorbidities (<a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01242f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=372924e6-9552-45ce-82ec-7a4e72ee0c1f&type=jpeg&name=JBJS.23.01242f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;">Percentage of patients &lt;21 years of age
                          diagnosed with spondylolisthesis who underwent surgery, by year.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01242f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=372924e6-9552-45ce-82ec-7a4e72ee0c1f&type=jpeg&name=JBJS.23.01242f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">Percentage of utilization of each surgical
                          approach, by year. PSF = posterior spinal fusion with posterior instrumentation, PSF+I =
                          posterior spinal fusion with posterior instrumentation plus interbody, ASF = anterior spinal
                          fusion without posterior instrumentation, and A+PSF = anterior spinal fusion plus posterior
                          instrumentation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01242f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=372924e6-9552-45ce-82ec-7a4e72ee0c1f&type=jpeg&name=JBJS.23.01242f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Percentage of patients diagnosed with
                          spondylolisthesis who underwent surgical treatment, by age. Less than 0.1% of patients &lt;12
                          years of age underwent surgical treatment and, therefore, were excluded from the figure.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Patient Demographics by Surgical Approach<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 726.037px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.854545);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">PSF (N =
                          236 [40.8%])</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">PSF+I (N =
                          219 [37.9%])</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ASF (N =
                          66 [11.4%])</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">A+PSF (N =
                          57 [9.9%])</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          578)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex <span
                            data-id="emphasis_44" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101
                          (42.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94
                          (42.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (22.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (35.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">230
                          (39.8)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">135
                          (57.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">125
                          (57.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51
                          (77.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37
                          (64.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">348
                          (60.2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.8 ±
                          3.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.5 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.1 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.9 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.5 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.11</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CCI<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 ±
                          1.02</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5 ±
                          0.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 ±
                          0.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 ±
                          0.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S. region <span
                            data-id="emphasis_46" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.32</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Midwest</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86
                          (36.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83
                          (37.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (31.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (29.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">207
                          (35.8)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Northeast</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (11.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (16.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83
                          (14.4)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> South</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90
                          (38.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68
                          (31.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (34.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (45.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">207
                          (35.8)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> West</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (14.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (15.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (18.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81
                          (14.0)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">PSF = posterior spinal fusion with posterior instrumentation, PSF+ I =
                        posterior spinal fusion with posterior instrumentation plus interbody, ASF = anterior spinal
                        fusion without posterior instrumentation, A+PSF = anterior spinal fusion plus posterior
                        instrumentation, and CCI = Charlson Comorbidity Index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">In the 90-day postoperative period, patients experienced a
                  low rate of surgical site infections (n = 15, 2.6%), dehiscence (n = 13, 2.2%), hematoma (n &lt; 11,
                  &lt;2.4%), and neurological injury (n &lt; 11, &lt;2.4%). Acute postoperative complications did not
                  differ significantly among surgical approaches. The overall readmission rate was 6.4%, which did not
                  vary significantly among surgical approaches (p = 0.41).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The overall reoperation rate within 5 years was 13.1%. The
                  overall risk of reoperation within 5 years was highest in the ASF group (31.8%) (p &lt; 0.05). The
                  reoperation risk within 5 years did not differ among the 3 posterior surgical approaches (PSF = 11.9%,
                  PSF+I = 10.5%, and A+PSF = 7.0%; p = 0.83). Patients who underwent ASF had significantly higher
                  reoperation rates within 1 year (21.2%), 2 years (28.8%), and 5 years (31.8%) than those who underwent
                  surgery with the other 3 surgical approaches (p &lt; 0.05). The other significant difference was
                  patients who underwent PSF+I: they also had a higher reoperation rate within 1 year (8.1%) when
                  compared with patients who underwent the other posterior approaches (p &lt; 0.05). Rates of revision
                  surgery, pseudoarthrosis, subsequent decompression surgery, and all-cause return to the OR are
                  presented in <a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>. Of the
                  80 patients who returned to the OR, removal of instrumentation (34, 42.5%), insertion of
                  instrumentation (25, 31.3%), decompression surgery (25, 31.3%), insertion of a biomechanical mesh or
                  cage (18, 22.5%), and exploration of the spinal fusion (13, 16.3%) were the most common procedures
                  performed. The mean time between the index surgery and return to the OR was 1.6 ± 1.6 years. A higher
                  percentage of girls with spondylolisthesis underwent subsequent surgery (55 of 348 [15.8%]) compared
                  with boys (21 of 195 [10.8%]; p = 0.01). Further analysis revealed that girls were 2.1 (95% CI: 1.3 to
                  3.6) times more likely to undergo reoperation than boys. Kaplan-Meier curves using all-cause
                  reoperation as the end point demonstrated an overall 5-year reoperation-free survival rate of 85.5%
                  (95% CI: 82.5% to 88.6%).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Several studies have found a high rate of postoperative
                  complications in the surgical management of spondylolisthesis in young patients, including
                  neurological injury, implant failure, infection, and reoperation<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">19</span></a>. However, large-scale data evaluating these complications
                  have still been lacking. Furthermore, there exists a paucity of literature evaluating discrete
                  surgical techniques and their respective impacts on both short- and long-term clinical outcomes. The
                  current study is &gt;8 times larger than the next-largest studies investigating long-term outcomes
                  among surgical approaches in young patients with spondylolisthesis<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">21</span></a>. Our findings show that surgical treatment was associated
                  with low 90-day complication rates and a 13.1% reoperation rate within 5 years. Patients who underwent
                  ASF had the highest reoperation rate within 5 years (31.8%), while patients who underwent the
                  posterior procedures had lower reoperation rates that did not differ significantly from one another
                  (PSF = 11.9%, PSF+I = 10.5%, and A+PSF = 7.0%).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The existing literature investigating the prevalence of
                  spondylolisthesis in young patients and reoperations has also faced noteworthy sample-size
                  limitations. Differences between the sexes have not been thoroughly described in the epidemiologic
                  data that do exist. In our study, more girls underwent surgical treatment than boys (2.0% versus
                  1.4%). In 1984, Fredrickson et al. reported that girls with spondylolysis are more likely to progress
                  to spondylolisthesis than boys<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">22</span></a>.
                  Later studies suggested that girls may face this elevated risk due to higher grades of slippage<a
                    href="" data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_14" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_14" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">23</span></a>. Unfortunately, we were unable to obtain slip angles from
                  the PearlDiver database and thus could not investigate the association between patient sex and slip
                  angle.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Several techniques for the surgical treatment of
                  spondylolisthesis have been described; however, the optimal approach in young patients remains
                  debatable. Over the last few decades, some studies have suggested that posterior-only approaches yield
                  better outcomes than anterior or circumferential approaches<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_15" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">24</span></a>. Others have published data indicating that circumferential
                  approaches are superior<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">26</span></a>. Our trend analysis during the 10-year study period
                  revealed that the overall proportion of each surgical approach has not changed, with the PSF and PSF+I
                  approaches being more common than the ASF and A+PSF approaches (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). Additionally, a positive association was observed between age
                  and individuals receiving surgery (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>). It
                  should be noted that the incidence of surgery from the year 2020 was not included in our trend
                  analysis due to a lack of sufficient data, likely secondary to restrictions for elective surgeries
                  that were imposed during the COVID-19 pandemic. To our knowledge, only 1 previous study has
                  investigated broad trends in spinal fusion approaches for spondylolisthesis in young patients<a
                    href="" data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">13</span></a>. In 2018, Rumalla et al. utilized the Kids’ Inpatient
                  Database (KID), which is only sampled every 3 years, to examine data from the 4 most recent years
                  (2003, 2006, 2009, and 2012) to compare 3 spinal fusion approaches<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">13</span></a>. Our findings from the PearlDiver database, which provides
                  a more updated and granular analysis, are concordant in that the distribution of surgical approaches
                  has not changed. Perhaps the absence of change may be attributed to a lack of large-scale data
                  supporting the superiority of one technique over the others.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Our overall reoperation rate of 13.1% (76 of 578) within 5
                  years aligns with previous studies, which described ranges from 0.9% to 42%<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">28</span></a>. The large range is likely attributable to the previously
                  noted small sample sizes, single-center studies, and disease prevalence. Smaller studies (e.g.,
                  Lundine et al.<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">18</span></a>)
                  have reported a 23% reoperation rate among 24 patients, while larger, multicenter studies (e.g.,
                  Nielsen et al.<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">19</span></a>)
                  have documented a higher rate of 40% among 50 patients. Importantly, in our study we found that
                  patients undergoing ASF had the highest risk of subsequent surgery compared with PSF and A+PSF
                  surgical approaches, which did not differ from each other. This finding is congruent with the
                  pediatric<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_22" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">30</span></a> and adult literature<a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">31</span></a>. A 2017 Scoliosis Research Society (SRS) Evidence Based
                  Medicine Committee review recommended instrumented and circumferential spinal fusion for the lowest
                  nonunion risk<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">30</span></a>.
                  Notably, data supporting these approaches come from single-center studies<a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">32</span></a>. Our national data support these findings. Additionally, we
                  found that adding an interbody fusion during PSF did not appear to improve the rates of pseudarthrosis
                  and reoperation. A number of studies have compared PSF with or without interbody fusion, but the
                  scientific evidence in favor of interbody fusion has not been reported to help<a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">33</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">In our clinical practice, stand-alone ASFs in active young
                  patients are not performed. An A+PSF is preferred because adding posterior instrumentation exposes the
                  patient to relatively small risk while greatly increasing the rigidity of the construct<a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">34</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">35</span></a>. Additionally, it has been our experience, consistent with
                  Nielsen et al.<a href="" data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">19</span></a>,
                  that the addition of an interbody fusion in young people with spondylolisthesis increases the risk of
                  postoperative nerve root issues while providing little benefit in terms of spinal fusion.
                  Consequently, we have stopped performing PSF with an interbody fusion and prefer A+PSF.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">In this study, we observed a 90-day postoperative
                  complication rate of &lt;3% each with respect to surgical site infection, dehiscence, hematoma, dural
                  tear, cauda equina syndrome, paralysis, and neurological injury. Of the complications investigated,
                  neurological deficits have received the most attention. It is important to note that the PearlDiver
                  database cannot capture transient or minor neurological deficits, such as motor strength and
                  sensitivity deficits. Reports from the SRS have noted that the overall neurological deficit rate in
                  pediatric lumber spinal deformity surgeries ranges from 0.71% to 0.94%<a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">36</span></a>. It is hypothesized that acute postoperative neurological
                  deficits are likely due to the stretching of the L5 nerve root caused by anterior translation rather
                  than due to the correction of lumbosacral kyphosis<a href="" data-id="citation_reference_60"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">37</span></a>.
                  Studies investigating postoperative neurological deficits report nearly full neurological recovery
                  between 2 and 18 months postoperatively<a href="" data-id="citation_reference_61"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_63" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">38</span></a>. This adds assurance that although a few patients may
                  experience postoperative neurological deficits, they are likely to make a full recovery. Persistent
                  neurological injuries should be investigated with computed tomography or magnetic resonance imaging to
                  determine any location of malpositioned instrumentation or spinal cord compression<a href=""
                    data-id="citation_reference_65" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">28</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Although database research is useful for obtaining data
                  for diseases and treatments with low incidences, such as spondylolisthesis in young patients, it is
                  limited by the amount of detail that is provided. Namely, we were unable to evaluate radiographic
                  data, operative reports, pain scores, slip grades, surgeon experience, hospital volume, technical
                  expertise, and indications for surgery, all of which are valuable to inform the decision-making
                  process for treatment, the indication for surgery, and the reason for reoperation in individual
                  patients. Additionally, we were unable to differentiate among the subtypes of spondylolisthesis
                  (namely, dysplastic, isthmic, degenerative, posttraumatic, and pathologic). The current ICD diagnosis
                  codes for spondylolisthesis refer to the affected regions of the spine rather than the actual
                  subtypes. Consistent with other studies and our clinical experience, it is likely that the majority of
                  our patients had isthmic or posttraumatic spondylolisthesis or spondylolisthesis caused by overuse
                  athletic injuries, but this could not be ascertained from the database<a href=""
                    data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">39</span></a>. Furthermore, the use of postoperative bracing or casting,
                  although not as common since the emergence of superior internal fixation techniques, could not be
                  accurately captured within the database due to inconsistencies in billing during the 90-day global
                  periods. To ensure that reoperation rates were accurate, we utilized only lumbar-specific CPT codes
                  and avoided add-on codes that could double-count the number of reoperations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this is the largest reported series of
                  spondylolisthesis surgery in young people. The rate of reoperation within 5 years after
                  spondylolisthesis surgery was 13.1%. The 5-year reoperation rate of 31.8% after a stand-alone ASF
                  appears unacceptably high compared with the other approaches and is &gt;4 times higher than the 7.0%
                  reoperation rate after an A+PSF. Consistent with previous clinical series, the addition of an
                  interbody fusion to a PSF did not decrease the reoperation rate and did not appear to offer any
                  advantages over a PSF alone.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I257" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I257</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Hu SS</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Tribus CB</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Diab M</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Ghanayem AJ</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Spondylolisthesis and
                  spondylolysis</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Instr Course Lect.</span> 2008;57:431-45.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spondylolisthesis%20and%20spondylolysis&as_occt=title&as_sauthors=%20%22SS%20Hu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Hammerberg KW</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">New concepts on the pathogenesis
                  and classification of spondylolisthesis</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2005 Mar 15;30(6):S4-11.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=New%20concepts%20on%20the%20pathogenesis%20and%20classification%20of%20spondylolisthesis&as_occt=title&as_sauthors=%20%22KW%20Hammerberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Randall RM</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Silverstein M</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Goodwin R</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Review of Pediatric
                  Spondylolysis and Spondylolisthesis</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Sports Med Arthrosc Rev.</span> 2016 Dec;24(4):184-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Review%20of%20Pediatric%20Spondylolysis%20and%20Spondylolisthesis&as_occt=title&as_sauthors=%20%22RM%20Randall%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Attiah MA</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Macyszyn L</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Cahill PJ</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Management of spondylolysis and
                  spondylolisthesis in the pediatric population: A review</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Semin Spine Surg.</span>
                2014;26(4):230-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20spondylolysis%20and%20spondylolisthesis%20in%20the%20pediatric%20population%3A%20A%20review&as_occt=title&as_sauthors=%20%22MA%20Attiah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Standaert CJ</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Herring SA</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Halpern B</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> King O</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Spondylolysis</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Phys Med Rehabil Clin N
                  Am.</span> 2000 Nov;11(4):785-803.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spondylolysis&as_occt=title&as_sauthors=%20%22CJ%20Standaert%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Standaert CJ</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Herring SA</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Spondylolysis: a critical
                  review</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Br J
                  Sports Med.</span> 2000 Dec;34(6):415-22.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spondylolysis%3A%20a%20critical%20review&as_occt=title&as_sauthors=%20%22CJ%20Standaert%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Hirano K</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Kawakami N</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Yukawa Y</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Kato F</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Hachiya Y</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Kanemura T</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Kamiya M</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Deguchi M</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Ito Z</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Wakao N</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Ando K</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Tauchi R</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Muramoto A</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Ishiguro N</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Surgically treated cases of
                  lumbar spondylolysis and isthmic spondylolisthesis: a multicenter study</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Spinal Disord Tech.</span>
                2015 Jun;28(5):193-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgically%20treated%20cases%20of%20lumbar%20spondylolysis%20and%20isthmic%20spondylolisthesis%3A%20a%20multicenter%20study&as_occt=title&as_sauthors=%20%22K%20Hirano%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Scheepers MS</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Streak Gomersall J</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Munn Z</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">The effectiveness of surgical
                  versus conservative treatment for symptomatic unilateral spondylolysis of the lumbar spine in
                  athletes: a systematic review</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">JBI Database System Rev Implement Rep.</span> 2015 Apr
                17;13(3):137-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effectiveness%20of%20surgical%20versus%20conservative%20treatment%20for%20symptomatic%20unilateral%20spondylolysis%20of%20the%20lumbar%20spine%20in%20athletes%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22MS%20Scheepers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Tarpada SP</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Kim D</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Levine NL</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Morris MT</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Cho W</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Comparing Surgical Treatments
                  for Spondylolysis: Review on Current Research</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Clin Spine Surg.</span> 2021 Oct
                1;34(8):276-85.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparing%20Surgical%20Treatments%20for%20Spondylolysis%3A%20Review%20on%20Current%20Research&as_occt=title&as_sauthors=%20%22SP%20Tarpada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Rivollier M</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Marlier B</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Kleiber JC</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Eap C</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Litre CF</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Surgical treatment of
                  high-grade spondylolisthesis: Technique and results</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Orthop.</span> 2020 Aug
                25;22:383-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20treatment%20of%20high-grade%20spondylolisthesis%3A%20Technique%20and%20results&as_occt=title&as_sauthors=%20%22M%20Rivollier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Sharma M</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Jain N</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Wang D</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Ugiliweneza B</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Boakye M</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Trends of Different Surgical
                  Approaches in Patients With Single-Level Lumbar Isthmic Spondylolisthesis: A National Registry
                  Analysis</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Cureus.</span> 2023 Jan 25;15(1):e34194.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20of%20Different%20Surgical%20Approaches%20in%20Patients%20With%20Single-Level%20Lumbar%20Isthmic%20Spondylolisthesis%3A%20A%20National%20Registry%20Analysis&as_occt=title&as_sauthors=%20%22M%20Sharma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Bohlman HH</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Cook SS</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">One-stage decompression and
                  posterolateral and interbody fusion for lumbosacral spondyloptosis through a posterior approach.
                  Report of two cases</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1982 Mar;64(3):415-8.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=480331" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Rumalla K</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Yarbrough CK</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Pugely AJ</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Dorward IG</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Spinal Fusion for Pediatric
                  Spondylolisthesis: National Trends, Complications, and Short-Term Outcomes</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Neurosurgery.</span> 2018
                May 1;82(5):701-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spinal%20Fusion%20for%20Pediatric%20Spondylolisthesis%3A%20National%20Trends%2C%20Complications%2C%20and%20Short-Term%20Outcomes&as_occt=title&as_sauthors=%20%22K%20Rumalla%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Maurice HD</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Morley TR</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Cauda equina lesions following
                  fusion in situ and decompressive laminectomy for severe spondylolisthesis. Four case reports</span>.
                <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 1989 Feb;14(2):214-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cauda%20equina%20lesions%20following%20fusion%20in%20situ%20and%20decompressive%20laminectomy%20for%20severe%20spondylolisthesis.%20Four%20case%20reports&as_occt=title&as_sauthors=%20%22HD%20Maurice%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Lykissas MG</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Aichmair A</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Widmann R</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Sama AA</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Paresis of the L5 nerve root
                  after reduction of low-grade lumbosacral dysplastic spondylolisthesis: a case report</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Pediatr Orthop B.</span>
                2014 Sep;23(5):461-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Paresis%20of%20the%20L5%20nerve%20root%20after%20reduction%20of%20low-grade%20lumbosacral%20dysplastic%20spondylolisthesis%3A%20a%20case%20report&as_occt=title&as_sauthors=%20%22MG%20Lykissas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Molinari RW</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Bridwell KH</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Ungacta FF</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Riew KD</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Complications in the surgical
                  treatment of pediatric high-grade, isthmic dysplastic spondylolisthesis. A comparison of three
                  surgical approaches</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 1999 Aug 15;24(16):1701-11.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Complications%20in%20the%20surgical%20treatment%20of%20pediatric%20high-grade%2C%20isthmic%20dysplastic%20spondylolisthesis.%20A%20comparison%20of%20three%20surgical%20approaches&as_occt=title&as_sauthors=%20%22RW%20Molinari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Burkus JK</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Lonstein JE</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Winter RB</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Denis F</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Long-term evaluation of
                  adolescents treated operatively for spondylolisthesis. A comparison of in situ arthrodesis only with
                  in situ arthrodesis and reduction followed by immobilization in a cast</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 1992 Jun;74(5):693-704.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=573746" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Lundine KM</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Lewis SJ</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Al-Aubaidi Z</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Alman B</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Howard AW</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Patient outcomes in the
                  operative and nonoperative management of high-grade spondylolisthesis in children</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                2014 Jul-Aug;34(5):483-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20outcomes%20in%20the%20operative%20and%20nonoperative%20management%20of%20high-grade%20spondylolisthesis%20in%20children&as_occt=title&as_sauthors=%20%22KM%20Lundine%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Nielsen E</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Andras LM</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Siddiqui AA</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Michael N</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Garg S</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Paloski M</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Brighton BK</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Ferrero E</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Mazda K</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Ilharreborde B</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Skaggs DL</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">40% reoperation rate in
                  adolescents with spondylolisthesis</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2020 Oct;8(5):1059-67.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=40%25%20reoperation%20rate%20in%20adolescents%20with%20spondylolisthesis&as_occt=title&as_sauthors=%20%22E%20Nielsen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Helenius I</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Lamberg T</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Österman K</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Schlenzka D</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Yrjönen T</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Tervahartiala P</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Seitsalo S</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Poussa M</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Remes V</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Posterolateral, anterior, or
                  circumferential fusion in situ for high-grade spondylolisthesis in young patients: a long-term
                  evaluation using the Scoliosis Research Society questionnaire</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2006 Jan
                15;31(2):190-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterolateral%2C%20anterior%2C%20or%20circumferential%20fusion%20in%20situ%20for%20high-grade%20spondylolisthesis%20in%20young%20patients%3A%20a%20long-term%20evaluation%20using%20the%20Scoliosis%20Research%20Society%20questionnaire&as_occt=title&as_sauthors=%20%22I%20Helenius%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Remes V</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Lamberg T</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Tervahartiala P</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Helenius I</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Schlenzka D</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Yrjönen T</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Osterman K</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Seitsalo S</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Poussa M</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Long-term outcome after
                  posterolateral, anterior, and circumferential fusion for high-grade isthmic spondylolisthesis in
                  children and adolescents: magnetic resonance imaging findings after average of 17-year
                  follow-up</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2006 Oct 1;31(21):2491-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20outcome%20after%20posterolateral%2C%20anterior%2C%20and%20circumferential%20fusion%20for%20high-grade%20isthmic%20spondylolisthesis%20in%20children%20and%20adolescents%3A%20magnetic%20resonance%20imaging%20findings%20after%20average%20of%2017-year%20follow-up&as_occt=title&as_sauthors=%20%22V%20Remes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Fredrickson BE</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Baker D</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> McHolick WJ</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Yuan HA</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Lubicky JP</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">The natural history of
                  spondylolysis and spondylolisthesis</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1984 Jun;66(5):699-707.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=487186" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Lonstein JE</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Spondylolisthesis in children.
                  Cause, natural history, and management</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 1999 Dec 15;24(24):2640-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spondylolisthesis%20in%20children.%20Cause%2C%20natural%20history%2C%20and%20management&as_occt=title&as_sauthors=%20%22JE%20Lonstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Shufflebarger HL</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Geck MJ</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">High-grade isthmic dysplastic
                  spondylolisthesis: monosegmental surgical treatment</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2005 Mar
                15;30(6):S42-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High-grade%20isthmic%20dysplastic%20spondylolisthesis%3A%20monosegmental%20surgical%20treatment&as_occt=title&as_sauthors=%20%22HL%20Shufflebarger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Eismont FJ</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Norton RP</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Hirsch BP</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Surgical management of lumbar
                  degenerative spondylolisthesis</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2014 Apr;22(4):203-13.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20management%20of%20lumbar%20degenerative%20spondylolisthesis&as_occt=title&as_sauthors=%20%22FJ%20Eismont%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Singh M</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Balmaceno-Criss M</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Alsoof D</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Burch MB</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Sakr I</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Diebo BG</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> McDonald C</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Basques B</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Kuris EO</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Daniels AH</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Reduction of adolescent grade
                  IV L5-S1 spondylolisthesis with anterior joystick manipulation during a combined anterior and
                  posterior surgical approach: A case report</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">N Am Spine Soc J.</span> 2023 Aug
                9;16:100263.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reduction%20of%20adolescent%20grade%20IV%20L5-S1%20spondylolisthesis%20with%20anterior%20joystick%20manipulation%20during%20a%20combined%20anterior%20and%20posterior%20surgical%20approach%3A%20A%20case%20report&as_occt=title&as_sauthors=%20%22M%20Singh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Muschik M</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Zippel H</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Perka C</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Surgical management of severe
                  spondylolisthesis in children and adolescents. Anterior fusion in situ versus anterior spondylodesis
                  with posterior transpedicular instrumentation and reduction</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 1997 Sep
                1;22(17):2036-42, discussion: 2043.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20management%20of%20severe%20spondylolisthesis%20in%20children%20and%20adolescents.%20Anterior%20fusion%20in%20situ%20versus%20anterior%20spondylodesis%20with%20posterior%20transpedicular%20instrumentation%20and%20reduction&as_occt=title&as_sauthors=%20%22M%20Muschik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Fu KMG</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Polly DW Jr</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Perra JH</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Sansur CA</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Berven SH</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Broadstone PA</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Choma TJ</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Goytan MJ</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Noordeen HH</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Knapp DR Jr</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Hart RA</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Donaldson WF 3rd</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Boachie-Adjei O</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Shaffrey CI</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Morbidity and mortality in the
                  surgical treatment of six hundred five pediatric patients with isthmic or dysplastic
                  spondylolisthesis</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2011 Feb 15;36(4):308-12.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Morbidity%20and%20mortality%20in%20the%20surgical%20treatment%20of%20six%20hundred%20five%20pediatric%20patients%20with%20isthmic%20or%20dysplastic%20spondylolisthesis&as_occt=title&as_sauthors=%20%22KMG%20Fu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Douleh DG</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Albert G</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Garg S</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Surgical Techniques for
                  Adolescent Lumbar Spondylolisthesis</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">JPOSNA.</span> 2022;4(4):545.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20Techniques%20for%20Adolescent%20Lumbar%20Spondylolisthesis&as_occt=title&as_sauthors=%20%22DG%20Douleh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Crawford CH 3rd</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Larson AN</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Gates M</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Bess RS</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Guillaume TJ</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Oetgen ME</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Ledonio CG</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Sanders J</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Burton DC</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Current Evidence Regarding the
                  Treatment of Pediatric Lumbar Spondylolisthesis: A Report From the Scoliosis Research Society Evidence
                  Based Medicine Committee</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2017;5(5):284-302.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20Evidence%20Regarding%20the%20Treatment%20of%20Pediatric%20Lumbar%20Spondylolisthesis%3A%20A%20Report%20From%20the%20Scoliosis%20Research%20Society%20Evidence%20Based%20Medicine%20Committee&as_occt=title&as_sauthors=%20%22CH%20Crawford%2C%203rd%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Kreiner DS</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Baisden J</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Mazanec DJ</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Patel RD</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Bess RS</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Burton D</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Chutkan NB</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Cohen BA</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Crawford CH 3rd</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Ghiselli G</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Hanna AS</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Hwang SW</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Kilincer C</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Myers ME</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Park P</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Rosolowski KA</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Sharma AK</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Taleghani CK</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Trammell TR</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Vo AN</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Williams KD</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Guideline summary review: an
                  evidence-based clinical guideline for the diagnosis and treatment of adult isthmic
                  spondylolisthesis</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2016 Dec;16(12):1478-85.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Guideline%20summary%20review%3A%20an%20evidence-based%20clinical%20guideline%20for%20the%20diagnosis%20and%20treatment%20of%20adult%20isthmic%20spondylolisthesis&as_occt=title&as_sauthors=%20%22DS%20Kreiner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Lamberg T</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Remes V</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Helenius I</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Schlenzka D</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Seitsalo S</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Poussa M</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Uninstrumented In Situ Fusion
                  for High-Grade Childhood and Adolescent Isthmic Spondylolisthesis: Long-Term Outcome</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2007;89(3):512.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1007871" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Wu Z</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Guo D</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> You H</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Fan X</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">A comprehensive comparison of
                  posterior lumbar interbody fusion versus posterolateral fusion for the treatment of isthmic and
                  degenerative spondylolisthesis: A meta-analysis of prospective studies</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Clin Neurol
                  Neurosurg.</span> 2020 Jan;188:105594.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comprehensive%20comparison%20of%20posterior%20lumbar%20interbody%20fusion%20versus%20posterolateral%20fusion%20for%20the%20treatment%20of%20isthmic%20and%20degenerative%20spondylolisthesis%3A%20A%20meta-analysis%20of%20prospective%20studies&as_occt=title&as_sauthors=%20%22Y%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Tye EY</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Tanenbaum JE</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Alonso AS</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Xiao R</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Steinmetz MP</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Mroz TE</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Savage JW</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Circumferential fusion: a
                  comparative analysis between anterior lumbar interbody fusion with posterior pedicle screw fixation
                  and transforaminal lumbar interbody fusion for L5-S1 isthmic spondylolisthesis</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Spine J.</span> 2018
                Mar;18(3):464-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Circumferential%20fusion%3A%20a%20comparative%20analysis%20between%20anterior%20lumbar%20interbody%20fusion%20with%20posterior%20pedicle%20screw%20fixation%20and%20transforaminal%20lumbar%20interbody%20fusion%20for%20L5-S1%20isthmic%20spondylolisthesis&as_occt=title&as_sauthors=%20%22EY%20Tye%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Chandra V</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Singh RK</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Anterior lumbar inter-body
                  fusion with instrumentation compared with posterolateral fusion for low grade
                  isthmic-spondylolisthesis</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Acta Orthop Belg.</span> 2016 Mar;82(1):23-30.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20lumbar%20inter-body%20fusion%20with%20instrumentation%20compared%20with%20posterolateral%20fusion%20for%20low%20grade%20isthmic-spondylolisthesis&as_occt=title&as_sauthors=%20%22V%20Chandra%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Bivona LJ</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> France J</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Daly-Seiler CS</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Burton DC</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Dolan LA</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Seale JJ</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> de Kleuver M</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Ferrero E</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Gurd DP</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Konya D</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Lavelle WF</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Sarwahi V</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Suratwala SJ</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Yilgor C</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Li Y</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Spinal deformity surgery is
                  accompanied by serious complications: report from the Morbidity and Mortality Database of the
                  Scoliosis Research Society from 2013 to 2020</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2022
                Nov;10(6):1307-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spinal%20deformity%20surgery%20is%20accompanied%20by%20serious%20complications%3A%20report%20from%20the%20Morbidity%20and%20Mortality%20Database%20of%20the%20Scoliosis%20Research%20Society%20from%202013%20to%202020&as_occt=title&as_sauthors=%20%22LJ%20Bivona%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Bouyer B</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Bachy M</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Courvoisier A</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Dromzee E</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Mary P</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Vialle R</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">High-grade lumbosacral
                  spondylolisthesis reduction and fusion in children using transsacral rod fixation</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">Childs Nerv Syst.</span>
                2014 Mar;30(3):505-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High-grade%20lumbosacral%20spondylolisthesis%20reduction%20and%20fusion%20in%20children%20using%20transsacral%20rod%20fixation&as_occt=title&as_sauthors=%20%22B%20Bouyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Sherman FC</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Rosenthal RK</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Hall JE</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Spine fusion for spondylolysis
                  and spondylolisthesis in children</span>. <span data-id="emphasis_38" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 1979 Jan-Feb;4(1):59-66.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spine%20fusion%20for%20spondylolysis%20and%20spondylolisthesis%20in%20children&as_occt=title&as_sauthors=%20%22FC%20Sherman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Tsirikos AI</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Garrido EG</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Spondylolysis and
                  spondylolisthesis in children and adolescents</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2010
                Jun;92(6):751-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spondylolysis%20and%20spondylolisthesis%20in%20children%20and%20adolescents&as_occt=title&as_sauthors=%20%22AI%20Tsirikos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;372924e6-9552-45ce-82ec-7a4e72ee0c1f&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=372924e6-9552-45ce-82ec-7a4e72ee0c1f&type=supplement&name=12555"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D372924e6-9552-45ce-82ec-7a4e72ee0c1f%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 265</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01242</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 31, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_40"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedic Surgery, Cedars-Sinai Medical Center, Los Angeles,
                            California</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;372924e6-9552-45ce-82ec-7a4e72ee0c1f&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=372924e6-9552-45ce-82ec-7a4e72ee0c1f&type=pdf&name=JBJS.23.01242.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=372924e6-9552-45ce-82ec-7a4e72ee0c1f&type=pdf&name=JBJS.23.01242.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_46" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I258"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I258</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;372924e6-9552-45ce-82ec-7a4e72ee0c1f&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=372924e6-9552-45ce-82ec-7a4e72ee0c1f&type=zip&name=JBJS.23.01242.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Paal K. Nilssen, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8041-5860" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8041-5860</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nakul Narendran, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6356-1433" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6356-1433</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ryan A. Finkel, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kenneth D. Illingworth, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">David L. Skaggs, MD, MMM<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:David.Skaggs@cshs.org" class=""
                    style="position: unset;">David.Skaggs@cshs.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6137-5576" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6137-5576</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Cedars-Sinai Medical Center, Los Angeles,
                California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 27414.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
