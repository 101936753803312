import { mockedIssues } from "@constants/mocked-image-challenge-data";
import type { ItemData } from "schema/item";

import { useArticles } from "@features/articles/hooks/useArticles";
import useItems from "./useItems";

const SECTION_ITEM_LIMIT = 5;

export const getRecommendedItems = (items: ItemData[], tags: string[]) => {
	return items
		.filter(
			(item) =>
				!item.completedAt && item.tags?.some((tag) => tags.includes(tag.label)),
		)
		.sort((a, b) => {
			const dateA = a.publishedAt ? new Date(a.publishedAt) : new Date(0);
			const dateB = b.publishedAt ? new Date(b.publishedAt) : new Date(0);
			return dateB.getTime() - dateA.getTime();
		})
		.slice(0, SECTION_ITEM_LIMIT);
};

const getRecentItems = (items: ItemData[] = []) => {
	const recent = items
		.sort((a, b) => {
			const dateA = a.publishedAt ? new Date(a.publishedAt) : new Date(0);
			const dateB = b.publishedAt ? new Date(b.publishedAt) : new Date(0);
			return dateB.getTime() - dateA.getTime();
		})
		.slice(0, SECTION_ITEM_LIMIT);

	return recent;
};

// const constructSections = (items: ItemData[], tags: string[]) => {
// 	const recommended = getRecommendedItems(items, tags);

// 	const recent = getRecentItems(items);

// 	const popular = items
// 		.sort((a, b) => b.numAttempts - a.numAttempts)
// 		.slice(0, SECTION_ITEM_LIMIT);

// 	return [
// 		{
// 			title: "Recommended",
// 			data: [mapItemsToDisplayFormat(recommended)],
// 			rightButtonProps: {
// 				cta: "More",
// 				link: "challenges/recommended",
// 			},
// 		},
// 		{
// 			title: "Recently added",
// 			data: [mapItemsToDisplayFormat(recent)],
// 			rightButtonProps: {
// 				cta: "More",
// 				link: "challenges/recent",
// 			},
// 		},
// 		{
// 			title: "Popular",
// 			data: [mapItemsToDisplayFormat(popular)],
// 			rightButtonProps: {
// 				cta: "More",
// 				link: "challenges/popular",
// 			},
// 		},
// 	];
// };

export const mapItemsToDisplayFormat = (items: ItemData[]) => {
	return items.map((item) => ({
		id: item.id,
		title: item.data.stem,
		type: "challenge",
		tags: item.tags,
		accessType: item.accessType,
		updatedAt: item.updatedAt,
		imageUri: item.media?.[0]?.url || "https://placeholder.com/image.jpg",
		numResponses: item.numAttempts,
		description: `#${item.id}`,
		completed: item.completedAt != null,
	}));
};

// const getFeaturedAndNextChallenge = (
// 	items: ItemData[],
// 	futureItems?: ItemData[],
// ) => {
// 	const defaultTimezone = "UTC";

// 	const sortedItems = items.sort((a, b) => {
// 		const dateA = a.publishedAt
// 			? DateTime.fromISO(a.publishedAt, { zone: a.timezone || defaultTimezone })
// 			: DateTime.fromMillis(0);
// 		const dateB = b.publishedAt
// 			? DateTime.fromISO(b.publishedAt, { zone: b.timezone || defaultTimezone })
// 			: DateTime.fromMillis(0);
// 		return dateB.toMillis() - dateA.toMillis();
// 	});

// 	const currentChallenge = sortedItems?.find((item) => {
// 		if (!item.publishedAt) {
// 			return false;
// 		}
// 		const itemDate = DateTime.fromISO(item.publishedAt, {
// 			zone: item.timezone || defaultTimezone,
// 		});
// 		const now = DateTime.now().setZone(item.timezone || defaultTimezone);
// 		return itemDate <= now;
// 	});

// 	const sortedFutureItems = futureItems?.sort((a, b) => {
// 		const dateA = a.publishedAt
// 			? DateTime.fromISO(a.publishedAt, { zone: a.timezone || defaultTimezone })
// 			: DateTime.fromMillis(0);
// 		const dateB = b.publishedAt
// 			? DateTime.fromISO(b.publishedAt, { zone: b.timezone || defaultTimezone })
// 			: DateTime.fromMillis(0);
// 		return dateA.toMillis() - dateB.toMillis();
// 	});

// 	const nextChallenge = sortedFutureItems?.find((item) => {
// 		if (!item.publishedAt) {
// 			return false;
// 		}
// 		const itemDate = DateTime.fromISO(item.publishedAt, {
// 			zone: item.timezone || defaultTimezone,
// 		});
// 		const now = DateTime.now().setZone(item.timezone || defaultTimezone);
// 		return itemDate > now;
// 	});

// 	return {
// 		featured:
// 			currentChallenge && currentChallenge.completedAt == null
// 				? {
// 						sectionTitle: "Featured Challenge",
// 						id: currentChallenge.id,
// 						title: currentChallenge.data.stem,
// 						description: `#${currentChallenge.id}`,
// 						publishedAt: currentChallenge.publishedAt,
// 						challengeNumber: currentChallenge.id,
// 						numResponses: currentChallenge.numAttempts,
// 						imageUri: currentChallenge.media?.[0]?.url,
// 						buttonProps: {
// 							cta: "Start",
// 							icon: "play-outline" as const,
// 							onPress: () => {
// 								//TODO: Implement this
// 							},
// 						},
// 					}
// 				: undefined,
// 		next:
// 			nextChallenge && currentChallenge && currentChallenge.completedAt != null
// 				? {
// 						sectionTitle: "Next Challenge",
// 						id: currentChallenge.id,
// 						title: currentChallenge.data.stem,
// 						description: `#${currentChallenge.id}`,
// 						numResponses: currentChallenge.numAttempts,
// 						publishedAt: nextChallenge.publishedAt,
// 						challengeNumber: currentChallenge.id,
// 						imageUri: currentChallenge.media?.[0]?.url,
// 						buttonProps: {
// 							cta: "Start",
// 							icon: "play-outline" as const,
// 							onPress: () => {
// 								//TODO: Implement this
// 							},
// 						},
// 					}
// 				: undefined,
// 	};
// };

export const useImageChallengeHome = () => {
	const { filteredItems, isLoading } = useItems();
	const { randomizedRecommendedArticles } = useArticles();

	const jbjsSectionList = [
		{
			title: "Recommended content",
			rightButtonProps: {
				cta: "More content",
				link: "/collections/issues?recommended=true",
			},
			data: [randomizedRecommendedArticles],
		},
		{
			title: "Latest issue",
			rightButtonProps: {
				cta: "Full issue",
				link: `/collections/issue/${mockedIssues[0].id}`,
			},
			data: [mockedIssues[0].articles],
		},

		{
			title: "Featured challenges",
			rightButtonProps: {
				cta: "More",
				link: "challenges/recent",
			},
			data: [mapItemsToDisplayFormat(getRecentItems(filteredItems))],
		},
	];

	const imageChallengeHomeSectionList = jbjsSectionList;

	const handleQuickActionPress = (_type?: string) => {
		//TODO: Implement quick action press
	};

	const quickActionsChips = [
		{
			label: "What's new in knee?",
			icon: "bullhorn-outline",
			tooltip: "What’s new in knee?",
		},
		{
			label: "Complete challenge",
			icon: "arm-flex-outline",
			tooltip: "Complete challenge",
		},
		{
			label: "Complete challenge",
			icon: "arm-flex-outline",
			tooltip: "Complete challenge",
		},
	];

	// const { featured: featuredChallenge, next: nextChallenge } =
	// 	filteredItems && isNejm
	// 		? getFeaturedAndNextChallenge(filteredItems, futureItems)
	// 		: { featured: undefined, next: undefined };

	return {
		imageChallengeHomeSectionList,
		handleQuickActionPress,
		quickActionsChips,
		isLoading,
		flattenItems: filteredItems,
		featuredChallenge: undefined,
		nextChallenge: undefined,
	};
};
