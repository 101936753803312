/* -----------------Globals--------------- */
import { ActivityIndicator } from "react-native-paper";

/* -----------------Child components--------------- */
import Box from "../Box";
import EmptyView from "../EmptyView";
import { ChatList } from "./ChatList";

/* -----------------Types--------------- */
import type { MessagesViewProps } from "./types/props";

import { defaultAiAssistantInfo } from "@memorang/helpers";
/* -----------------Helpers & Hooks--------------- */
import { ailaEmptyStateImg } from "./constants";

export const MessagesView = ({
	messages,
	messageActions,
	isRecommendedQuestionsFetching,
	loading,
	streaming,
	recommendedQueries = [],
	handleSelectQuery,
	loader,
	context,
	handleNavigateToReference,
	emptyStateProps,
	showReference,
	showSummarizeResponse,
	referenceThumbnails,
	assistantInfo = defaultAiAssistantInfo,
	isThreadLoading,
	isItemAnswered = true,
}: MessagesViewProps) => {
	const showRecommendedChipsLoading =
		!(loading || streaming) && isRecommendedQuestionsFetching;

	if (isThreadLoading) {
		return (
			<Box flex={1} height="100%" justifyContent="center" alignItems="center">
				<ActivityIndicator size="large" />
			</Box>
		);
	}

	const emptyViewSubtitle = isItemAnswered
		? `Ask anything related to ${context}`
		: `${assistantInfo.alias} will activate after you answer the question`;

	return (
		<Box gap={16} height="100%">
			{messages?.length && isItemAnswered ? (
				<ChatList
					assistantInfo={assistantInfo}
					messages={messages}
					loader={loader}
					showRecommendedChipsLoading={showRecommendedChipsLoading}
					recommendedQueries={recommendedQueries}
					handleSelectQuery={handleSelectQuery}
					handleNavigateToReference={handleNavigateToReference}
					showReference={showReference}
					showSummarizeResponse={showSummarizeResponse}
					messageActions={messageActions}
					referenceThumbnails={referenceThumbnails}
				/>
			) : (
				<EmptyView
					title={`${assistantInfo.alias} is your Ai-Powered Learning Assistant`}
					subtitle={emptyViewSubtitle}
					image={{ uri: ailaEmptyStateImg }}
					{...emptyStateProps}
				/>
			)}
		</Box>
	);
};
