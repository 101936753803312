import { useUser } from "@clerk/clerk-expo";
import { useToast } from "@contexts/ToastContext";
import { getSelectCountryOnboarding } from "@helpers/find-onboarding";
import { useAppStore } from "@hooks/useAppStore";
import { useCurrentCountryStore } from "@hooks/useCurrentCountryStore";
import { updateUserInfo } from "@memorang/api-client";
import { router } from "expo-router";
import { useMemo, useState } from "react";

export const useCountryOnboarding = () => {
	const [searchQuery, setSearchQuery] = useState<string>("");

	const onboarding = useAppStore((store) => store.onboarding);

	const { showToast } = useToast();

	const { user } = useUser();
	const email = user?.emailAddresses[0].emailAddress;

	const onboardingCountryStep = getSelectCountryOnboarding(onboarding!);

	const countries = onboardingCountryStep?.data!;

	const appId = useAppStore((store) => store.app?.id);

	const sectionedCountryListData = useMemo(() => {
		if (!countries) {
			return [];
		}

		const sectionData = countries.reduce(
			(acc, country) => {
				const firstLetter = country.title.charAt(0).toUpperCase();
				const existingSection = acc.find(
					(section) => section.title === firstLetter,
				);

				const finalCountryTitle = `${country.image}  ${country.title}`;

				if (existingSection) {
					existingSection.data.push({
						name: finalCountryTitle,
						id: country.type,
					});
				} else {
					acc.push({
						title: firstLetter,
						data: [{ name: finalCountryTitle, id: country.type }],
					});
				}

				return acc;
			},
			[] as Array<{ title: string; data: Array<{ name: string; id: string }> }>,
		);

		return sectionData
			.map((section) => ({
				...section,
				data: section.data.sort((a, b) => a.name.localeCompare(b.name)),
			}))
			.sort((a, b) => a.title.localeCompare(b.title));
	}, [countries]);

	const { country, setCountry } = useCurrentCountryStore();

	const handleSearchQuery = (query: string) => {
		setSearchQuery(query);
	};

	const handleClickContinue = (itemId: string) => {
		const selectedCountry = countries?.find(
			(country) => country.type === itemId,
		);
		showToast({
			message: `Country switched from ${country?.title} to ${selectedCountry?.title}`,
			duration: 2000,
		});
		setCountry(selectedCountry!);
		updateUserInfo(email!, appId!, {
			country: selectedCountry?.type,
		});
		router.back();
	};

	return {
		handleClickContinue,
		searchQuery,
		handleSearchQuery,
		countries,
		sectionedCountryListData,
		initSelectedItemId: country?.type,
	};
};
