/* -----------------Globals--------------- */
import type React from "react";
import { useEffect, useRef, useState } from "react";

import { defaultAiAssistantInfo } from "@memorang/helpers";
/* -----------------Helpers & Hooks--------------- */
import {
	SearchContextProvider,
	useSearchContext,
} from "./contexts/SearchContext";
import { useSearchView } from "./hooks/useSearchView";

import type { AiAssistantInfo } from "@memorang/ui";
/* -----------------Types--------------- */
import type { TextInput } from "react-native";
import type { FeedbackParams } from "../feedback";
import type { HandleTracking } from "../types";
import type { EpubParams, VectorStoreParams } from "./types";

/* -----------------Child components--------------- */
import { Box, ChatUI } from "@memorang/ui";

type Props = {
	context: string;
	handleTracking: HandleTracking;
	vectorStoreParams: VectorStoreParams;
	userId: string;
	handleClose?: () => void;
	defaultQuery?: string;
	feedbackDialogParams?: FeedbackParams;
	epubParams?: EpubParams;
	handleNavigateToReference?: (url: string) => void;
	bookIds?: string[];
	filterSourceIds?: number[];
	searchContextMenu?: React.ReactNode;
	searchContext?: string;
	referenceThumbnails?: Record<string, string>;
	assistantInfo?: AiAssistantInfo;
};

type InnerAiLAStaticProps = Props & {
	constructEpubParams: EpubParams | undefined;
	setConstructEpubParams: React.Dispatch<
		React.SetStateAction<EpubParams | undefined>
	>;
};

// TODO: Change component name
export const AiLAStatic = (props: Props) => {
	const [constructEpubParams, setConstructEpubParams] = useState(
		props.epubParams,
	);
	return (
		<SearchContextProvider
			defaultQuery={props.defaultQuery}
			userId={props.userId}
			vectorStoreParams={props.vectorStoreParams}
			epubParams={constructEpubParams}
			context={props.context}
			sessionId={props.feedbackDialogParams?.sessionId!}
			assistantInfo={props.assistantInfo ?? defaultAiAssistantInfo}
			activityType="reader"
		>
			<InnerAiLAStatic
				{...props}
				constructEpubParams={constructEpubParams}
				setConstructEpubParams={setConstructEpubParams}
			/>
		</SearchContextProvider>
	);
};

const InnerAiLAStatic = ({
	feedbackDialogParams,
	handleTracking,
	epubParams,
	handleNavigateToReference,
	filterSourceIds,
	searchContext,
	searchContextMenu,
	setConstructEpubParams,
	referenceThumbnails,
}: InnerAiLAStaticProps) => {
	const inputRef = useRef<TextInput | null>(null);
	const [shouldFocusInput, setShouldFocusInput] = useState(false);

	useEffect(() => {
		if (shouldFocusInput) {
			inputRef?.current?.focus();
			setShouldFocusInput(false);
		}
	}, [shouldFocusInput]);
	const emptyStateOptions = epubParams
		? [
				{
					label: "Summarize this page",
					action: () => interceptSearchQuery("Summarize this page", true),
				},
				// TODO: Uncomment when we have the right implementation
				// {
				//   label: `Ask anything about this page`,
				//   action: () => {
				//     setShouldFocusInput(true);
				//   },
				// },
				// {
				//   label: 'Recommend some questions to ask',
				//   action: () => interceptSearchQuery(`Recommend some questions to ask`),
				// },
				// {
				//   label: `Ask anything across all books`,
				//   action: () =>
				//     interceptSearchQuery(`Ask anything across all books`, false, 'global'),
				// },
				// {
				//   label: `Ask anything about ${context}`,
				//   action: () =>
				//     interceptSearchQuery(`Ask anything about ${context}`, false, 'aila'),
				// },
			]
		: [];

	const {
		loading,
		streaming,
		context,
		showReference,
		searchQuery,
		messages,
		abortSearch,
		assistantInfo,
		isThreadLoading,
	} = useSearchContext();

	const {
		handleSelectQuery,
		localRecommendedQueries,
		messageActions,
		messageFeedbackComponents,
		isRecommendedQuestionsFetching,
		interceptSearchQuery,
		handleResetChatState,
	} = useSearchView({
		epubParams,
		feedbackDialogParams,
		handleTracking,
		setConstructEpubParams,
		filterSourceIds,
		searchContext,
		handleSelectSummarizeChip: () =>
			interceptSearchQuery("Summarize this page", true),
	});

	return (
		<Box position="relative" height="100%">
			<ChatUI>
				<ChatUI.ScrollView>
					<ChatUI.MessageList
						assistantInfo={assistantInfo}
						messages={messages}
						isRecommendedQuestionsFetching={isRecommendedQuestionsFetching}
						loading={loading}
						streaming={streaming}
						recommendedQueries={localRecommendedQueries}
						handleSelectQuery={handleSelectQuery}
						context={context}
						showSummarizeResponse={!(loading || streaming)}
						handleNavigateToReference={handleNavigateToReference}
						emptyStateProps={{
							emptyStateOptions,
						}}
						showReference={showReference}
						messageActions={messageActions}
						referenceThumbnails={referenceThumbnails}
						isThreadLoading={isThreadLoading}
					/>
				</ChatUI.ScrollView>
				<ChatUI.BottomAppBar
					showConversationMode
					showInlineButtons
					searchQuery={searchQuery.value}
					interceptSearchQuery={interceptSearchQuery}
					searchContext={context}
					searchContextMenu={searchContextMenu}
					handleReset={handleResetChatState}
					disableSearch={loading || streaming}
					handleAbortSearch={abortSearch}
					ref={inputRef}
				/>
			</ChatUI>
			{messageFeedbackComponents}
		</Box>
	);
};
