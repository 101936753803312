/* -----------------UI--------------- */
import { Pressable } from "react-native";

/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Text } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";

export type BreadcrumbType = {
	label: string;
	action?: () => void;
};

type Props = {
	breadcrumbs: BreadcrumbType[];
	textVariant?: React.ComponentProps<typeof Text>["variant"];
};
export const CustomBreadcrumb = (props: Props) => {
	const { breadcrumbs, textVariant = "titleLarge" } = props;
	const theme = useAppTheme();

	return (
		<Box flexDirection="row" alignItems="center" flexWrap="wrap">
			{breadcrumbs.map(({ label, action }, index) => (
				<Pressable
					key={label}
					onPress={() => {
						if (action) {
							action();
						}
					}}
				>
					<Box flexDirection="row" alignItems="center">
						<Text
							variant={textVariant}
							style={{
								fontFamily: undefined,
								color:
									index !== breadcrumbs.length - 1
										? theme.colors.onPrimaryContainer
										: theme.colors.onSurfaceVariant,
							}}
						>
							{label}
						</Text>

						{index !== breadcrumbs.length - 1 && (
							<Icon
								name="chevron-right"
								size={20}
								style={{
									paddingHorizontal: 8,
								}}
								color={theme.colors.onSurfaceVariant}
							/>
						)}
					</Box>
				</Pressable>
			))}
		</Box>
	);
};
