import { useAppTheme } from "@hooks/useAppTheme";
import { Button, type ButtonProps } from "react-native-paper";

type Props = ButtonProps;

const CustomButton = ({ style, ...props }: Props) => {
	const theme = useAppTheme();
	return (
		<Button
			{...props}
			style={[
				theme.overrides?.button && {
					borderRadius: theme.overrides.button.borderRadius,
				},
				style,
			]}
		/>
	);
};

export default CustomButton;
