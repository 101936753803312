import { newEvents } from "@constants/tracking";
import { Box } from "@memorang/ui";
import { trackCustomEvent } from "analytics";
import { useEffect } from "react";
import { List, RadioButton } from "react-native-paper";
import FilterBottomSheetWrapper from "./FilterBottomSheetWrapper";

type SortItem = {
	label: string;
	icon: string;
	type: string;
};

type Props = {
	showModal: boolean;
	handleSheetClose: () => void;
	sortItems: SortItem[];
	currentSortType: string;
	handleSelectSortItem: (type: string) => void;
};

const SortModalContainer = ({
	showModal,
	handleSheetClose,
	sortItems,
	currentSortType,
	handleSelectSortItem,
}: Props) => {
	const title = "Sort by";
	useEffect(() => {
		if (showModal) {
			trackCustomEvent({
				eventName: newEvents.bottomSheetOpened,
				type: "sort",
				source: "challenges",
			});
		}
	}, [showModal]);
	return (
		<FilterBottomSheetWrapper
			showModal={showModal}
			title={title}
			snapPoints={["55%", "80%"]}
			handleSheetClose={handleSheetClose}
		>
			<Box paddingBottom={24}>
				{sortItems.map((item) => {
					const isSelected = item.type === currentSortType;
					return (
						<List.Item
							key={item.label}
							title={item.label}
							left={(props) => <List.Icon {...props} icon={item.icon} />}
							onPress={() => {
								handleSelectSortItem(item.type);
							}}
							right={(props) => (
								<RadioButton.Android
									{...props}
									value={item.type}
									onPress={() => {
										handleSelectSortItem(item.type);
									}}
									status={isSelected ? "checked" : "unchecked"}
								/>
							)}
						/>
					);
				})}
			</Box>
		</FilterBottomSheetWrapper>
	);
};

export default SortModalContainer;
