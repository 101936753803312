/* ----------------- Types --------------- */

import { useAilaStore } from "@features/session";
import { Box, CustomListSubheader, ScrollContainer } from "@memorang/ui";
import AssistantFab from "components/AssistantFab";
import { useRouter } from "expo-router";
import { issues } from "../constants/issues";
import type { Article } from "../types";
/* ----------------- Components --------------- */
import { SavedArticlesList } from "./SavedArticlesList";

interface Props {
	savedArticles: Article[];
	handleSelectArticle: (id: number) => void;
	handleNavigateToArticle: (id: number) => void;
	selectedArticleIds: number[];
}
const SavedArticlesView = ({
	savedArticles,
	handleSelectArticle,
	handleNavigateToArticle,
	selectedArticleIds,
}: Props) => {
	const router = useRouter();
	const updateUserPrompt = useAilaStore((state) => state.updateUserPrompt);
	const updateItemId = useAilaStore((state) => state.updateItemId);
	const updateIsItemAnswered = useAilaStore(
		(state) => state.updateIsItemAnswered,
	);
	const updateOriginalQuestionData = useAilaStore(
		(state) => state.updateOriginalQuestionData,
	);

	const handleNavigateToAssistant = () => {
		const finalSelectedArticleIds =
			selectedArticleIds.length === 0
				? savedArticles.map((article) => article.id)
				: selectedArticleIds;

		const sortedSelectedArticleIds = finalSelectedArticleIds.sort(
			(a, b) => Number(a) - Number(b),
		);
		const selectedArticles = issues
			.flatMap((issue) => issue.articles)
			.filter((article) =>
				finalSelectedArticleIds.includes(article.id as never),
			);

		const userPrompt = selectedArticles.reduce((acc, article, index) => {
			return `${acc}\nArticle ${index + 1} title: ${article.title}\nArticle ${index + 1} type: ${article.type}`;
		}, "");

		updateUserPrompt(userPrompt);
		updateItemId(JSON.stringify(sortedSelectedArticleIds));
		updateIsItemAnswered(true);
		updateOriginalQuestionData(undefined);
		router.push({
			pathname: "/aila/[itemId]",
			params: {
				itemId: sortedSelectedArticleIds.join("_"),
			},
		});
	};

	return (
		<>
			<ScrollContainer>
				<Box>
					<CustomListSubheader>Saved Articles</CustomListSubheader>
					<SavedArticlesList
						savedArticles={savedArticles}
						handleSelectArticle={handleSelectArticle}
						handleNavigateToArticle={handleNavigateToArticle}
						selectedArticleIds={selectedArticleIds}
					/>
				</Box>
			</ScrollContainer>
			<AssistantFab
				handleAssistantFabPress={handleNavigateToAssistant}
				customBottomPosition={2}
				disabled={selectedArticleIds.length === 0}
			/>
		</>
	);
};

export default SavedArticlesView;
