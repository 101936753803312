import { DialogWrapper, MarkdownRenderer } from "@memorang/ui";
/* ----------------- UI --------------- */
import { Dialog } from "react-native-paper";
import CustomKeyboardAvoidingView from "../CustomKeyboardAvoidingView";
import DeleteFeedbackForm from "../DeleteFeedbackForm";
import DeleteOptions from "../DeleteOptions";

import ToastProvider from "@contexts/ToastContext";
/* ----------------- Features --------------- */
import { dialogTexts } from "@features/feedback";

type Props = {
	visible: boolean;
	handleDismiss: () => void;
	handleSetFeedback: (feedback: string) => void;
	feedback: string;
	handleShowDeleteConfirmation: () => void;
	checkboxStates?: Record<string, "checked" | "unchecked">;
	handleSelectOption: (index: string) => void;
	selectedReasons?: string;
};
const DeleteFeedbackDialog = (props: Props) => {
	const {
		visible,
		handleDismiss,
		handleSetFeedback,
		feedback,
		handleShowDeleteConfirmation,
		checkboxStates,
		handleSelectOption,
		selectedReasons,
	} = props;

	const {
		deletefeedback: { title, body, options },
	} = dialogTexts;

	const Body = () => <MarkdownRenderer textAlign="center" text={body} />;

	return (
		<DialogWrapper
			maxWidth={"xs"}
			visible={visible}
			handleClose={handleDismiss}
		>
			<Dialog.Icon icon="message-alert-outline" />
			<Dialog.Title
				style={{
					textAlign: "center",
				}}
			>
				{title}
			</Dialog.Title>
			<Dialog.Content>
				<CustomKeyboardAvoidingView scrollPosition={300}>
					<Body />
					<DeleteOptions
						options={options}
						handleSelectOption={handleSelectOption}
						checkboxStates={checkboxStates}
					/>

					<ToastProvider>
						<DeleteFeedbackForm
							handleDismiss={handleDismiss}
							selectedReasons={selectedReasons}
							handleSetFeedback={handleSetFeedback}
							feedback={feedback}
							handleShowDeleteConfirmation={handleShowDeleteConfirmation}
						/>
					</ToastProvider>
				</CustomKeyboardAvoidingView>
			</Dialog.Content>
		</DialogWrapper>
	);
};

export default DeleteFeedbackDialog;
