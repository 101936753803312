/* -----------------Globals--------------- */
import { Platform } from "react-native";

/* -----------------Third Party--------------- */
import { openComposer } from "react-native-email-link";

const composeMessage = (
	subject: string,
	body: string,
	supportEmail: string,
) => {
	if (Platform.OS === "web") {
		const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(
			subject,
		)}&body=${encodeURIComponent(body)}`;
		window.location.href = mailtoLink;
	} else {
		const isAndroid = Platform.OS === "android";
		openComposer({
			to: isAndroid ? supportEmail : encodeURIComponent(supportEmail),
			subject: isAndroid ? subject : encodeURIComponent(subject),
			body: isAndroid ? body : encodeURIComponent(body),
		});
	}
};

export { composeMessage };
