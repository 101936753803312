import type {
	BottomSheetFooterProps,
	BottomSheetModal,
} from "@gorhom/bottom-sheet";
/* -----------------Globals--------------- */
import { useEffect, useRef } from "react";

import { useAppTheme } from "@hooks/useAppTheme";
import { Box } from "@memorang/ui";
import PostAnswerWrapper from "@memorang/ui/src/components/PostAnswerWrapper";
import CustomButton from "components/CustomButton";
import { Pressable } from "react-native";
import { Divider, Text } from "react-native-paper";

type Props = {
	showModal: boolean;
	title: string;
	handleClearAll?: () => void;
	children: React.ReactNode;
	snapPoints?: string[];
	handleSheetClose: () => void;
	paddingBottom?: number;
	renderFooter?: (p: BottomSheetFooterProps) => React.ReactNode;
};

const FilterBottomSheetWrapper = ({
	showModal,
	title,
	handleClearAll,
	children,
	snapPoints = ["50%", "90%"],
	handleSheetClose,
	paddingBottom = 0,
	renderFooter,
}: Props) => {
	const bottomSheetRef = useRef<BottomSheetModal>(null);

	const theme = useAppTheme();

	const handleOpenInitialPosition = () => {
		if (bottomSheetRef.current) {
			bottomSheetRef.current.present();
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (showModal) {
			handleOpenInitialPosition();
		} else if (bottomSheetRef.current) {
			bottomSheetRef.current.dismiss();
		}
	}, [showModal]);

	const handleSetSnapIndex = (index: number) => {
		if (index === -1) {
			handleSheetClose();
		}
	};

	const CustomHandle = () => {
		return (
			<Pressable>
				<Box
					backgroundColor={theme.colors.background}
					style={{
						borderTopLeftRadius: 24,
						borderTopRightRadius: 24,
					}}
				>
					<Box
						width="100%"
						flexDirection="row"
						justifyContent="center"
						paddingTop={8}
						paddingBottom={16}
					>
						<Box
							width={80}
							height={6}
							backgroundColor={theme.colors.divider}
							borderRadius={4}
						/>
					</Box>
					<Box
						paddingHorizontal={16}
						paddingBottom={16}
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Text variant="titleLarge">{title}</Text>
						{handleClearAll && (
							<CustomButton onPress={handleClearAll}>Clear all</CustomButton>
						)}
					</Box>
					<Divider />
				</Box>
			</Pressable>
		);
	};
	return (
		<PostAnswerWrapper
			showModal={showModal}
			enablePanDownToClose
			snapPoints={snapPoints}
			handleSetSnapIndex={handleSetSnapIndex}
			snapIndex={0}
			ref={bottomSheetRef}
			flexGrow
			renderFooter={renderFooter}
			padding={0}
			paddingBottom={paddingBottom}
			animateOnMount
			customHandle={<CustomHandle />}
		>
			{children}
		</PostAnswerWrapper>
	);
};

export default FilterBottomSheetWrapper;
