export const getHeight = (size: string) => {
	switch (size) {
		case "small":
			return 40;
		case "medium":
			return 56;
		case "large":
			return 72;
		default:
			return 56;
	}
};
