/* -----------------Globals--------------- */
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
/* -----------------Helpers & Hooks--------------- */
import { groupExamsByCategory } from "../helpers";

import { zustandStorage } from "@helpers/storage";
/* -----------------Types--------------- */
import type { ExamDetails } from "../types";

type Section = {
	title: string;
	data: ExamDetails[];
	icon: string;
};

interface ExamState {
	exams: ExamDetails[];
	groupedExams: Section[];
	setExams: (data: ExamDetails[]) => void;
}

export const useExams = create<ExamState>()(
	devtools(
		persist(
			(set) => ({
				exams: [],
				groupedExams: [],
				setExams: (exams: ExamDetails[]) => {
					const groupedExams = groupExamsByCategory(exams);

					set({ exams, groupedExams });
				},
			}),
			{
				name: "exam-storage",
				storage: createJSONStorage(() => zustandStorage),
			},
		),
	),
);
